import { Component, OnInit,ViewChild ,HostListener , ChangeDetectorRef , NgZone  } from '@angular/core';
import { VenteService } from 'src/app/demo/service/vente.service';
import { ArticleService } from 'src/app/demo/service/article.service';
import { GenererVenteService } from 'src/app/demo/service/generer-vente.service';
import { DetailsTicketComponent } from '../details-ticket/details-ticket.component';
import { ShareticketService } from 'src/app/demo/service/shareticket.service';
import { ShareticketsService } from 'src/app/demo/service/sharetickets.service';
import { Router } from '@angular/router';
import { ConfirmationService , MessageService  } from 'primeng/api';
import {FactureTicketPDFComponent} from  '../facture-ticket-pdf/facture-ticket-pdf.component';
// import { DialogService } from 'primeng/dynamicdialog';
// import { CustomDialogComponent } from '../../pages/costum/custom-dialog/custom-dialog.component';

interface Vente {

  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number;
  timbre: number;
  prix_net: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  // code_facture_client: string;
  numeroTicket: string;

  date_vente: string;
  client : string ;
  store : string ;
  idclient
  idstore 
  etablissement : string ;
  idetab
  selected?: boolean;
  product?: string ;
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 
}
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-generer-vente',
  templateUrl: './generer-vente.component.html',
  styleUrls: ['./generer-vente.component.scss']
})
export class GenererVenteComponent implements OnInit {
@ViewChild(DetailsTicketComponent) detailDialog: DetailsTicketComponent;
@ViewChild(FactureTicketPDFComponent) factureDialog: FactureTicketPDFComponent; 
ventes: Vente[] = [];
filteredVentes: Vente[] = [];
selectedVentes: Vente[] = [];
selectAllCheckbox: boolean = false;
TotalNet : number = 0;
Etablissements
Depots
Clients
selectedEtablissement
selectedStore
selectedClient
filtredClient
filtredStore
filtredEtablissement
selectedArticle
articles
filteredArticle
pageMinimized = false;
loading = false;
totalRecords: any;
page=1
size=5
TotalNetselected : number = 0;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.pageMinimized = window.innerWidth <= 768; // Adjust the breakpoint as needed
    }

    isPageMinimized() {
      return this.pageMinimized;
    }


    constructor(private zone: NgZone ,private cdr: ChangeDetectorRef ,  private messageService: MessageService,private venteService:VenteService,private confirmationService: ConfirmationService,private articleService:ArticleService , private GenerVente :GenererVenteService ,private router: Router , private sharedService: ShareticketService ,private sharedServices: ShareticketsService) { }

    changeselect(vente : Vente) {
        if (vente.selected === true) {
          this.TotalNetselected += vente.prix_net
     
        }else{
          this.TotalNetselected -= vente.prix_net
        }
       // this.TotalNetselected = Math.abs(this.TotalNetselected)
    }
    isselected(): boolean  {
      return this.selectedVentes.filter(v => v.selected === true).length > 0;
    }
 
    onSelectAllCheckboxChange() {
        this.filteredVentes.forEach((vente) => (vente.selected = this.selectAllCheckbox));
        this.selectAllCheckbox ? this.TotalNetselected = -this.TotalNet : this.TotalNetselected = 0
    }

    async ngOnInit() {
      await this.getEtablissement()
      this.getClients()
      this.getArticles()
    
      console.log(this.ventes);
      this.sharedService.currentVenteData.subscribe((vente) => {
        if (this.detailDialog) {
          this.detailDialog.setData(vente);
        }
      });
    }

    filterVentesByClientAndStore(clientName: string, storeName: string): void {
        console.log('Filtering by client:', clientName);
        console.log('Filtering by store:', storeName);
        if (this.selectedClient && this.selectedStore) {
        this.filteredVentes = this.ventes.filter(
          (vente) => vente.client === clientName && vente.store === storeName
        );
        }else {
        
        }
        console.log('Filtered Ventes:', this.filteredVentes);
      }
      onCellClick(rowIndex: number) {
        const val = this.filteredVentes[rowIndex] ;
        this.sharedService.setVenteData(val);
        this.detailDialog.visible = true ;
    }
//---------------------Vente API CONSUM------------------//
    onPageChangePaginator(event: any) {
      this.page = event.page + 1; // Adjust for 0-based index
      this.size = event.rows;
      this.filterVentes();
    }

    async getVentes(clientName: string, storeName: string) {
      this.loading = true;
      this.filteredVentes = [];
      this.GenerVente.getVentes(clientName, storeName,this.page,this.size,1).subscribe((response) => {
      console.log('data',response)
      this.totalRecords = response.meta.pagination.total
        this.filteredVentes = response.data.map((venteData: any) => {
          const vente: Vente = {
            id: venteData.id,
            pourcentage_remise: venteData.attributes.pourcentage_remise,
            remise: venteData.attributes.remise,
            ht: venteData.attributes.ht,
            taxes: venteData.attributes.taxes,
            ttc: venteData.attributes.ttc,
            total_fodec: venteData.attributes.total_fodec,
            timbre: venteData.attributes.timbre,
            prix_net: venteData.attributes.prix_net,
            uuid: venteData.attributes.uuid,
            createdAt: venteData.attributes.createdAt,
            updatedAt: venteData.attributes.updatedAt,
            // code_facture_client: venteData.attributes.numeroTicket,
            numeroTicket: venteData.attributes.numeroTicket,

            date_vente: venteData.attributes.date_vente,
            client: venteData.attributes.client.data.attributes.name,
            idclient : venteData.attributes.client.data.id ,
            etablissement: venteData.attributes.etablissement.data.attributes.name,
            store: venteData.attributes.store.data.attributes.name,
            idstore : venteData.attributes.store.data.id,
            idetab :venteData.attributes.etablissement.data.id,
            // product : venteData.attributes.products.data.attributes.code_article_generique,
            // designation :  venteData.attributes.products.data.attributes.designation,
            clientcode :  venteData.attributes.client.data.attributes.code,
            clientadress: venteData.attributes.client.data.attributes.adresse ,
            clienttelephone :  venteData.attributes.client.data.attributes.telephone,
          };
          if (venteData.attributes.products && venteData.attributes.products.data.length > 0) {
            const productAttributes = venteData.attributes.products.data[0].attributes;
            vente.product = productAttributes.code_article_generique;
            vente.designation = productAttributes.designation;
          } else {
            vente.product = null;
            vente.designation = null;
          }
          console.log('dqzdqzdzdzqdqzdqzd',vente.designation)
          console.log('dqzdqz11111111111',vente.product)
          this.filteredVentes.push(vente);
          this.TotalNet += parseFloat(vente.prix_net.toFixed(3)); 
          console.log('le total est ' , this.TotalNet)
          return vente;

        });
      });

      this.loading = false;
    }

    async filterVentes() {
    
      // Check if both selectedClient and selectedStore are not empty
      if (this.selectedClient && this.selectedStore) {
        console.log('selectedclient' , this.selectedClient)
        this.fieldsEmpty = false;
        this.TotalNet = 0 ;
        this.TotalNetselected = 0 ;
        await this.getVentes(this.selectedClient.name, this.selectedStore.name);
        
      } else {
        this.fieldsEmpty = true;
      }
    }

    resetFilters(): void {
      this.selectedEtablissement = null;
      this.selectedStore = null;
      this.selectedClient = null;
      this.selectedArticle = null;
      this.filteredArticle = [];
      this.filteredVentes = []; // Clear the filtered data
    }
    fieldsEmpty: boolean = false;
    @ViewChild('confirmationPopup') confirmationPopup: any;


    //----------------------------------------------//
   filterClient(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Clients as any[]).length; i++) {
        let country = (this.Clients as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filtredClient = filtered;
    }
    filterArticle(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;

      for (let i = 0; i < (this.articles as any[]).length; i++) {
          let country = (this.articles as any[])[i];
          if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }

      this.filteredArticle = filtered;
    }
    private showMessage(summary: string, severity: string) {
      this.messageService.add({
        severity: severity,
        summary: summary,
      });
    }
    async showConfirmation1() {
      this.selectedVentes = this.filteredVentes.filter((vente) => vente.selected);
      this.confirmationService.confirm({
        message: 'Êtes-vous sûr de vouloir continuer?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Facture finit',
        rejectLabel: 'Anuller', // New button label
        accept: async () => {
          if (this.selectedVentes.length > 0) {
            await this.sharedServices.setSelectedVentes(this.selectedVentes); 
            await this.factureDialog.showDialog(true, this.selectedClient, this.selectedStore, this.selectedEtablissement);
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Accepté' });
            this.confirmationService.close();
          } else {
            await this.sharedServices.setSelectedVentes([]);
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Sélectionnez un ticket' });
            this.confirmationService.close();
          }
        },
        reject: async () => {
        //   if (this.selectedStore) {
        //   if (this.selectedVentes.length > 0) {
        //     await this.sharedServices.setSelectedVentes(this.selectedVentes); 
        //     await this.factureDialog.showDialog(false, this.selectedClient, this.selectedStore, this.selectedEtablissement);
        //     this.confirmationService.close();
        //   }else{
        //     await this.sharedServices.setSelectedVentes([]);
        //     await this.factureDialog.showDialog(false, this.selectedClient, this.selectedStore, this.selectedEtablissement);
        //     this.confirmationService.close();
        //   }
        // }else {
        //   this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Sélectionnez un depot' });
        // }
        this.confirmationService.close();
      }
      });
    }

// async showConfirm() {
//   this.selectedVentes = this.filteredVentes.filter((vente) => vente.selected);

//   const ref = this.dialogService.open(CustomDialogComponent, {
//     header: 'Confirmation',
//     data: {
//       message: 'Êtes-vous sûr de vouloir continuer?',
//       acceptLabel: 'Facture finit',
//       rejectLabel: 'Brouillon',
//       rejectLabel2: 'Annuler',
//     },
//     width: '50%',
//   });

//   const result = await ref.onClose.toPromise();

//   if (result === 'accept') {
//     // Handle accept action
//   } else if (result === 'reject') {
   
//   } else if (result === 'reject2') {
//     // Handle the action for the second reject button (Annuler)
//   }
// }



    filterStore(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;

      for (let i = 0; i < (this.Depots as any[])?.length; i++) {
          let country = (this.Depots as any[])[i];
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }

      this.filtredStore = filtered;
    }
    filterEtablissement(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;

      for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
          let country = (this.Etablissements as any[])[i];
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }

      this.filtredEtablissement = filtered;
    }
    async getEtablissement(){
      await this.venteService.getEtablissements().then(res=>{
        
        console.log(res)
      this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
        store=>({id:store.id,name:store.attributes.name})
      )}))})
      console.log(this.Etablissements)
  
  
    }
    async getSelectedEatb(){
      console.log("selected Etablissement",this.selectedEtablissement)
      this.Depots=this.selectedEtablissement.stores
    }
    async getSelecteddepot(){
      this.selectedVentes = [];
      this.filteredVentes = [];
    }
    async getSelectedclient(){
      this.selectedVentes = [];
      this.filteredVentes = [];
    }

    getClients(){
      this.venteService.getClients().then(res=>{
      this.Clients=res.data.map(item=>({id:item.id,name:item.attributes.name, clientcode:item.attributes.code , address:item.attributes.adresse , phone:item.attributes.telephone ,  CTVA:item.attributes.matricule_fiscale  }))
      console.log('clients',this.Clients)
    })
    }
    async getArticles(){
      await this.articleService.getArticles().then(res=>
        {console.log(res)
        this.articles=res?.data.map(items=>
          ({
            id: items.id,
            code_article_generique:items?.attributes?.code_article_generique,
            code_fournisseur:items?.attributes?.code_fournisseur,
            designation:items?.attributes?.designation,
            sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
            collection:items?.attributes?.collection.data?.attributes?.name,
            rayon:items?.attributes?.rayon.data?.attributes?.name,
            prix_achat_ttc:items?.attributes?.prix_achat_ttc,
            prix_vente_ttc:items?.attributes?.prix_vente_ttc,
            prix_achat_ht:items?.attributes?.prix_achat_ht,
            prix_vente_ht:items?.attributes?.prix_vente_ht,
            fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
            nature:'',
            Unite_de_stock:'',
            variations:items?.attributes?.variations.data
          })
          )
        
        })
        console.log(this.articles)
    }

}
