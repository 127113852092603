import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { article } from '../demo/domain/article';
import { Product } from '../demo/domain/product';
import { ArticleService } from '../demo/service/article.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
 // styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  editproductDialog:boolean
  editvariationDialog :boolean
  editvariationDialog2 :boolean
  deleteVariationDialog:boolean
listfournisseurs
fournisseur
product2
  editPT
article:article
  taille
  couleurs
  varProd
  codebarre
VP
id
imageNewProduct
showImage = false;
selectedTaille
selectedColor
  uploadedImage: File;
  dbImage: any;
  postResponse: any;
  successResponse: string;
  image: any;
  uploadedFiles:any[]=[]
  idimage
product 
variation:{
  code_article
}
productvariation=[]

selectedFournisseur;
submitted: boolean;
productDialog: boolean;
variationdialog :boolean;
filteredFourniss: any[];
cols = [
  { field: 'reference', header: "reference" },
  { field: 'code_article_generique', header: 'code_article_generique' },
  { field: 'image', header: 'image' },
  { field: 'prix_vente', header: 'prix vente' },
  {field: 'fournisseur', header: 'fournisseur'}
  



  
  
];

colsvariationP = [
  
  { field: 'code_article_generique', header: 'code article' },

  // { field: 'code_barre', header: 'code barre' },
  { field: 'color', header: 'couleur' },
  { field: 'taille', header: 'taille' },
  



  
  
];
  constructor( private s:ArticleService , private messageService: MessageService,private cdr: ChangeDetectorRef) { }

 async  ngOnInit() {
  await this.getProduct()
  await this.gettaille()
  await this.getcouleur()
  await this.getfournisseur()

  }




  filterFourniss(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.listfournisseurs.length; i++) {
        const country = this.listfournisseurs[i];
        if (country.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredFourniss = filtered;
   console.log(this.filteredFourniss)
  // if (this.selectedFournisseur) {
  //   // Assign the selected value to editPT.fournisseur
  //   this.editPT.fournisseur = this.selectedFournisseur;
  // } 

}


  async getfournisseur(){
    await this.s.getfournisseur().then(data=>{
      this.listfournisseurs=data.data.map(x=>({
        value:x.id,
  label:x.attributes.name}))
      console.log('fournisseur',this.listfournisseurs)
      
    })
    

  }

  openNew() {
   // this.product1 = {};
    this.productvariation=[]
    this.submitted = false;
    this.productDialog = true;
}

addvariation(code) {
  // this.product1 = {};
  this.submitted = false;
   this.variationdialog = true;
   this.selectedTaille=""
   this.selectedColor=""
   this.product2=code
}

hideDialog() {
  this.productDialog = false;
  this.submitted = false;
}
hideDialogV() {
  this.variationdialog= false;
  this.submitted = false;
}



edithideDialogV() {
  this.editvariationDialog= false;
  this.submitted = false;
}

edithideDialogV2() {
  this.editvariationDialog2= false;
  this.submitted = false;
}
//EDIT VARIATION
async editVariations(){
  let data={
 
    "taille":this.selectedTaille,
    "color":this.selectedColor}
    console.log(data)
  await this.s.editvariation(data,this.article.id).then(res=>{
    console.log('data',res.data.id)
    console.log('data',res)
    this.varProd=res.data.id
    // this.productvariation=res.data.attributes.variations.data.map(x=>({
    //   id:x.id,
        
    //   code_article_generique:x.attributes.code_article_generique,
    //   code_barre:x.attributes.code_barre,
    //   taille:{id:x.attributes.taille.data.id,name:x.attributes.taille.data.attributes.name},
    //   color:{id:x.attributes.color.data.id,name:x.attributes.color.data.attributes.name},
    //   product:this.id
  
    // }))
  
    // console.log("this.productvariation",this.productvariation);



  })
  /*let productdata={
    "variations":this.varProd
  }
  await this.s.editProducts(this.editPT.id,productdata).then()
console.log(this.editPT.id)*/
  await this.s.getVariationP(this.editPT.id).then(res=>{
  
    console.log("resf",res.data.attributes.variations.data
    )
    
  
    this.productvariation=res.data.attributes.variations.data.map(x=>({
      id:x.id,
        
      code_article_generique:x.attributes.code_article_generique,
      code_barre:x.attributes.code_barre,
      taille:{id:x.attributes.taille.data.id,name:x.attributes.taille.data.attributes.name},
      color:{id:x.attributes.color.data.id,name:x.attributes.color.data.attributes.name},
      product:this.id
  
    }))
  
  
    console.log("this.productvariation",this.productvariation);
    
  })
  
  
  this.editvariationDialog= false;
  this.messageService.add({severity: 'success',  key:'1' ,summary: 'Success', detail: 'variation updated successfully'});

   
    //this.products = this.products.filter(val => val.id !== this.product.id);
   // this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
   // this.product = {};
  


}

async editProducts(){
  // console.log(this.editPT?.fournisseur)
  let data={
    "reference": this.editPT.reference,
            "code_article_generique": this.editPT.code_article_generique,
            "libelle_article": "456",
            "prix_vente":this.editPT.prix_vente,
            "image":this.idimage,
           "fournisseur":this.editPT.fournisseur.value

     }

  await this.s.editProducts(this.editPT.id,data).then(async (data)=>{
console.log("editproduct",data)
this.VP=1
this.messageService.add({severity: 'success',  key:'1' ,summary: 'Success', detail: 'product updated successfully'});
  })


 
    
}




  async getProduct() {
    await this.s.getproduct().then(async (data) => {
      console.log("data",data)
      this.product=data.data.map(x=>({
        id:x.id,
        libelle_article:x.attributes.libelle_article,
        reference:x.attributes.reference,
        code_article_generique:x.attributes.code_article_generique,
        prix_vente:x.attributes.prix_vente,
        fournisseur:x?.attributes?.fournisseur?.data,
        image:  x.attributes.image.data!=null? "https://prefacture.rosace.com.tn"+x.attributes.image.data.attributes.formats.small.url:"",
        variations:x.attributes.variations
        
       
      }))
    
     
      
     
      
  
    });
    console.log(this.product)
  
  }


  async getVariationProduct(id) {
    await this.s.getVariationProduct(id).then(async (data) => {
      console.log("productvariation",data)
     
    
     
      
     
      
  
    });

  }
  async gettaille() {
    await this.s.gettaille().then(data => {
      console.log("tailles",data)
     
    this.taille=data.data.map(x=>({
      value:x.id,
label:x.attributes.name


    }))
     
      
     
      
  
    });

    console.log("taille",this.taille)
    
  
  }


  async getcouleur() {
    await this.s.getcolor().then(data => {
      console.log("colors",data)
     
    this.couleurs=data.data.map(x=>({
      value:x.id,
label:x.attributes.name


    }))
     
      
     
      
  
    });

    console.log("couleurs",this.couleurs)
    
  
  }

  async saveProduct(){
  //  this.product.fournisseur=this.selectedFournisseur
  console.log(this.product)
  
  console.log(this.selectedFournisseur)

   this.s.addarticle({"data":{
      "reference": this.product.reference,
      "code_article_generique": this.product.code,
      "libelle_article":this.product.libelle_article,
      "prix_vente": this.product.prix,
      "fournisseur":this.selectedFournisseur.value,
      "image":this.idimage
      
      }})
    .then(async (res)=>{
      // console.log(this.product)
      console.log("reponse api save article",res)
     this.product2=this.product.code
     console.log("code article",this.product2)

      this.VP=1;
      // console.log("res",res)
      this.imageNewProduct=res.data.attributes.image.data!=null?res.data.attributes.image.data.attributes.formats.small.url:""
      this.showImage=true

      console.log("img",this.imageNewProduct)
      this.id=res.data.id
      console.log(this.id)
    // console.log("res",(res.data.attributes.variations.data).length)

    if(res.data.attributes.variations.data.length>0){
    this.productvariation=res.data
    
   let tab=res.data.variations.data.map(x=>({
      
      code_article:x.attributes.code_article_generique,
      code_barre:x.attributes.code_barre,
      //taille:x.data.attributes.name,
      //color:x.data.attributes.name,
      product:this.id

    }))

    console.log("variations",tab)
  }
  
    
  })

    
  // await this.getProduct() 
  }



  

 
  


  onUpload(event) {
    this.idimage=event.originalEvent.body[0].id
    console.log(this.idimage)
    console.log("event",event.originalEvent.body[0].id)
    
    for (const file of event.files) {
        this.uploadedFiles.push(file);
        console.log("file",file);
        console.log("uploadedfiles",this.uploadedFiles);

    }
   
    //this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
}

async saveVariations(){
  //console.log("codearticle",this.product.code)
 // console.log("codde barre",this.product.code_barre)
//  console.log('taille',this.taille)
 console.log('color',this.selectedColor )
 console.log('taille',this.selectedTaille )

 /* console.log("id",this.id)*/

await this.s.addvariations({data:{
  "code_barre":'',
  "code_article_generique":this.product2,
  "taille":this.selectedTaille,
  "color":this.selectedColor,
   "product":this.id}}).then(res=>{
    console.log('variation',res);
    
  
this.productvariation.push({
"id":res.data.id,
  "code_barre":res.data.attributes.code_barre,
  "code_article_generique":this.product2,
  // "taille":res.data.attributes.taille.data.attributes.name,
  "taille":{id:res.data.attributes.taille.data.id,name:res.data.attributes.taille.data.attributes.name},

  "color":{id:res.data.attributes.color.data.id,name:res.data.attributes.color.data.attributes.name},
   "product":this.id})
   console.log(this.productvariation)
  //  this.productvariation=res.data.attributes.map(x=>({
  //   id:x.id,
      
  //   code_article_generique:x.attributes.code_article_generique,
  //   code_barre:x.attributes.code_barre,
  //   taille:{id:x.attributes.taille.data.id,name:x.attributes.taille.data.attributes.name},
  //   color:{id:x.attributes.color.data.id,name:x.attributes.color.data.attributes.name},
  //   // taille:x.attributes.taille.data.attributes.name,
  //   // color:x.attributes.color.data.attributes.name,
  //   product:this.id

  // }))
   //vider les champs
   this.codebarre = ""; 
   this.selectedTaille = null;
   this.selectedColor = null; 

console.log("this.pv",this.productvariation)
    // this.variationdialog= false;
    this.editvariationDialog=false
    this.editvariationDialog2=false
  }
  

)





}



deleteVariation(article: article) {
 console.log(article)
  this.deleteVariationDialog = true;
 //this.article=article


  this.article = {...article};
  console.log("arti",this.article)
}


async confirmDelete(){
  console.log("id",this.article.id)

await this.s.deleteVariation(this.article.id).then(x=>{
  this.deleteVariationDialog = false;

 
})
await this.s.getVariationP(this.id).then(res=>{
  
  console.log("resf",res.data.attributes.variations.data
  )
  

  this.productvariation=res.data.attributes.variations.data.map(x=>({
    id:x.id,
      
    code_article_generique:x.attributes.code_article_generique,
    code_barre:x.attributes.code_barre,
    taille:x.attributes.taille.data.attributes.name,
    color:x.attributes.color.data.attributes.name,
    product:this.id

  }))


  console.log("this.productvariation",this.productvariation);
  
})



 
  //this.products = this.products.filter(val => val.id !== this.product.id);
 // this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
 // this.product = {};
}


editVariation(article: article) {
  this.article = {...article};
  this.selectedColor=article['color'].id
  this.selectedTaille=article['taille'].id
  this.editvariationDialog = true;

  console.log("arti",this.article)
  // this.editvariationDialog=true
}
// someMethod() {
//   // Modify selectedTaille or selectedColor here
//   this.editVariation2
//   this.cdr.detectChanges(); // Trigger change detection
// }
editVariation2(article: article) {
  console.log(this.editPT)

  this.article = {...article};
  
  this.editvariationDialog2 = true;

  console.log("variation",this.article)
  this.selectedColor=article['color'].id
  this.selectedTaille=article['taille'].id
  console.log(this.selectedColor)
  console.log(this.selectedTaille)
}
cancel(){

  this.editproductDialog=false;
}

// getSelectedItemName(): string {
//   return item.name;
// }

async editProduct(products  ){
  this.editproductDialog=true;

  console.log(products)
this.fournisseur=products.fournisseur 
console.log(this.fournisseur)
this.selectedFournisseur=this.fournisseur

  this.editPT=products
let id =this.editPT.id
this.editPT.fournisseur={value: products.fournisseur?.id, label: products.fournisseur?.attributes.name} 
console.log(this.editPT.fournisseur)

  await this.s.getVariationP(this.editPT.id).then(res=>{
  
    console.log("resf",res.data.attributes.variations.data
    )
    
  
    this.productvariation=res.data.attributes.variations.data.map(x=>({
      id:x.id,
        
      code_article_generique:x.attributes.code_article_generique,
      code_barre:x.attributes.code_barre,
      taille:{id:x.attributes.taille.data.id,name:x.attributes.taille.data.attributes.name},
      color:{id:x.attributes.color.data.id,name:x.attributes.color.data.attributes.name},
      // taille:x.attributes.taille.data.attributes.name,
      // color:x.attributes.color.data.attributes.name,
      product:this.id
  
    }))})
  console.log("productvariatioon",this.productvariation);

  // console.log("product",this.editPT);
}

}



