<!-- details-transfert.component.html -->
<p-dialog header="Details Transfert" [(visible)]="detailstransfertDialog" [style]="{ width: '70%' }" [modal]="true" >
    <div *ngIf="transferts" class="details">


      <div class="details-container">

        <div class="container">
          <div class="title"><strong>Etablissement Emetteur:</strong></div>
          <div>{{transferts.etabEmett.name }}</div>
          <div class="title"><strong>Depot Emetteur:</strong></div>
          <div>{{transferts.depotEmet.name }}</div>
         
        </div>
      </div>

        <div class="details-container">

        <div class="container">
          <div class="title"><strong>Etablissement Recepteur:</strong></div>
          <div>{{transferts.etabRecep.name }}</div>
          <div class="title"><strong>Depot recepteur:</strong></div>
          <div>{{transferts.depotRecep.name }}</div>
         
        </div>

      </div>

      <div *ngFor="let transfert of transferProducts">

        <div class="details-container">
          <!-- Container 1 -->
          <div class="container">
            <div class="title"><strong>Article:</strong></div>
            <div>{{transfert.code_article_generique}}</div>
            <div class="title"><strong> Fournisseur :</strong></div>
            <div>{{transfert.fournisseur.name}}</div>
           
          </div>
        </div>
      </div>

      </div>

      <p-table
      [value]="transfertVariations"
      responsiveLayout="stack" 
      scrollHeight="flex"
      [paginator]="true"
      [rows]="5"
      [rowsPerPageOptions]="[5,10, 25, 50]"
      dataKey="id"
      >
      <ng-template pTemplate="header">
      <tr>
      <th>Article</th>
      <th>Quantité</th>
      <th>Couleur</th>
      <th>Taille</th>
      
      <!-- <th pSortableColumn="Prix" style="width:20%">Prix <p-sortIcon field="Prix"></p-sortIcon></th> -->
      
      </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transfert let-rowIndex="rowIndex">
        <!-- <ng-container *ngFor="let variation of transfert.variations "> -->
      <tr>
      
      <td >{{ transfert.variations.article}}</td>
      <td >{{ transfert.quantite }}</td>
      <td >{{ transfert.variations.color.name }}</td>
      <td>{{ transfert.variations.taille.name }}</td>
   
      
      </tr>
    <!-- </ng-container> -->
      </ng-template>
      <ng-template pTemplate="paginatorleft">
      </ng-template>
      <ng-template pTemplate="paginatorright">
      </ng-template>
      </p-table>
      
  </p-dialog>
  