import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ShareticketsService {

  private selectedVentesSource = new BehaviorSubject<any[]>([]);
  selectedVentes$ =  this.selectedVentesSource.asObservable();
  setSelectedVentes(ventes: any[]): void {
    this.selectedVentesSource.next(ventes);
  }
  constructor() { }
}
