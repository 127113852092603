import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
   //**************************************handle errrors***************************************** */
   private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
     //******************************************************************************* */

  async getConfig() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/configuration`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }

}
