import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
interface Vente {

  
  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number;
  timbre: number;
  prix_net: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  // code_facture_client: string;
  numeroTicket: string;
  date_vente: string;
  client : string ;
  store : string ;
  etablissement : string ;
  selected?: boolean;
  product?: string ;
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 
}
@Injectable({
  providedIn: 'root'
})
export class ShareticketService {
  private venteData = new BehaviorSubject<Vente | null>(null);
  currentVenteData = this.venteData.asObservable();

  setVenteData(vente: Vente) {
    this.venteData.next(vente);
  }

  constructor() { }
}
