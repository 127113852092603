import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ClienttansferService {

  private clientAddedSource = new Subject<void>();

  clientAdded$ = this.clientAddedSource.asObservable();

  notifyClientAdded() {
    this.clientAddedSource.next();
  }
  constructor() { 


  }
}
