import { throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  filtredUsersUrl: any="";
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }

  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
  async fetchUsersByPage() {
    console.log("xx")
    let promise = new Promise<any>((resolve, reject) => {
      let url =
        environment.url +
        "/Users?populate=role"
     
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }




  async getAllRolesAsync() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/users-permissions/roles`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }




 
}
