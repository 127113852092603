import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/models/client';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  total = 0 ;
  private isToastShown: boolean = false;

  // private apiUrl = 'https://prefacture.rosace.com.tn/API/clients?';
  private apiUrl = `${environment.url}/clients?`;

  // private apiUrlDel = 'https://prefacture.rosace.com.tn/API/clients';
  private apiUrlDel = `${environment.url}/clients`;

  constructor(private http: HttpClient, private messageService: MessageService) {}
   //**************************************handle errrors***************************************** */
   private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
     //******************************************************************************* */

  async getClients(page: number, size: number , field: string = 'id' , option:number=-1 , filter ?: string ): Promise<Client[]> {
    const searchUrl = `filters[$or][0][name][$containsi]=${filter}&filters[$or][1][code][$containsi]=${filter}`;
    const sortOrder = option === 1 ? 'ASC' : 'DESC';
    const sort = field && option ? `sort=${field}:${sortOrder}` :  ''
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let GetApi = ''
    if(filter && filter != ''){
       GetApi = `${this.apiUrl}&${pagin}&${sort}&${searchUrl}`
    }else{
       GetApi = `${this.apiUrl}&${pagin}&${sort}`
    }
    try {
      const apiResponse: any = await this.http.get(GetApi).toPromise();
      this.total = apiResponse.meta?.pagination?.total 
      return apiResponse.data.map((clientData: any) => Client.fromApiResponse(clientData));
    } catch (err) {
      console.error('Error fetching clients:', err);
      this.handleApiError(err);
          throw err; 
    }
  }

  async postClient(client: Client): Promise<any> {
    try {
      const response = await this.http.post<any>(this.apiUrl, client).toPromise();
      return response;
    } catch (err) {
      this.handleApiError(err);
      throw err;     }
  }

  deleteClientById(id: number): Promise<any> {
    // const url = `${this.apiUrlDel}/${id}`;
    // return this.http.delete(url).toPromise();
    const url = `${this.apiUrlDel}/${id}`;

  try {
    return this.http.delete(url)
      .toPromise()
      .catch((error) => {
        this.handleApiError(error);
        throw error; // Rethrow the error after handling it
      });
  } catch (err) {
    // Handle synchronous errors if needed
    console.error(' error:', err);
    this.handleApiError(err);
    throw err;   }
  }

  async updateClientById(id: number, updatedClient: Client): Promise<Client> {
    const url = `${this.apiUrlDel}/${id}`;
    try {
      const response = await this.http.put<Client>(url, updatedClient).toPromise();
      return response;
    } catch (err) {
      // Handle errors as needed
      this.handleApiError(err);
      throw err;     }
  }

}
