import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/demo/service/users.service';
import {Table} from 'primeng/table';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
 // styleUrls: ['./users.component.scss']
 providers: [DialogService]

})
export class UsersComponent implements OnInit {
  listRoles
  roles
  position: string;
  visible: boolean;
  users=[]
  header
  stores=[{name:"R2"},{ name:"R1"},{ name:"R1"},{ name:"R1"}]
  cols = [
    { field: 'username', header: "Nom" },
    { field: 'role', header: 'Role' },
    { field: 'date', header: 'Date de creation' },
    { field: 'blocked', header: 'Bloqué' },
    { field: 'confirmed', header: 'Confirmé' }
    ,
    { field: 'email', header: 'E-mail de confirmation' }
];

  constructor( private service: UsersService,public dialogService: DialogService) { }

 async ngOnInit() {
  await this.getUsers()
  await this. getListRoles()
  console.log(this.listRoles)

  }


  showDialog(position: string) {
    this.position = position;
    this.visible = true;
}
//This function changes the date format
  changeformat(date){
    const myDate = new Date(date);
    const formattedDate = myDate.toLocaleDateString();
  
    return formattedDate
  }
  async getUsers() {
    //This function displays the user's information
    await this.service.fetchUsersByPage().then(async (users) => {
     console.log("users" , users)
     this.users=users.map(x=>({
      username:x.  username,
      role: x.role.name,
      date:this.changeformat(x.createdAt)

     }))
   
    // this.header =Object.keys(users[0]);
     //console.log("ffff",this.users)
    });

}

async getListRoles() {
  await this.service.getAllRolesAsync().then(async (dataRoles) => {
    this.roles = dataRoles.roles;
    
    this.roles=dataRoles.roles.map(x=>({

      id:x.id,
      name: x.name

    }))
    console.log(this.roles)

  });
  

}

}