<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-toast></p-toast>

            <h5> Saisie Facture </h5>		
			<div class="container">
				<div class="content">
					<!-- <p-dropdown class="drop" [options]="Etablissements"  [(ngModel)]="selectedEtablissement" optionLabel="name" placeholder="Etablissement" (onChange)="getSelectedEatb()"></p-dropdown>
					<p-dropdown class="drop" [options]="Depots" [(ngModel)]="selectedStore" optionLabel="name" placeholder="Depot"></p-dropdown>
					<p-dropdown class="drop" [options]="Clients" optionLabel="name" placeholder="Client"></p-dropdown> -->
					<p-autoComplete  [(ngModel)]="selectedEtablissement"  [suggestions]="filtredEtablissement"  (completeMethod)="filterEtablissement($event)"  placeholder="Etablissement" 
					field="name" [dropdown]="true"   (onSelect)="getSelectedEatb()" style = "margin-right : 10px;"
					></p-autoComplete>
	
					<p-autoComplete  [(ngModel)]="selectedStore"  [suggestions]="filtredStore"  (completeMethod)="filterStore($event)" placeholder="Depot"
					field="name" [dropdown]="true"   style = "margin-right : 10px;"  (onSelect)="getSelectedDepot()"
					></p-autoComplete>
	
					<p-autoComplete  [(ngModel)]="slectedClient" [suggestions]="filtredClient"  (completeMethod)="filterClient($event)" placeholder="Client" 
					field="name" [dropdown]="true"   style = "margin-right : 10px;"  (onSelect)="getSelectedClient()"
					></p-autoComplete>
	
				  <div class="custom-cal" style = "margin-right : 10px;">
				<p-calendar class="drop" [(ngModel)]="date" [showIcon]="true" inputId="icon"
            placeholder="Calendrier" (ngModelChange)="onDateChange($event)"></p-calendar>
				  </div>
				  <!-- <div class="field">
					<label htmlFor="name">Code Facture </label>
					<input pInputText id="name" type="text" [(ngModel)]="CodeFourniss" />
				  </div> -->
				  <button pButton pRipple type="button" icon="pi pi-plus" label="Nouvelle Ticket" class="p-button-success mr-2 mb-" (click)="openDialog()" style="float: right;"></button>

				</div>
			  </div>
<br>
<p-table [columns]="cols" [value]="ventes" dataKey="id" responsiveLayout="stack" scrollHeight="flex"  >

             
                <ng-template pTemplate="header" let-columns>
					
					<tr>
						<th style="width: 6px; text-align: center; position: relative;">
							<button type="button" pButton pRipple [pRowToggler]="{}" class="p-button-text p-button-rounded p-button-plain" 
									icon="pi pi-chevron-right" (click)="toggleAllRows()"></button>
						  </th>
						<th *ngFor="let col of columns">
						
						<div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>


						<div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						
						<div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
                        <div  *ngIf="col.field=='prixht'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
						<div  *ngIf="col.field=='prixttc'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
                        <div  *ngIf="col.field=='remise'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
                      
                        <div  *ngIf="col.field=='Total_HT'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
						<div  *ngIf="col.field=='Total_TTC'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
						<div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>

						</th>
						
						
						
					</tr>
				</ng-template>


                <ng-template pTemplate="body" let-rowData  let-columns="columns" let-vente let-ri="rowIndex">
					<tr  [pEditableRow]="columns">

						<td style="border-right: 1px solid #ccc; text-align: center;">
							<button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
							[icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRow(rowData,ri)" style="font-size: 1em;"></button>

                        </td>
						<td style="min-width: 12rem;" *ngFor="let col of columns">

							<!-- <div  *ngIf="col.field=='codeVariation'"class="flex justify-content-between align-items-center">
								
								{{Achats.codeVariation}}
						   </div> -->
					   
							<div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
								
								 {{vente.ref}}
							</div>
						
						   <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
								
							{{vente.designation }}
					   </div>
					   <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
								
						{{vente.qte}}
				   </div>
							<div  *ngIf="col.field=='prixht'"class="flex justify-content-between align-items-center">
										
							{{vente.prixHT | roundToThreeDecimals }}
						</div>
							
						<div  *ngIf="col.field=='prixttc'"class="flex justify-content-between align-items-center">
										
						{{vente.prixTTC | roundToThreeDecimals}}
					</div>
									
					<div  *ngIf="col.field=='remise'"class="flex justify-content-between align-items-center">
							
						{{vente.remise}}
					</div>
					<div  *ngIf="col.field=='Total_HT'"class="flex justify-content-between align-items-center">
							
					{{vente.montantHT | roundToThreeDecimals }}
				</div>
				<div  *ngIf="col.field=='Total_TTC'"class="flex justify-content-between align-items-center">
							
				{{vente.montantTTC | roundToThreeDecimals }}
			</div>
			<div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
							
				<button pButton pRipple type="button" icon="pi pi-trash" 
				class="p-button-danger mr-2 mb-2" style="width: 40px; background-color: #EF4444;"
				(click)="deleteRow(ri)">
				</button>			</div>
		 
						</td>
                       
                    
                    </tr>
						
					</ng-template>

					<ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex">
						<tr >
							<!-- <tr *ngIf="isExpanded[rowIndex]" > -->

							<td colspan="7">
								<div class="p-p-3">
									<!-- <div   style="text-align: center;">
										<button pButton pRipple  (click)="addvariation(rowData)" label="ajouter variations de produit " class="p-button-success">
                    
										</button>
									</div> -->
									<!-- <br> -->
									<p-table [value]="rowData.variationsVente" dataKey="id">
										<ng-template pTemplate="header">
											<tr>
												<th>Code Variations</th>
												<th>Couleur</th>
												<th>Taille</th>
												<th>quantité</th>
												<th>Montant</th>
												
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-variationsVente>
											<tr>
												<td>{{variationsVente.code_barre}}</td>
												<td>{{variationsVente.Color	}}</td>
												<td>{{variationsVente.Size	}}</td>
												<td>
													<!-- {{variationsVente.QTE}} -->
												<p-inputNumber [(ngModel)]="variationsVente.QTE" mode="decimal" (ngModelChange)="onQteChange(rowData,rowIndex,variationsVente)" [showButtons]="true" inputId="minmax-buttons"  [max]="100"> </p-inputNumber>

												</td>
												<td>{{variationsVente.QTE*rowData.prixTTC | roundToThreeDecimals }}</td>
												<td> <div class="flex">
													<!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editVariation2(variation,rowData)"></button>
												 <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button>  -->
												</div></td>
												
											</tr>
										</ng-template>
										
									</p-table>
								</div>
							</td>
						</tr>
					</ng-template>

            </p-table>
		
				<!-- <h6>Facture Achats</h6> -->
				<!-- <p>Bonjour</p> -->
			  
				<!-- Display Remise % and Remise value -->
				 
				
				  <!-- Display HT and its value, Total fodec and its value on the same line -->
				  <div style="display: flex; margin-top: 30px; justify-content: flex-end;">

					<!-- Column 1: Remise% and Remise -->
					<div style="flex: 0,5; border-right: 1px solid #b6b6b6; padding-right: 20px;">
					 <!-- <div style="display: flex; margin-bottom: 10px;justify-content: space-between; ">-->
						<!--<p style="font-weight: bold;">Remise %:</p>-->
						<!--<div style="display: flex; align-items: center; margin-left: 30px;">-->
						<!--  <input [(ngModel)]="remisePercentage" type="number" style="width: 50px; color: blue; border-style: none;" (ngModelChange)="updateValues()" (input)="validateInput()" min="0" max="100" style = "margin-bottom: 20px;"  (keyup)="validateRemise($event)">-->
						<!--  <p style="margin-left: 5px;margin-bottom: 20px;">%</p>-->
						<!--</div>-->
					 <!-- </div>-->
					 <!-- <div style="display: flex; justify-content: space-between;">-->
						<!--<p style="font-weight: bold;">Remise :</p>-->
						<!--<p style="margin-left: 10px;">{{ remiseAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>-->
						<!--<span style="margin-right: 5px;">TND</span>-->

					 <!-- </div>-->
					</div>
				  
					<!-- Column 2: HT, Taxes, TTC -->
					<div style="flex: 0,5; border-right: 1px solid #b6b6b6; padding: 0 20px;">
					  <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
						<p style="font-weight: bold;">HT:</p>
						<p style="margin-left: 10px;">{{ htAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
						<span style="margin-right: 5px;">TND</span>

					  </div>
					  <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
						<p style="font-weight: bold;">Taxes :</p>
						<p style="margin-left: 10px;">{{ taxesAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
						<span style="margin-right: 5px;">TND</span>

					  </div>
					  <div style="display: flex; justify-content: space-between;">
						<p style="font-weight: bold;">TTC :</p>
						<p style="margin-left: 10px;">{{ ttcAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
						<span style="margin-right: 5px;">TND</span>

					  </div>
					</div>
				  
					<!-- Column 3: Total Fodec, Timbre, Net a Payer -->
					<div style="flex: 0,5; padding-left: 20px;">
						<ng-container *ngIf="showFodec ">
					  <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
						<p style="font-weight: bold;">Total Fodec {{fodec}}%:</p>
						<p style="margin-left: 10px;">{{ totalFodec | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
						<span style="margin-right: 5px;">TND</span>

					  </div>
					</ng-container>

					  <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
						<p style="font-weight: bold;" *ngIf="timbreAmount>0">Timbre :</p>
						<!-- <p style="margin-left: 10px;">{{ timbreAmount | currency: 'TND': 'symbol':'1.3-3' }}</p> -->
						<!-- <input [(ngModel)]="timbreAmount" type="number" style="width: 50px; color: blue; border-style: none;" (ngModelChange)="updateValues()" (input)="validateInput()" min="0" max="100" style = "margin-bottom: 20px;"> -->

						<input *ngIf="timbreAmount>0"
						(keydown)="validateTimbre($event)"

						 [(ngModel)]="timbreAmount " 
						[value]="timbreAmount | roundToThreeDecimals| number:'1.3-3'"
						(ngModelChange)="updateValues()"
						(input)="onInput($event)"
						(change)="onChange($event)"
	   					type="number"  step="0.01" style="width: 50px; color: blue; border-style: none;" 
						  min="0" max="100" style = "margin-bottom: 20px;">

					  </div>
					  <div style="display: flex; justify-content: space-between;">
						<p style="font-weight: bold; color: rgb(0, 0, 150);">Net a Payer :</p>
						<p style="margin-left: 20px; color: rgb(0, 0, 150); font-weight: 500;">{{ netToPayAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
						<span style="margin-right: 5px;">TND</span>

					  </div>
					</div>
				  
				  </div>
				  
				  <div style="display: flex; margin-top: 20px; justify-content: flex-end;">
					<button pButton pRipple type="button" 
					label="Enregistrer" class="p-button-success mr-2 mb-"
					 style="font-weight: bold; font-size: 20px;"
					 (click)="AddVente()"
					 ></button>
				  </div>
			
		
			
        
			<div class="card flex justify-content-center">
				<!-- <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator> -->
			</div>
        </div></div></div>
<app-add-vente-dialog (dataSent)="receiveTicket($event)"></app-add-vente-dialog>
<app-confirmation-dialog (dataEvent)="receiveConfirm($event)"></app-confirmation-dialog>

