import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleBticketService {

  private apiUrl = 'https://prefacture.rosace.com.tn/bruillon/getAllBventes';

  constructor(private http: HttpClient) { }

  async getAllBventes(): Promise<any> {
    return await this.http.get(this.apiUrl)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return  Promise.reject(error.message || error);
  }
}
