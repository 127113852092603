<div class="card col-12">
    <p-tabView styleClass="tabview-custom ">
        <p-tabPanel header="Header I">
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-calendar"></i> -->
                <span>Donnée de base</span>
            </ng-template>
         <ng-template pTemplate="body">
            <div class="grid ">


                <div class="col-4 ">
                
                    <div class="card shadow-2  border-round-sm ml-0" style="height: 600px;">
                       <div class="card-header">
                           <div class="title">
                               <h5>Sous-Familles</h5>
                               <p-toast></p-toast>
                
                           </div>
                           
                       </div>
                 
                       <p-table [value]="SousFamille" dataKey="id"  responsiveLayout="stack" [scrollable]="true" scrollHeight="400px" editMode="row" 
                       [lazy]="true" (onLazyLoad)="loadSousFAmille($event)" [loading]="loading" >
                           <ng-template pTemplate="caption">
                               <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveFamille()"></button>
                               <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                               (click)="deleteFamille()"
                               [disabled]="!selectedFamille || !selectedFamille.length"></button>
                
                               <span class="p-input-icon-left mb-2 ml-0 w-8 ">
                                   <i class="pi pi-search"></i>
                                   <input pInputText type="text" #filter [(ngModel)]="searchTermFamille" placeholder="Rechercher..." class="w-full"  (ngModelChange)="getSearch($event)" style="height: 35px;"/>
                    
                               </span> 
                
                           </ng-template>
                           <ng-template pTemplate="header">
                               <tr>
                                   <th class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox  [binary]="true"
                                           [(ngModel)]="selectAllCheckbox"
                                           name="checkbox{{rowIndex}}"
                                           (onChange)="selectAll($event)"></p-checkbox>
                                       </div>
                                   </th>
                                   <th pSortableColumn="name" >Nom<p-sortIcon field="{{name}}"></p-sortIcon> </th>
                                   <th  pSortableColumn="code" >Code<p-sortIcon field="{{code}}"></p-sortIcon> </th>
                
                                   <th class="w-1rem"></th>
                               </tr>
                           
                           </ng-template>
                           <ng-template pTemplate="body" let-famille let-editing="editing" let-ri="rowIndex" >
                               <ng-container *ngIf="ri === 0 && newRow">
                                   <tr>
                                       <td></td>
                                     <td>
                                       <input class="w-7rem" pInputText type="text" [(ngModel)]="newFamille">
                
                                     </td>
                                     <td>
                                        <input  class="w-7rem" pInputText type="text" [(ngModel)]="newCodeFamille">
                 
                                      </td>
                                     <td>
                                       <button  pButton pRipple type="button"  icon="pi pi-check" (click)="AddFamille()" class="p-button-rounded p-button-text p-button-success ml-4 mr-2"></button>
                
                                     </td>
                                   </tr>
                                 </ng-container>
                               <tr [pEditableRow]="famille">
                
                                   <td class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox
                                           [binary]="true"
                                           name="checkbox{{rowIndex}}"  
                                           [(ngModel)]="famille.selected"
                                           (onChange)="checkboxChanged($event, famille)" ></p-checkbox>
                                       </div>
                                   </td>
                
                                   <td >
                                       <p-cellEditor>
                                           <ng-template pTemplate="input">
                                               <input class="w-7rem" pInputText type="text" [(ngModel)]="famille.name">
                                           </ng-template>
                                           <ng-template pTemplate="output">
                                               {{famille.name}}
                                           </ng-template>
                                       </p-cellEditor>
                                   </td>
                                   <td >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="w-7rem" pInputText type="text" [(ngModel)]="famille.code">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{famille.code}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                   <td class="w-1rem">
                                       <div class="flex align-items-center justify-content-center gap-0">
                                           <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(famille)" class="p-button-rounded p-button-text"></button>
                                           <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(famille)" class="p-button-rounded p-button-text p-button-success mr-1"></button>
                                           <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(famille, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                       </div>
                                   </td>
                                   
                               </tr>
                           </ng-template>
                    
                   </p-table>

                   <!-- <div class="flex justify-content-center ">

                   <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>


                   </div> -->

                   </div>
                </div> 
                <div class="col-4">
                
                    <div class="card card shadow-2  border-round-sm ml-0 " style="height: 600px;">
                       <div class="card-header">
                           <div class="title">
                               <h5>Collections</h5>
                           </div>
                           
                       </div>
                       <p-table 
                       [value]="collections"
                        dataKey="id" 
                        responsiveLayout="stack"
                        [scrollable]="true"
                        scrollHeight="400px"
                        editMode="row" 
                        [lazy]="true"  
                        (onLazyLoad)="loadSousCollection($event)">
                        <ng-template pTemplate="caption">
                            <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveCollection()"></button>
                            <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                            (click)="deleteCollection()"
                            [disabled]="!selectedCollection || !selectedCollection.length"></button>
                
                            <span class="p-input-icon-left mb-2 ml-0 w-8">
                               <i class="pi pi-search"></i>
                               <input pInputText type="text" #filter  [(ngModel)]="searchTermCollection" placeholder="Rechercher..." class="w-full"  (input)="getSearchCollection($event)" style="height: 35px;"/>
                
                           </span> 
                        </ng-template> 
                           <ng-template pTemplate="header">
                               <tr>
                                   <th  class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox  [binary]="true"
                                           name="checkbox{{rowIndex}}" 
                                           (onChange)="selectAllCollection($event)"></p-checkbox>
                                       </div>
                                   </th>
                                   <th  pSortableColumn="name" >Nom<p-sortIcon field="{{name}}"></p-sortIcon> </th>
                                   <th   pSortableColumn="code">Code <p-sortIcon field="{{code}}"></p-sortIcon></th>
                
                                     
                                   <th  class="w-1rem"></th>
                
                                 
                               </tr>
                           </ng-template>
                           <ng-template pTemplate="body" let-collection  let-editing="editing" let-ri="rowIndex">
                            <ng-container *ngIf="ri === 0 && newRowCollection">
                                <tr>
                                    <td></td>
                                  <td>
                                    <input class="w-7rem" pInputText type="text" [(ngModel)]="newCollection">
                
                                  </td>
                                  <td>
                                    <input class="w-7rem" pInputText type="text" [(ngModel)]="newCodeCollection">
                
                                  </td>
                                  <td>
                                    <button  pButton pRipple type="button"  icon="pi pi-check" (click)="AddCollection()" class="p-button-rounded p-button-text p-button-success ml-4 mr-2"></button>
                
                                  </td>
                                </tr>
                              </ng-container>
                               <tr [pEditableRow]="collection">
                                   <td  class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox
                                           [binary]="true"
                                           name="checkbox{{rowIndex}}"                            
                                           [(ngModel)]="collection.selected"
                                           (onChange)="checkboxChangedCollection($event, collection)"></p-checkbox>
                                       </div>
                                   </td>
                                   <td >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="w-7rem" pInputText type="text" [(ngModel)]="collection.name">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{collection.name}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input class="w-7rem" pInputText type="text" [(ngModel)]="collection.code">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{collection.code}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                
                                <td  class="w-1rem">
                                    <div class="flex align-items-center justify-content-center gap-2">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInitCollection(collection)" class="p-button-rounded p-button-text"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSaveCollection(collection)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelCollection(collection, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </div>
                                </td>
                               </tr>
                           </ng-template>
                    
                   </p-table>
                   </div>
                </div>
                <div class="col-4">
                
                    <div class="card card shadow-2  border-round-sm  ml-0" style="height: 600px;">
                       <div class="card-header">
                           <div class="title">
                               <h5>Rayons</h5>
                           </div>
                           
                       </div>
                       <p-table 
                       [value]="rayons"
                       dataKey="id"
                       responsiveLayout="stack"
                       [scrollable]="true"
                        scrollHeight="400px"
                        editMode="row" 
                        [lazy]="true"  
                        (onLazyLoad)="loadRayon($event)">
                        <ng-template pTemplate="caption">
                            <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveRayon()"></button>
                            <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                            (click)="deleteRayon()"
                            [disabled]="!selectedRayon || !selectedRayon.length"></button>
                
                            <span class="p-input-icon-left mb-2 ml-0 w-8">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" #filter [(ngModel)]="searchTermRayon" placeholder="Rechercher..." class="w-full" (input)="getSearchRayon($event)" style="height: 35px;"/>
                 
                            </span> 
                        </ng-template>
                           <ng-template pTemplate="header">
                               <tr>
                                   <th  class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox  [binary]="true"
                                           name="checkbox{{rowIndex}}"
                                           (onChange)="selectAllRayon($event)"></p-checkbox>
                                       </div>
                                   </th>
                                   <th  pSortableColumn="name">Nom <p-sortIcon field="{{name}}"></p-sortIcon></th>
                                   <th  pSortableColumn="code">Code<p-sortIcon field="{{code}}"></p-sortIcon> </th>
                
                                   <th  class="w-1rem"></th>
                                 
                               </tr>
                           </ng-template>
                           <ng-template pTemplate="body" let-rayon  let-editing="editing" let-ri="rowIndex">
                            <ng-container *ngIf="ri === 0 && newRowRayon">
                                <tr>
                                    <td></td>
                                  <td>
                                    <input class="w-7rem" pInputText type="text"   [(ngModel)]="newRayon">

                
                                  </td>
                                  <td>
                                    <input class="w-7rem" pInputText type="text" [(ngModel)]="newCodeRayon">
                
                                  </td>
                                  <td>
                                    <button  pButton pRipple type="button"  icon="pi pi-check" (click)="AddRayon()" class="p-button-rounded p-button-text p-button-success ml-4 mr-2"></button>
                
                                  </td>
                                </tr>
                              </ng-container>
                               <tr [pEditableRow]="rayon">
                                   <td  class="w-1rem">
                                       <div class="field-checkbox">
                                           <p-checkbox
                                           [binary]="true"
                                           name="checkbox{{rowIndex}}"
                                           [(ngModel)]="rayon.selected"                            
                                           (onChange)="checkboxChangedRayon($event, rayon)"></p-checkbox>
                                       </div>
                                   </td>
                                   <td  >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input  class="w-7rem" pInputText type="text" [(ngModel)]="rayon.name">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rayon.name}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td >
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input  class="w-7rem" pInputText type="text" [(ngModel)]="rayon.code">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rayon.code}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td  class="w-1rem">
                                    <div class="flex align-items-center justify-content-center gap-2">
                                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInitRayon(rayon)" class="p-button-rounded p-button-text"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSaveRayon(rayon)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelRayon(rayon, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </div>
                                </td>
                               </tr>
                           </ng-template>
                    
                   </p-table>
                   </div>
                </div>
                
                
                </div>

         </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Header II">
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-user"></i> -->
                <span>Dimension</span>
            </ng-template>
            <ng-template pTemplate="body">
                <div class="grid ">
    
    
                    <div class="col-5 ">
                    
                        <div class="card shadow-2  border-round-sm ml-0" style="height: 600px;">
                           <div class="card-header">
                               <div class="title">
                                   <h5>Couleurs</h5>
                                   <p-toast></p-toast>
                    
                               </div>
                               
                           </div>
                     
                           <p-table [value]="couleurs" dataKey="id"  responsiveLayout="stack" [scrollable]="true" scrollHeight="400px" editMode="row" 
                           [lazy]="true" (onLazyLoad)="loadCouleurs($event)" [loading]="loading" >
                               <ng-template pTemplate="caption">
                                   <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveCouleurs()"></button>
                                   <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                                   (click)="deleteCouleur()"
                                   [disabled]="!selectedCouleurs || !selectedCouleurs.length"></button>
                    
                                   <span class="p-input-icon-left mb-2 ml-0 w-8 ">
                                       <i class="pi pi-search"></i>
                                       <input pInputText type="text" #filter [(ngModel)]="searchTermCouleur" placeholder="Rechercher..." class="w-full"  (input)="getSearchCouleur($event)" style="height: 35px;"/>
                        
                                   </span> 
                    
                               </ng-template>
                               <ng-template pTemplate="header">
                                   <tr>
                                       <th class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox  [binary]="true"
                                               name="checkbox{{rowIndex}}"
                                               (onChange)="selectAllCouleur($event)"></p-checkbox>
                                           </div>
                                       </th>
                                       <th pSortableColumn="name" >Nom <p-sortIcon field="{{name}}"></p-sortIcon></th>
                                       <th   pSortableColumn="code">Code <p-sortIcon field="{{code}}"></p-sortIcon></th>
                    
                                       <th class="w-1rem"></th>
                                   </tr>
                               
                               </ng-template>
                               <ng-template pTemplate="body" let-couleur let-editing="editing" let-ri="rowIndex" >
                                   <ng-container *ngIf="ri === 0 && newRowCouleur">
                                       <tr>
                                           <td></td>
                                         <td>
                                           <input class="w-8rem" pInputText type="text" [(ngModel)]="newCouleur">
                    
                                         </td>
                                         <td>
                                            <input  class="w-8rem" pInputText type="text" [(ngModel)]="newCodeCouleur">
                     
                                          </td>
                                         <td>
                                           <button  pButton pRipple type="button"  icon="pi pi-check" (click)="AddCouleur()" class="p-button-rounded p-button-text p-button-success ml-4 mr-2"></button>
                    
                                         </td>
                                       </tr>
                                     </ng-container>
                                   <tr [pEditableRow]="couleur">
                    
                                       <td class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox
                                               [binary]="true"
                                               name="checkbox{{rowIndex}}"  
                                               [(ngModel)]="couleur.selected"
                                               (onChange)="checkboxChangedCouleur($event, couleur)" ></p-checkbox>
                                           </div>
                                       </td>
                    
                                       <td >
                                           <p-cellEditor>
                                               <ng-template pTemplate="input">
                                                   <input class="w-7rem" pInputText type="text" [(ngModel)]="couleur.name">
                                               </ng-template>
                                               <ng-template pTemplate="output">
                                                   {{couleur.name}}
                                               </ng-template>
                                           </p-cellEditor>
                                       </td>
                                       <td >
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="w-7rem" pInputText type="text" [(ngModel)]="couleur.code">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{couleur.code}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                       <td class="w-1rem">
                                           <div class="flex align-items-center justify-content-center gap-0">
                                               <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInitCouleur(couleur)" class="p-button-rounded p-button-text"></button>
                                               <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSaveCouleur(couleur)" class="p-button-rounded p-button-text p-button-success mr-1"></button>
                                               <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelCouleur(couleur, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                           </div>
                                       </td>
                                       
                                   </tr>
                               </ng-template>
                        
                       </p-table>
                       </div>
                    </div> 
                    <div class="col-4">
                    
                        <div class="card card shadow-2  border-round-sm ml-0 " style="height: 600px;">
                           <div class="card-header">
                               <div class="title">
                                   <h5>Tailles</h5>
                               </div>
                               
                           </div>
                           <p-table 
                           [value]="tailles"
                           dataKey="id"
                            responsiveLayout="stack"
                            [scrollable]="true"
                            scrollHeight="400px" 
                            editMode="row" 
                            [lazy]="true" 
                            (onLazyLoad)="loadSousTaille($event)">
                            <ng-template pTemplate="caption">
                                <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveTaille()"></button>
                                <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                                (click)="deleteTailles()"
                                [disabled]="!selectedTaille || !selectedTaille.length"></button>
                    
                                <span class="p-input-icon-left mb-2 ml-0 w-8">
                                   <i class="pi pi-search"></i>
                                   <input pInputText type="text" #filter [(ngModel)]="searchTermTaille" placeholder="Rechercher..." class="w-full"  (input)="getSearchTaille($event)" style="height: 35px;"/>
                    
                               </span> 
                            </ng-template> 
                               <ng-template pTemplate="header">
                                   <tr>
                                       <th  class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox  [binary]="true"
                                               name="checkbox{{rowIndex}}" 
                                               (onChange)="selectAllTaille($event)"></p-checkbox>
                                           </div>
                                       </th>
                                       <th pSortableColumn="name">Nom <p-sortIcon field="{{name}}"></p-sortIcon></th>
                                       <!-- <th  >Code </th> -->
                    
                                         
                                       <th  class="w-1rem"></th>
                    
                                     
                                   </tr>
                               </ng-template>
                               <ng-template pTemplate="body" let-taille  let-editing="editing" let-ri="rowIndex">
                                <ng-container *ngIf="ri === 0 && newRowTaille">
                                    <tr>
                                        <td></td>
                                      <td>
                                        <input class="w-7rem" pInputText type="text" [(ngModel)]="newTaille">
                    
                                      </td>
                                      <!-- <td>
                                        <input class="w-7rem" pInputText type="text" [(ngModel)]="newCodeTaille">
                    
                                      </td> -->
                                      <td>
                                        <button  pButton pRipple type="button"  icon="pi pi-check" (click)="AddTaille()" class="p-button-rounded p-button-text p-button-success ml-4 mr-2"></button>
                    
                                      </td>
                                    </tr>
                                  </ng-container>
                                   <tr [pEditableRow]="taille">
                                       <td  class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox
                                               [binary]="true"
                                               name="checkbox{{rowIndex}}"                            
                                               [(ngModel)]="taille.selected"
                                               (onChange)="checkboxChangedTaille($event, taille)"></p-checkbox>
                                           </div>
                                       </td>
                                       <td >
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="w-7rem" pInputText type="text" [(ngModel)]="taille.name">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{taille.name}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <!-- <td >
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="w-7rem" pInputText type="text" [(ngModel)]="taille.code">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{taille.code}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td> -->
                    
                                    <td  class="w-1rem">
                                        <div class="flex align-items-center justify-content-center gap-2">
                                            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInitTaille(taille)" class="p-button-rounded p-button-text"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSaveTaille(taille)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelTaille(taille, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                                        </div>
                                    </td>
                                   </tr>
                               </ng-template>
                        
                       </p-table>
                       </div>
                    </div>
                    <div class="col-3">
                    
                        <div class="card card shadow-2  border-round-sm  ml-0" style="height: 600px;">
                           <div class="card-header">
                               <div class="title">
                                   <h5>Masque</h5>
                               </div>
                               
                           </div>
                           <p-table 
                           [value]="masque"
                            dataKey="id" 
                            [responsive]="true"
                            responsiveLayout="stack"
                            [scrollable]="true"
                            scrollHeight="400px"
                            editMode="row" 
                            [lazy]="true"
                             (onLazyLoad)="loadMasque($event)">
                            <ng-template pTemplate="caption">
                                <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success mr-2 mb-2"  (click)=" saveMasque()"></button>
                                <button  pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger mr-2 mb-2 " style="  background-color: #EF4444;" 
                                (click)="deleteMasques()"
                                [disabled]="!selectedMasque || !selectedMasque.length"></button>
                    
                                <span class="p-input-icon-left mb-2 ml-0 w-8">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" #filter [(ngModel)]="searchTermMasque" placeholder="Rechercher..." class="w-full" (input)="getSearchMasque($event)" style="height: 35px;"/>
                     
                                </span> 
                            </ng-template>
                               <ng-template pTemplate="header">
                                   <tr>
                                       <th  class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox  [binary]="true"
                                               name="checkbox{{rowIndex}}"
                                               (onChange)="selectAllMasque($event)"></p-checkbox>
                                           </div>
                                       </th>
                                       <th pSortableColumn="name">Nom <p-sortIcon field="{{name}}"></p-sortIcon></th>                    
                                       <th  class="w-1rem"></th>
                                     
                                   </tr>
                               </ng-template>
                               <ng-template pTemplate="body" let-masque  let-editing="editing" let-ri="rowIndex">
                  
                                   <tr>
                                       <td  class="w-1rem">
                                           <div class="field-checkbox">
                                               <p-checkbox
                                               [binary]="true"
                                               name="checkbox{{rowIndex}}"
                                               [(ngModel)]="masque.selected"                            
                                               (onChange)="checkboxChangedMasque($event, masque)"></p-checkbox>
                                           </div>
                                       </td>
                                       <td  >
                                        {{masque.name}}
                                    </td>
                                
                                    <td  class="w-1rem">
                                        <div class="flex align-items-center justify-content-center gap-2">
        
                                            <button  pButton pRipple type="button"  icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="ConsulterMasque(masque, ri)"></button>


                                        </div>
                                    </td>
                                   </tr>
                                   
                               </ng-template>
                       </p-table>
                       </div>
                    </div>
                    
                    
                    </div>
    
             </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Header III">
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-search"></i> -->
                <span>Gestion</span>
                <!-- <i class="pi pi-cog"></i> -->
            </ng-template>
           <ng-template pTemplate="body">
            <div class="grid  ">
            <div class="card shadow-2  border-round-sm ml-0" >
                <div class="card-header">
                    <div class="title">
                        <!-- <h5>Sous-Familles</h5> -->
                        <p-toast></p-toast>
                    </div>
                </div>
               
                <div class="formgrid grid">
                    <div class="field col-6 md:col-3">
                        <label for="firstname6">Nom Société</label>
                        <input id="firstname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.nomSociete}" [(ngModel)]="companyInfo.nomSociete" (input)="inputChange('nomSociete',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="lastname6">Nom</label>
                        <input id="lastname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.nom}"  [(ngModel)]="companyInfo.nom" (input)="inputChange('nom',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="firstname6">Adressse</label>
                        <input id="firstname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.adresse}"  [(ngModel)]="companyInfo.adresse" (input)="inputChange('adresse',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <!-- <label for="lastname6">Numéro Registration</label> -->
                        <label for="lastname6">Code TVA</label>
                        <input id="lastname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.numeroRegistration}"   [(ngModel)]="companyInfo.numeroRegistration" (input)="inputChange('numeroRegistration',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>

                    <div class="field col-6 md:col-3">
                        <label for="firstname6">Telephone </label>
                        <input id="firstname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.telephone}"  [(ngModel)]="companyInfo.telephone" (input)="inputChange('telephone',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="lastname6">RC</label>
                        <input id="lastname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.rc}"  [(ngModel)]="companyInfo.rc" (input)="inputChange('rc',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="firstname6">Fax</label>
                        <input id="firstname6"  [(ngModel)]="companyInfo.fax" (input)="inputChange('fax',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="lastname6">E-Mail </label>
                        <input id="lastname6"  [(ngModel)]="companyInfo.email" (input)="inputChange('email',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>

                    <div class="field col-6 md:col-3">
                        <label for="lastname6">Tax Identification</label>
                        <input id="lastname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.taxIdentification}"  [(ngModel)]="companyInfo.taxIdentification" (input)="inputChange('taxIdentification',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="firstname6">Route Adresse</label>
                        <input id="firstname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.routeAdresse}"  [(ngModel)]="companyInfo.routeAdresse" (input)="inputChange('routeAdresse',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-6 md:col-3">
                        <label for="lastname6">Image URL</label>
                        <input id="lastname6" [ngClass]="{'empty-field': fieldsEmpty && !companyInfo.imageURL}"  [(ngModel)]="companyInfo.imageURL" (input)="inputChange('imageURL',$event)" type="text" class="border-blue-500   p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
            </div>

            <div class="col-8">

            <div class="card shadow-2  border-round-sm ml-0 " >
                <!-- <div class="col"> -->

                <div class="p-fluid formgrid grid  ">

                <div class="field col">
                    <label  >TVA</label>
                    <p-inputNumber  mode="decimal" (onInput)="inputNumberChange('tva',$event)"  [(ngModel)]="additionalInfo.tva"  [showButtons]="true" [min]="0" [max]="100" ></p-inputNumber>
                    </div>

                <div class="field col">
                    <label  >Timbre</label>
                    <p-inputNumber  mode="decimal" (onInput)="inputNumberChange('timbre',$event)"  [(ngModel)]="additionalInfo.timbre"  [showButtons]="true" [min]="0" [max]="100" ></p-inputNumber>
                    </div>

                    <div class="field col">
                        <label  >Fodec</label>
                        <p-inputNumber  mode="decimal" (onInput)="inputNumberChange('fodec',$event)"  [(ngModel)]="additionalInfo.fodec"  [showButtons]="true" [min]="0" [max]="100" ></p-inputNumber>
                        </div>

                        
                </div>

               

                </div>

            </div>

            <div class="col-4">

                <div class="card shadow-2  border-round-sm ml-0 h-8rem " >
                    <div class="formgroup-inline">

                        <label class="mr-4">Dimensionné</label>
                        <p-inputSwitch  (onChange)="inputSwitchChange('dimensionne',$event)" [(ngModel)]="additionalInfo.dimensionne"  ></p-inputSwitch>
    
                        </div>
                </div>
            </div>

            </div>

            <div  class="flex flex-row-reverse flex-wrap mt-4 ">
                <!-- <button pButton pRipple type="button" label="Modifier" class="p-button mr-2 " style="background-color: #3B82F6;font-weight: bold; font-size: 20px;" ></button> -->
                <button pButton pRipple type="button" [disabled]="disableEnregistrer" label="Enregistrer" class="p-button-success " style="font-weight: bold; font-size: 20px;" (click)="enregistrerConfig()"></button>
               </div>   
           </ng-template>
          
        </p-tabPanel>
    </p-tabView>
</div>
<app-delete-dialog (newItemEvent)="receiveConfirmationMasque($event)" (newItemEvent)="receiveConfirmationTaille($event)" (newItemEvent)="receiveConfirmation($event)" (newItemEvent)="receiveConfirmationCollection($event)" (newItemEvent)="receiveConfirmationCouleur($event)" (newItemEvent)="receiveConfirmationRayon($event)"></app-delete-dialog>
<app-add-masque ></app-add-masque>