
    
		<!-- dialog code -->		
    <p-dialog header="Header" [(visible)]="visible" [style]="{ width: '20vw' }">
		
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Ajouter Utilisateur</span>
        </ng-template>  
		<div class="card p-fluid">
			
			<div class="field">
				<label htmlFor="name1">Nom de l'Utilisateur</label>
				<input pInputText id="name1" type="text" />
			</div>
			<div class="field">
				<label htmlFor="email1">Email</label>
				<input pInputText id="email1" type="text" />
			</div>
			<div class="field">
                <label for="password">Mot de passe</label>
                <p-password ></p-password>
            </div>
			<div class="field">
				<label for="Role">Role</label>
					<p-dropdown inputId="role" [autoDisplayFirst]="false" [options]="roles" optionLabel="name"></p-dropdown>
					
			
			</div>
			<div class="field">
				<label for="multiselect">Depot</label>
					
					<p-multiSelect [options]="stores" optionLabel="name"></p-multiSelect>
			
			</div>

		</div>
		

	
        <ng-template pTemplate="footer">
			<button pButton pRipple   (click)="visible = false" label="annuler" class="p-button-danger"></button>
			<button pButton pRipple   (click)="visible = false" label="confirmer" class="p-button-success"></button>
        </ng-template>
    </p-dialog>

<!-- dialog code -->

<!-- table code -->

<div class="grid">
	<div class="col-12">
		<div class="card">

		
			<h5>Liste des utilsateurs</h5>
			<p-table [columns]="cols" [value]="users" styleClass="p-datatable-gridlines" [globalFilterFields]="['Nom']" >
				

				<ng-template pTemplate="caption">
<p-button  (click)=" showDialog('top')" icon="pi pi-user" styleClass="p-button-rounded p-button-info p-button-text-rigth"></p-button>
				</ng-template>
				<ng-template pTemplate="header" let-columns>
					
					<tr>
						<th *ngFor="let col of columns">
							<div  *ngIf="col.field=='username'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="username" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='role'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="role" display="menu" placeholder="Search by role"></p-columnFilter>
						</div>


						<div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="date" display="menu" ></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='blocked'"class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter type="text" field="blocked" display="menu" ></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='confirmed'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="confirmed" display="menu" ></p-columnFilter>
						</div>

						
						<div  *ngIf="col.field=='email'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="email" display="menu" ></p-columnFilter>
						</div>

						
						</th>
						
						
						
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
						<td style="min-width: 12rem;" *ngFor="let col of columns">
							{{ rowData[col.field] }}
<div  *ngIf="col.field=='confirmed'||col.field=='blocked'"class="flex justify-content-between align-items-center">
	<p-inputSwitch inputId="switch1"  ></p-inputSwitch>
	
						</div>

						<div  *ngIf="col.field=='email'"class="flex justify-content-between align-items-center">
							
							<i  class="pi pi-sync"  style=" align-items: center;justify-content: center ;font-size: 1.3rem" ></i>
												</div>

							<p-cellEditor>
								<ng-template pTemplate="input">
									<input pInputText type="text" [(ngModel)]="users.role">
								</ng-template>
							</p-cellEditor>
						</td>
						
							
							
						
					</tr>
				</ng-template>
			</p-table>
		</div></div></div>
		<!-- table code -->