import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AchatsService } from '../demo/service/achats.service';
import { ColumnFilterFormElement } from 'primeng/table';
import {ConfigService} from '../demo/service/app.config.service';
import { NodeService } from '../demo/service/nodeservice';
import { JsonpClientBackend } from '@angular/common/http';
import { ProductService } from '../demo/service/productservice';
import { ArticleService } from '../demo/service/article.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
interface Achat{
  product:string;
  fournisseur;
  store;
  prixAchat;
  variations
  // // image:string;
  // taille:number;
  // couleur:string;
  // qte:number
}
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-achat',
  templateUrl: './achat.component.html',
  //styleUrls: ['./achat.component.scss']
})
export class AchatComponent implements OnInit {
    @ViewChild('team', { static: true }) teamTable: any;
    @ViewChild('ff', { static: true }) ff: any;
    @ViewChild('colorIndex') colorIndexRef!: ElementRef;
    @ViewChild('sizeIndex') sizeIndexRef!: ElementRef;



products
S=[]
c
//tab=[{taille:37},{taille:36},{taille:44}]
tab =[{ t36:1,t37:11,color:"bleu",},{ t36:1,t37:0,color:"red",},{ t36:0,t37:1,color:"green",}
]

colorSizeData: { size: string, colors: string[] }[] = [];
editVarDialog :boolean
selectedFournisseur
prixAchat
selectedstore
size
color
ColorsSizes
achats
intialColorsSize;
newAchat:Achat
inputValues: any[] = []; // Array to hold input values for each combination of size and color
savedValues: any[] = []; // Array to hold saved input values with size and color
listfournisseurs
liststore : any[];
listAchats
achatVariations
// expanded: boolean = false; // Variable to control the visibility of the table
  tableData: any[] = [];
filteredstore
filteredFourniss: any[];
productsFilter
variationsFilter
submitted: boolean;
achattDialog: boolean;
selectedProductAdvanced  
selectedVariationAdvanced 
filteredProducts: any[];
filteredVariations: any[];
filters=[]
expandedRows: boolean[] = [];
paginationInfo: any;
currentPage: number=1;
pageSize: number;
totalItems: number;
pageNumbers: number[];
currentpage
DataToSend

 x=1
colsV = [


  { field: 'taille', header: 'taille' },
  

  { field: 'color', header: 'couleur' }, 
  { field: 'quantité', header: 'quantité' },]
 cols = [
  // { field: 'button', header: "button" },
    { field: 'codeA', header: "code article" },
    { field: 'store', header: 'store' },
    { field: 'image', header: 'image' },
    { field: 'Pachat', header: 'prix Achat' },
    { field: 'idAchat', header: 'idAchat' },

    // { field: 'taille', header: 'taille' },
 
    // { field: 'color', header: 'couleur' },
    
    
];
colsWithoutExcluded = this.cols.filter(col => col.field !== 'idAchat');

cols2 = [
  { field: 'idAchat', header: 'idAchat' },
  { field: 'codeB', header: "code a barre" },
  { field: 'qte', header: 'quantité' },
  { field: 'couleur', header: 'couleur' },
  { field: 'taille', header: 'taille' },
  // { field: 'taille', header: 'taille' },

  // { field: 'color', header: 'couleur' },
  
  
];
colsWithoutExcluded2 = this.cols2.filter(col => col.field !== 'idAchat');

clos3=[
   { field: 'colors', header: "colors" },
{ field: 'sizes', header: 'sizes' },
]
 
dialogStyle = {'width': '50vw', 'height': '50vh'};

toggleRowExpansion(rowIndex: number) {
  console.log(rowIndex)
  this.expandedRows[rowIndex] = !this.expandedRows[rowIndex];
}


  constructor(public s:ArticleService, private service: AchatsService, public configService: ConfigService,private nodeService: NodeService,private productService : ProductService) { 
    this.loading = true;
  }
  async sendTheNewValue(event){
//  console.log(event.target.value)
 this.DataToSend=event.target.value
 this.service.sendData({code:this.DataToSend})
 await this.service.getProducts().then(data=>{
  console.log(data)
  this.productsFilter=data.data.map(x=>({
    id : x.id,
    code_article_generique : x.attributes.code_article_generique,
    libelle_article : x.attributes.libelle_article,
    prix_vente: x.attributes.prix_vente,
    reference : x.attributes.reference,
    image :x.attributes.image.data!=null?"https://prefacture.rosace.com.tn"+x.attributes.image.data.attributes.formats.thumbnail.url :"",
    variations : x.attributes.variations.data,
    fournisseur:{"name":x?.attributes?.fournisseur?.data?.attributes?.name,"id":x?.attributes?.fournisseur?.data?.id}
  }))
})
    // console.log(this.selectedFourniss)
    // console.log(event.target.value);
    // this.isChanged=event.target.value
    // if(this.isChanged ){
    //   this.disableSaveButton=false
  
    // }
    }
  async getAllAchat(){
    try {
      this.loading = true;
      const response:any = await this.service.getAllAchats().toPromise();
        

     this.listAchats = response['data'];
     this.paginationInfo=response.meta.pagination
   this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
      console.log(this.paginationInfo)

      console.log(this.listAchats)
       //map achat variations
     /*  if(this.listAchats){
        console.log(this.listAchats)
        for(const list of this.listAchats){
          this.achatVariations = this.getNestedProperty(list, ['attributes', 'achat_variations','data']).map(x=>
            {
              // console.log('Mapping item:', x);
              const mappedItem = {
                idAchat:this.getNestedProperty(list, ['id']),
          codeB: this.getNestedProperty(x, ['attributes', 'variation', 'data', 'attributes', 'code_barre']),
          qte: this.getNestedProperty(x, ['attributes', 'quantity', ]),
          couleur: this.getNestedProperty(x, ['attributes', 'variation', 'data', 'attributes', 'color','data', 'attributes', 'name']),
          taille: this.getNestedProperty(x, ['attributes', 'variation', 'data', 'attributes', 'taille','data', 'attributes', 'name']),
        
              };
              // console.log('Mapped item:', mappedItem);
    
              return mappedItem;
        
          }
            );
          console.log(this.achatVariations)
        }
       
      }
     */
      if (this.listAchats ) {
        this.achats=this.listAchats.map(x=>{
          // console.log('Mapping item:', x);
          const mappedItem = {
           idAchat:this.getNestedProperty(x, ['id']),

      codeA: this.getNestedProperty(x, ['attributes', 'product', 'data', 'attributes', 'code_article_generique']),
      store: this.getNestedProperty(x, ['attributes', 'store', 'data', 'attributes', 'name']),
      Pachat: this.getNestedProperty(x, ['attributes', 'prixAchat']),
      // color: this.getNestedProperty(x, ['attributes', 'variation', 'data', 'attributes', 'color', 'data', 'attributes', 'name']),
      // taille: this.getNestedProperty(x, ['attributes', 'variation', 'data', 'attributes', 'taille', 'data', 'attributes', 'name']),
      image: this.getNestedProperty(x, ['attributes', 'product', 'data', 'attributes', 'image', 'data', 'attributes', 'formats', 'thumbnail', 'url']) ?
        "https://prefacture.rosace.com.tn" + this.getNestedProperty(x, ['attributes', 'product', 'data', 'attributes', 'image', 'data', 'attributes', 'formats', 'thumbnail', 'url']) :
        '',
        // achat_variations:this.getNestedProperty(x,['attributes', 'achat_variations', 'data'])
        achat_variations: this.getNestedProperty(x, ['attributes', 'achat_variations', 'data' ]),


          };
          // console.log('Mapped item:', mappedItem);

          return mappedItem;
    
      })
      console.log(this.achats)

     
      


    }
    } catch (error) {
      console.error('Error fetching listAchats:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
  }
  showRowData(rowdata){
    console.log(rowdata)
  }
  // toggleRowExpansion(rowData: any, listAchats: any): void {
  //   console.log("listAchat:", listAchats);

  //   console.log("rowData:", rowData);

  //   if (!rowData?.attributes?.achat_variations) {
  //     // Data doesn't exist for this row, handle as needed
  //     console.log("Data does not exist for this row. Row expansion not allowed.");
  //     rowData.expanded = !rowData.expanded;

  //     // rowData.buttonDisabled = true;
  //     return; // Prevent further execution
  // }
  // Toggle the expansion state using PrimeNG's toggleRow method
//   listAchats.toggleRow(rowData);
    
// }
   getNestedProperty(obj: any, keys: string[]): any {
    return keys.reduce((acc, key) => (acc && acc[key]) ? acc[key] : '', obj);
  }
  // toggleTable() {
  //   this.expanded = !this.expanded; // Toggle the visibility of the table
  // }
  oldFilters  : object = {}
  testmed : object = {}
async   ngOnInit() : Promise<void>{
  // this.loading = true;
 /* setTimeout(() => {
    // this.getProduct()
          this.loading = false;
          
      }, 3000);*/
      
  this.onPageChange({ page: 0 });
  await  this.getAllAchat()
     console.log(this.listAchats)
     

    //  for(const list of this.listAchats){
    //   console.log(list['id'])
    //  }
     
  console.log(this.ff)
  this.testmed["test"]="test"
//    await this.configService.getFilter().then(data=>{
//      this.filters=data.data

//  }) 
 await this.getfournisseur()
  //  await this.getAchats([])
  //  await this.getStore()
   this.S=Object.keys(this.tab[0]);
   this.c=Object.values(this.tab)
   /* Object.keys(this.teamTable.filters).forEach(res=>{
      this.oldFilters[res]=[]
   // this.oldFilters[res]=this.teamTable.filters[res]
   this.teamTable.filters[res].forEach(r=>{
    //this.oldFilters[res].push(r)
    console.log("tetetet")
   })
  }) */
  //await this.setOldFilter(this.teamTable.filters)
    console.log(this.oldFilters)
    await this.service.getProducts().then(data=>{
      console.log(data)
      this.productsFilter=data.data.map(x=>({
        id : x.id,
        code_article_generique : x.attributes.code_article_generique,
        libelle_article : x.attributes.libelle_article,
        prix_vente: x.attributes.prix_vente,
        reference : x.attributes.reference,
        image :x.attributes.image.data!=null?"https://prefacture.rosace.com.tn"+x.attributes.image.data.attributes.formats.thumbnail.url :"",
        variations : x.attributes.variations.data,
        fournisseur:{"name":x.attributes.fournisseur.data.attributes.name,"label":x.attributes.fournisseur.data.id}
      }))
    })
   // console.log(this.productsFilter)
  }

  customFilterCallback(event,tt){
  console.log(event,tt)
  }

async setOldFilter(data){
console.log(data)
  let d=data
  
  Object.keys(d).map(res=>{

  this.oldFilters[res]= d.res

}) 
this.oldFilters=d

}
enlargeDialog() {
  this.dialogStyle = {'width': '100%', 'height': '100%'};
}
 async onFilter(event,team)   {
  console.log(this.oldFilters,event)
  if(this.oldFilters/*!={}*/)
 { if( (JSON.stringify(event.filters  )===JSON.stringify(this.oldFilters  ))){
    console.log('====')
  }else{
    this.setOldFilter(event.filters)
  }
 }else{
  this.setOldFilter(event.filters)
 }
 
  }
  /*async getAchats(filters) {
    //This function displays the user's information
     await this.service.getAchats(filters).then( result => {
console.log(result)
console.log(result['data'])
      let p=result['data'].map(x=>({
      codeA:x.attributes.product.data.attributes.code_article_generique,
      store:x.attributes.store.data.attributes.name,
      Pvente:x.attributes.product.data.attributes.prix_vente,
      color :x.attributes.variation.data.attributes.color.data.attributes.name,
      taille:x.attributes.variation.data.attributes.taille.data.attributes.name,
      image: x.attributes.product.data.attributes.image.data!=null?"https://prefacture.rosace.com.tn"+x.attributes.product.data.attributes.image.data.attributes.formats.thumbnail.url :""


  


     }))
     console.log(p)
     if(this.products ===p){

     }else
     {
       this.products=p}
   
  
 
    });

 
}*/
loading: boolean = false;
// loadNodes(event: any) {
  
//  // console.log(event)
//   this.loading = true;
//   setTimeout(() => {
//       this.loading = false;
      
//   }, 1000);
// }
hideDialog() {
  this.achattDialog = false;
  this.submitted = false;
  this.selectedProductAdvanced=''
  this.selectedstore=''
  this.selectedFournisseur=''
  this.prixAchat=''
}
openNew() {
 // this.product = {};
  this.submitted = false;
  this.achattDialog = true;
}
filterProduct(event) {
  //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  let filtered: any[] = [];
  let query = event.query;
  for (let i = 0; i < this.productsFilter.length; i++) {
    let productsFilter = this.productsFilter[i];
    if (productsFilter.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
      filtered.push(productsFilter);
    }
  }

  this.filteredProducts = filtered;
 //console.log(this.filteredProducts)
 // this.selectedFournisseur=
}
/*filterVariation(event) {
  
  //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  let filtered: any[] = [];
  let query = event.query;
  
   for (let i = 0; i < this.variationsFilter.length; i++) {
    let variationsFilter = this.variationsFilter[i];
   
     if (variationsFilter.code_barre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
      filtered.push(variationsFilter);
    } 

  }

  this.variationsFilter = filtered;
  console.log(this.variationsFilter)
}*/
saveProduct(){
  /*ancien code */
  console.log(this.selectedProductAdvanced,this.selectedVariationAdvanced)
  const variations=[]

for(const val of this.variationsFilter){
  for(const value of this.savedValues){
  if(val['taille']==value['size'] && val['color']==value['color']){
   variations.push({"variation":val['id'],"quantity":value['value']})
  }
}  }
  //  let newAchat:Achat;
  //  const colorindexvalue=this.colorIndexRef.nativeElement.getAttribute('data-color-index');
   this.newAchat={
    product:this.selectedProductAdvanced['id'],
    store:this.selectedstore['value'],
    fournisseur:this.selectedFournisseur['id'],
    variations:variations,
    prixAchat:this.prixAchat
    
   }
  //  console.log(JSON.stringify(this.newAchat))
  console.log(this.newAchat)
this.service.saveAchat(this.newAchat).subscribe(response =>{console.log(response)
const  mappedItem = {
  idAchat:response['achat'].id,

codeA: response['achat']['product']['code_article_generique'],
store:response['achat']['store']['name'],
Pachat:response['achat']['prixAchat'],
//x.attributes.image.data!=null?"https://prefacture.rosace.com.tn"+x.attributes.image.data.attributes.formats.thumbnail.url :""
image: response['achat']['product']['image']!=null?"https://prefacture.rosace.com.tn"+response['achat']['product']['image']['formats']['small']['url']:"",
achat_variations: response['achatVariations']

 };
console.log(mappedItem)
this.achats.unshift(mappedItem)
}



)
  //  this.newAchat
  console.log (this.listAchats)
 console.log (this.achats)
  this.hideDialog()
}

checkCombinationExists(size: string, color: string): boolean {
  return (
    this.intialColorsSize.hasOwnProperty(size) &&
    this.intialColorsSize[size].includes(color)
  );
}

updateInputValue(newValue: number, sizeIndex: number, colorIndex: number) {
  // Update the inputValues array with the new value
  // this.inputValues[sizeIndex * this.variationsFilter[this.variationsFilter.length - 1]['colors'].length + colorIndex] = newValue;
  // console.log('size',sizeIndex,'color',colorIndex,this.inputValues)
  // console.log(this.InitialColorsSizes)
  this.saveInputValue(sizeIndex, colorIndex, newValue);
  console.log(this.savedValues)

}
saveInputValue(sizeIndex: number, colorIndex: number, value: number) {
  // Remove any existing saved value for the given size and color
  this.removeSavedValue(sizeIndex, colorIndex);
  
  // Save the input value along with size and color
  const size = this.variationsFilter[this.variationsFilter.length - 1]['sizes'][sizeIndex];
  const color = this.variationsFilter[this.variationsFilter.length - 1]['colors'][colorIndex];
  
  this.savedValues.push({ size, color, value });
  // console.log(this.savedValues)
}
removeSavedValue(sizeIndex: number, colorIndex: number) {
  // Remove existing saved value for the given size and color
  const size = this.variationsFilter[this.variationsFilter.length - 1]['sizes'][sizeIndex];
  const color = this.variationsFilter[this.variationsFilter.length - 1]['colors'][colorIndex];
  
  const indexToRemove = this.savedValues.findIndex(savedValue =>
    savedValue.size === size && savedValue.color === color
  );
  
  if (indexToRemove !== -1) {
    this.savedValues.splice(indexToRemove, 1);
  }
}

changeProd(event){
  //console.log(this.selectedProductAdvanced)
  console.log(event)
 this.selectedFournisseur={"id":event.fournisseur.id,"name":event.fournisseur.name}
 console.log(this.selectedFournisseur)
  console.log(event.variations)
  this.variationsFilter=event.variations.map(x=>({
    id : x.id,
    code_barre : x.attributes? x.attributes.code_barre :"",
    color : x.attributes? x?.attributes?.color?.data?.attributes?.name :"",
    taille : x.attributes? x?.attributes?.taille?.data?.attributes?.name :""}))
  // Extract distinct colors and sizes
  const distinctColors = [...new Set(this.variationsFilter.map(item => item.color))];
  const distinctSizes = [...new Set(this.variationsFilter.map(item => item.taille))];

  // Add a single object with distinct colors and sizes
  this.variationsFilter.push({
    colors: distinctColors,
    sizes: distinctSizes
  });
  console.log(this.variationsFilter)

// console.log(this.variationsFilter[3]['colors'])
// console.log(this.variationsFilter[3]['sizes'])
// this.ListOfSizes=this.variationsFilter[3]['sizes']
console.log(this.variationsFilter[this.variationsFilter.length - 1])
// console.log(this.ListOfSizes)
      console.log("this.variationsFilter",this.variationsFilter)
      // console.log(this.size)
this.ColorsSizes=[this.variationsFilter[this.variationsFilter.length - 1]]

for (let size of this.variationsFilter[this.variationsFilter.length - 1]['sizes']) {
  const colorsForSize = [...this.variationsFilter[this.variationsFilter.length - 1]['colors']];
  this.colorSizeData.push({ size: size, colors: colorsForSize });
}
console.log(this.colorSizeData)
console.log(this.findMissingCombinations())

}
/*findMissingCombinations() {
  // const missingCombinations = [];
  const availableColors = [];
  const sizeColorsMapping = {}; // New component property to hold size and filtered colors

  for(const variation of this.variationsFilter){
    for(const val of this.colorSizeData ){

      if(val['size']==variation['taille']){
      availableColors.push(variation['color'])
      
      }}
      /*   // Remove colors from val colors that don't exist in available colors
    const filteredColors = val['colors'].filter(color => availableColors.includes(color));

    // Update the val colors with the filtered colors
    val['colors'] = filteredColors;//
    for (const val of this.colorSizeData) {
      if (val['size'] === variation['taille']) {
        const filteredColors = val['colors'].filter(color => availableColors.includes(color));
        sizeColorsMapping[val['size']] = filteredColors; // Store size and filtered colors
      }
    }
  }

  
    return sizeColorsMapping

}*/
findMissingCombinations() {
  const sizeColorsMapping = {}; // New component property to hold size and filtered colors

  for (const val of this.colorSizeData) {
    const availableColors = [];

    for (const variation of this.variationsFilter) {
      if (val['size'] === variation['taille']) {
        availableColors.push(variation['color']);
      }
    }

    const filteredColors = val['colors'].filter(color => availableColors.includes(color));
    sizeColorsMapping[val['size']] = filteredColors; // Store size and filtered colors
  }

  //  sizeColorsMapping; // Assign the new property
this.intialColorsSize=sizeColorsMapping
  return sizeColorsMapping;
}

async getStore(): Promise<any[]>{
 /* await this.service.getStore().then(data=>{
    this.liststore=data.data.map(x=>({
      id:x.id,
name:x.attributes.name}))
    console.log('STORE',this.liststore)
    
  })*/
  try {
    const data = await this.service.getStore();
    return data.data.map(x => ({
      value: x.id,
      label: x.attributes.name
    }));
  } catch (error) {
    throw error;
  }
  
 
}


async filterstore(event) {
  try {
    const store = await this.getStore(); // Await the promise to get the resolved data
    console.log(store); // The resolved data from the Promise
    
    const filtered: any[] = [];
    const query = event.query;
    
    for (let i = 0; i < store.length; i++) {
      const country = store[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    }

    this.filteredstore = filtered;
  } catch (error) {
    console.error(error);
  }

}

async getfournisseur(){
  await this.service.getfournisseur().then(data=>{
    this.listfournisseurs=data.data.map(x=>({
      id:x.id,
name:x.attributes.name}))
    console.log('fournisseur',this.listfournisseurs)
    
  })
  

}
filterFourniss(event) {
  
  const filtered: any[] = [];
  const query = event.query;
  for (let i = 0; i < this.listfournisseurs.length; i++) {
      const country = this.listfournisseurs[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredFourniss = filtered;
 console.log(this.filteredFourniss)

}

async editvariation(v) {
  console.log("v",v)

  this.editVarDialog =true
}
async onPageChange(event) {
  /*this.loading = true;
  setTimeout(() => {
    // this.getProduct()
          this.loading = false;
          
      }, 3000);*/
  console.log(event)
  this.currentPage=event.page
  console.log(this.currentPage)
  await this.service.sendData({page:this.currentPage+1})
  await this.getAllAchat()
   
 }
 close(){

 }
}
