import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {IconsComponent} from './utilities/icons.component';

import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {BlocksComponent} from './blocks/blocks/blocks.component';
import { UsersComponent } from './pages/users.component';
import { AchatComponent } from './pages/achat.component';
import { ArticleComponent } from './pages/article.component';
import { FournisseurComponent } from './pages/fournisseur.component';
import { ProductComponent } from './pages/product/product.component';
import { AddProductComponent } from './pages/add-product/add-product.component';
import { AddVariationComponent } from './pages/add-variation/add-variation.component';
import { StockArticleComponent } from './pages/stock-article/stock-article.component';
import { AddFournisseurComponent } from './pages/fournisseur/add-fournisseur/add-fournisseur.component';
import { ImportProductComponent } from './pages/import-product/import-product.component';
import { ListProductsComponent } from './pages/list-products/list-products.component';
import { ListFournisseursComponent } from './pages/list-fournisseurs/list-fournisseurs.component';
import { ListArticlesComponent } from './pages/list-articles/list-articles.component';
import { AddArticleComponent } from './pages/add-article/add-article.component';
import { AchatsComponent } from './pages/achats/achats.component';
import { AddAchatArticleComponent } from './pages/add-achat-article/add-achat-article.component';
import { VentesComponent } from './pages/ventes/ventes.component';
import { AddVenteDialogComponent } from './pages/add-vente-dialog/add-vente-dialog.component';
import { ListFournissComponent } from './pages/list-fourniss/list-fourniss.component';
import { SaisietransfertComponent } from './pages/saisietransfert/saisietransfert.component';
import { AddTransfertComponent } from './pages/add-transfert/add-transfert.component';
import { GenererVenteComponent } from './pages/generer-vente/generer-vente.component';
import { ConsultationTicketComponent } from './pages/consultation-ticket/consultation-ticket.component';
import { DetailsTicketComponent } from './pages/details-ticket/details-ticket.component';
import { FactureTicketPDFComponent } from './pages/facture-ticket-pdf/facture-ticket-pdf.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MouvementStockComponent } from './pages/mouvement-stock/mouvement-stock.component';
import { ConsulteAchatComponent } from './pages/consulte-achat/consulte-achat.component';
import { ConsulteDetailsAchatComponent } from './pages/consulte-details-achat/consulte-details-achat.component';
import { HomeComponent } from './pages/home/home.component';
import { ConsultationFactureComponent } from './pages/consultation-facture/consultation-facture.component';
import { ConsulteTransfertComponent } from './pages/consulte-transfert/consulte-transfert.component';
import { ConsulteDetailsTransfertComponent } from './pages/consulte-details-transfert/consulte-details-transfert.component';
import { ModuledraftComponent } from './module/moduledraft/moduledraft.component';
import { ClientGestionComponent } from './pages/client-gestion/client-gestion.component';
import { AddClientComponent } from './pages/ClientGestion/add-client/add-client.component';
import { ConsulteArticleComponent } from './pages/consulte-article/consulte-article.component';
import { ModuleStockComponent } from './pages/module-stock/module-stock.component';
import { SousfamilleComponent } from './pages/sousfamille/sousfamille.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { RayonComponent } from './pages/rayon/rayon.component';
import { ParametreComponent } from './pages/parametre/parametre.component';
import { AddMasqueComponent } from './pages/add-masque/add-masque.component';
@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: 'dashboard', component: AddClientComponent},
                     {path: 'dashboard', component: DashboardDemoComponent},
                    {path: 'pages/add-client/add-client', component: HomeComponent},
                    {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                    {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                    {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
                    {path: 'uikit/input', component: InputDemoComponent},
                    {path: 'uikit/button', component: ButtonDemoComponent},
                    {path: 'uikit/table', component: TableDemoComponent},
                    {path: 'uikit/list', component: ListDemoComponent},
                    {path: 'uikit/tree', component: TreeDemoComponent},
                    {path: 'uikit/panel', component: PanelsDemoComponent},
                    {path: 'uikit/overlay', component: OverlaysDemoComponent},
                    {path: 'uikit/media', component: MediaDemoComponent},
                    {path: 'uikit/menu', loadChildren: () => import('./demo/view/menus/menus.module').then(m => m.MenusModule)},
                    {path: 'uikit/message', component: MessagesDemoComponent},
                    {path: 'uikit/misc', component: MiscDemoComponent},
                    {path: 'uikit/charts', component: ChartsDemoComponent},
                    {path: 'uikit/file', component: FileDemoComponent},
                    {path: 'utilities/icons', component: IconsComponent},
                    {path: 'pages/empty', component: EmptyDemoComponent},
                    {path: 'pages/crud', component: AppCrudComponent},
                    {path: 'pages/users', component: UsersComponent},
                    {path: 'pages/article', component: ArticleComponent},
                    {path: 'pages/consulte-article', component: ConsulteArticleComponent},
                    {path: 'pages/sous-famille', component: SousfamilleComponent},
                    {path: 'pages/collection', component: CollectionComponent},
                    {path: 'pages/rayon', component: RayonComponent},
                    {path: 'pages/parametre', component: ParametreComponent},
                    {path: 'pages/add-masque', component: AddMasqueComponent},


                    {path: 'pages/product/product', component: ProductComponent},
                    {path: 'pages/add-product/add-product', component: AddProductComponent},
                    {path: 'pages/stock-article/stock-article', component: StockArticleComponent},
                    {path: 'pages/mouvement-stock/mouvement-stock', component: MouvementStockComponent},
                    {path: 'pages/mouvement-stock/mouvement-stock', component: MouvementStockComponent},
                    {path: 'pages/list-products/list-products', component: ListProductsComponent},
                    {path: 'pages/list-articles/list-articles', component: ListArticlesComponent},
                    {path: 'pages/add-article/add-article', component: AddArticleComponent},
                    {path: 'pages/facture-ticket-pdf/facture-ticket-pdf', component: FactureTicketPDFComponent},
                    {path: 'Moduleticket', component: ModuledraftComponent},
                    {path: 'Module-stock', component: ModuleStockComponent},
                    {path: 'pages/add-variation/add-variation', component: AddVariationComponent},
                    {path: 'pages/fournisseur/add-fournisseur', component: AddFournisseurComponent},
                    {path: 'pages/import-product/import-product', component: ImportProductComponent},
                    {path: 'pages/consulte-achats', component: ConsulteAchatComponent},
                    {path: 'pages/consulte-details-achats', component: ConsulteDetailsAchatComponent},
                    {path: 'pages/consultation-facture', component: ConsultationFactureComponent},
                    {path: 'pages/facture-achats', component: AchatsComponent},
                    {path: 'pages/facture-ventes', component: VentesComponent},
                    {path: 'pages/Add-achat', component: AddAchatArticleComponent},
                    {path: 'pages/Add-vente', component: AddVenteDialogComponent},
                    {path: 'pages/saisie-transfer', component: SaisietransfertComponent},
                    {path: 'pages/add-transfert', component: AddTransfertComponent},
                    {path: 'pages/consulte-Transfert', component: ConsulteTransfertComponent},
                    {path: 'pages/consulte-details-Transfert', component: ConsulteDetailsTransfertComponent},
                    {path: 'pages/details-ticket', component: AddTransfertComponent},
                    {path: 'pages/Acceuil', component: HomeComponent},
                    {path: 'pages/ClientGestion', component: ClientGestionComponent},
                    {path: 'pages/fournisseur', component: FournisseurComponent},
                    {path: 'pages/list-fournisseurs', component: ListFournisseursComponent},
                    {path: 'pages/list-fourniss', component: ListFournissComponent},
                    {path: 'pages/generer-facture', component: GenererVenteComponent},
                    {path: 'pages/consultation-ticket', component: ConsultationTicketComponent},
                    {path: 'pages/achat', component: AchatComponent},
                    {path: 'pages/calendar', component: AppCalendarComponent},
                    {path: 'pages/timeline', component: AppTimelineDemoComponent},
                    {path: 'components/charts', component: ChartsDemoComponent},
                    {path: 'components/file', component: FileDemoComponent},
                    {path: 'documentation', component: DocumentationComponent},
                    {path: 'blocks', component: BlocksComponent},
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
