



<app-add-fournisseur #myDialog [data]="dataToSend" (newItemEvent)="handleData($event)" ></app-add-fournisseur>

<div class="grid">
	<div class="col-12">
		<div class="card">

            <h5>Liste des fournisseurs</h5>

            <p-table [columns]="cols" [value]="fourniss" styleClass="p-datatable-gridlines" [globalFilterFields]="['Nom']" >
				
				<ng-template pTemplate="caption">
                    <!-- <p-button   icon="pi pi-user" styleClass="p-button-rounded p-button-info p-button-text-rigth"></p-button> -->
					<button  icon="pi pi-user" pButton pRipple type="button" label="Ajouter Fournisseur" class="p-button-success mr-2 mb-2" (click)=" openDialog()"></button>
                                    </ng-template>
									<app-add-fournisseur #myDialog [data]="dataToSend"></app-add-fournisseur>


				<ng-template pTemplate="header" let-columns>
					
					<tr>
						
						<th style="min-width: 12rem;border-left: none; " *ngFor="let col of columns">
							<div  *ngIf="col.field=='code'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="code" display="menu" placeholder="Search by code"></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='name'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="name" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>
						<div  *ngIf="col.field=='adresse'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="adresse" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>
						<div  *ngIf="col.field=='tel'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="tel" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>
						<div  *ngIf="col.field=='mf'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="mf" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>
						<div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="date" display="menu" placeholder="Search by name"></p-columnFilter>
						</div>

						

						

						

						
					

						
						</th>
						<th></th>
						
						
					</tr>
				</ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
						<td style="min-width: 12rem;border-left: none; " *ngFor="let col of columns">
							
<div  *ngIf="col.field=='code'"class="flex justify-content-between align-items-center">
	{{ rowData[col.field] }}
	
						</div>

						<div  *ngIf="col.field=='name'"class="flex justify-content-between align-items-center">
							
							{{ rowData[col.field] }}
												</div>
												
						<div  *ngIf="col.field=='adresse'"class="flex justify-content-between align-items-center">
							
							{{ rowData[col.field] }}
												</div>

												<div  *ngIf="col.field=='tel'"class="flex justify-content-between align-items-center">
							
													{{ rowData[col.field] }}
																		</div>

																		<div  *ngIf="col.field=='mf'"class="flex justify-content-between align-items-center">
							
																			{{ rowData[col.field] }}
																								</div>

																								<div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
							
																									{{ rowData[col.field] }}
																														</div>
																																				

							
						</td>
						
							<td style="min-width: 12rem;border-left: none; "><div class="flex">
								<button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editFournisseur(rowData)"></button>
							 <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button> 
							</div></td>
							
						
					</tr>
				</ng-template>
					
		
			</p-table>
			<div class="card flex justify-content-center">
				<p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator>
			</div>

			<p-toast key="toast2" ></p-toast>	
        </div></div></div>
		<div *ngIf="loading" class="splash-screen">
			<div  class="spinner">
				<span class="ball-1"></span>
				<span class="ball-2"></span>
				<span class="ball-3"></span>
				<span class="ball-4"></span>
				<span class="ball-5"></span>
				<span class="ball-6"></span>
				<span class="ball-7"></span>
				<span class="ball-8"></span>
			</div>
		</div>