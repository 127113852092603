import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ArticleService } from 'src/app/demo/service/article.service';
import { AddProductComponent } from '../add-product/add-product.component';
import { AddVariationComponent } from '../add-variation/add-variation.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { UploadService } from '../../demo/service/upload.service';
import { ImportProductComponent } from '../import-product/import-product.component';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';

// interface PageEvent {
//   first: number;
//   rows: number;
//   page: number;
//   pageCount: number;
// }
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  providers: [MessageService],
  styleUrls: ['./product.component.scss'],
  styles:[`
  .upload-container {
    display: flex;
    align-items: center;} /* Align items vertically in the center */
  `
  ],
})
export class ProductComponent implements OnInit {
  @ViewChild(AddProductComponent) myDialog: AddProductComponent; // ViewChild decorator
  @ViewChild(AddVariationComponent) myDialogV: AddVariationComponent; // ViewChild decorator
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;
  @ViewChild(ImportProductComponent) ImportDialog: ImportProductComponent;


products
editPT
productDialog: boolean;
submitted: boolean;
productvariation=[]
variationdialog :boolean;
deleteVariationDialog:boolean
productData
idimage
uploadedFiles:any[]=[]
imageNewProduct
editVariation
VariationToDelete
ProdToDelete
Prod
paginationInfo: any;
currentPage: number=1;
pageSize: number;
totalItems: number;
pageNumbers: number[];
currentpage
productsToSend
listCSV
CSVData
importData
sendImportedData
etat:boolean
  constructor(private s:ArticleService , private messageService: MessageService,private uploadService:UploadService,private config:ConfigurationService) { 
    // this.s.sendData(this.currentPage)

  }
  test(){
//test bilel
  }
  cols = [
    { field: 'reference', header: "reference" },
    { field: 'code_article_generique', header: 'code_article_generique' },
    { field: 'image', header: 'image' },
    { field: 'prix_vente', header: 'prix vente' },
    {field: 'fournisseur', header: 'fournisseur'}
    
  ];

  async ngOnInit() {
    this.onPageChange({ page: 0 });
    await this.getProduct()
    this.handleData
    this.handleList

    this.config.getConfig().then(res=>
      {     console.log(res)
        // this.productData.etat=res.data.attributes.article_dimensionne
      this.etat=res.data.attributes.article_dimensionne
      
      }   )

  }
  loading: boolean = true;
  
// loadNodes(event: LazyLoadEvent) {
  
//  console.log(event)
//   this.loading = true;
//   setTimeout(() => {
// // this.getProduct()
//       this.loading = false;
      
//   }, 1000);
// }
async importedFile(event){
  try {
    const data = await this.uploadService.getFile(event);
    console.log(data);
    this.CSVData = data;
    console.log(this.CSVData);
  } catch (error) {
    console.error(error);
  }
 //this.uploadService.getConfig().subscribe((config: any) => {console.log(config)})

 this.processCSVDataWithMapping(this.CSVData);


}

async processCSVDataWithMapping(items: any[]) {
  this.uploadService.getConfig().subscribe((config: any) => {
    const mappedItems = items.map(item => {
      const mappedItem: any = {};
      // console.log(item)
      for (const csvHeader in item) {
        if (item.hasOwnProperty(csvHeader)) {
          const apiProperty = config.csvHeaders[csvHeader];
          if (apiProperty) {
            mappedItem[apiProperty] = item[csvHeader];
          }
        }
      }

      return mappedItem;
    });

    console.log(mappedItems);
     const groupedArticles = new Map();

    mappedItems.forEach(item => {
      const key = item.code_article_generique; // or item.reference if that's the unique identifier

      if (!groupedArticles.has(key)) {
        groupedArticles.set(key, {
          reference: item.reference,
          code_article_generique: item.code_article_generique,
          libelle_article: item.libelle_article,
          prix_vente: item.prix_vente,
          fournisseur: {"name":item.fournisseur,"code":item.fournisseur},
          variation: []
        });
      }

      groupedArticles.get(key).variation.push({
        code_article_generique: item.code_article_generique,
        code_barre: item.code_barre,
        taille:  item.taille,
        color: item.color,
        // createdAt: item.createdAt,
        // updatedAt: item.updatedAt
      });
    });

    const finalResult = Array.from(groupedArticles.values());
    console.log(finalResult);
this.importData=finalResult  
// if(finalResult[0].variation && this.etat==false){
// this.myDeleteDialog.showDialog("import")
// }
// else{
  this.importedData();
 this.ImportDialog.showDialog()
// }

    
  });
  
}

async importedData(){
  const data =this.s.importData(this.importData).subscribe(
    response => {
      console.log(response);
      this.sendImportedData=response
      // console.log(this.sendImportedData);
    },
    error => {
      console.error(error);
      // Handle error here, e.g., display a toast notification
    }
  );}
 

uploaded(event:UploadEvent){
  console.log(event)
}

toggleRow(item) {
  item.expanded = !item.expanded;
}
  openNew() {
    // this.product1 = {};
     this.productvariation=[]
     this.submitted = false;
    //  this.productDialog = true;
    this.myDialog.showDialog()
 }

handleList(data){
console.log(data)
this.products=data
}
handleNotification(data) {
  if(data==0){
    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: 'Product Created',
      life: 3000
    });
  }
 if(data==1){
    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: 'Product Updated',
      life: 3000
    });
  }
  if(data==-1){
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Error on creating product',
      life: 3000
    });
  }
  if(data==-2){
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Error on updating product',
      life: 3000
    });
  }
}
  handleNotificationV(data) {
    if(data==0){
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Variation Created',
        life: 3000
      });
    }
    else{
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Variation Updated',
        life: 3000
      });
    }
  }
  async getProduct() {
    try {
      this.loading = true;
  
      const data = await this.s.getproduct();
      console.log("data", data);
  
      this.products = data.data.map(x => ({
        id: x.id,
        libelle_article: x.attributes.libelle_article,
        reference: x.attributes.reference,
        code_article_generique: x.attributes.code_article_generique,
        etat: x.attributes.etat,
        expanded:false,

        prix_vente: x.attributes.prix_vente,
        fournisseur: x?.attributes?.fournisseur?.data,
        image: x.attributes.image?.data != null ? "https://prefacture.rosace.com.tn" + x?.attributes?.image?.data?.attributes?.formats?.small?.url : "",
        variations: x.attributes.variations
      }));
  
      this.paginationInfo = data.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  
      this.productsToSend = this.products;
  
      console.log(this.products);
      console.log(this.paginationInfo);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      this.loading = false;
    }
  }
  

  onUpload(event) {
    this.idimage=event.originalEvent.body[0].id
    console.log(this.idimage)
    console.log("event",event.originalEvent.body[0].id)
    
    for (const file of event.files) {
        this.uploadedFiles.push(file);
        console.log("file",file);
        console.log("uploadedfiles",this.uploadedFiles);

    }
   
    //this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
}
handleData(data: any) {
  // Handle the received data here
  console.log('Received data in parent component:', data);
  this.productData=data
}
editProduct(prod){
console.log(prod)
this.editPT=prod
this.myDialog.showDialog(this.editPT)

}
async getVariationsOfProd(idprod){
  this.s.getVariationsOfProd(idprod).then(async (res)=>{
    console.log("variation of prod",res)
  })
 }
 async addvariation(rowdata){
  console.log(rowdata.variations.data)
  await  this.myDialogV.showDialog(null,{"idProd":rowdata.id,"code":rowdata?.code_article_generique  ,"allProducts":this.products})

 }
  async editVariation2(variation,product){
  console.log(product)
  this.Prod=product
  console.log(variation)
  const variat={
    code_article_generique:  variation.attributes.code_article_generique,

code_barre: variation.attributes.code_barre,
color: {id:variation.attributes.color.data.id , name: variation.attributes.color.data.attributes.name},
id: variation.id,
taille:{id:variation.attributes.taille.data.id , name: variation.attributes.taille.data.attributes.name},
  }
  console.log(variat)

  this.editVariation=variat
  // const data={"idProd":product.id,"code":product.code_article_generique,"prodVariations":product.variations.data}
  // console.log(data)
    this.myDialogV.showDialog(this.editVariation,  {"idProd":product.id,"code":product.code_article_generique,"prodVariations":product.variations.data})
 }
 deleteVariation(variation){
  const variat={
    code_article_generique:  variation.attributes.code_article_generique,
  
  code_barre: variation.attributes.code_barre,
  color: {id:variation.attributes.color.data.id , name: variation.attributes.color.data.attributes.name},
  id: variation.id,
  taille:{id:variation.attributes.taille.data.id , name: variation.attributes.taille.data.attributes.name},
  }
  this.myDeleteDialog.showDialog({"variationId":variat.id})
 }
 deleteProduct(product){
console.log(product)
this.myDeleteDialog.showDialog({"productId":product.id})

 }
 receivedMessageHandler(event){
  console.log(event)
  console.log(this.Prod)
  // this.Prod.variations.data=event
  // this.productData.variations=event
 }
//  first: number = 0;

//  rows: number = 10;

 async onPageChange(event) {
 /* this.loading=true
  setTimeout(() => {
    // this.getProduct()
          this.loading = false;
          
      }, 5000);
*/
  console.log(event)
  this.currentPage=event.page
  console.log(this.currentPage)
  await this.s.sendData(this.currentPage+1)
  await this.getProduct()

  // this.loading=false

    //  this.first = event.first;
    //  this.rows = event.rows;
 }
}
