import { ArticleService } from './../../demo/service/article.service';
import { FournisseurService } from './../../demo/service/fournisseur.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AddProductComponent } from '../add-product/add-product.component';
import { AddVariationComponent } from '../add-variation/add-variation.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { StockArticleService } from 'src/app/demo/service/stock-article.service';
import { Paginator } from 'primeng/paginator';


interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-stock-article',
  templateUrl: './stock-article.component.html',
  styleUrls: ['./stock-article.component.scss']
})

export class StockArticleComponent implements OnInit {

  @ViewChild(AddProductComponent) myDialog: AddProductComponent; // ViewChild decorator
  @ViewChild(AddVariationComponent) myDialogV: AddVariationComponent; // ViewChild decorator
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;
  @ViewChild('paginator', {static: false}) paginator: Paginator

  Etablissements
  loading
  Articles
  Fournisseurs 
  Depots :any[]
  Couleurs
  Tailles
  Stocks
  totalPhys
  totalAchat
  currentPage: number=1;
  pageSize: number=10;
  totalItems: number;
  pageNumbers: number[];
  currentpage
  paginationInfo: any;
  rowsPerPageOptions = [5, 10,20,25];
  selectedFournisseurs
  selectedArticle
  selectedDepot
  selectedCouleur
  selectedTaille
  showdepot:boolean=true
  checkedEnStock: boolean = false;
  filteredFourniss: any[] | undefined;
  filteredArticles :any[] | undefined;
  filteredDepots :any[] | undefined;
  filteredCouleurs :any[] | undefined;
  filteredtailles :any[] | undefined;
  filtredEtablissements:any[] | undefined;
  selectedEtablissement
  cols
  selectedGroupingOption: boolean = true;
  GroupingOption: boolean = true;
  sortOrder
  sortField
  filterButtonClicked: boolean = false;
  constructor(private messageService: MessageService,private stockservice:StockArticleService,private articleservice:ArticleService,private fournissService:FournisseurService) { 

  }
  // private updateCurrentPage(currentPage: number): void {
  //   setTimeout(() => this.paginator.changePage(currentPage),0);    
  // }
  private updateCurrentPage():void{
   
      setTimeout(() => {
        this.paginator.changePage(0)
      });
  }
  
// reset($event) {
//   this.paginator.changePageToFirst($event);
// }
  getDisplayedColumns() {
    if(this.GroupingOption){
      if (this.selectedGroupingOption) {
        this.cols=[
          { field: 'article', header: "Article" },
          { field: 'depot', header: 'Dépot' },
          { field: 'fournisseur', header: "Fournisseur" },
          { field: 'physique', header: 'Physique' },
          { field: 'achat', header: 'Achat' },
        ];
        
      } else {
        this.cols= [
          { field: 'article', header: "Article" },
          { field: 'depot', header: 'Dépot' },
          { field: 'fournisseur', header: "Fournisseur" },
          { field: 'physique', header: 'Physique' },
          { field: 'achat', header: 'Achat' },
          { field: 'taille', header: 'Taille' },
          { field: 'color', header: 'Couleur' },
        ];
      }
    }
    else{
      if (this.selectedGroupingOption) {
        this.cols=[
          { field: 'article', header: "Article" },
          { field: 'fournisseur', header: "Fournisseur" },
          { field: 'physique', header: 'Physique' },
          { field: 'achat', header: 'Achat' },
        ];
        
      } else {
        this.cols= [
          { field: 'article', header: "Article" },
          { field: 'fournisseur', header: "Fournisseur" },
          { field: 'physique', header: 'Physique' },
          { field: 'achat', header: 'Achat' },
          { field: 'taille', header: 'Taille' },
          { field: 'color', header: 'Couleur' },
        ];
      }
    }
  
}
// Assuming this is part of your component class
getColspan(field: string): number {
  if (field === 'physique') {
      return 3;  // Adjust the colspan based on your actual column structure
  } else if (field === 'achat') {
      return 1;  // Adjust the colspan based on your actual column structure
  } else {
      return 1;
  }
}

getFooterText(field: string): string {
  console.log(field)
  if (field === 'physique') {
      return 'Total physique:';
  } else if (field === 'achat') {
      return 'Total Achat:';
  } else {
      return '';
  }
}

  async ngOnInit() {
    // this.onPageChange({ page: 0 , rows: this.pageSize});
    await  this.getArticle()
    await  this.getFournisseurs()
    // await  this.getEtablissement()
    await  this.getCouleurs()
    await  this.getTailles()
    await  this.initializeData()
    await  this.getDisplayedColumns()
    // await  this.getAllStockArtilceByStore()
    
  }
  async  onRefreshButtonClick(){
    this.filterButtonClicked=false
    this.selectedFournisseurs = [];
    this.selectedArticle =  [];
    this.selectedEtablissement=[]
    this.selectedDepot =  [];
    this.selectedCouleur= [];
    this.selectedTaille= [],
    this.checkedEnStock=false
    this.getStocks();
  //  await this.updateCurrentPage(0)
  //  setTimeout(async () => {
  //   await this.getStocks();
  // }, 0);
  }

  onRadioButtonChange(newValue: boolean) {
    this.selectedGroupingOption = newValue;
    console.log( this.selectedGroupingOption )
    
  }
  RadioButtonChange(newValue: boolean) {
    console.log("changed value of grouping option",newValue)
    this.GroupingOption = newValue;
    console.log( "GroupingOption",this.GroupingOption )
    
    
  }
  async onRadioButtonClick(newValue: boolean) {

  // await  this.updateCurrentPage()
  //  setTimeout(async () => {
  //   await this.getStocks();
  // });
    // this.updateCurrentPage(0)

    // Reset values when Détaillé par taille is selected
    // if (!newValue) {
    //   this.selectedFournisseurs =  [];
    //   this.selectedArticle =  [];
    //   this.selectedDepot =  [];
    //   this.selectedCouleur= [];
    //   this.selectedTaille= [],
    //   this.checkedEnStock=false
    // }
  }
  RadioButtonClick(newValue: boolean) {
        this.showdepot=!this.showdepot
    console.log("changed value of grouping option",newValue)
    this.GroupingOption = newValue;
  
      // this.selectedFournisseurs =  [];
      // this.selectedArticle =  [];
      // this.selectedDepot =  [];
      // this.selectedCouleur= [];
      // this.selectedTaille= [],
      // this.checkedEnStock=false,
      // this.Stocks=[]
  }
  // changeCheckbox(){
  //   this.paginator.changePage(0);

  // }

  async initializeData() {
   
    await this.getEtablissement().then(() => {
      if (this.Etablissements && this.Etablissements.length > 0) {
        console.log(this.Etablissements)
        this.selectedEtablissement=this.Etablissements
        this.selectedDepot = [];
        this.Depots = [];

         setTimeout(() => {
          
          for ( let etabliss of this.Etablissements){
            for ( let depot of etabliss.stores){
              console.log(depot)
  
              this.Depots.push(depot) ;
              this.selectedDepot.push(depot)
            }
            }
       });
       console.log(this.selectedDepot)
      }
    });

    // this.selectedArticle=this.Articles
    // this.selectedFournisseurs=this.Fournisseurs
    // this.selectedCouleur=this.Couleurs
    // this.selectedTaille=this.Tailles
 }
 
 
  filterEtablissements(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
  
    for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
        let country = (this.Etablissements as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
  
    this.filtredEtablissements = filtered;
  }

  async getEtablissement(){
    await this.stockservice.getEtablissements().then(res=>{
       
       console.log(res)
     this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
       store=>({id:store.id,name:store.attributes.name})
     )}))})
     console.log("etablissement",this.Etablissements)
   }

  filterFourniss(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Fournisseurs as any[]).length; i++) {
        let country = (this.Fournisseurs as any[])[i];
        if (country.nom.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredFourniss = filtered;
}

filterArticles(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Articles as any[]).length; i++) {
      let country = (this.Articles as any[])[i];
      if (country.codeArticle.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredArticles = filtered;
}

filterDepots(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Depots as any[]).length; i++) {
      let country = (this.Depots as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredDepots = filtered;
}

filterCouleurs(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Couleurs as any[]).length; i++) {
      let country = (this.Couleurs as any[])[i];
      if (country.nom.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredCouleurs = filtered;
}

filterTailles(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Tailles as any[]).length; i++) {
      let country = (this.Tailles as any[])[i];
      if (country.nom.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredtailles = filtered;
}

filterArticlesWithSearch(event: any) {
  const query = event.filter; // The user input in the filter
  console.log("filtered Article",  query)
this.articleservice.getArticlesWithContain(query).then(res=>{
  this.Articles=res.data.map(item=>({id:item.id,codeArticle:item.attributes.code_article_generique}))
  console.log("Articles",  this.Articles)
})
}

 getArticle(){
  this.articleservice.getArticlesWithContain().then(res=>{
    this.Articles=res.data.map(item=>({id:item.id,codeArticle:item.attributes.code_article_generique}))
    console.log("Articles",  this.Articles)
  })
 }

 filterFournisseurWithSearch(event: any) {
  const query = event.filter;
  console.log("filtered Fournisseurs",  query)
this.fournissService.getFournisseurWithContain(query).then(res=>{
this.Fournisseurs=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
  console.log("Fournisseurs",this.Fournisseurs)
})
}

getFournisseurs(){
  this.fournissService.getFournisseurWithContain().then(res=>{
    this.Fournisseurs=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
      console.log("Fournisseurs",this.Fournisseurs)
    })

}
filterDepotWithSearch(event: any) {
  const query = event.filter; 
  console.log("filtered depot",  query)
this.stockservice.getDepotsWithContain(query).then(res=>{
  this.Depots=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
  console.log("depots",  res)
})
}

filterCouleursWithSearch(event: any) {
  const query = event.filter; 
  console.log("filtered couleurs",  query)
this.stockservice.getCouleursWithContain(query).then(res=>{
  this.Couleurs=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
  console.log("couleurs",    this.Couleurs)

})
}

getCouleurs(){
  this.stockservice.getCouleursWithContain().then(res=>{
    this.Couleurs=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
    console.log("couleurs",    this.Couleurs)

})
}

filterTaillesWithSearch(event: any) {
  const query = event.filter; // The user input in the filter
  console.log("filtered Tailles",  query)
this.stockservice.getTaillesWithContain(query).then(res=>{
  this.Tailles=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
  console.log("tailles",  this.Tailles)
})
}

getTailles(){
  this.stockservice.getTaillesWithContain().then(res=>{
    this.Tailles=res.data.map(item=>({id:item.id,nom:item.attributes.name}))
    console.log("tailles",  this.Tailles)
  })
}

SelectFournisseur(event){
  console.log(event)
  console.log("selcted fourniss",this.selectedFournisseurs)

}

SelectEtabliss(event){
  console.log(event)
  console.log("selcted etablissement",this.selectedEtablissement)
  this.Depots=[]
  this.selectedDepot=[]

  if(this.selectedEtablissement && this.selectedEtablissement.length!==0 && this.selectedEtablissement.length<2 ){
    console.log(1)
    // console.log(this.selectedEtablissement[0].stores[0])
    this.Depots=this.selectedEtablissement[0].stores
    this.selectedDepot.push(this.selectedEtablissement[0].stores[0])

  }

  if(this.selectedEtablissement && this.selectedEtablissement.length>1 ){
    for(let etablissement of this.selectedEtablissement){
      for(let depot of etablissement.stores){
        this.Depots.push(depot)
        this.selectedDepot.push(depot)

  
      }

    }    console.log(2)

  }
  if(!this.selectedEtablissement && this.selectedEtablissement.length==0 ){
    this.Depots=[]
    console.log(3)

  }
}

SelectDepots(event){
  console.log(event)
  console.log("selcteddepot",this.selectedDepot)

}

SelectArticles(event){
  console.log(event)
  console.log("selcted articles",this.selectedArticle)


}

SelectCouleurs(event){
  console.log(event)
  console.log("selcted couleurs",this.selectedCouleur)


}

SelectTailles(event){
  console.log(event)
  console.log("selcted tailles",this.selectedTaille)


}


/***********************************updated code************************************** */
/***********************************sort stock ************************************** */


 onLazyLoad(event: LazyLoadEvent): void {
  this.sortField = event.sortField;
  this.sortOrder = event.sortOrder;
  // if (this.sortField === 'physique' || this.sortField === 'achat' ) {
  //   // console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);

  //   // this.sortQteNumeric(this.sortOrder, this.sortField);
  // } else {
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    // If it's not 'qte', make your service call or handle other sorting logic
    this.stockservice.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
     this.getStocks();
  //     this.updateCurrentPage(0)
  //  setTimeout(async () => {
  //   await this.getStocks();
  // }, 0);
  

  }

  
/***********************************stock with store************************************** */

async getAllStockArtilceByStore() {
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

      const res = await this.stockservice.getStocksByStore();
    console.log("Updated code - get all stock produit by store", res);

    this.Stocks = res.data?.map(item => ({
      article: item.code_article_generique,
      fournisseur: item.fournisseur_name,
      depot: item.store_name,
      physique: item.total_quantity,
      achat: item.total_quantity_purchased
    }));
    
    console.log("All stocks Produit with store", this.Stocks);

    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  } catch (error) {
    console.error('Error fetching stocks by store:', error);
  } finally {
    this.loading = false; // Set loading to false after receiving the response or in case of an error
  }
}

async getAllStockVariationByStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await  this.stockservice.getStocksByStore(null,'',null,null,null,'getVariations').then(res=>{
    console.log("updated code get all stock variations by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased,
        color:item.color,
        taille:item.taille }))
    console.log("all stocks variations with store", this.Stocks)

    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getStockArtilceByStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await this.stockservice.getStocksByStore(null,'',null,null,null,'getProduct').then(res=>{
    // console.log("updated code get all stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log("all stocks Produit diff 0 with store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getStockVariationByStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await this.stockservice.getStocksByStore(null,'',null,null,null,'getVariation').then(res=>{
    // console.log("updated code get all stock variations by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased,
        color:item.color,
        taille:item.taille }))
    console.log("all stocks variations diff 0 with store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockArtilceByStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  // await  this.stockservice.getStocksByStore(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getProducts').then(res=>{
    await  this.stockservice.getStocksByStore(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes).then(res=>{

    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log(" stocks Produit with filter by store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockVariationByStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksByStore(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getVariations').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased ,
        color:item.color,
        taille:item.taille
      }))
    console.log(" stocks variation with filter by store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockArtilcewithStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksByStore(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getProduct').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log(" stocks Produit diff 0 with filter by store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockVariationWithStore(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksByStore(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getVariation').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log(" stocks variation diff 0 with filter by store", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getStocks(){
          // this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})
        // await    this.updateCurrentPage(0)
        // this.paginator.changePage(0)
          // article en stock  cocher  
          setTimeout(async () => {
    if(this.checkedEnStock){
      console.log(" article en stock  cocher");
        // regrouper par depot 
        if(this.GroupingOption==true){
          console.log(" Grouping Option:", this.GroupingOption);
    
      console.log("Selected Grouping Option:", this.selectedGroupingOption);
    
      if(this.selectedGroupingOption){
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
    {
       this.getFiltredStockArtilcewithStore()
    }
    else{
       this.getStockArtilceByStore()
    }
      }
      if(!this.selectedGroupingOption){
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
    {
       this.getFiltredStockVariationWithStore()
    }
    else{
       this.getStockVariationByStore()
    
    }
      }
    }
        // regrouper par somme stock 
    
    else{
      
     if(this.selectedGroupingOption){
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
    {
       this.getFiltredStockArtilcewithSum()
    }
    else{
       this.getStockArtilceBySum()
    
    }
        }
        if(!this.selectedGroupingOption){
          if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
    {
       this.getFiltredStockVariationWithSum()
    }
    else{
       this.getStockVariationBySum()
    
    }
        }
    }
    }

        // article en stock non cocher  
    else{
      console.log(" article en stock non cocher");

          // regrouper par depot 
      if(this.GroupingOption==true){
        console.log(" Grouping Option:", this.GroupingOption);
  
    console.log("Selected Grouping Option:", this.selectedGroupingOption);
  
    if(this.selectedGroupingOption){
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
  {
      this.getFiltredStockArtilceByStore()
  }
  else{
     this.getAllStockArtilceByStore()
  }
    }
    if(!this.selectedGroupingOption){
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
  {
     this.getFiltredStockVariationByStore()
  }
  else{
     this.getAllStockVariationByStore()
  
  }
    }
  }
      // regrouper par somme stock 
  
  else{
    
   if(this.selectedGroupingOption){
    if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
  {
     this.getFiltredStockArtilceBySum()
  }
  else{
     this.getAllStockArtilceBySum()
  
  }
      }
      if(!this.selectedGroupingOption){
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
  {
     this.getFiltredStockVariationBySum()
  }
  else{
     this.getAllStockVariationBySum()
  
  }
      }
  }
    }
  })

}
FilterWithButtonClick() {
  this.filterButtonClicked=true
  // article en stock non cocher 
  if(this.checkedEnStock==false){
    if(this.GroupingOption){
      if (!this.selectedGroupingOption) {
        console.log("regrouper par variations");
        if(this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0)
        {  
          this.getFiltredStockVariationByStore();
        }
    } else {
      console.log("regrouper par articles");
  
      if(this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0)
    {  
      this.getFiltredStockArtilceByStore();
    }
  
    }
    }
    else{
      if (!this.selectedGroupingOption) {
        console.log("regrouper par variations");
        if(this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0)
        {  
          this.getFiltredStockVariationBySum();
        }
    } else {
      console.log("regrouper par articles");
  
      if(this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0)
    {  
      this.getFiltredStockArtilceBySum();
    }
  
    }
    }
   
  }
  // article en stock cocher
  else{
    if(this.GroupingOption){
      console.log("article en stock cocher"); 
      if (this.selectedGroupingOption){
        console.log("regrouper par articles");
  
        this.getFiltredStockArtilcewithStore()
  
      }
      else{
        console.log("regrouper par variations");
        this.getFiltredStockVariationWithStore()
      }
    }
    else{
      console.log("article en stock cocher"); 
      if (this.selectedGroupingOption){
        console.log("regrouper par articles");
  
        this.getFiltredStockArtilcewithSum()
  
      }
      else{
        console.log("regrouper par variations");
        this.getFiltredStockVariationWithSum()
      }
    }
   
  }

}
getOnlyArticleInStock(){
  // this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})
  //  this.updateCurrentPage(0)
  if(this.checkedEnStock==true){
    if(this.GroupingOption){
      if (this.selectedGroupingOption) {
        // this.getStockArtilceByStore()
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0) && this.filterButtonClicked ){
          this.getFiltredStockArtilcewithStore()
    
        }
        else{
          this.getStockArtilceByStore()
    
        }
      }
      else{
        // this.getStockVariationByStore()
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
        {
          this.getFiltredStockVariationWithStore()
    
        }
        else{
          this.getStockVariationByStore()
    
        }
      }
    }
    else{
      if (this.selectedGroupingOption) {
        // this.getStockArtilceByStore()
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0) && this.filterButtonClicked ){
          this.getFiltredStockArtilcewithSum()
    
        }
        else{
          this.getStockArtilceBySum()
    
        }
      }
      else{
        // this.getStockVariationByStore()
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
        {
          this.getFiltredStockVariationWithSum()
    
        }
        else{
          this.getStockVariationBySum()
    
        }
      }
    }
   

  }
  else{
     if(this.GroupingOption){
      if(this.selectedGroupingOption){
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 ) && this.filterButtonClicked ){
          this.getFiltredStockArtilceByStore()
    
        }
        else{
          this.getAllStockArtilceByStore()
    
        }
          }
          else{
            if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
        {
          this.getFiltredStockVariationByStore()
    
        }
        else{
          this.getAllStockVariationByStore()
    
        }
          }
     }
     else{
      if(this.selectedGroupingOption){
        if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0) && this.filterButtonClicked  ){
          this.getFiltredStockArtilceBySum()
    
        }
        else{
          this.getAllStockArtilceBySum()
    
        }
          }
          else{
            if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
        {
          this.getFiltredStockVariationBySum()
    
        }
        else{
          this.getAllStockVariationBySum()
    
        }
          }
     }

    
  }
}
refreshPagination(){
  console.log('current page',this.paginator.getPageCount() )
  // console.log('first',this.first)

  if(1 < this.paginator.getPageCount()  ){
    
     this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})
     this.paginator.first = 0;
  }
}
async onPageChange(event) {
   
  console.log(event)
  this.currentPage=event.page
  this.pageSize=event.rows
  console.log(this.currentPage)
await this.stockservice.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
if(this.checkedEnStock==false){
  // regrouper par depot 
  if(this.GroupingOption){

    if(this.selectedGroupingOption){
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0) && this.filterButtonClicked ){
         this.getFiltredStockArtilceByStore()
  
      }
      else{
        this.getAllStockArtilceByStore()
  
      }
        }
        else{
          if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
      {
         this.getFiltredStockVariationByStore()
  
      }
      else{
        this.getAllStockVariationByStore()
  
      }
        }
  }
  // regrouper par somme stock
  else {
    if(this.selectedGroupingOption){
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0) && this.filterButtonClicked ){
         this.getFiltredStockArtilceBySum()
  
      }
      else{
        this.getAllStockArtilceBySum()
  
      }
        }
        else{
          if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
      {
         this.getFiltredStockVariationBySum()
  
      }
      else{
        this.getAllStockVariationBySum()
  
      }
        }
  }
}
else{
  if(this.GroupingOption){
    if (this.selectedGroupingOption) {
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 ) && this.filterButtonClicked ){
        this.getFiltredStockArtilcewithStore()
  
      }
      else{
        this.getStockArtilceByStore()
  
      }
    }
    else{
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
      {
        this.getFiltredStockVariationWithStore()
  
      }
      else{
        this.getStockVariationByStore()
  
      }
    }
  }
  else{
    if (this.selectedGroupingOption) {
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 ) && this.filterButtonClicked ){
        this.getFiltredStockArtilcewithSum()
  
      }
      else{
        this.getStockArtilceBySum()
  
      }
    }
    else{
      if((this.selectedArticle?.length > 0 || this.selectedDepot?.length > 0 || this.selectedFournisseurs?.length > 0 || this.selectedCouleur?.length > 0 || this.selectedTaille?.length > 0) && this.filterButtonClicked )
      {
        this.getFiltredStockVariationWithSum()
  
      }
      else{
        this.getStockVariationBySum()
  
      }
    }
  }
}

 }
 /*********************************** par somme stock************************************** */
 async getAllStockArtilceBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await this.stockservice.getStocksBySommeStocks().then(res=>{
    // console.log("updated code get all stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log("all stocks Produit with somme", this.Stocks)

    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockArtilceBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksBySommeStocks(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getProducts').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log(" stocks Produit with filter by somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getAllStockVariationBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await this.stockservice.getStocksBySommeStocks(null,'',null,null,null,'getVariations').then(res=>{
    // console.log("updated code get all stock variations by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased,
        color:item.color,
        taille:item.taille }))
    console.log("all stocks variations with somme", this.Stocks)

    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockVariationBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksBySommeStocks(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getVariations').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased ,
        color:item.color,
        taille:item.taille
      }))
    console.log(" stocks variation with filter by somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getStockArtilceBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await  this.stockservice.getStocksBySommeStocks(null,'',null,null,null,'getProduct').then(res=>{
    // console.log("updated code get all stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log("all stocks Produit diff 0 with somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockArtilcewithSum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await  this.stockservice.getStocksBySommeStocks(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getProduct').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased }))
    console.log(" stocks Produit diff 0 with filter by somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
  } catch (error) {
    console.error('Error fetching stocks by store:', error);
  } finally {
    this.loading = false; // Set loading to false after receiving the response or in case of an error
  }
}
async getStockVariationBySum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

    await this.stockservice.getStocksBySommeStocks(null,'',null,null,null,'getVariation').then(res=>{
    // console.log("updated code get all stock variations by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased,
        color:item.color,
        taille:item.taille }))
    console.log("all stocks variations diff 0 with somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
async getFiltredStockVariationWithSum(){
  try {
    this.loading = true;
    // await this.stockservice.sendData({currentPage:1,pageSize:this.pageSize})

  console.log("fourniss",this.selectedFournisseurs,"article",this.selectedArticle,"depot",this.selectedDepot)

  const depotIds = this.selectedDepot?.map(depot => depot.id);
  const articleIds = this.selectedArticle?.map(article => article.codeArticle);
  const fournisseurIds = this.selectedFournisseurs?.map(fournisseur => fournisseur.id);
  const couleurIds = this.selectedCouleur?.map(couleur => couleur.id);
  const tailleCodes = this.selectedTaille?.map(taille => taille.id);
  await this.stockservice.getStocksBySommeStocks(fournisseurIds,articleIds,depotIds,couleurIds,tailleCodes,'getVariation').then(res=>{
    // console.log("updated code get  stock produit by store",res)
    this.Stocks=res.data?.map(item=>(
      {article:item.code_article_generique,
        fournisseur:item.fournisseur_name, 
        depot:item.store_name ,
        physique:item.total_quantity,
        achat:item.total_quantity_purchased ,
        color:item.color,
        taille:item.taille}))
    console.log(" stocks variation diff 0 with filter by somme", this.Stocks)
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    this.totalPhys=this.Stocks.reduce((total, item) => total + parseInt(item.physique), 0);
    this.totalAchat=this.Stocks.reduce((total, item) => total + parseInt(item.achat), 0);
  })
} catch (error) {
  console.error('Error fetching stocks by store:', error);
} finally {
  this.loading = false; // Set loading to false after receiving the response or in case of an error
}
}
}


