import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MessageService } from 'primeng/api';
import { DialogService } from 'src/app/demo/service/dialog.service';
import { FournisseurService } from 'src/app/demo/service/fournisseur.service';

@Component({
  selector: 'app-add-fournisseur',
  templateUrl: './add-fournisseur.component.html',
  styleUrls: ['./add-fournisseur.component.scss'],
  styles: [`  
  .field {
    display: inline-block;
    width: 48%; 
    margin: 10px 1%; 
    box-sizing: border-box;
  }
  
  .field label {
    display: block;
    margin-bottom: 5px;
  }
  
  .field input {
    width: 100%;
    height: 40PX !important;
    padding: 5px;
      border-color: #3B82F6;
  }
  .required {
    color: #DC3545;
  }
  .bi.bi-exclamation-circle {
    color: #DC3545;

    cursor: pointer;
  }
  .icon {
    display: flex;
  }
  .input-container {
      position: relative;
      width: 100%;
  }
  .bi {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px; /* Adjust as needed */
     
  }
  .field .text-danger {
    color: red;
    font-size: 12px; /* Adjust as needed */
    position: absolute;
    bottom: 30;
  
  }

.field input.ng-dirty.ng-invalid {
  border-color: #DC3545; /* Your desired border color */
}

  `]
})
export class AddFournisseurComponent implements OnInit {
  @Input() data=false
  @Output() newItemEvent = new EventEmitter<any>();
  @Output() notifyParent = new EventEmitter<any>();
  @Output() PaginationToParent = new EventEmitter<any>();


  @Input() ListFourniss;
 // @Input() fournissList
// fournissList
loading:boolean=false
    fourniss
  position: string;
  visible: boolean;
  submitted=false
  idFourniss
  isChanged=false
  message
  show=false
  messages1
  msgs
  ErrorMsg:string[]
showRequiredMsg:boolean =false
previousData
currentPage: number=1;
pageSize: number=10;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
rowsPerPageOptions = [5, 10,20,25];
  constructor(private messageService: MessageService,private service:FournisseurService,public dialogService: DialogService) { 
    
    this.showDialog('top',false)

    //this.newItemEvent.emit(this.showDialog('top'))
  }

  async ngOnInit() {
    // this.showDialog()
   console.log('test')
   this.visible=false
   this.showDialog('top',false)
    // this.initialize()
// await this.showDialog('top')
console.log(this.idFourniss)


  }
  // setFournissList(value){
  //   console.log(value)

  //   this.fournissList=value
  // }
  initialize() {
    return 'im achild component'
  }
   showDialog(position: string,cas:boolean,data?) {
    // this.visible=this.data
    this.name = data?.name 
    this.code = data?.code 
    this.adresse = data?.adresse 
    this.tel = data?.tel 
    this.mf = data?.mf 
    this.codeTVA=data?.code_tva
    this.idFourniss=data?.id
    console.log(data)
    this.position = position;
    this.visible=cas
    this.previousData={
      code:this.code.value,
      name:this.name.value ,
      adresse:this.adresse.value ,
      telephone:this.tel.value,
      mf:this.mf.value,
      code_tva:this.codeTVA.value}
      console.log(this.previousData)
   // this.visible = this.dialogService.getVisible();
   // return 'test'

}
  form = new FormGroup({
    name: new FormControl("", [
      Validators.required,
      // Validators.minLength(5),
    ]),
    code: new FormControl("", [

      Validators.required,
      // Validators.minLength(10),
    ]),
    codeTVA: new FormControl("", [

      // Validators.required,
      // Validators.minLength(10),
    ]),
    adresse :new FormControl("",[ Validators.required,Validators.maxLength(15)]),
    tel:new FormControl("",[Validators.pattern(/^\d{8}$/),Validators.maxLength(8),Validators.minLength(8),]),
    mf:new FormControl("")
  });
  
onKeyPress(event: any) {
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
  if (this.form.get('tel')?.value?.length >=8 ) {
    event.preventDefault();
  }
}
  isInvalid(controlName: string) {
    const control = this.form.get(controlName)
    return control && control.invalid && (control.dirty || control.touched) ;
    // const isInvalid = control && control.invalid && (control.dirty || control.touched);

    // if (isInvalid) {
    //   control.setErrors({ 'invalid': true }); // Set a custom error to trigger the class change
    // }
  
    // return isInvalid;
    
  }
  hideIcon(controlName: string) {
     const control = this.form.get(controlName);
  if ( !control.value) {
    control.markAsDirty();
    
  }   

  }
  
  get name() {
    
    return this.form.get("name");
   }
   get code() {
     
     return this.form.get("code");

    }
    get codeTVA() {
     
      return this.form.get("codeTVA");
 
     }
    get adresse() {
    
      return this.form.get("adresse");
     }
     get tel() {
    
      return this.form.get("tel");
     }
     get mf() {
    
      return this.form.get("mf");
     }
     set name(nov:any) {
    
      this.form.get('name').setValue(nov);

         }
     set code(nov:any) {
       
      this.form.get('code').setValue(nov);  
      }
      set codeTVA(nov:any) {
       
        this.form.get('codeTVA').setValue(nov);  
        }
      set adresse(nov:any) {
      
        this.form.get('adresse').setValue(nov);       }

       set tel(nov:any) {
      
        this.form.get('tel').setValue(nov);       }

       set mf(nov:any) {
      
 this.form.get('mf').setValue(nov);       }

     close(){
      this.form.reset();
      this.visible=false
      this.message=''
      this.show=false
      this.showRequiredMsg=false
    
    }
    valueChanged(event){
    console.log(event)
if(this.idFourniss){
  if(event){
    const currentData = {
      code: this.form.get("code").value,
      name: this.form.get("name").value,
      adresse: this.form.get("adresse").value,
      telephone: this.form.get("tel").value,
      mf: this.form.get("mf").value,
      code_tva: this.form.get("codeTVA").value
    };
    const isDataChanged = Object.keys(currentData).some(key => currentData[key] !== this.previousData[key]);
    console.log(isDataChanged)

    if (isDataChanged) {
      this.isChanged = true;
    }

  }
}
   
    }

    onKeyDown(event: KeyboardEvent) {

      this.clearErrorMessages();

    }
    showErrorViaMessages(messages) {
      this.msgs = [];
      // this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg});
      messages.forEach(msg => {
        this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg });
      });
      
  }
  showErrorViaMessage(msg) {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg});
    // messages.forEach(msg => {
    //   this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg });
    // });
    
}
  clearErrorMessages() {
    this.msgs = [];
  }
  validateForm() {
    const errorMessages = [];

    const telControl = this.form.get('tel');
  
    if (telControl && telControl.value) {
      if (!telControl.valid) {
      //  return 'verifier téléphone'
      errorMessages.push('Vérifier le téléphone');

      }
    } 
    const requiredFields = Object.keys(this.form.controls).filter(controlName => {
      const control = this.form.get(controlName);
      const validators = control.validator && control.validator({} as AbstractControl);
      return validators && validators.hasOwnProperty('required');
    });
  
    const isEmptyRequiredField = requiredFields.some(controlName => {
      const control = this.form.get(controlName);
      return !control.value;
    });
  
    if (isEmptyRequiredField) {
      requiredFields.forEach(controlName => {
        this.hideIcon(controlName);
        this.showRequiredMsg = true;
      });
      // return 'Champ(s) obligatoire.';
      errorMessages.push('Champ(s) obligatoire.');


    }
    // return null; 
    return errorMessages.length > 0 ? errorMessages : null;

  }
  
    async  createfourniss(){
      
      this.visible=true
      this.ErrorMsg=this.validateForm();
      // console.log(this.ErrorMsg)
      if (this.ErrorMsg) {
        this.showErrorViaMessages(this.ErrorMsg);
        return; 
      }
     
        if(!this.idFourniss  ){
          try {
            this.loading = true;

            let data ={code:this.form.get("code").value,
        name:this.form.get("name").value,adresse:this.form.get("adresse").value,telephone:this.form.get("tel").value,mf:this.form.get("mf").value,code_tva:this.form.get("codeTVA").value}
        console.log(this.form.get("tel").value)
        await this.service.createfourniss(data).then(res=>{
          console.log("res",res)
          const mappedFourniss={
            id:res.data.id,
            name:res.data.attributes.name,
            code:res.data.attributes.code,
            adresse:res.data.attributes.adresse,
            tel:res.data.attributes.telephone,
            mf:res.data.attributes.mf,
            code_tva:res.data.attributes.code_tva,
            date: new Date(res.data.attributes.createdAt).toLocaleDateString() 
          }

          // this.paginationInfo = res.meta.pagination;
          // this.currentPage = this.paginationInfo.page;
          // this.pageSize = this.paginationInfo.pageSize;
          // this.totalItems = this.paginationInfo.total;
          // this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
          
          console.log("mappedFourniss",mappedFourniss)
          
          // this.fournissList.unshift(mappedFourniss)
          this.ListFourniss.unshift(mappedFourniss)
          if (this.ListFourniss.length > 0) {
            this.PaginationToParent.emit(1)
          }
          // this.messageService.add({key: 'toast2', severity:  'success', summary: 'Success', detail: ' fournisseur  ajouté' });
          this.notifyParent.emit(1)
          this.form.reset();
          this.visible=false
        }).catch((error)=>  
        {

          // for(let detail of error.error.error.details.errors){
          //   for(let msg of detail.path ){
          //     this.showErrorViaMessage('Erreur lors de la creation ! '+detail.message+' '+msg )

          //   }
          // }
          for(let detail of error.error.error.details.errors){
            for(let msg of detail.path ){
               if (detail.message =="This attribute must be unique" && detail.name=="ValidationError"){
                this.showErrorViaMessage('le champ'+' '+msg+' doit etre unique' )

              }
            }
          }
          console.log(error)
          this.visible=true
          console.log(error.error.error.details.errors[0].path[0] ,error.error.error.message)

          
        }
          );

        } catch (error) {
          console.error('Error fetching fournisseurs:', error);
        } finally {
          this.loading = false; // Set loading to false after receiving the response or in case of an error
        } 
          }
              
  
        else{

          try {
            this.loading = true;


          if(this.isChanged==true){
            this.show=false
            const validationError = this.validateForm();
            if (validationError) {
              this.showErrorViaMessages(validationError);
              return; // Don't proceed with creation if there's an error
            }
            else{
               const data={code:this.form.get("code").value,
          name:this.form.get("name").value,adresse:this.form.get("adresse").value,
          telephone:this.form.get("tel").value,mf:this.form.get("mf").value,code_tva:this.form.get("codeTVA").value}
          this.service.Updatefourniss(this.idFourniss,data).then(res=>{
            console.log(res)
            const mappedUpdatedFourniss={
              id:res.data.id,
              name:res.data.attributes.name,
              code:res.data.attributes.code,
              adresse:res.data.attributes.adresse,
              tel:res.data.attributes.telephone,
              mf:res.data.attributes.mf,
              code_tva:res.data.attributes.code_tva,
            }
            this.ListFourniss=this.ListFourniss.filter(prod=>prod.id != mappedUpdatedFourniss.id)
            this.ListFourniss.unshift(mappedUpdatedFourniss)
            console.log(this.ListFourniss)
            this.newItemEvent.emit(this.ListFourniss)

            this.isChanged=false
            this.visible=false
            this.notifyParent.emit(2)
          })
          .catch(error => {
            console.error(error);

            for(let detail of error.error.error.details.errors){
              for(let msg of detail.path ){
                 if (detail.message =="This attribute must be unique" && detail.name=="ValidationError"){
                  this.showErrorViaMessage('le champ'+' '+msg+' doit etre unique' )

                }
              }
            }
            // this.showErrorViaMessage('Erreur lors de la mise à jour');
          })
        
          
            }
           

          }
          else{
          this.showErrorViaMessage("au moins une valeur doit être modifiée")
          // this.show=true
          return
          }

        } catch (error) {
          console.error('Error fetching fournisseurs:', error);
        } finally {
          this.loading = false; // Set loading to false after receiving the response or in case of an error
        } 
          
        }
       
  }
    async getfourniss() {
      await this.service.getFournisseur().then(async (data) => {
        console.log("data",data)
        this.fourniss=data.data.map(x=>({
          name:x.attributes.name,
          code:x.attributes.code,
          adresse:x.attributes.adresse,
          tel:x.attributes.telephone,
          mf:x.attributes.mf,
          code_tva : x.attributes.code_tva,
          date: new Date(x.attributes.createdAt).toLocaleDateString() ,
        }))
      
      });
      console.log(this.fourniss)
    }
}
