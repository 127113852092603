import { AchatsService } from './../../demo/service/achats.service';
import { Component, OnInit,ViewChild } from '@angular/core';
// import { AchatsService } from 'src/app/demo/service/achats.service';
import { DatePipe } from '@angular/common';
import { ConsulteDetailsAchatComponent } from '../consulte-details-achat/consulte-details-achat.component';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-consulte-achat',
  templateUrl: './consulte-achat.component.html',
  styleUrls: ['./consulte-achat.component.scss']
})
export class ConsulteAchatComponent implements OnInit {
  @ViewChild(ConsulteDetailsAchatComponent) detailAchatDialog: ConsulteDetailsAchatComponent;
  @ViewChild('paginator', { static: false }) paginator?: Paginator;

  loading
Achats
totalQte:number=0
totalTTC:number=0
Detailsachat
selectedArticle
selectedEtablissement: any[] = []; 
selectedFournisseur
selectedStore
Etablissements
articles
Fournisseurs
Depots
filtredEtablissement
filteredFournisseurs
date
sortField
sortOrder
currentPage: number=1;
pageSize: number=10;
totalItems: number;
pageNumbers: number[];
currentpage
first:number=0
paginationInfo: any;
rowsPerPageOptions = [5, 10,20,25];
buttonClicked: boolean = false;


  constructor(private achatService:AchatsService, private datePipe: DatePipe) { }
  cols = [
    { field: 'num', header: "Numéro Bon" },

    { field: 'date', header: "Date Achat" },
    { field: 'qte', header: "Quantité" },

    { field: 'fournisseurName', header: ' Fournisseur ' },
    { field: 'etablissement', header: 'Etablissement' },
    { field: 'depot', header: 'Depot' },
    {field: 'prixnet', header: 'Prix Net'},
    {field: 'action', header: 'Action'}
  ];

 async  ngOnInit() {
  // this.onPageChange({ page: 0 , rows: this.pageSize });

  this.getEtablissement().then(() => {
    if (this.Etablissements && this.Etablissements.length > 0) {
      setTimeout(() => {
        this.selectedEtablissement = [this.Etablissements[0]];
        this.Depots=this.selectedEtablissement[0].stores
        console.log( this.Depots)
      this.selectedStore=[this.Depots[0] ]
     });
    }
  });
  await this.getFournisseurs()
  await this.getArticles()

    // await this.getAchats()
  }
  filterFournisseurs(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
  
    for (let i = 0; i < (this.Fournisseurs as any[]).length; i++) {
        let country = (this.Fournisseurs as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
  
    this.filteredFournisseurs = filtered;
    console.log('qdqsd',this.filteredFournisseurs)
  }
  async getFournisseurs(){
    await this.achatService.getFournisseurs().then(res=>
      {console.log(res)
      this.Fournisseurs=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Fournisseurs)
     
  }
  onFournisseurChange(event: any) {
    console.log('Selected fourniss:', this.selectedFournisseur);
  }

  async getArticles(){
    await this.achatService.getArticles().then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
         
        })
        )
      
      })
      console.log(this.articles)
     
  }
  onArticleChange(event: any) {
    console.log('Selected Article:', this.selectedArticle);
  }
  filterEtablissement(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
  
    for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
        let country = (this.Etablissements as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
  
    this.filtredEtablissement = filtered;
    console.log('qdqsd',this.filtredEtablissement)
  }
  async getEtablissement(){
    await this.achatService.getEtablissements().then(res=>{
       
       console.log(res)
     this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
       store=>({id:store.id,name:store.attributes.name})
     )}))})
     console.log(this.Etablissements)
     this.selectedEtablissement=this.Etablissements[0]
     console.log("default selection",this.selectedEtablissement)

 
   }

   async getSelectedEatb(){

    console.log("selected Etablissement",this.selectedEtablissement)

  }
  onStoreChange(event: any) {
    console.log('Selected Store:', this.selectedStore);
  }

  onEtablissementChange(event: any) {
   
    this.Depots = [] ;
    if(this.selectedEtablissement.length==1){
      for(let etab of this.selectedEtablissement){
        this.selectedStore=[etab.stores[0]]

        for(let store of etab.stores){
          this.Depots.push(store)

        }
      }
    }
    if(this.selectedEtablissement.length>1){
      for ( let etablissement of this.selectedEtablissement) {
        for ( let store of etablissement.stores){
          this.Depots.push(store) ;
          this.selectedStore.push(store)
        }
        console.log('selected depot',this.Depots)
      }
    }
  
  
    console.log('selected Etabliss',this.selectedEtablissement)
  }
  Refreshachat(){
    this.selectedEtablissement=[]
    this.selectedStore=[]
    this.selectedFournisseur=[]
    this.selectedArticle=[]
    this.date=''
     this.getAchats()

  }

  onLazyLoad(event: LazyLoadEvent): void {
  this.sortField = event.sortField;
  this.sortOrder = event.sortOrder;
  console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
  if (this.sortField === 'qte') {
    this.sortQteNumeric(this.sortOrder);
  } else {
    // If it's not 'qte', make your service call or handle other sorting logic
    this.achatService.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
    this.filterachat();
  }
  // this.achatService.getSort({sortMode:this.sortOrder,sortFiled:this.sortField })
  // this.filterachat();
  }

  sortQteNumeric(order: number) {
    console.log(order)
    if (this.cols.some(col => col.field === 'qte')) {
      const numericData = this.Achats.filter(item => typeof item.qte === 'number' && item.qte !== null && item.qte !== undefined);
      numericData.sort((a, b) => (order === 1) ? a.qte - b.qte : b.qte - a.qte);
      this.Achats=numericData
      console.log('Numeric Data after sorting:', numericData);

      
    } else {
      console.error('The "qte" field is not present in the columns.');
    }
  
}
  async filterachat() {

    try {
      this.loading = true;

    console.log(this.selectedArticle,this.selectedFournisseur,this.selectedStore,this.date)


    if (this.selectedFournisseur?.length > 0  || this.selectedStore?.length > 0  || this.selectedArticle?.length > 0  || this.date ) {
      const depotIds = this.selectedStore?.map(depot => depot.id);
      const articleIds = this.selectedArticle?.map(article => article.id);
      const fournisseurIds = this.selectedFournisseur?.map(fournisseur => fournisseur.id);
      const date=this.date

      await this.achatService.ConsulteAchats(this.selectedEtablissement,depotIds, fournisseurIds,articleIds,date).then(res=>
       { console.log('filtred achat',res)
        this.Achats=res.data.map(item=>
          ({
            id:item.id,
            qte: item.attributes.achat_variations.data.reduce((total, variation) => total + variation.attributes.quantity, 0),
            date:this.formatDate(item.attributes.createdAt),
            fournisseur:{id:item.attributes.fournisseur?.data?.id,name:item.attributes.fournisseur?.data?.attributes?.name,code:item.attributes.fournisseur?.data?.attributes?.code},
            fournisseurName:item.attributes.fournisseur?.data?.attributes?.name,
            etablissement:item.attributes.etablissement.data.attributes.name,
            depot:item.attributes.store.data.attributes.name,
            Remise:item.attributes.remise,
            timbre:item.attributes.timbre,
            ht:item.attributes.ht,
            taxes:item.attributes.taxes,
            ttc:item.attributes.ttc,
            pourcentage_remise:item.attributes.pourcentage_remise,
            total_fodec:item.attributes.total_fodec,
            prixnet:item.attributes.prix_net,
            products :item.attributes.products ,
            num:item.attributes.code_facture,
            achatsVariations:item.attributes.achat_variations.data.map(item=>(
              {
                id:item.id,
                quantite:item.attributes.quantity,
                prixttc:item.attributes.prix_produit,
                prixht:item.attributes.ht,
                remise:item.attributes.remise,
                tva:item.attributes.tva,
                variation:  { 
                  id:item.attributes.variation?.data?.id,
                  code_article_generique:item.attributes.variation?.data?.attributes?.code_article_generique,
                  code_barre:item.attributes.variation?.data?.attributes?.code_barre,
                  color:{id:item.attributes.variation?.data?.attributes?.color?.data?.id,name:item.attributes.variation?.data?.attributes?.color?.data?.attributes?.name},
                  taille:{id:item.attributes.variation?.data?.attributes?.taille?.data?.id,name:item.attributes.variation?.data?.attributes?.taille?.data?.attributes?.name},
                },
              }
              ))
        }))
        this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
      }
        );
        this.totalQte=this.Achats.filter(item => !isNaN(item.qte)) // Filter out items with NaN qte
        .reduce((total, item) => total + item.qte, 0);
        this.totalTTC=this.Achats.reduce((total, item) => total + item.ttc, 0);
        if (this.date && this.date[0]) {
          const startDate = this.date[0];
        const oneDayBefore = new Date(startDate);
        oneDayBefore.setDate(startDate.getDate() - 1);
        this.date[0] = oneDayBefore
        console.log('One day before:', oneDayBefore);
        console.log('output date :', this.date);
      }
      if (this.date && this.date[1]) {
        const startDate = this.date[1];
      const oneDayBefore = new Date(startDate);
      oneDayBefore.setDate(startDate.getDate() - 1);
      this.date[1] = oneDayBefore
      console.log('One day before:', oneDayBefore);
      console.log('output date:', this.date);
    }
    } else {
      await this.achatService.ConsulteAchats().then(res=>
        { console.log('filtred achat',res)
         this.Achats=res.data.map(item=>
           ({
             id:item.id,
             qte: item.attributes.achat_variations.data.reduce((total, variation) => total + variation.attributes.quantity, 0),
             date:this.formatDate(item.attributes.createdAt),
             fournisseur:{id:item.attributes.fournisseur?.data?.id,name:item.attributes.fournisseur?.data?.attributes?.name,code:item.attributes.fournisseur?.data?.attributes?.code},
             fournisseurName:item.attributes.fournisseur?.data?.attributes?.name,
             etablissement:item.attributes.etablissement.data.attributes.name,
             depot:item.attributes.store.data.attributes.name,
             Remise:item.attributes.remise,
             timbre:item.attributes.timbre,
             ht:item.attributes.ht,
             taxes:item.attributes.taxes,
             ttc:item.attributes.ttc,
             pourcentage_remise:item.attributes.pourcentage_remise,
             total_fodec:item.attributes.total_fodec,
             prixnet:item.attributes.prix_net,
             products :item.attributes.products ,
             num:item.attributes.code_facture,
             achatsVariations:item.attributes.achat_variations.data.map(item=>(
               {
                 id:item.id,
                 quantite:item.attributes.quantity,
                 prixttc:item.attributes.prix_produit,
                 prixht:item.attributes.ht,
                 remise:item.attributes.remise,
                 tva:item.attributes.tva,
                 variation:  { 
                  id:item.attributes.variation?.data?.id,
                  code_article_generique:item.attributes.variation?.data?.attributes?.code_article_generique,
                  code_barre:item.attributes.variation?.data?.attributes?.code_barre,
                  color:{id:item.attributes.variation?.data?.attributes?.color?.data?.id,name:item.attributes.variation?.data?.attributes?.color?.data?.attributes?.name},
                  taille:{id:item.attributes.variation?.data?.attributes?.taille?.data?.id,name:item.attributes.variation?.data?.attributes?.taille?.data?.attributes?.name},
                },
               }
               ))
         }))
         this.paginationInfo = res.meta.pagination;
         this.currentPage = this.paginationInfo.page;
         this.totalItems = this.paginationInfo.total;
         this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
       }
         );  
         this.totalQte=this.Achats.filter(item => !isNaN(item.qte)) // Filter out items with NaN qte
         .reduce((total, item) => total + item.qte, 0);
         this.totalTTC=this.Achats.reduce((total, item) => total + item.ttc, 0); 
         if (this.date && this.date[0]) {
          const startDate = this.date[0];
        const oneDayBefore = new Date(startDate);
        oneDayBefore.setDate(startDate.getDate() - 1);
        this.date[0] = oneDayBefore
        console.log('One day before:', oneDayBefore);
        console.log('output date :', this.date);
      }
      if (this.date && this.date[1]) {
        const startDate = this.date[1];
      const oneDayBefore = new Date(startDate);
      oneDayBefore.setDate(startDate.getDate() - 1);
      this.date[1] = oneDayBefore
      console.log('One day before:', oneDayBefore);
      console.log('output date:', this.date);
    } 
  }
  } catch (error) {
    console.error('Error fetching fournisseurs:', error);
  } finally {
    this.loading = false; 
  }
  }
  formatDate(date: string): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
async getAchats(){
  try {
    this.loading = true;

  await this.achatService.ConsulteAchats().then(res=>{
    console.log("achats",res)
    this.Achats=res.data.map(item=>
      ({
        id:item?.id,
        num:item.attributes.numero_bon,
        qte: item.attributes.achat_variations.data.reduce((total, variation) => total + variation.attributes.quantity, 0),
        date:this.formatDate(item.attributes.createdAt),
        fournisseur:{id:item.attributes.fournisseur?.data?.id,name:item.attributes.fournisseur?.data?.attributes?.name,code:item.attributes.fournisseur?.data?.attributes?.code},
        fournisseurName:item.attributes.fournisseur?.data?.attributes?.name,
        etablissement:item.attributes.etablissement.data.attributes.name,
        depot:item.attributes.store.data.attributes.name,
        Remise:item.attributes.remise,
        timbre:item.attributes.timbre,
        ht:item.attributes.ht,
        taxes:item.attributes.taxes,
        ttc:item.attributes.ttc,
        pourcentage_remise:item.attributes.pourcentage_remise,
        total_fodec:item.attributes.total_fodec,
        prixnet:item.attributes.prix_net,
        products :item.attributes.products ,
        achatsVariations:item.attributes.achat_variations.data.map(item=>(
          {
            id:item.id,
            quantite:item.attributes.quantity,
            prixttc:item.attributes.prix_produit,
            prixht:item.attributes.ht,
            remise:item.attributes.remise,
            tva:item.attributes.tva,
            variation:  { 
              id:item.attributes.variation?.data?.id,
              code_article_generique:item.attributes.variation?.data?.attributes?.code_article_generique,
              code_barre:item.attributes.variation?.data?.attributes?.code_barre,
              color:{id:item.attributes.variation?.data?.attributes?.color?.data?.id,name:item.attributes.variation?.data?.attributes?.color?.data?.attributes?.name},
              taille:{id:item.attributes.variation?.data?.attributes?.taille?.data?.id,name:item.attributes.variation?.data?.attributes?.taille?.data?.attributes?.name},
            },
          }
          ))
    }))
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  }
  )
  console.log("achats",this.Achats)
  this.totalQte=this.Achats.filter(item => !isNaN(item.qte)) // Filter out items with NaN qte
  .reduce((total, item) => total + item.qte, 0);
  this.totalTTC=this.Achats.reduce((total, item) => total + item.ttc, 0);
} catch (error) {
  console.error('Error fetching fournisseurs:', error);
} finally {
  this.loading = false; 
}
}
showDetails(rowdata,index){
  console.log("achat to send",rowdata,index)

  this.detailAchatDialog.showDialog(rowdata)
}
refreshPagination(){
  console.log('current page',this.paginator.getPageCount() )
  console.log('first',this.first)
  this.buttonClicked = false;

  if(1 < this.paginator.getPageCount()  ){
    
     this.achatService.sendData({currentPage:1,pageSize:this.pageSize})
     this.paginator.first = 0;
  }
}
setCurrentPage(n: number) {
  
}
async onPageChange(event) {
 
    console.log(event)
    console.log("nb rows per page",event.rows)
    this.pageSize=event.rows
    this.currentPage=event.page
    console.log(this.currentPage)
  if ((this.selectedFournisseur?.length > 0  || this.selectedStore?.length > 0  || this.selectedArticle?.length > 0  || this.date) &&  this.buttonClicked  ) {
    await this.achatService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
    await this.filterachat()
  }
  else{
    await this.achatService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
   await this.getAchats()

  }
 
   
   }
   
}
