<!-- facture-ticket-pdf.component.html -->
<p-dialog [(visible)]="factureDialog" style="height: 3508px; width: 2480px; margin: 0 auto;" [maximizable]="true"
[breakpoints]=" 
        { 
            '800px': '30vw',  
            '650px': '100vw' 
        }"  
header="Facture" [modal]="true" class="p-fluid" >
<head>
    <title>Facture Generator</title>
    <script src="html2pdf.js"></script>
  </head>
<div class="card">
    <p-progressSpinner *ngIf="!showContent" ></p-progressSpinner>
  <div id="FactureContent"   *ngIf="showContent">
    <div class="container-big">
      <div class="column" style="margin-left: -14px;">
        <div class="company-info-container" style="height : 170px">
            <div class="text-column" *ngIf="companyInfo && ClientInfo">
            <!-- <h6 style="font-size: 14px; font-weight: 600; color: #000000; line-height: 1.2; margin-bottom: 4px;">{{ companyInfo.name }}</h6> -->
            <img [src]="companyInfo.imageUrl" alt="Logo de l'entreprise" style="max-width:250px; max-height: 100px; padding-bottom:5px; margin-top: -30px;">
              <p style="line-height: 1.2;margin-bottom: 4px; color: #000000;">Adresse : {{ companyInfo.address }}</p>
              <!--<p style="padding-right: 10px; line-height: 1.2;margin-bottom: 4px; color: #000000;">Téléphone : {{ companyInfo.phone }}</p>-->
              <!-- <p *ngIf="companyInfo.email" style="line-height: 0.8;margin-bottom: 4px; color: #000000;">E-mail : {{ companyInfo.email }}</p> -->
              <p style="line-height: 0.8;margin-bottom: 4px; color: #000000;">CTVA : {{ companyInfo.taxIdentification }}</p>
          </div>
        </div>
      </div>
         <div class="column ; width: 50%">
            <div class="text-column" *ngIf="companyInfo&& ClientInfo">
            <p class="font-semibold " style="font-size: 16px;margin-bottom: 4px ; color: #000000; margin-top: -20px;">Facture N°  {{numeroFactures}} </p>
          <p style="font-size: 14px;margin-top: -12px; color: #000000;">Date : {{ currentDate | date:'dd - MM - yyyy' }}</p>
            <div class="company-info-container"  style="height : 125px">
            <p class="font-semibold " style="font-size: 16px; color: #000000;margin-bottom: 8px;">Client :{{ ClientInfo.name }}</p>
            <p style="line-height: 0.8;margin-bottom: 8px; color: #000000;">Adresse : {{ ClientInfo.address }}</p>
            <!-- <p style="line-height: 0.8;margin-bottom: 8px;">Code client : {{ ClientInfo.clientcode }}</p> -->
            <p style="line-height: 0.8;margin-bottom: 8px ; color: #000000;">Téléphone : {{ ClientInfo.phone }}</p>
            <p style="line-height: 0.8;margin-bottom: 8px; color: #000000;">CTVA : {{ ClientInfo.CTVA }}</p>
          </div>
        </div>
        </div> 
      </div>
      <table class="custom-table" style="border-collapse: collapse; height: 550px; width: 100%; font-size: 14px; border: 1px solid #000000;overflow-y: auto; color: #000000; padding-left: 28px;">
        <thead>
          <tr>
            <th style="border: 1px solid #000000;  padding: 4px;color: #000000;">Référence</th>
             <th style="border: 1px solid #000000; padding: 4px;color: #000000;">Désignation</th>
            <th style="border: 1px solid #000000; padding: 4px;color: #000000;">Quantité</th>
            <th style="border: 1px solid #000000; padding: 4px;color: #000000;">Prix U.HT</th>
            <th style="border: 1px solid#000000; padding: 4px;color: #000000;">TVA %</th>
                    <th style="border: 1px solid #000000; padding: 4px;color: #000000;">Remise </th>  
            <th style="border: 1px solid #000000; padding: 4px;color: #000000;">Total HT</th>
          </tr>
        </thead>
     <tr style=" height: 20px; text-align: center; margin-top : 5px ; " *ngFor="let item of factureItems">
          <td  >{{ item.reference }}</td>
          <td  >{{ item.designation }}</td>
          <td >{{ item.quantity }}</td>
          <td >{{ item.pricePerUnitHT | roundToThreeDecimals }}</td>
          <td >{{ item.tva }} </td>
          <td  >{{ item.remise }} %</td>
          <td >{{ item.total | roundToThreeDecimals }}</td>
        </tr>
        <div style="border-left: 1px solid #000000;color: #000000;"> </div>
      </table>
        <div class="container-big ">
          <div class="column">
            <table class="custom-table" style="border-collapse: collapse; width: 100%; font-size: 12px; border: 1px solid #000000;overflow-y: auto; color: #000000; padding-left: 28px;">
              <thead>
                <tr>
                  <th style="border: 1px solid #000000;  padding: 4px;color: #000000;">TAXE</th>
                  <th style="border: 1px solid #000000; padding: 4px;color: #000000;">BASE</th>
                  <th style="border: 1px solid #000000; padding: 4px;color: #000000;">TAUX</th>
                  <th style="border: 1px solid#000000; padding: 4px;color: #000000;">MONTANT</th>
                </tr>
              </thead>
               <tr style=" height: 15px; text-align: center;" >
                <td style="border-right : 1px solid #000000;  padding: 4px;color: #000000;" > TVA </td>
                <td style="border-right : 1px solid #000000;  padding: 4px;color: #000000;" >{{ base -  totalRemise| roundToThreeDecimals }} </td>
                <td  style="border-right : 1px solid #000000;  padding: 4px;color: #000000;" >{{TVA}}%</td>
                <td >{{ sumOfTaxes | roundToThreeDecimals }}</td>
              </tr>
            </table>
        <p style="color: #000000; margin-top: 20px; font-weight: bold;"> Arrêtée la présente facture à la somme de : </p><br><p style="margin-top : -20px;">{{ convertToDinarAndMillime(netpaye - totalRemise) }} .</p>
            <p style="color: #000000; font-size: larger;">Remarque :</p>
          </div>
          <div class="column" style="margin-right: -21px; margin-top: -15px;">
            <div class="info-section1" style="font-size: 12px; text-align: left;">
              <p style="margin-bottom: 1px; font-weight: bold; color: #000000;">Total HTVA :</p>
              <p style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;  margin-top: -19px ;">{{sumOfHt | roundToThreeDecimals }} TND</p>


              <p style="margin-bottom: 1px; font-weight: bold; color: #000000;">Total Remise :</p>
              <p style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;  margin-top: -19px ;">{{totalRemise| roundToThreeDecimals }} TND</p>

              <p style="margin-bottom: 1px; font-weight: bold; color: #000000;">Total TVA :</p>
              <p style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;margin-top: -19px ;">{{ sumOfTaxes | roundToThreeDecimals }} TND</p>
            
              <p style="margin-bottom: 1px; font-weight: bold; color: #000000;">Timbre :</p>
              <p style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;margin-top: -19px ;">{{ timbre | roundToThreeDecimals }} TND</p>
            
              <p *ngIf="sumoffodec" style="margin-bottom: 1px; font-weight: bold; color: #000000;">Fodec:</p>
              <p *ngIf="sumoffodec" style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;margin-top: -19px ;">{{ sumoffodec | roundToThreeDecimals }} TND</p>
            
              <p style="margin-bottom: 1px; font-weight: bold; color: #000000;">Net à payer :</p>
              <p style="margin-bottom: 1px; font-weight: medium; color: #000000;text-align: right;margin-top: -19px ;">{{ netpaye - totalRemise | roundToThreeDecimals }} TND</p>
            
            </div>
            
            <h4 style="text-align: center;color: #000000; margin-top: 50px">Cachet & signature</h4>
          </div>
        </div>
  </div>
</div>
<ng-template pTemplate="footer">
  <div class="container-big" >
    <!-- <p-calendar *ngIf="!consult" [(ngModel)]="currentDate" class="drop" [showIcon]="true" inputId="icon" placeholder="Calendrier" style = "margin-right : 20px;"></p-calendar> -->
    <!-- <p-button class="mr-2" *ngIf="!consult" (click)="showConfirmation()" icon="pi pi-check" label="Confirmer"></p-button> -->
    <p-button  (click)="generateInvoicePDF()" icon="bi bi-printer" label="Imprimer"></p-button>
  </div>
</ng-template>
    <!-- <p-toast  ></p-toast> -->
    <p-confirmDialog  [style]="{width: '30vw'}"></p-confirmDialog>
  

</p-dialog>
