import { ArticleService } from './../../demo/service/article.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { AddArticleComponent } from '../add-article/add-article.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { LazyLoadEvent, MessageService } from 'primeng/api';

@Component({
  selector: 'app-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['./list-articles.component.scss']
})
export class ListArticlesComponent implements OnInit {
  @ViewChild(AddArticleComponent) addArticleDialog: AddArticleComponent; // ViewChild decorator
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;

  selectedArticle:string[] = [];
  selectAllCheckbox:boolean=false
  articles
  loading
  searchTerm
  sortOrder
  sortField
  currentPage: number=1;
pageSize: number=10;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
rowsPerPageOptions = [5, 10,20,25];

  constructor(private articleService:ArticleService , private messageService: MessageService) { }
  cols = [
    { field: 'code_article_generique', header: "Code article" },
    { field: 'code_fournisseur', header: 'Ref fournisseur ' },
    { field: 'designation', header: 'Designation' },
    {field: 'image', header: 'Image'},
    { field: 'sous_famille', header: 'Sous Famille' },
    {field: 'collection', header: 'Collection'},
    {field: 'rayon', header: 'Rayon'},
    {field: 'prix_achat_ttc', header: 'Prix Achat'},
    {field: 'prix_vente_ttc', header: 'Prix Vente'},
    {field: 'fournisseur', header: 'Fournisseur'},
    {field: 'Action', header: 'Action'}


    
  ];
  ngOnInit(): void {
    //  this.onPageChange({ page: 0 , rows: this.pageSize});

  }
  onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    if(this.searchTerm){
      this.articleService.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
      
      try {
        this.loading = true;
      this.articleService.getSearchedArticle(this.searchTerm).then(res=>{
        console.log(res)
            
        this.articles = res.data.map(items => ({
          id: items.id,
          code_article_generique: items.attributes?.code_article_generique,
          code_fournisseur: items.attributes?.code_fournisseur,
          designation: items.attributes?.designation,
          sous_famille: {
            id: items.attributes?.sous_famille.data?.id,
            name: items.attributes?.sous_famille.data?.attributes?.name
          },
          collection: {
            id: items.attributes?.collection.data?.id,
            name: items.attributes?.collection.data?.attributes?.name
          },
          rayon: {
            id: items.attributes?.rayon.data?.id,
            name: items.attributes?.rayon.data?.attributes?.name
          },
          prix_achat_ttc: items.attributes?.prix_achat_ttc,
          prix_vente_ttc: items.attributes?.prix_vente_ttc,
          prix_achat_ht: items.attributes?.prix_achat_ht,
          prix_vente_ht: items.attributes?.prix_vente_ht,
          fournisseur: {
            id: items.attributes?.fournisseur.data?.id,
            name: items.attributes?.fournisseur.data?.attributes?.name
          },
          nature: items.attributes?.nature,
          Unite_de_stock: items.attributes?.unite_de_stock,
          image: {
            id: items.attributes?.image?.data?.id,
            name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              : ""
          },
          variations: items.attributes?.variations?.data?.map(item => ({
            color: {
              id: item.attributes?.color?.data?.id,
              name: item.attributes?.color?.data?.attributes?.name || ''
            },
            taille: {
              id: item.attributes?.taille?.data?.id,
              name: item.attributes?.taille?.data?.attributes?.name || ''
            }
          })),
          selected: false,
        }));
    
        console.log(this.articles);
        
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    console.log("pagination info", this.paginationInfo);
      })
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
        
    }
    else{
      this.articleService.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
      this.getArticles()   
    }
  
    }

  selectAll(event){
   
    this.articles.forEach(art => {
      art.selected = event.checked;
      if (event.checked) {
        this.selectedArticle.push(art.id);
      }
      else{
        this.selectedArticle=[]
      }
    });
  
    console.log(this.selectedArticle);
  
    }
  checkboxChanged(event, art) {
    const ArticleId = art.id;
  console.log(art);

  if (event.checked) {
    this.selectedArticle.push(ArticleId);
  } else {
    const index = this.selectedArticle.indexOf(ArticleId);
    if (index > -1) {
      this.selectedArticle.splice(index, 1);
    }
  }

  console.log(this.selectedArticle);
  
  }
  deleteArticle(){
    this.myDeleteDialog.showDialog()
   
  }
  handleDeleteEvent(data){
    try {
      this.loading = true;
    if(data=="yes"){
      for(const idArt of this.selectedArticle){
        this.articleService.deleteArticle(idArt).then(res=> console.log("deleted article",res))
        this.articles = this.articles.filter(article => article.id !== idArt);
    
      }
      this.selectedArticle=[]
      this.selectAllCheckbox=false
      
    }
  } catch (error) {
    console.error('Error fetching fournisseurs:', error);
  } finally {
    this.loading = false; // Set loading to false after receiving the response or in case of an error
  } 
      }

      async getArticles() {
        try {
          this.loading = true;

          const res = await this.articleService.getArticles();
      
          console.log(res);
      
          this.articles = res.data.map(items => ({
            id: items.id,
            code_article_generique: items.attributes?.code_article_generique,
            code_fournisseur: items.attributes?.code_fournisseur,
            designation: items.attributes?.designation,
            sous_famille: {
              id: items.attributes?.sous_famille.data?.id,
              name: items.attributes?.sous_famille.data?.attributes?.name
            },
            collection: {
              id: items.attributes?.collection.data?.id,
              name: items.attributes?.collection.data?.attributes?.name
            },
            rayon: {
              id: items.attributes?.rayon.data?.id,
              name: items.attributes?.rayon.data?.attributes?.name
            },
            prix_achat_ttc: items.attributes?.prix_achat_ttc,
            prix_vente_ttc: items.attributes?.prix_vente_ttc,
            prix_achat_ht: items.attributes?.prix_achat_ht,
            prix_vente_ht: items.attributes?.prix_vente_ht,
            fournisseur: {
              id: items.attributes?.fournisseur.data?.id,
              name: items.attributes?.fournisseur.data?.attributes?.name
            },
            nature: items.attributes?.nature,
            Unite_de_stock: items.attributes?.unite_de_stock,
            image: {
              id: items.attributes?.image?.data?.id,
              name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
                ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
                : ""
            },
            variations: items.attributes?.variations?.data?.map(item => ({
              color: {
                id: item.attributes?.color?.data?.id,
                name: item.attributes?.color?.data?.attributes?.name || ''
              },
              taille: {
                id: item.attributes?.taille?.data?.id,
                name: item.attributes?.taille?.data?.attributes?.name || ''
              }
            })),
            selected: false,
          }));
      
          console.log(this.articles);
          
      this.paginationInfo = res.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      // this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

      console.log("pagination info", this.paginationInfo);
        } catch (error) {
          console.error("Error fetching articles:", error);
          // Handle the error as needed, e.g., show an error message
        } finally {
          this.loading = false;         }
      }
      

      getSearch(event){
        console.log(event.target.value);
    
        try {
          this.loading = true;
      
          let searchInputValues=[]
          searchInputValues.push(event.target.value);
          const searchData = searchInputValues.join(' ');
    
          this.articleService.getSearchedArticle(searchData).then(res=>{
            console.log(res)
                
            this.articles = res.data.map(items => ({
              id: items.id,
              code_article_generique: items.attributes?.code_article_generique,
              code_fournisseur: items.attributes?.code_fournisseur,
              designation: items.attributes?.designation,
              sous_famille: {
                id: items.attributes?.sous_famille.data?.id,
                name: items.attributes?.sous_famille.data?.attributes?.name
              },
              collection: {
                id: items.attributes?.collection.data?.id,
                name: items.attributes?.collection.data?.attributes?.name
              },
              rayon: {
                id: items.attributes?.rayon.data?.id,
                name: items.attributes?.rayon.data?.attributes?.name
              },
              prix_achat_ttc: items.attributes?.prix_achat_ttc,
              prix_vente_ttc: items.attributes?.prix_vente_ttc,
              prix_achat_ht: items.attributes?.prix_achat_ht,
              prix_vente_ht: items.attributes?.prix_vente_ht,
              fournisseur: {
                id: items.attributes?.fournisseur.data?.id,
                name: items.attributes?.fournisseur.data?.attributes?.name
              },
              nature: items.attributes?.nature,
              Unite_de_stock: items.attributes?.unite_de_stock,
              image: {
                id: items.attributes?.image?.data?.id,
                name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
                  ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
                  : ""
              },
              variations: items.attributes?.variations?.data?.map(item => ({
                color: {
                  id: item.attributes?.color?.data?.id,
                  name: item.attributes?.color?.data?.attributes?.name || ''
                },
                taille: {
                  id: item.attributes?.taille?.data?.id,
                  name: item.attributes?.taille?.data?.attributes?.name || ''
                }
              })),
              selected: false,
            }));
        
            console.log(this.articles);
            
        this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        // this.pageSize = this.paginationInfo.pageSize;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  
        console.log("pagination info", this.paginationInfo);
          })
      
        
        } catch (error) {
          console.error('Error fetching fournisseurs:', error);
        } finally {
          this.loading = false; // Set loading to false after receiving the response or in case of an error
        }
    
      }

  openDialog(){
this.addArticleDialog.showDialog()
  }
  receiveArticle(data){
    console.log("the received article",data,"the image",data.product?.image)
    const newArticle={
      id:data.product.id,
      code_article_generique:data.product.code_article_generique,
      code_fournisseur:data.product?.code_fournisseur,
      designation:data.product?.designation,
      // image:{name:data.product?.image!=null?"https://prefacture.rosace.com.tn"+data.product?.image.formats.thumbnail.url :"",}
       image: {id:data.product?.image?.id,
        name:data.product?.image?.formats?.thumbnail?.url
        ? "https://prefacture.rosace.com.tn" + data.product?.image.formats?.thumbnail?.url
        : ""
      },
      prix_achat_ht:data.product.prix_achat_ht,
      prix_vente_ht:data.product.prix_vente_ht,
      sous_famille:{id:data.product?.sous_famille.id ,name:data.product?.sous_famille.name},
      collection:{id:data.product?.collection.id ,name:data.product?.collection.name},
      rayon:{id:data.product?.rayon.id ,name:data.product?.rayon.name},
      prix_achat_ttc:data.product?.prix_achat_ttc,
      prix_vente_ttc:data.product?.prix_vente_ttc,
      fournisseur:{id:data.product?.fournisseur.id ,name:data.product?.fournisseur.name},
      nature:data.product.nature,
      Unite_de_stock:data.product.unite_de_stock,
      selected:false,
      variations:data.productVariations.map(item=>({color:{id:item.color.id,name:item.color.name},taille:{id:item.taille.id,name:item.taille.name}}))
    }
    this.articles.unshift(newArticle)
    console.log(this.articles)
  }
  receiveEditedArticle(data){
    console.log("the edited article",data)
    console.log("test")

    const newArticle=data.data?.map(data=>({
      id:data.id,
      code_article_generique:data.attributes.code_article_generique,
      code_fournisseur:data.attributes?.code_fournisseur,
      designation:data.attributes?.designation,
      // image:{name:data.attributes?.image!=null?"https://prefacture.rosace.com.tn"+data.attributes?.image.formats.thumbnail.url :"",}
       image: {id:data.attributes?.image?.data?.id,
        name:data.attributes?.image?.data?.attributes?.formats?.thumbnail?.url
        ? "https://prefacture.rosace.com.tn" + data.attributes?.image.data.attributes.formats?.thumbnail?.url
        : ""
      },
      prix_achat_ht:data.attributes.prix_achat_ht,
      prix_vente_ht:data.attributes.prix_vente_ht,
      sous_famille:{id:data.attributes?.sous_famille.data.id ,name:data.attributes?.sous_famille.data.attributes.name},
      collection:{id:data.attributes?.collection.data.id ,name:data.attributes?.collection.data.attributes.name},
      rayon:{id:data.attributes?.rayon.data.id ,name:data.attributes?.rayon.data.attributes.name},
      prix_achat_ttc:data.attributes?.prix_achat_ttc,
      prix_vente_ttc:data.attributes?.prix_vente_ttc,
      fournisseur:{id:data.attributes?.fournisseur.data.id ,name:data.attributes?.fournisseur.data.attributes.name},
      nature:data.attributes.nature,
      Unite_de_stock:data.attributes.unite_de_stock,
      selected:false,
      variations:data.attributes.variations?.data.map(item=>({color:{id:item.attributes.color.data.id,name:item.attributes.color.data.attributes.name},taille:{id:item.attributes.taille.data.id,name:item.attributes.taille.data.attributes.name}}))
    })
    )
        console.log(newArticle)
        const index = this.articles.findIndex(article =>  newArticle.some(newItem => newItem.id === article.id));
        console.log(index)

        if (index !== -1) {
          // Replace the article at the found index with the edited article
          this.articles.splice(index,1);
          this.articles.unshift(newArticle[0])

          // console.log("Updated articles", this.articles);
        }
    console.log(this.articles)
  }
  handleNotification(data){
console.log(data)
if(data==1){
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Article ajouté avec succé',
    life: 3000
  });
}
if(data==2){
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Article Modifier avec succé',
    life: 3000
  });
}

  }
  editArticler(indexrow,article){
    console.log(indexrow)

console.log(article)
this.addArticleDialog.showDialog(article)
  }
  
  async onPageChange(event) {
    if(!this.searchTerm){
      console.log(event)
      this.currentPage=event.page
      this.pageSize=event.rows
      console.log(this.currentPage)
   await this.articleService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
     await this.getArticles()
    }
    else{

      this.currentPage=event.page
      this.pageSize=event.rows
   await this.articleService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
   try {
    this.loading = true;
      this.articleService.getSearchedArticle(this.searchTerm).then(res=>{
        console.log(res)
            
        this.articles = res.data.map(items => ({
          id: items.id,
          code_article_generique: items.attributes?.code_article_generique,
          code_fournisseur: items.attributes?.code_fournisseur,
          designation: items.attributes?.designation,
          sous_famille: {
            id: items.attributes?.sous_famille.data?.id,
            name: items.attributes?.sous_famille.data?.attributes?.name
          },
          collection: {
            id: items.attributes?.collection.data?.id,
            name: items.attributes?.collection.data?.attributes?.name
          },
          rayon: {
            id: items.attributes?.rayon.data?.id,
            name: items.attributes?.rayon.data?.attributes?.name
          },
          prix_achat_ttc: items.attributes?.prix_achat_ttc,
          prix_vente_ttc: items.attributes?.prix_vente_ttc,
          prix_achat_ht: items.attributes?.prix_achat_ht,
          prix_vente_ht: items.attributes?.prix_vente_ht,
          fournisseur: {
            id: items.attributes?.fournisseur.data?.id,
            name: items.attributes?.fournisseur.data?.attributes?.name
          },
          nature: items.attributes?.nature,
          Unite_de_stock: items.attributes?.unite_de_stock,
          image: {
            id: items.attributes?.image?.data?.id,
            name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              : ""
          },
          variations: items.attributes?.variations?.data?.map(item => ({
            color: {
              id: item.attributes?.color?.data?.id,
              name: item.attributes?.color?.data?.attributes?.name || ''
            },
            taille: {
              id: item.attributes?.taille?.data?.id,
              name: item.attributes?.taille?.data?.attributes?.name || ''
            }
          })),
          selected: false,
        }));
    
        console.log(this.articles);
        
    this.paginationInfo = res.meta.pagination;
    this.currentPage = this.paginationInfo.page;
    // this.pageSize = this.paginationInfo.pageSize;
    this.totalItems = this.paginationInfo.total;
    this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);

    console.log("pagination info", this.paginationInfo);
      })
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
    }
     
     }
}
