import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {
  apiUrl
  total= 0 
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }

  async getFromStrapi( table : string ,page ?: number, size ?: number , field?: string , option? : any , filter ?: string , searshdata1? : string , searshdata2? : string ,date? ,population?    ): Promise<any[]> {
    let url=`?`
    if(population){
      for(let populate of population){
      url+=`populate=${populate}`
      }
    }
    this.apiUrl = environment.url;
    this.apiUrl = `${this.apiUrl}/${table}${url}`
    let searchUrl = `filters[$or][0][${searshdata1}][$containsi]=${filter}`;
    searchUrl = searshdata2 ?  `${searchUrl}&filters[$or][1][${searshdata2}][$containsi]=${filter}`: searchUrl
    const sortOrder = option === 1 ? 'ASC' : 'DESC';
    const sort = field && option ? `sort=${field}:${sortOrder}` :  ''
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let GetApi = ''
    if(filter && filter != ''){
       GetApi = `${this.apiUrl}&${pagin}&${sort}&${searchUrl}`
    }else{
       GetApi = `${this.apiUrl}&${pagin}&${sort}`
    }
    // date ?  GetApi = `${GetApi}${this.StrapiFilterDate(date, 0 , 'createdAt')}` : '' ;
    try {
      const apiResponse: any = await this.http.get(GetApi).toPromise();
      this.total = apiResponse.meta?.pagination?.total 
      return apiResponse.data;
    } catch (error) {
      console.error('Error fetching clients:', error);
      this.handleApiError(error);
      throw error; // Rethrow the error after handling it
        }
  }  

  async postStrapi(table , classe,population?): Promise<any> {
    let url=`?`
    if(population){
      for(let populate of population){
      url+=`populate=${populate}`
      }
    }
    this.apiUrl = environment.url;
    this.apiUrl = `${this.apiUrl}/${table}${url}`
        try {
      const response = await this.http.post<any>(this.apiUrl, classe).toPromise();
      return response;
    } catch (error) {
      this.handleApiError(error);
      throw error; // Rethrow the error after handling it
        }
  }

  deleteWithId(table , id: number): Promise<any> {
    this.apiUrl = environment.url;
    this.apiUrl = `${this.apiUrl}/${table}/${id}`
    return this.http.delete(this.apiUrl).toPromise();
  }
   //-------------------------------- Update Strapi --------------------------------//
  async updateWithId(table ,id: number, updatedclasse,population?): Promise<any> {
    let url=`?`
    if(population){
      for(let populate of population){
      url+=`populate=${populate}`
      }
    }
    this.apiUrl = environment.url;
    this.apiUrl = `${this.apiUrl}/${table}/${id}${url}`
    try {
      const response = await this.http.put<any>(this.apiUrl, updatedclasse).toPromise();
      return response;
    } catch (error) {
      this.handleApiError(error);
      throw error; // Rethrow the error after handling it
        }
  }
     //-------------------------------- Update Config --------------------------------//
updateConfig(data){
  let promise = new Promise<any>((resolve, reject) => {
    let url = `${environment.url}/configuration`
    this.http
      .put(url,{data})
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        this.handleApiError(error);
        throw error; // Rethrow the error after handling it
            });
  });
  return promise;
}
}

