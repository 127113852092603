<p-dialog header="Ajouter client" [(visible)]="visible" [style]="{ width: '40vw' }"  (onHide)="close()" [modal]="true">
  <div *ngIf="loading" class="splash-screen">
    <div  class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
  </div>
    <form [formGroup]="clientForm" (ngSubmit)="createClient()">
        <div class="card p-fluid">
        <!--------------------------- nom ---------------------------->
            <div class="field">
                <label for="name">Nom<span class="required"> *</span></label>
                <div class="input-container">
                  <input pInputText id="name" formControlName="name" type="text" />
                </div>         
              </div>
           <!--------------------------- Code ---------------------------->
              <div class="field">
                <label for="code">Code<span class="required"> *</span></label>
                <div class="input-container">
                  <input pInputText id="code" formControlName="code" type="text" />
                </div>
              
              </div>
                <!--------------------------- mf ---------------------------->
                <div class="field">
                  <label for="code">Matricule Fiscale</label>
                  <div class="input-container">
                    <input pInputText id="matricule_fiscale" formControlName="matricule_fiscale" type="text" />
                  </div>
                
                </div>
           <!--------------------------- Adresse ---------------------------->
              <div class="field">
                <label for="adresse">Adresse<span class="required"> *</span></label>
                <div class="input-container">
                  <input pInputText id="adresse" formControlName="adresse" type="text" />

                </div>
              </div>
         <!--------------------------- Telephone ---------------------------->
              <div class="field">
                  <label for="tel">Téléphone</label>
                  <div class="input-container">
                      <p-inputMask mask="99999999" formControlName="telephone" placeholder="Entrer  votre numéro"></p-inputMask>
                  </div>
                </div>
               

    </div>
</form>


      <div style="color: red; display: flex; align-items: center;">
        <i *ngIf="show" class="bi-exclamation-circle" style="flex-shrink: 0; color: red"></i>
        <span *ngIf="show" style="flex-shrink: 1;">{{ message }}</span>
      </div>
      <p-messages [value]="msgs"></p-messages>
  
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Fermer"  [disabled]= "loading" icon="pi pi-times" class="p-button-text" (click)="close()"></button>
        <button pButton pRipple label="Enregistrer" [disabled]="loading"  icon="pi pi-check" class="p-button-text" (click)="createClient()"></button>
      </ng-template>
    

</p-dialog>
