import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ParametreService } from 'src/app/demo/service/parametre.service';

@Component({
  selector: 'app-sousfamille',
  templateUrl: './sousfamille.component.html',
  styleUrls: ['./sousfamille.component.scss']
})
export class SousfamilleComponent implements OnInit {
  loading:boolean=false
  sortOrder
  sortField
  SousFamille
  collections
  rayons
  cols = [
    { field: 'code_article_generique', header: "Code article" },
    { field: 'code_fournisseur', header: 'Ref fournisseur ' },
  ];
  constructor(private parameterService:ParametreService) { }

  ngOnInit(): void {
    this.getSousFamille()
    this.getCollection()
    this.getRayons()
  }

  onLazyLoad(event: LazyLoadEvent): void {
    // console.log('Sort Event:', event);
  this.sortField = event.sortField;
  this.sortOrder = event.sortOrder;
  // console.log('Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
  // this.getfilterVentes();
  }
async getSousFamille(){
  await this.parameterService.getFromStrapi('sous-familles',1,25).then(res=>{
    // console.log('Sous Famille',res)

     this.SousFamille=res.map(item=>({id:item.id,name:item.attributes.name}))

  })
  console.log('Sous Famille',this.SousFamille)

}

async getCollection(){
  await this.parameterService.getFromStrapi('collections',1,25).then(res=>{
    // console.log('Sous Famille',res)

     this.collections=res.map(item=>({id:item.id,name:item.attributes.name}))

  })
  console.log('collections',this.collections)

}


async getRayons(){
  await this.parameterService.getFromStrapi('rayons',1,25).then(res=>{
    // console.log('Sous Famille',res)

     this.rayons=res.map(item=>({id:item.id,name:item.attributes.name}))

  })
  console.log('rayons',this.rayons)

}
}
