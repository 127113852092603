import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulte-details-transfert',
  templateUrl: './consulte-details-transfert.component.html',
  styleUrls: ['./consulte-details-transfert.component.scss']
})
export class ConsulteDetailsTransfertComponent implements OnInit {
detailstransfertDialog
transferts
transfertVariations
transferProducts

  constructor() { }

  ngOnInit(): void {
  }
  async showDialog(data?){
    this.detailstransfertDialog=true
    console.log(data)
    this.transferts=data
    // console.log(this.transferts.product)
    this.transfertVariations=this.transferts.transfert_variations
    // console.log(this.transfertVariations)
    this.transferProducts=this.transferts.product
    console.log(this.transferProducts)

  }
  hideDialog() {
    this.detailstransfertDialog=false
  }
}
