import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TransfertService } from 'src/app/demo/service/transfert.service';
import { ConsulteDetailsTransfertComponent } from '../consulte-details-transfert/consulte-details-transfert.component';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-consulte-transfert',
  templateUrl: './consulte-transfert.component.html',
  styleUrls: ['./consulte-transfert.component.scss']
})
export class ConsulteTransfertComponent implements OnInit {
  @ViewChild(ConsulteDetailsTransfertComponent) detailTransfertDialog: ConsulteDetailsTransfertComponent;
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  sortOrder
  sortField
  loading
  selectedEtablissement
  selectedEtablissementRecepteur
  selectedStore
  selectedStoreRecepteur
  selectedFournisseur
  selectedArticle
  date
  Etablissements
  EtablissementsRecepteur
  articles
  Fournisseurs
  Depots
  DepotsRecepteur

  Transferts
  currentPage: number=1;
pageSize: number=10;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
rowsPerPageOptions = [5, 10,20,25];
  cols = [
    { field: 'num', header: "Numéro Transfert" },

    { field: 'date', header: "Date Transfert" },
    { field: 'etabEmett', header: ' Etab Emetteur ' },
    { field: 'depotEmet', header: 'Depot Emetteur' },
    { field: 'etabRecep', header: 'Etab Recepteur' },
    {field: 'depotRecep', header: 'Depot Recepteur'},
    {field: 'action', header: 'Action'}
  ];

  constructor(private datePipe: DatePipe,private transferService:TransfertService) { }

  async ngOnInit() {
    this.onPageChange({ page: 0 , rows: this.pageSize });
    await this.getArticles()
    // await this.getEtablissement()
    await this.getFournisseurs()
    await this.initializeData()
    // await this.getTransferts()
  }

  showDetails(rowdata,index){
    console.log("transfert to send",rowdata,index)
  
    this.detailTransfertDialog.showDialog(rowdata)
  }
  RefreshTransfert(){
    this.selectedEtablissement=[]
    this.selectedEtablissementRecepteur=[]
    this.selectedStore=[]
    this.selectedStoreRecepteur=[]
    this.selectedFournisseur=[]
    this.selectedArticle=[]
    this.date=''
     this.getTransferts()

  }
  onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    this.transferService.getSort({sortMode:this.sortOrder,sortFiled:this.sortField })
    this.getTransferts();
    }

    formatDate(date: string): string {
      return this.datePipe.transform(date, 'yyyy-MM-dd ');
    }
// *****************************************initialize multiselect****************************************************

    async initializeData() {
   
      await this.getEtablissement().then(() => {
        if (this.Etablissements && this.Etablissements.length > 0) {
          console.log(this.Etablissements)
          this.selectedEtablissement=this.Etablissements

          this.selectedStore = [];

          this.Depots = [];
  
           setTimeout(() => {
            
            for ( let etabliss of this.Etablissements){
              for ( let depot of etabliss.stores){    
                this.Depots.push(depot) ;
                this.selectedStore.push(depot)

              }
              }
         });
         console.log(this.selectedStore)

        }

        if (this.EtablissementsRecepteur && this.EtablissementsRecepteur.length > 0) {
          console.log(this.EtablissementsRecepteur)
          this.selectedEtablissementRecepteur=this.EtablissementsRecepteur
          this.selectedStoreRecepteur = [];

          this.DepotsRecepteur = [];
  
           setTimeout(() => {
            
            for ( let etabliss of this.EtablissementsRecepteur){
              for ( let depot of etabliss.stores){    
                this.DepotsRecepteur.push(depot) ;
                this.selectedStoreRecepteur.push(depot)

              }
              }
         });
         console.log(this.selectedStoreRecepteur)

        }
      });
  
      // this.selectedArticle=this.articles
      // this.selectedFournisseur=this.Fournisseurs
      
   }
   // ********************************************detect changes *************************************************

    onEtablissementChange(event: any) {
   
      this.Depots = [] ;
      this.selectedStore=[]
      if(this.selectedEtablissement.length==1){
        for ( let etablissement of this.selectedEtablissement) {
          for ( let store of etablissement.stores){
            this.Depots.push(store) ;
          }
          this.selectedStore=  [etablissement.stores[0]]
        }
      }
      if(this.selectedEtablissement.length>1){
        for ( let etablissement of this.selectedEtablissement) {
          for ( let store of etablissement.stores){
            this.Depots.push(store) ;
            this.selectedStore.push(store)
    
          }
        }
      }

      console.log('selected Etabliss',this.selectedEtablissement)
      console.log('selected depot',this.selectedStore)

    }
    onEtablissementrecepteurChange(event: any) {
   
      this.DepotsRecepteur = [] ;
      this.selectedStoreRecepteur=[]
  if(this.selectedEtablissementRecepteur.length==1){
    for ( let etablissement of this.selectedEtablissementRecepteur) {
      for ( let store of etablissement.stores){
        this.DepotsRecepteur.push(store) ;
      }
      this.selectedStoreRecepteur=  [etablissement.stores[0]]
    }
  }
  if(this.selectedEtablissementRecepteur.length>1){
    for ( let etablissement of this.selectedEtablissementRecepteur) {
      for ( let store of etablissement.stores){
        this.DepotsRecepteur.push(store) ;
        this.selectedStoreRecepteur.push(store)

      }
    }
}
         console.log('selected Etabliss recep',this.selectedEtablissementRecepteur)
      console.log('selected depot recep',this.selectedStoreRecepteur)

    }

    onStoreChange(event: any) {
      console.log('Selected Store:', this.selectedStore);
    }
    onStoreRecepteurChange(event: any) {
      console.log('Selected Store recep:', this.selectedStoreRecepteur);
    }
    onFournisseurChange(event: any) {
      console.log('Selected fourniss:', this.selectedFournisseur);
    }
  
    onArticleChange(event: any) {
      console.log('Selected Article:', this.selectedArticle);
    }
    // ****************************************get data*****************************************************

    async getArticles(){
      await this.transferService.getArticles().then(res=>
        {console.log(res)
        this.articles=res?.data.map(items=>
          ({
            id: items.id,
            code_article_generique:items?.attributes?.code_article_generique,
           
          })
          )
        
        })
        console.log(this.articles)
       
    }

    async getFournisseurs(){
      await this.transferService.getFournisseurs().then(res=>
        {console.log(res)
        this.Fournisseurs=res?.data.map(items=>
          ({
            id: items.id,
            name:items?.attributes?.name,
           
          })
          )
        
        })
        console.log(this.Fournisseurs)
       
    }

    async getEtablissement(){
      await this.transferService.getEtablissements().then(res=>{
         
         console.log(res)
       this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
         store=>({id:store.id,name:store.attributes.name})
       )}))
       this.EtablissementsRecepteur=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
        store=>({id:store.id,name:store.attributes.name})
      )}))
      })
       
       console.log(this.Etablissements)
       console.log(this.EtablissementsRecepteur)

       this.selectedEtablissement=this.Etablissements[0]
       this.selectedEtablissementRecepteur=this.EtablissementsRecepteur[0]

       console.log("default selection",this.selectedEtablissement)
       console.log("default selection",this.selectedEtablissementRecepteur)

  
   
     }
// **************************************** get transfert method *****************************************************

async getTransferts() {
      try {
        this.loading = true;

        
    if (this.selectedFournisseur?.length > 0  || this.selectedStore?.length > 0 || this.selectedStoreRecepteur?.length > 0  || this.selectedArticle?.length > 0  || this.date ) {
      const depotIds = this.selectedStore?.map(depot => depot.id);
      const depotRecepIds = this.selectedStoreRecepteur?.map(depot => depot.id);
      const articleIds = this.selectedArticle?.map(article => article.id);
      const fournisseurIds = this.selectedFournisseur?.map(fournisseur => fournisseur.id);
      const date=this.date

      await  this.transferService.ConsulteTransfert(this.selectedEtablissement,depotIds,depotRecepIds, fournisseurIds,articleIds,date).then(res=>{
        console.log("transferts",res)
        this.Transferts=res.data.map(item=>({
          num:item.id,
          date:this.formatDate(item.attributes.date_transfert),
          etabEmett:{id:item.attributes.etablissement_emetteur.data.id,name:item.attributes.etablissement_emetteur.data.attributes.name},
          depotEmet:{id:item.attributes.store_emetteur.data.id,name:item.attributes.store_emetteur.data.attributes.name},
          etabRecep:{id:item.attributes.etablissement_recepteur.data.id,name:item.attributes.etablissement_recepteur.data.attributes.name},
          depotRecep:{id:item.attributes.store_recepteur.data.id,name:item.attributes.store_recepteur.data.attributes.name},
          product:item.attributes.products.data.map(item=>(
            {id:item.id,
            code_article_generique:item.attributes.code_article_generique,
            fournisseur:{id:item.attributes.fournisseur.data.id,name:item.attributes.fournisseur.data.attributes.name}
            })) ,
          transfert_variations:item.attributes.transfert_variations.data.map(item=>(
          {
            id:item.id,
            quantite:item.attributes.quantity,
            variations:
            {
              id:item.attributes.variation.data.id,
              article:item.attributes.variation.data.attributes.code_article_generique,
              color:{id:item.attributes.variation.data.attributes.color.data.id,name:item.attributes.variation.data.attributes.color.data.attributes.name},
              taille:{id:item.attributes.variation.data.attributes.taille.data.id,name:item.attributes.variation.data.attributes.taille.data.attributes.name}
            }
          }
          ))
        })
        )
        this.paginationInfo = res.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
      })

      console.log("mapped transfert with filter",this.Transferts)


    }
      else{
        await  this.transferService.ConsulteTransfert().then(res=>{
          console.log("transferts",res)
          this.Transferts=res.data.map(item=>({
            num:item.id,
            date:this.formatDate(item.attributes.date_transfert),
            etabEmett:{id:item.attributes.etablissement_emetteur.data.id,name:item.attributes.etablissement_emetteur.data.attributes.name},
            depotEmet:{id:item.attributes.store_emetteur.data.id,name:item.attributes.store_emetteur.data.attributes.name},
            etabRecep:{id:item.attributes.etablissement_recepteur.data.id,name:item.attributes.etablissement_recepteur.data.attributes.name},
            depotRecep:{id:item.attributes.store_recepteur.data.id,name:item.attributes.store_recepteur.data.attributes.name},
            product:item.attributes.products.data.map(item=>(
              {id:item.id,
              code_article_generique:item.attributes.code_article_generique,
              fournisseur:{id:item.attributes.fournisseur?.data?.id,name:item.attributes.fournisseur?.data?.attributes?.name}
              })) ,
            transfert_variations:item.attributes.transfert_variations.data.map(item=>(
            {
              id:item.id,
              quantite:item.attributes.quantity,
              variations:
              {
                id:item.attributes.variation.data.id,
                article:item.attributes.variation.data.attributes.code_article_generique,
                color:{id:item.attributes.variation.data.attributes.color.data.id,name:item.attributes.variation.data.attributes.color.data.attributes.name},
                taille:{id:item.attributes.variation.data.attributes.taille.data.id,name:item.attributes.variation.data.attributes.taille.data.attributes.name}
              }
            }
            ))
          })
          )
          this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
        })

        console.log("mapped transfert",this.Transferts)

      }
    
        
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; 
    }
    }
    // **************************************** pagination*****************************************************
    refreshPagination(){
      // console.log('current page',this.paginator.getPageCount() )
      // console.log('first',this.first)
    
      if(1 < this.paginator.getPageCount()  ){
        
         this.transferService.sendData({currentPage:1,pageSize:this.pageSize})
         this.paginator.first = 0;
      }
    }
    async onPageChange(event) {
 
      console.log(event)
      console.log("nb rows per page",event.rows)
      this.pageSize=event.rows
      this.currentPage=event.page
      console.log(this.currentPage)
    // if (this.selectedFournisseur?.length > 0  || this.selectedStore?.length > 0 || this.selectedStoreRecepteur?.length > 0  || this.selectedArticle?.length > 0  || this.date ) {
      await this.transferService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
      await this.getTransferts()  
    //   }
    // else{
    //   await this.transferService.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
    //  await this.getTransferts()
  
    // }
   
     
     }
}
