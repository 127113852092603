import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArticleService } from 'src/app/demo/service/article.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-variation',
  templateUrl: './add-variation.component.html',
  styleUrls: ['./add-variation.component.scss']
})
export class AddVariationComponent implements OnInit {
  data: any; 
  // @Output() dataEvent = new EventEmitter<string>();
  @Output() outputvariationToParent  : EventEmitter<string> = new EventEmitter();
  @Output() outputvariationToList  : EventEmitter<string> = new EventEmitter();
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  variationdialog
  code=""
  idProd
  taille
  couleurs
  selectedColor
  selectedTaille
  idVariation
  prodVariations
  filtredArray
  listVariations
  allProducts
  constructor( private s:ArticleService) { }

  async ngOnInit(): Promise<void> {
    console.log(this.data)
    await this.gettaille()
  await this.getcouleur()

  }
   showDialog(data? :any,add? :any) {
    console.log(data)

    this.data=add
    console.log(add)
    if(data){
      console.log( this.data?.prodVariations        )
      this.idVariation=data?.id
     
      this.selectedColor=data?.color?.id
      this.selectedTaille=data?.taille?.id
     this.variationdialog= true;
    }
    
      console.log(this.data)
      this.code=add?.code
      this.idProd=add?.idProd
      this.prodVariations=add?.prodVariations
      this.listVariations=add?.prodVariations
      this.variationdialog= true;
      console.log(this.listVariations)
      this.allProducts=add?.allProducts
      console.log(this.allProducts)
      let prod=this.allProducts?.filter(prod=>prod.id == this.idProd)
      console.log(prod)
      
    
    
  }

  hideDialogV() {
    // this.outputvariationToParent.emit (this.prodVariations)
    // this.outputvariationToList.emit (this.prodVariations)

    this.variationdialog= false;
    this.selectedTaille = null;
    this.selectedColor = null; 
    
  }
  async gettaille() {
    await this.s.gettaille().then(data => {
      console.log("tailles",data)
     
    this.taille=data.data.map(x=>({
      value:x.id,
label:x.attributes.name


    }))
     
      
     
      
  
    });

    console.log("taille",this.taille)
    
  
  }


  async getcouleur() {
    await this.s.getcolor().then(data => {
      console.log("colors",data)
     
    this.couleurs=data.data.map(x=>({
      value:x.id,
label:x.attributes.name


    }))
  
    });

    console.log("couleurs",this.couleurs)
    
  }
  // sendData(dataToSend) {
  //   this.dataEvent.emit(dataToSend);
  // }
   saveVariations(){
    //console.log("codearticle",this.product.code)
   // console.log("codde barre",this.product.code_barre)
  //  console.log('taille',this.taille)
  //  console.log('color',this.selectedColor )
  //  console.log('taille',this.selectedTaille )
  
   /* console.log("id",this.id)*/
  if(!this.idVariation){
     this.s.addvariations({data:{
      "code_barre":'CodeBarre',
      "code_article_generique":this.code,
      "taille":this.selectedTaille,
      "color":this.selectedColor,
       "product":this.idProd}}).then(res=>{
        console.log('variation',res);
        if (this.data.prodVariations){ const newVariation = {
          id: res.data.id, // Assuming 'id' is a valid property in res.data
          code_article_generique: res.data.attributes.code_article_generique,
          code_barre: res.data.attributes.code_barre,
          taille: {
            id: res.data.attributes.taille.data.id,
            name: res.data.attributes.taille.data.attributes.name,
          },
          color: {
            id: res.data.attributes.color.data.id,
            name: res.data.attributes.color.data.attributes.name,
          }
        };
        this.prodVariations=this.data.prodVariations
        this.prodVariations.push(newVariation);
        console.log( this.data.prodVariations)
      }
       
       //vider les champs
       if(this.allProducts && this.allProducts!==undefined  ){
        let prod=this.allProducts.filter(prod=>prod.id == this.idProd)
        prod[0].variations?.data.push(res.data)
        console.log(prod)


       }
       this.selectedTaille = null;
       this.selectedColor = null; 
    
    // console.log("this.pv",this.productvariation)
       
      }
      
    )
    this.variationdialog= false;
    this.notifyParent.emit(0);

    
  }
  else {
     this.s.editvariation({
      "taille":this.selectedTaille,
      "color":this.selectedColor
    },this.idVariation).then(res=>{
      console.log(res)
      const newVariation = {
        id: res.data.id, // Assuming 'id' is a valid property in res.data
        code_article_generique: res.data.attributes.code_article_generique,
        code_barre: res.data.attributes.code_barre,
        taille: {
          id: res.data.attributes.taille.data.id,
          name: res.data.attributes.taille.data.attributes.name,
        },
        color: {
          id: res.data.attributes.color.data.id,
          name: res.data.attributes.color.data.attributes.name,
        }
      };
      // this.prodVariations=this.data.prodVariations
      // for(let val of  this.prodVariations){
      //   const index=this.prodVariations.indexOf(val.id==res.data.id)
      //   console.log(index)

      // }

      this.prodVariations = this.prodVariations.filter(variation => variation.id !== res.data.id);
      // for(let val of this.filtredArray){
        // this.prodVariations.push(val)
      // }
      
      this.prodVariations.push(newVariation);
      this.data.prodVariations=this.prodVariations 
      this.outputvariationToParent.emit (this.prodVariations)

      const indexToUpdate = this.listVariations.findIndex(variation => variation.id === res.data.id);

if (indexToUpdate !== -1) {
    this.listVariations[indexToUpdate] = res.data;
}
      else{
        this.listVariations=this.listVariations.filter(variation => variation.id !== res.data.id);
        this.listVariations.push(res.data);
       this.outputvariationToList.emit ( this.listVariations)
 
      }
      

      // console.log(this.filtredArray)
      
      console.log(this.prodVariations)
      console.log(this.data.prodVariations)
      console.log(this.listVariations)

    })
    
 
    this.variationdialog= false;
    this.selectedTaille = null;
    this.selectedColor = null; 
    this.notifyParent.emit(1);
    this.idVariation=null
  }
  
  }
  
  
}
