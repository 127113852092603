<p-dialog [(visible)]="articleDialog" [style]="{width: '765px' ,height:'615px'}" 
[breakpoints]=" 
        { 
            '800px': '30vw',  
            '650px': '100vw' 
        }"  
header="Ajouter article" [modal]="true" class="p-fluid" (onHide)="hideDialog()">
  <!-- <p-toast></p-toast> -->

  <ng-template pTemplate="content"  >
    <div style="display: flex;">
<p-tabView  orientation="center">
       <p-tabPanel header="GENERAL"  >
        <div class="content">
          <div class="field-container" >
          

             <div class="field">
              <label htmlFor="name">Code article<span class="required"> *</span></label>
              <div class="input-container">
              <input pInputText id="name"   [readonly]="disableArticle ? true : false"  [class.empty-field]="(fieldsEmpty  && !article.code_article_generique) || EXIST" type="text" (input)="onInputChange('code_article_generique',$event)" [(ngModel)]="article.code_article_generique" />
              </div>
            </div>

            <div class="field">
              <label htmlFor="name">Ref fournisseur</label>
              <div class="input-container">
              <input pInputText id="name"  type="text" (input)="onInputChange('code_fournisseur',$event)"  [(ngModel)]="article.code_fournisseur" />
              </div>
            </div>

            <div class="field">
              <label htmlFor="name">Designation</label>
              <div class="input-container">
              <input pInputText id="name"  type="text" (input)="onInputChange('designation',$event)" [(ngModel)]="article.designation"  />
              </div>
            </div>
          </div>
          
          <div class="drop">
          

            <div class="dropdown-container">
              <label htmlFor="name">Fournisseur<span class="required"> *</span></label>
              <div class="custom">

            <p-dropdown  [options]="Fournisseurs" [ngClass]="{'empty-dropdown': fieldsEmpty && !article.fournisseur}" [showClear]="true" placeholder="<<Aucun>>"  (onChange)="selectChanges($event, 'fournisseur')" [(ngModel)]="article.fournisseur" optionLabel="name"></p-dropdown>
            </div>
            </div>
            
         
          <div class="dropdown-container">
            <label htmlFor="name">Collection<span class="required"> *</span></label>
            <div class="custom">

          <p-dropdown  [options]="Collections"   [ngClass]="{'empty-dropdown': fieldsEmpty && !article.collection}" [showClear]="true" placeholder="<<Aucun>>" (onChange)="selectChanges($event, 'collection')" [(ngModel)]="article.collection" optionLabel="name"></p-dropdown>
          </div>
          </div>
          
          <div class="dropdown-container">
            <label htmlFor="name">Rayon<span class="required"> *</span></label>
            <div class="custom">

          <p-dropdown [options]="Rayons" [ngClass]="{'empty-dropdown': fieldsEmpty && !article.rayon}"  [showClear]="true" placeholder="<<Aucun>>" (onChange)="selectChanges($event, 'rayon')" [(ngModel)]="article.rayon" optionLabel="name"></p-dropdown>
            </div>
          </div>

          <div class="dropdown-container">
            <label htmlFor="name">Sous Famille<span class="required"> *</span></label>
            <div class="custom">

          <p-dropdown  [options]="Familles"  [ngClass]="{'empty-dropdown': fieldsEmpty && !article.sous_famille}" [showClear]="true" placeholder="<<Aucun>>" (onChange)="selectChanges($event, 'sous_famille')" [(ngModel)]="article.sous_famille"   optionLabel="name"></p-dropdown>
          </div>
          </div>

        </div>
          <div class="container ">


            <div class="dropdown-container">
              <label htmlFor="name">Nature<span class="required"> *</span></label>
              <div class="custom-dropdown">
  
            <p-dropdown [options]="Natures" [ngClass]="{'empty-dropdown': fieldsEmpty && !article.nature}" [showClear]="true" placeholder="<<Aucun>>" (onChange)="selectChanges($event, 'nature')" [(ngModel)]="article.nature"></p-dropdown>
            </div>
            </div>


            <div class="dropdown-container ">
              <label htmlFor="name">Unité de Stock<span class="required"> *</span></label>
              <div class="custom-dropdown">

            <p-dropdown  [appendTo]="'body'"  [options]="UniteStock" [ngClass]="{'empty-dropdown': fieldsEmpty && !article.unite_de_stock}"  [showClear]="true" placeholder="<<Aucun>>" (onChange)="selectChanges($event, 'unite_de_stock')" [(ngModel)]="article.unite_de_stock"></p-dropdown>
          </div>
            </div>

          
           

             </div>
            </div>
      </p-tabPanel>
      <p-tabPanel header="TARIFICATION">
        <div class="achat">

        <h4 style="color: #64748B;">Achat</h4>
      </div>

      <div class="container-tarif">
        <div class="fieldTarif">
            <label htmlFor="name">Prix HT <span class="required"> *</span></label>
            <div class="input-container">
            <input pInputText id="name"  (keypress)="onKeyPress($event)"  [class.empty-field]="fieldsEmpty  && !article.prix_achat_ht" type="text" [(ngModel)]="article.prix_achat_ht"  (input)="onInputChange('prix_achat_ht',$event)" (ngModelChange)="updateValuesAchat()"  />
            </div>
          </div>

           <div class="fieldTarif">
            <label htmlFor="name">TVA</label>
            <div class="input-container">
            <input pInputText id="name" type="text"  [(ngModel)]="tvaValue" [disabled]="true"/>
            </div>
          </div>

          <div class="fieldTarif">
            <label htmlFor="name">Prix TTC </label>
            <div class="input-container">
            <input pInputText id="name"  type="text" [(ngModel)]="article.prix_achat_ttc"  [disabled]="true"/>
            </div>
          </div>

     

        </div>
              <div class="p-divider-container">
                  <p-divider layout="vertical"  > </p-divider> 
              </div>  

              <div class="vente">
              <h4 style="color: #64748B;">Vente</h4>
            </div>
             
            <div class="second-container">
              <div class="field-Tarif">
                  <label htmlFor="name">Prix HT<span class="required"> *</span> </label>
                  <div class="input-container">
                  <input pInputText (keypress)="onKeyPress($event)" [class.empty-field]="fieldsEmpty  && !article.prix_vente_ht" type="text" (input)="onInputChange('prix_vente_ht',$event)"  (ngModelChange)="updateValuesVente($event)"  [(ngModel)]="article.prix_vente_ht" />
                  </div>
                </div>
                
  
                 <div class="field-Tarif">
                  <label htmlFor="name">TVA</label>
                  <div class="input-container">
                  <input pInputText  type="text"   [(ngModel)]="tvaValue"  [disabled]="true" />
                  </div>
                </div>
  
                <div class="field-Tarif">
                  <label htmlFor="name">Prix TTC </label>
                  <div class="input-container">
                  <input pInputText  type="text" [disabled]="true"  [(ngModel)]="article.prix_vente_ttc" />
                  </div>
                </div>

                <div class="fieldTarif-check">
                  <div class="col-12 md:col-6">
                    <div class="p-inputgroup">
                      <!-- <span class="p-inputgroup-addon"><p-checkbox [binary]="true" ></p-checkbox></span>
                      <input type="text" pInputText placeholder="FODEC" disabled > -->
                    </div>
                  </div>
                </div>

                </div>
      </p-tabPanel>

      <p-tabPanel header="PHOTO">
        <div style=" margin-left: 45px; ">

                  <p-fileUpload name="files" url="https://prefacture.rosace.com.tn/api/upload" 
                            (onUpload)="onUpload($event)"
                            multiple="multiple" accept="image/*" maxFileSize="1000000"
                            styleClass="custom-file-upload">
                      <ng-template pTemplate="content">
                          <!-- <ul *ngIf="uploadedFiles.length"> -->
                            <ul >
                              <li *ngIf="existingImageUrl">
                                <img [src]="existingImageUrl" width="50" height="50" alt="Existing Image">
                                
                              </li>
                              <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                          </ul>
                          <!-- <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                        </ul> -->
                      </ng-template>
                  </p-fileUpload>
                  </div>
    
      </p-tabPanel>

      <p-tabPanel *ngIf="dimension"  header="DIMENSION" class="tab-content" >
        <div class="field-container-Dim">
        <div class="field-dim"  >
              <div class="custom-dim1" >

            <p-dropdown  [options]="Masques" [ngClass]="{'empty-dropdown': fieldsEmpty && !tailles}" [showClear]="true" placeholder="Masque Taille" (onChange)="selectChanges($event, 'tailles')"  [(ngModel)]="tailles" optionLabel="name" (onChange)="getSizes()"></p-dropdown>
            </div>
        </div>

         <div class="field-dim">
          <div class="custom-dim2" >
           
              <p-multiSelect inputId="multiselect"  [ngClass]="{'empty-dropdown': fieldsEmpty && (!couleurs || couleurs.length === 0)}" class="custom-ms" [options]="Couleurs" (onChange)="selectChanges($event, 'couleurs')" [(ngModel)]="couleurs"  [defaultLabel]="generateCustomLabel()" optionLabel="name"></p-multiSelect>
              
        </div>
        </div>
      </div>
    <div class="container-table">
     <p-table  dataKey="id" styleClass="p-datatable-gridlines" [value]="test" [responsive]="true">
      
          <ng-template pTemplate="header">
            <tr>
              <ng-container *ngIf=" (tailles && tailles !== undefined) || (couleurs !== undefined && couleurs.length > 0)">
                <!-- <ng-container *ngIf=" couleurs "> -->

               <th class="custom-th" >COULEURS/TAILLES</th>
              <!-- </ng-container> -->
            </ng-container>


               <ng-container *ngIf=" tailles " >
               <ng-container *ngFor="let taille of sizes ; let tdindex = index">
                <th>{{ taille.name }}</th>
              </ng-container>
                       <!--<th style="min-width: 6rem;">
                  Jaune
              </th>
              <th style="min-width: 6rem;">
                  Marron
              </th> -->
            </ng-container>
            </tr>
           
          </ng-template>
          <ng-template pTemplate="body"  let-colIndex>
            <ng-container *ngIf="couleurs " >

            <ng-container *ngFor="let color of couleurs ">

            <tr>
              <td  style="background-color: #ffffff" >{{color.name}}</td>
              <ng-container *ngIf="sizes">
                <ng-container *ngIf="couleurs">

                  <ng-container *ngFor="let taille of sizes; let colIndex = index">
                    <ng-container *ngIf="!BlocUpdateShow">

                    <td 
                    (click)="toggleCellSelection(color, taille)" 
                    [ngClass]="
                    {'selected-cell': isSelectedCell(color.name, taille),
                    'empty-cell': fieldsEmpty &&  (!selectedCells ||selectedCells.length==0)}">

                      <span *ngIf="showIcon  && isSelectedCell(color.name, taille)">
                        <i class="pi pi-check" style="color: white; text-align: center;; display: block; margin: auto;"></i>
                      </span>

                    </td>
                  </ng-container>
                    <ng-container *ngIf=" BlocUpdateShow ">

                      <td
                      [ngClass]="{'selected-cell': isCellSelected(color.name, taille)}"
                      (click)="toggleCellSelection(color, taille)">
  
                        <span *ngIf="showIcon &&  isCellSelected(color.name, taille)">
                          <i class="pi pi-check" style="color: white; text-align: center;; display: block; margin: auto;"></i>
                        </span>
  
                      </td>
                    </ng-container>
                  

                  <!-- <ng-container *ngFor="let taille of sizes; let colIndex = index"> -->

                    

                  <!-- </ng-container> -->
                </ng-container>

                </ng-container>
              </ng-container>
            </tr>
                        </ng-container>
                      </ng-container>

          </ng-template>
          
          </p-table>
        </div>
      </p-tabPanel>
      </p-tabView>
    </div>
</ng-template>
<ng-template pTemplate="footer">
<button pButton pRipple label="annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
<button pButton pRipple [disabled]="disableSaveButton" label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="ArtilceToEdit ? EditArtilce() : saveArticle()"></button>
</ng-template>
</p-dialog>