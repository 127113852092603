 <!-- *****************************save product*********************************** -->
 <p-dialog [(visible)]="productDialog" [style]="{width: '850px'}" header="Ajouter article" [modal]="true" class="p-fluid" (hide)="hideDialog()">
    <p-toast></p-toast>

    <ng-template pTemplate="content"  >
      
        
        <img *ngIf="showImage"  [src]="'https://prefacture.rosace.com.tn'+this.imageNewProduct" [alt]="this.imageNewProduct" width="150" class="mt-0 mx-auto mb-5 block shadow-2"  >
    <div class="field">
        <label for="name">Reference</label>
        <input type="text" [disabled]="disable"  pInputText id="Reference" [(ngModel)]="productData.ref" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
    </div>
    <div class="field">
        <label for="code_article_generique">code_article_generique</label>
        <input type="text"   [disabled]="disable" pInputText id="code_article_generique"  [(ngModel)]="productData.code" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
    </div>

    <div class="field">
        <label for="libelle_article">libelle_article</label>
        <input type="text" pInputText id="libelle_article"  (input)="sendTheNewValue($event)" [(ngModel)]="productData.libelle" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.libelle_article">Name is required.</small>
    </div>

    <div class="field">
        <label for="prix vente">prix vente</label>
        <input type="text" pInputText id="prix vente" (input)="sendTheNewValue($event)" [(ngModel)]="productData.pVente" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
    </div>

    <div class="field">

        <label for="fournisseur">fournisseur</label>
    <!-- <p-autoComplete [suggestions]="filteredFourniss" [(ngModel)]="productData.fourniss" (completeMethod)="filterFourniss($event)" field="label" [dropdown]="true"> -->
        <p-autoComplete  [(ngModel)]="productData.fourniss"  [suggestions]="filteredFourniss"  (completeMethod)="filterFourniss($event)" (onSelect)="onFournisseurSelect($event)"  field="label" [dropdown]="true">

    </p-autoComplete>
    </div>
    <br>
    <div class="field">
        <div class="grid">
            <!-- <div class="col-12 md:col-4">
                <div class="field-checkbox">
                    <p-checkbox name="dimensionne"  [binary]="true" [(ngModel)]="val1Check" id="ny" onchange="changeCheckedValue($event.checked,'true')" (click)="onCheckboxClick('true')"></p-checkbox>
                    <label for="ny">Dimensionné</label>
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="field-checkbox">
                    <p-checkbox name="dimensionne"  [binary]="true" [(ngModel)]="val2Check" id="sf" (click)="onCheckboxClick('false')"></p-checkbox>
                    <label for="sf">Non Dimensionné</label>
                </div>
            </div> -->
            <div class="col-12 md:col-4">
                <div class="field-checkbox">
                    <p-radioButton 
                    name="dimensionne"  
                    [value]="true" 
                    [(ngModel)]="etat"
                    [disabled]="true"
                  ></p-radioButton>
                  <!-- (click)="onRadioButtonChange(true)" -->

                  <label for="dimensionne">Dimensionné</label>
                   
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="field-checkbox">
                    <p-radioButton 
                    name="dimensionne"  
                    [value]="false" 
                    [(ngModel)]="etat"
                    [disabled]="true"
                  

                  ></p-radioButton>
                    <!-- (click)="onRadioButtonChange(false)" -->
                  <label for="dimensionne">Non Dimensionné</label>
                </div>
            </div>
            </div>

        </div>
        <!-- <div class="field">
            <div class="grid">
                <div class="col-12 md:col-4">
                    <div class="field-checkbox">
                        <p-checkbox name="dimonsionne"  [binary]="true" [(ngModel)]="etat" id="ny" (click)="onCheckboxClick('true')"></p-checkbox>
                        <label for="ny">Dimensionné</label>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field-checkbox">
                        <p-checkbox name="non-dimensionne"  [binary]="true" [(ngModel)]="etat" id="sf" (click)="onCheckboxClick('false')"></p-checkbox>
                        <label for="sf">Non Dimensionné</label>
                    </div>
                </div>
                </div>
    
            </div> -->
    <div class="field">
        <label for="image">image</label>
        
<p-fileUpload name="files"  url="https://prefacture.rosace.com.tn/api/upload" (onUpload)="onUpload($event)" (input)="sendTheNewValue($event)"
accept="image/*" maxFileSize="1000000"></p-fileUpload>


        
    </div>
    <div class="field">
        <button pButton pRipple  *ngIf="showButton" (click)="addvariation()" label="ajouter variations de produit " class="p-button-success">
                    
        </button>
        </div>
        <app-add-variation  [data]="dataToSend" (outputvariationToParent)="receivedMessageHandler($event)" (notifyParent)="handleNotification($event)"></app-add-variation>
        <div *ngIf="showButton">
        <p-table [columns]="colsvariationP"  editMode="row" [value]="productData.variations" *ngIf="showVariationTable"  >
            <ng-template pTemplate="header" let-columns>
                
                <tr ptemplate="header">
                   <th *ngFor="let col of columns">
                    <div *ngIf="col.field=='code_article_generique'" class="flex justify-content-between align-items-center">
                        {{ col.header }}
                        

                        <p-columnFilter [filter] = "true" type="numeric" field="code_article_generique" display="menu" ></p-columnFilter>
                    </div>
                    <div *ngIf="col.field=='color'" class="flex justify-content-between align-items-center">
                        {{ col.header }}
                        

                        <p-columnFilter [filter] = "true" type="numeric" field="color" display="menu" ></p-columnFilter>
                    </div>
                    <div *ngIf="col.field=='taille'" class="flex justify-content-between align-items-center">
                        {{ col.header }}
                        

                        <p-columnFilter [filter] = "true" type="numeric" field="taille" display="menu" ></p-columnFilter>
                    </div>
                </th>
                    </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns" let-variation let-ri="rowIndex">
                <tr  [pEditableRow]="columns">
                    <td style="min-width: 12rem;" *ngFor="let col of columns">

                    
                        <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                        
                            {{rowData[col.field] }}
                       </div>
                       <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center">
                        <!-- <div *ngFor="let item of rowData[col.field] | keyvalue">
                            {{item.name}}
                          </div> -->
                        
                        {{rowData[col.field]?.name}}
                   </div>
                   <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
                        
                    {{rowData[col.field]?.name }}
               </div>
                    </td>
                
                    <td>
                        <div class="flex">
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editVariation2(rowData)"></button>
                         <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button> 
                        </div>
                    </td></tr></ng-template>
        </p-table>
    </div>
</ng-template>


<ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple [disabled]="disableSaveButton" label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
</ng-template>

</p-dialog>
<app-delete-dialog></app-delete-dialog>

 
