import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-stock',
  templateUrl: './module-stock.component.html',
  styleUrls: ['./module-stock.component.scss']
})
export class ModuleStockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
