import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Image } from '../domain/image';
import { MessageService } from 'primeng/api';

@Injectable()
export class PhotoService {
  private isToastShown: boolean = false;

    constructor(private http: HttpClient, private messageService: MessageService) { }
    private handleApiError(error) {
      // Handle API error status here
      console.error('API call failed:', error);
    
      if (error.status !== 200) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
        this.isToastShown = true;
        setTimeout(() => {
          this.isToastShown = false;
        }, 5000);
      } 
      
    }
//     getImages() {
//     return this.http.get<any>('assets/demo/data/photos.json')
//       .toPromise()
//       .then(res => res.data as Image[])
//       .then(data => data);
//     }

getImages(): Promise<Image[]> {
  const apiUrl = 'assets/demo/data/photos.json';

  try {
    return this.http.get<any>(apiUrl)
      .toPromise()
      .then(res => res.data as Image[])
      .then(data => data)
      .catch((error) => {
        this.handleApiError(error);
        throw error; // Rethrow the error after handling it
      });
  } catch (error) {
    // Handle synchronous errors (e.g., invalid JSON parsing)
    console.error('Synchronous error:', error);
    this.handleApiError(error);
    throw error; // Rethrow the error after handling it
    }
}

}