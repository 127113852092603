import { Component, EventEmitter, OnInit, Output,Input } from '@angular/core';
import { AchatsService } from 'src/app/demo/service/achats.service';
import { ArticleService } from 'src/app/demo/service/article.service';
import { MessageService } from 'primeng/api';
import { RoundToThreeDecimalsPipe } from 'src/app/round-to-three-decimals.pipe';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-add-achat-article',
  templateUrl: './add-achat-article.component.html',
  styleUrls: ['./add-achat-article.component.scss']
})
export class AddAchatArticleComponent implements OnInit {
  @Output() dataSent = new EventEmitter<any>();
  @Input() inputDetailsAchat
  loading:boolean
  Masques
  selectedArticle
  articles
  Articles
  variationsArticle
  Prix
  prixht
  Remise
  ColorsArray: any[] = [];
  TailleArray: any[] = [];
  newAchat
  achatVariations:any[]=[]
  isArticleSelectionDisabled: boolean 
  AchatDialog
  stocks
  StockArticle
  StockVariations
  DetailsAchats
  msgs
  filteredArticle
  receiveFiltredArticle
  emptyarticle:boolean=false
test = [
  { id: 'Q35', Marron: '', Jaune: '' },
  // { id: 'Q36', Marron: '', Jaune: '' },
  // Add more data as needed
];

 

  constructor(private achatService:AchatsService,private articleService:ArticleService,private Roundpipe:RoundToThreeDecimalsPipe,private messageService: MessageService) { 
    this.Remise=0

  }

 async ngOnInit() {
    this.getMasques()
    this.getArticles()
    // this.getStock()


  }
  // receiveListProductFromChild(event){
  //   console.log("article selectionner",event)
  // }
  filterArticle(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Articles as any[]).length; i++) {
        let country = (this.Articles as any[])[i];
        if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredArticle = filtered;
}
// filterArticle(event: AutoCompleteCompleteEvent) {
//   let filtered: any[] = [];
//   let query = event.query;

//   for (let i = 0; i < (this.articles as any[]).length; i++) {
//       let country = (this.articles as any[])[i];
//       if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
//           filtered.push(country);
//       }
//   }

//   this.filteredArticle = filtered;
// }
validateRemise(event){
  // console.log(event)
  const inputValue = event.target.value;
  console.log(inputValue)
  // Ensure the input is a non-negative number between 0 and 100
  const pattern = /^(100|[1-9]?\d|0)$/;
  if (pattern.test(inputValue)) {
    this.Remise = parseInt(inputValue, 10);
    if (this.Remise === 100) {
      this.prixht = 0;
      this.Prix = 0;
    }
  } 
  else {
    this.Remise = 0;
  }
}
  validateInput() {
    if (this.Remise < 0) {
      this.Remise = 0;
    } else if (this.Remise > 100) {
      this.Remise = 100;
    }
  }
  total : number  = 0;
  totalht : number  = 0;
  updateValues() {
    this.Prix = this.Remise == 0.0 ? this.total :   this.Roundpipe.transform((this.total * (100 - this.Remise)) / 100);
    this.prixht = this.Remise == 0.0 ? this.totalht :  this.Roundpipe.transform((this.totalht * (100-this.Remise))/100 );
  }
  async showDialog(data?){
    this.AchatDialog=true
    this.emptyarticle=false
    console.log("Details Achats",data)
    this.DetailsAchats=data
    if(data.fournisseur){
      // try {
      //   this.loading = true;
      this.achatService.FilterArticles(data.fournisseur.id).then(res=>{
        console.log(res)
        this.Articles=res?.data.map(items=>
          ({
            id: items.id,
            code_article_generique:items?.attributes?.code_article_generique,
            code_fournisseur:items?.attributes?.code_fournisseur,
            designation:items?.attributes?.designation,
            sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
            collection:items?.attributes?.collection.data?.attributes?.name,
            rayon:items?.attributes?.rayon.data?.attributes?.name,
            prix_achat_ttc:items?.attributes?.prix_achat_ttc,
            prix_vente_ttc:items?.attributes?.prix_vente_ttc,
            prix_achat_ht:items?.attributes?.prix_achat_ht,
            prix_vente_ht:items?.attributes?.prix_vente_ht,
            fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
            nature:'',
            Unite_de_stock:'',
            variations:items?.attributes?.variations.data
          })
          )
        
       
        console.log("filtred article by fourniss",this.Articles)
        if(this.Articles.length==0){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Fournisseur selectionner n a pas des articles' });

        }
      
        
      })
    // } catch (error) {
    //   console.error('Error:', error);
    // } finally {
    //   this.loading = false; // Set loading to false after receiving the response or in case of an error
    // }
    }

  }
 
  showErrorViaMessages(messages) {
    this.msgs = [];
    // this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg});
    messages.forEach(msg => {
      this.msgs.push({ severity: 'error', summary: 'Erreur', detail: msg });
    });
}
  // async getStock() {
  //   try {
  //     const res = await this.achatService.getStocks();
  //     console.log(res);
  
  //     this.stocks = res.data.map(item => ({
  //       id: item.id,
  //       qte: item.attributes.quantity,
  //       article: item.attributes.product.data?.attributes.code_article_generique,
  //       store: item.attributes.store.data?.attributes.name,
  //       variation: {
  //         id:item.attributes.variation.data.id,
  //         couleur: item.attributes.variation.data.attributes.color.data.attributes.name,
  //         taille: item.attributes.variation.data.attributes.taille.data.attributes.name
  //       }
  //     }));
  
  //     console.log("liste stocks",this.stocks);
  //   } catch (error) {
  //     console.error('Error fetching stocks:', error);
  //   }
  // }
  
  async getArticles(){
    await this.achatService.getArticles().then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      
      })
      console.log(this.articles)
     
  }
 
  getMasques(){
    this.achatService.getMasques().then(res=> {
      console.log(...res.data)
    this.Masques=res.data.map(item=>{return { name: item.attributes?.name, id: item.id, tailles:item.attributes?.tailles_masques };})
    // this.Tailles=res.data.attributes.tailles_masques.data
    console.log(this.selectedArticle)

    console.log(...this.Masques)
    })
  }

 async getwithkeyup(event){
    console.log("key press article", event.target.value)
      await this.achatService.getArticles(event.target.value).then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      
      })
      console.log(this.articles)
      const matchingArticle = this.Articles.find(article => article.code_article_generique === event.target.value);

  if (matchingArticle) {
this.selectedArticle=matchingArticle
  } else {
    // this.messageService.add({
    //   severity: 'error',
    //   summary: '',
    //   detail: 'Article n existe pas ' ,
    // })
    this.achatVariations=[]
    this.variationsArticle = [];
    this.ColorsArray = [];
    this.TailleArray = [];
    this.prixht=0
    this.Prix=0
    this.Remise=0

  }
  }
  getSelectedArticles(event) {
    console.log('Selected Article:', event.value);

    console.log("article selectionner",this.selectedArticle);
    this.Remise=0
    this.variationsArticle = [];
    this.achatVariations=[]
    this.Prix = null;
    this.ColorsArray = [];
    this.TailleArray = [];
    if (this.selectedArticle) {
      this.variationsArticle = this.selectedArticle.variations.map(item =>
        ({
          id: item.id,
          code_barre:item.attributes.code_barre,

          color: {
            colorName: item.attributes.color.data.attributes.name,
            colorId: item.attributes.color.data.id
          },
          taille: {
            tailleName: item.attributes.taille.data.attributes.name,
            TailleId: item.attributes.taille.data.id
          }
        }));
  
      this.Prix =  this.Roundpipe.transform(this.selectedArticle.prix_achat_ttc);
      this.prixht = this.Roundpipe.transform(this.selectedArticle.prix_achat_ht);
      this.total = this.selectedArticle.prix_achat_ttc;
      this.totalht = this.selectedArticle.prix_achat_ht;

      // const stockVariation=this.stocks.filter(stock => stock.article === this.selectedArticle.code_article_generique &&
      //   stock.store===this.DetailsAchats.store.name &&
      //   this.variationsArticle.some(variation => variation.color.colorName === stock.variation.couleur)
      //   );
      //      console.log("stock variation",stockVariation);
      //      this.StockVariations=stockVariation

      // this.StockArticle=this.stocks.filter(stock => stock.article === this.selectedArticle.code_article_generique);
      // console.log(this.StockArticle);

    }
    
    console.log("variations article",this.variationsArticle);
    this.getDistinctColorsAndTailles();
  }
  customSort(a: string, b: string): number {
    // Extract the numeric part from strings
    const numA = parseInt(a.match(/\d+/)?.[0] || "0", 10);
    const numB = parseInt(b.match(/\d+/)?.[0] || "0", 10);
  
    // Compare the numeric parts
    if (numA !== numB) {
      return numA - numB; // Sort numerically in descending order
    }
  
    // If numeric parts are equal, compare the entire strings
    return a.localeCompare(b);
  }
getDistinctColorsAndTailles() {
  const uniqueColors = new Set<string>();
  const uniqueTailles = new Set<string>();

  this.variationsArticle.forEach(item => {
    uniqueColors.add(item.color.colorName);
    uniqueTailles.add(item.taille.tailleName);
  });

  this.ColorsArray = Array.from(uniqueColors).map(colorName => ( colorName ));
  this.TailleArray = Array.from(uniqueTailles).sort((a, b) =>this.customSort(a, b));
  ;


  console.log(this.ColorsArray)
  console.log(this.TailleArray)

}
// customFilter(event) {
  
//   if (this.selectedArticle) {
//     return this.articles.filter(article => article.code_article_generique === this.selectedArticle.code_article_generique);
//   }
//   return this.articles;
// }

findVariationId(size, color): any | undefined {
  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  return variation ? variation.id : undefined;
}
onCellInput(event: any, color: any, size: any) {

    const idVariation=this.findVariationId(size,color)
    const variationObject = this.variationsArticle.find(variation => variation.id === idVariation);
    console.log(variationObject);

    const code_barre= this.variationsArticle.filter(variation => variation.id === idVariation);
    const cellValue = event.target.innerText;
   if (cellValue !== '' && cellValue !== '0' && cellValue !== null && cellValue !== undefined) {
    console.log({'QTE': cellValue, 'ID': idVariation, 'code_barre':variationObject.code_barre,'Color': color, 'Size': size});

    const newItem = {'QTE': cellValue, 'ID': idVariation, 'code_barre':variationObject.code_barre,'Color': color, 'Size': size};

    // Remove existing saved value for the given size and color
    this.removeSavedValue(size, color);

    // Save the new item
    this.achatVariations.push(newItem);
    console.log(this.achatVariations);
  }
  else {
    // If the cell value is empty, remove the value from achatVariations
    this.removeSavedValue(size, color);
    console.log(this.achatVariations);
  }
  // const idVariation=this.findVariationId(size,color)
  // const cellValue = event.target.innerText;
  // console.log({'QTE': cellValue,'ID':idVariation,'Color':color,'Size': size});
  // const newItem={'QTE': cellValue,'ID':idVariation,'Color':color,'Size': size}
  
  // // Remove existing saved value for the given size and color
  // this.removeSavedValue(size, color);
  
  // // Save the new item
  // this.achatVariations.push(newItem);
  // console.log(this.achatVariations);
}

removeSavedValue(size: string, color: string) {
  const indexToRemove = this.achatVariations.findIndex(item =>
    item.Size === size && item.Color === color
  );
  
  if (indexToRemove !== -1) {
    this.achatVariations.splice(indexToRemove, 1);
  }
}

onKeyPress(event: any) {
  console.log(event)
  const pattern = /^(?!0)[0-9]+$/; 
  const existingContent = event.target.innerText;
  const keyPressed = event.key;

  const inputValue = existingContent + keyPressed;

  if (!pattern.test(inputValue)) {
    console.log('Invalid value:', inputValue);
      event.preventDefault();

  } else {
    console.log('Valid value:', inputValue);
  }

}

isDisabled(size, color): boolean {
  // console.log(size,color)

  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  if(variation){

    return false
  }
  else {

    return true}
  // console.log(variation)
  // return variation !== undefined;
}
hide(){
  this.AchatDialog=false
  this.achatVariations=[]
this.prixht=''
this.Prix=''
this.Remise=0
this.selectedArticle=''
}
hideDialog() {
  if(this.selectedArticle){
    if(this.achatVariations && this.achatVariations.length>0){

    this.newAchat={'achatVariations':this.achatVariations,
    'prix':this.Prix,
    'prixht':this.prixht,
    'remise':this.Remise,
    'ref':this.selectedArticle.code_article_generique,
    'designation':this.selectedArticle.designation,
    'article':this.selectedArticle
  }
  console.log('new achat',this.newAchat)
  this.dataSent.emit(this.newAchat)
    this.AchatDialog=false
    this.achatVariations=[]
  this.prixht=''
  this.Prix=''
  this.Remise=0
  this.selectedArticle=''}
  else{
    if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Ajouter Quantité  ' ,
      })
    }
   
   }
  }
 else{
  this.emptyarticle=true
  // this.hide()
 }
}
sendAchat(){
  if(this.selectedArticle){
    if(this.achatVariations && this.achatVariations.length>0){

    this.newAchat=
    {'achatVariations':this.achatVariations,
    'prix':this.Prix,
    'prixht':this.prixht,
    'remise':this.Remise,
    'ref':this.selectedArticle.code_article_generique,
    'designation':this.selectedArticle.designation,
    'article':this.selectedArticle}
  console.log('new achat',this.newAchat)
  this.dataSent.emit(this.newAchat)
  
  this.achatVariations=[]
  this.Prix=''
  this.prixht=''
  this.Remise=0
  this.selectedArticle=''}
  else{
    if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Ajouter Quantité  ' ,
      })
    }
    
   }
  }
  else{
    this.emptyarticle=true
  // this.hide()
  }
}
}
