<p-dialog [(visible)]="venteDialog" [style]="{width: '750px' }"
[breakpoints]=" 
        { 
            '800px': '30vw',  
            '650px': '100vw' 
        }"  
header="Ajouter Transfert" [modal]="true" class="p-fluid" (onHide)="hide()" >
    <!-- <p-toast></p-toast> -->
  
    <ng-template pTemplate="content"  >
      <div >

        
        <div class="field-dropdown "  >
             
           <p-autoComplete  [(ngModel)]="selectedArticle" [appendTo]="'body'"  [suggestions]="filteredArticle"  (completeMethod)="filterArticle($event)" placeholder="Article"
				field="code_article_generique" [dropdown]="true"   (onKeyUp)="getwithkeyup($event)" [ngClass]="{'empty-input': !selectedArticle && emptyarticle}" (onSelect)="getSelectedArticles($event)"  (keyup)="onInputArticle($event)"  
				></p-autoComplete>
          <!-- <a routerLink="/pages/list-products/list-products"><i class="pi pi-filter"></i></a> -->

      </div>


  
      <div class="container-table">
        <div class="col-12 lg:col-12">
          <div class="card orders-table">

        <p-table  dataKey="id" [value]="test"  styleClass="p-datatable-gridlines" >
         
             <ng-template pTemplate="header">
               <tr>
                <ng-container *ngIf="selectedArticle">

                 <th class="custom-th">COULEURS/TAILLES</th>
                 <!-- <th></th> -->
                 <ng-container *ngFor="let size of TailleArray">
                  <th  class="size-th">{{size}}</th>


                 </ng-container>
                </ng-container>
                 <!-- <th style="min-width: 3rem;">
                     Jaune
                 </th>
                 <th style="min-width: 3rem;">
                     Marron
                 </th> -->
               </tr>
             </ng-template>
             <ng-template pTemplate="body" let-test >                                               
              <ng-container *ngFor="let color of ColorsArray">
                <ng-container *ngIf="selectedArticle">
               <tr>
                 <td style="background-color: #ffffff" >{{color}}</td>
                 <!-- <td></td> -->
                 <ng-container *ngFor="let size of TailleArray">
                  <td #td [attr.contenteditable]="isDisabled(size, color) ? null : true"
                   contenteditable="true"  pattern="[0-9]*"  
                   (keypress)="onKeyPress($event)" 
                   (input)="onCellInput($event, color, size)"
                   [style.background-color]="isDisabled(size, color) ? '#E2E8F0' : 'rgba(59, 130, 246, 1)'"
                   [style.color]="isDisabled(size, color) ? 'black' : 'white'"
                   [style.font-weight]="isDisabled(size, color) ? 'normal' : 'bold'"
                   [style.font-size]="isDisabled(size, color) ? '12px' : '14px'"></td>
                  </ng-container>
               </tr>
              </ng-container>
              </ng-container>
             </ng-template>
          </p-table>
           </div>
          </div> 
        </div>
       
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
  <button pButton pRipple label="Valider et Fermer" icon="pi pi-times" class="p-button-text" (click)="hideDialog(td)"></button>
  <button pButton pRipple [disabled]="disableSaveButton" label="Valider et Continuer" icon="pi pi-check" class="p-button-text" (click)="saveTransfert(td)"></button>
  </ng-template>
  </p-dialog>

