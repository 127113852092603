<div class="grid">
	<div class="col-12">
		<div class="card">
			<!-- <p-toast></p-toast> -->

            <h5>Gérer articles</h5>
			<!-- <p-toolbar styleClass="mb-4">
				
				<button pButton pRipple label="Ajouter" icon="pi pi-plus" class="p-button-success mr-2" ></button>
                <button  pButton pRipple type="button" icon="pi pi-plus" label="Ajouter " class="p-button-success mr-2 mb-2" style="width: 100px;" ></button>
                <button  pButton pRipple type="button" icon="pi pi-trash"label="Supprimer " class="p-button-danger mr-2 mb-2 " style="width: 110px;  background-color: #EF4444;" 
                
                [disabled]=""></button>
								
				<div class="upload-container">			
				<p-fileUpload mode="basic" accept=".csv" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" (onUpload)="uploaded($event)" (onSelect)="importedFile($event)"></p-fileUpload>
				</div>
			</p-toolbar>
			 -->
            <p-table [columns]="cols" [value]="articles"  dataKey="id" class="custom-table" responsive="true" responsiveLayout="stack" 
			[lazy]="true" (onLazyLoad)="onLazyLoad($event)"  scrollDirection="vertical" styleClass="p-datatable-sm ">
			
                <ng-template pTemplate="caption">
                    <button  pButton pRipple type="button" icon="pi pi-plus" label="Ajouter " class="p-button-success mr-2 mb-2" style="width: 100px;" (click)=" openDialog()"></button>
                    <button  pButton pRipple type="button" icon="pi pi-trash"label="Supprimer " class="p-button-danger mr-2 mb-2 " style="width: 110px;  background-color: #EF4444;" 
                    (click)="deleteArticle()"
                    [disabled]="!selectedArticle || !selectedArticle.length"></button>

                    <div style="position: relative; float: right; ">
                    <span class="p-input-icon-left mb-2">
                       <i class="pi pi-search"></i>
                       <!-- <input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search Keyword" class="w-full"/> -->
                       <input pInputText type="text" #filter  [(ngModel)]="searchTerm" placeholder="Rechercher" class="w-full"  (input)="getSearch($event)" style="height: 35px; "/>
       
                   </span>
                </div>
               </ng-template>
									
									
									
                <ng-template pTemplate="header" let-columns>
					
					<tr>
						<th  style="width: 100rem;" >
                            <div class="field-checkbox">
                                <p-checkbox  [binary]="true"
                                [(ngModel)]="selectAllCheckbox"
                                name="checkbox{{rowIndex}}"
                                [value]="fourniss"
                                (onChange)="selectAll($event)"></p-checkbox>
                            </div>
                        </th>
						<th *ngFor="let col of columns"  style="width: 100rem;"  pSortableColumn="{{col.field}}">
							<div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>

						</div>

						<div  *ngIf="col.field=='code_fournisseur'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>

						</div>


						<div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>

						</div>
						
						<div  *ngIf="col.field=='sous_famille'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
                        <div  *ngIf="col.field=='collection'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
						<div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
						<div  *ngIf="col.field=='rayon'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
                        <div  *ngIf="col.field=='prix_achat_ttc'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
                        <div  *ngIf="col.field=='prix_vente_ttc'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
                        <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>
                        <div  *ngIf="col.field=='Action'"class="flex justify-content-between align-items-center">
							{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>


						</div>

						</th>
						
					</tr>
				</ng-template>


                <ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
						<td  style="width: 100rem;" >
                            <div class="field-checkbox">
                                <p-checkbox
                                [binary]="true"
                                name="checkbox{{rowIndex}}"
								[(ngModel)]="product.selected"

								(onChange)="checkboxChanged($event, product)"
                               ></p-checkbox>
                            </div>
                        </td>
                       
						<td style="min-width: 12rem;" style="width: 100rem;" *ngFor="let col of columns">

							
							<div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
								
								 {{rowData[col.field] }}
							</div>
						
						   <div  *ngIf="col.field=='code_fournisseur'"class="flex justify-content-between align-items-center">
								
							{{rowData[col.field] }}
					   </div>
					   <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
								
						{{rowData[col.field]}}
				   </div>
				   <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
								
					<img src= "{{ rowData[col.field]?.name }}"  width="45" style="vertical-align: middle" />
				</div>
                   <div  *ngIf="col.field=='sous_famille'"class="flex justify-content-between align-items-center">
								
                    {{rowData[col.field].name}}
               </div>
			   <div  *ngIf="col.field=='collection'"class="flex justify-content-between align-items-center">
								
				{{rowData[col.field].name}}
		   </div>
		   <div  *ngIf="col.field=='rayon'"class="flex justify-content-between align-items-center">
								
			{{rowData[col.field].name}}
	   </div>
	   <div  *ngIf="col.field=='prix_achat_ttc'"class="flex justify-content-between align-items-center">
								
		{{rowData[col.field] | roundToThreeDecimals}}
   </div>
   <div  *ngIf="col.field=='prix_vente_ttc'"class="flex justify-content-between align-items-center">
								
	{{rowData[col.field] | roundToThreeDecimals }}
</div>
<div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
								
	{{rowData[col.field].name}}
</div>
				   
<div  *ngIf="col.field=='Action'"class="flex justify-content-between align-items-center">
								
	<button  pButton pRipple type="button" icon="pi pi-pencil" label="Modifier " class="p-button mr-2 mb-2" style="background-color: #3B82F6;width:100px;"
	 (click)="editArticler(ri,product)"></button>
</div>
		 
							
						</td>
                        <!-- <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editProduct(rowData)"></button>
								<button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(rowData)" ></button> 


                            </div>
                        </td> -->

                    
                    </tr>
						
					</ng-template>
				


            </p-table>
        
			
        
			<div class="card flex justify-content-center">
				<p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
			</div>
        </div>
	</div></div>
		<!-- <app-add-article (ArticleFromChild)="receiveArticle($event)" (notifyParent)="handleNotification($event)"></app-add-article> -->

        <app-add-article (ArticleFromChild)="receiveArticle($event)" (EditedArticleFromChild)="receiveEditedArticle($event)"  (notifyParent)="handleNotification($event)"></app-add-article>
		<app-delete-dialog #myDeleteDialog   (newItemEvent)="handleDeleteEvent($event)"></app-delete-dialog>
		<p-toast></p-toast>
		<div *ngIf="loading" class="splash-screen">
		<div  class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>
	</div>