import { Component, OnInit,ViewChild ,HostListener } from '@angular/core';
import { ModuleBticketService } from 'src/app/demo/service/Module/module-bticket.service';
import { LazyLoadEvent } from 'primeng/api';
interface Bticket {
  id: number;
  nature: string;
  qty: number;
  prixttc: number;
  totalttc: number;
  numero: string;
  souche: string;
  code_article_generic: string;
  code_a_barre: string;
  gl_numero: number;
  gl_typeprovenance: string;
  selected: false,

}

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-moduledraft',
  templateUrl: './moduledraft.component.html',
  styleUrls: ['./moduledraft.component.scss']
})
export class ModuledraftComponent implements OnInit {
  Btickets : Bticket[] = [];
  loading = false;
  pageMinimized = false;
  selectedTicket = [];
  selectAllCheckbox:boolean=false
  cols = [
    { header: 'Nature', value: 'nature' },
    { header: 'Qty', value: 'qty' },
    { header: 'Prixttc', value: 'prixttc' },
    { header: 'Totalttc', value: 'totalttc' },
    { header: 'Numero', value: 'numero' },
    { header: 'Souche', value: 'souche' },
    { header: 'Code Article Generic', value: 'code_article_generic' },
    { header: 'Code à Barre', value: 'code_a_barre' },
    { header: 'GL Numero', value: 'gl_numero' },
    { header: 'GL Type Provenance', value: 'gl_typeprovenance' }
  ];

  editingRows: boolean[] = new Array(this.Btickets.length).fill(false);


  selectedColumns: any[] = [
    this.cols[8],
    this.cols[5],
    this.cols[0],
    this.cols[2],
    this.cols[3],

  ];

  constructor( private TicketService : ModuleBticketService)  { }

  selectAll(event){
   
    this.Btickets.forEach(art => {
      art.selected = event.checked;
      if (event.checked) {
        this.selectedTicket.push(art.id);
      }
      else{
        this.selectedTicket=[]
      }
    });
  
    console.log("selected ticket", this.selectedTicket);
  
    }
    
    checkboxChanged(event, art) {
      const ArticleId = art.id;
    console.log(art);
  
    if (event.checked) {
      this.selectedTicket.push(ArticleId);
    } else {
      const index = this.selectedTicket.indexOf(ArticleId);
      if (index > -1) {
        this.selectedTicket.splice(index, 1);
      }
    }
  
    console.log(this.selectedTicket);
    
    }
 // ----------------- get ticket --------------------- //
getFilterType(column: any): string {
  // Adjust this logic based on your column types
  if (column.value === 'date_vente') {
    return 'date';
  } else {
    return 'text';
  }
}


 async loadBtickets() {
  this.loading = true;
  try {
    const response = await this.TicketService.getAllBventes();
    this.Btickets = response.map((ticketData: any) => ({
      id:  ticketData.id,
      nature: ticketData.nature,
      qty: ticketData.qty,
      prixttc: +ticketData.prixttc.toFixed(2),
      totalttc: +ticketData.totalttc.toFixed(2),
      numero: ticketData.numero,
      souche: ticketData.souche,
      code_article_generic: ticketData.code_article_generic,
      code_a_barre: ticketData.code_a_barre,
      gl_numero: ticketData.gl_numero,
      gl_typeprovenance: ticketData.gl_typeprovenance,
      selected: false,

    }));
  } catch (error) {
    console.error('Error loading Btickets:', error);
  }
  this.loading = false;
  console.log('ticketsss: ', this.Btickets)
}


 // ----------------- -------------- --------------------- //
 async ngOnInit() {
  await this.loadBtickets()
  }
  // ----------------- rounded and pip number  --------------------- //

  isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

   // ----------------- -------------- --------------------- //


  isPageMinimized() {
    return this.pageMinimized;
  }

  //--------------row edit ------------------------ //
  startEditing(rowIndex: number): void {
    // Set editing status to true for the clicked row
    this.editingRows[rowIndex] = true;
    console.log('new data' , this.Btickets[rowIndex])
  }

// Add a method to save changes
saveChanges(rowIndex: number): void {
  // Save changes and set editing status to false
  this.editingRows[rowIndex] = false;
  console.log('new data' , this.Btickets[rowIndex])
}

// Add a method to cancel editing
cancelEditing(rowIndex: number): void {
  // Cancel editing and set editing status to false
  this.editingRows[rowIndex] = false;
}


}
