<div class="grid">
	<div class="col-12">
		<div class="card">

            <h5>Liste des articles</h5>
        
            <p-table [columns]="cols" [value]="product" [globalFilterFields]="['Nom']" >
                <ng-template pTemplate="caption">
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                                    </ng-template>
                <ng-template pTemplate="header" let-columns>
					
					<tr>
                        <th></th>
						<th *ngFor="let col of columns">
							<div  *ngIf="col.field=='reference'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="reference" display="menu" placeholder="Search by  reference"></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="code_article_generique" display="menu" placeholder="Search by  code_article_generique"></p-columnFilter>
						</div>


						<div  *ngIf="col.field=='prix_vente'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="prix_vente" display="menu" placeholder="Search by  prix_vente"></p-columnFilter>
						</div>
						
						<div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							
						</div>
                        <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							
						</div>

						

						

						
					

						
						</th>
						
						
						
					</tr>
				</ng-template>


                <ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
                        <td>
                            <button type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
						<td style="min-width: 12rem;" *ngFor="let col of columns">

							
							<div  *ngIf="col.field=='reference'"class="flex justify-content-between align-items-center">
								
								 {{rowData[col.field] }}
							</div>
						
						   <div  *ngIf="col.field=='prix_vente'"class="flex justify-content-between align-items-center">
								
							{{rowData[col.field] }}
					   </div>
					   <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
								
						{{rowData[col.field]}}
				   </div>
                   <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
								
                    {{rowData[col.field]?.attributes?.name}}
               </div>
				   
			   <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
								
				<img src= "{{ rowData[col.field] }}"  width="45" style="vertical-align: middle" />
			</div>
							
						</td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editProduct(product)"></button>

                            </div>
                        </td>
                    
                    </tr>
						
					</ng-template>

            </p-table>
        
        
        
        
        </div></div></div>
        <!-- *****************************save product*********************************** -->
        <p-dialog [(visible)]="productDialog" [style]="{width: '850px'}" header="Ajouter article" [modal]="true" class="p-fluid">
           
            <ng-template pTemplate="content">
                
                <img *ngIf="showImage"  [src]="'https://prefacture.rosace.com.tn'+this.imageNewProduct" [alt]="this.imageNewProduct" width="150" class="mt-0 mx-auto mb-5 block shadow-2"  >
            <div class="field">
                <label for="name">Reference</label>
                <input type="text" pInputText id="Reference" [(ngModel)]="product.reference" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
            </div>
            <div class="field">
                <label for="code_article_generique">code_article_generique</label>
                <input type="text" pInputText id="code_article_generique" [(ngModel)]="product.code" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
            </div>

            <div class="field">
                <label for="libelle_article">libelle_article</label>
                <input type="text" pInputText id="libelle_article" [(ngModel)]="product.libelle_article" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.libelle_article">Name is required.</small>
            </div>

            <div class="field">
                <label for="prix vente">prix vente</label>
                <input type="text" pInputText id="prix vente" [(ngModel)]="product.prix" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
            </div>

            <div class="field">

                <label for="fournisseur">fournisseur</label>
            <p-autoComplete [(ngModel)]="selectedFournisseur" [suggestions]="filteredFourniss" (completeMethod)="filterFourniss($event)" field="label" [dropdown]="true">
            </p-autoComplete>
            </div>

            <div class="field">
                <label for="image">image</label>
                
<p-fileUpload name="files"  url="https://prefacture.rosace.com.tn/api/upload" (onUpload)="onUpload($event)"
accept="image/*" maxFileSize="1000000"></p-fileUpload>


                
            </div>

            <div *ngIf="VP==1">
                

                <button pButton pRipple    (click)="addvariation(product.code)" label="ajouter variation de produit " class="p-button-success">
			
                </button>
<br><br>
<!-- *****************************affichage variation******************************************* -->
                <p-table [columns]="colsvariationP"  editMode="row" [value]="productvariation"  >
                    <ng-template pTemplate="header" let-columns>
                        
                        <tr>
                            <th *ngFor="let col of columns">
                                <div *ngIf="col.field=='code_article_generique'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="code_article_generique" display="menu" ></p-columnFilter>
                                </div>
                                <div *ngIf="col.field=='color'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="color" display="menu" ></p-columnFilter>
                                </div>
                                <div *ngIf="col.field=='taille'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="taille" display="menu" ></p-columnFilter>
                                </div>
                            </th>
                            </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-variation let-ri="rowIndex">
                        <tr  [pEditableRow]="columns">
                            <td style="min-width: 12rem;" *ngFor="let col of columns">
    
                                
                                <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
								
                                    {{rowData[col.field] }}
                               </div>
                               <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center">
                                <!-- <div *ngFor="let item of rowData[col.field] | keyvalue">
                                    <p>{{item.name}}</p>
                                  </div> -->
								
                                {{rowData[col.field]?.name}}
                           </div>
                           <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
								
                            {{rowData[col.field]?.name }}
                       </div>
                            </td>
                        
                            <td>
                                <div class="flex">
                                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editVariation(variation)"></button>
                                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button>
                                </div>
                            </td></tr></ng-template>
                </p-table>
            

</div>

          
        




            </ng-template>


          

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
            </ng-template>
        </p-dialog>

<!-- ************************ajouter variation****************************** -->
        <p-dialog [(visible)]="variationdialog" [style]="{width: '450px'}" header="Ajouter variation de produit" [modal]="true" class="p-fluid">


           

            <ng-template pTemplate="content">


                <div class="field">
                    <label for="code">code_article_generique</label>
                    <input type="text"    [disabled]="true"  pInputText id="code_article_generique" [(ngModel)]="product2" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.code_article">Name is required.</small>
                </div>

                <!-- <div class="field">
                    <label for="code_barre">code_barre</label>
                    <input type="text" pInputText id="code_barre" [(ngModel)]="codebarre" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !code_barre">Name is required.</small>
                </div> -->

                <div class="field">
                    <label for="taille">taille</label>
                    <p-dropdown [options]="taille"  [(ngModel)]="selectedTaille"   placeholder="Select a size" [showClear]="true"></p-dropdown>
                </div>
                <div class="field">
                    <label for="code_barre">couleur</label>
                 
                 
                    <p-dropdown [options]="couleurs" [(ngModel)]="selectedColor"  placeholder="Select a color" [showClear]="true"></p-dropdown>
                </div>


                
            </ng-template>

  <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialogV()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveVariations()" ></button>

              
            </ng-template>

          

        </p-dialog>



        <p-dialog [(visible)]="deleteVariationDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="product">Are you sure you want to delete <b>{{product.id}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteProductDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>



            </ng-template>
        </p-dialog>



<!-- **************************edit variation********************* -->

        <p-dialog [(visible)]="editvariationDialog" [style]="{width: '450px'}" header="variation Details" [modal]="true" class="p-fluid">
           


            <ng-template pTemplate="content">


                <div class="field">
                    <label for="code">code_article_generique</label>
                    <input type="text"    [disabled]="true"  pInputText id="code_article_generique" [(ngModel)]="product.code" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.code_article">Name is required.</small>
                </div>

                <!-- <div class="field">
                    <label for="code_barre">code_barre</label>
                    <input type="text"  [disabled]="true" pInputText id="code_barre" [(ngModel)]="codebarre" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !code_barre">Name is required.</small>
                </div> -->

                <div class="field">
                    <label for="taille">taille</label>
                    <p-dropdown [options]="taille"  [(ngModel)]="selectedTaille"   placeholder="Select a size" [showClear]="true"></p-dropdown>
                </div>
                <div class="field">
                    <label for="code_barre">couleur</label>
                 
                 
                    <p-dropdown [options]="couleurs" [(ngModel)]="selectedColor"  placeholder="Select a color" [showClear]="true"></p-dropdown>
                </div>


                
            </ng-template>

  <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="edithideDialogV()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="editVariations()" ></button>

              
            </ng-template>
        </p-dialog>


<!--********************************************************  edit product-->
        <p-dialog [(visible)]="editproductDialog" [style]="{width: '450px'}" header="Product Details" [modal]="true" class="p-fluid">







            
           
                <ng-template pTemplate="content">
                    <img [src]=" editPT.image" [alt]="editPT.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2"  >
                <div class="field">
                    <label for="name">Reference</label>
                    <input type="text" pInputText id="Reference" [(ngModel)]="editPT.reference" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.reference">Name is required.</small>
                </div>
                <div class="field">
                    <label for="code_article_generique">code_article_generique</label>
                    <input type="text" pInputText id="code_article_generique" [(ngModel)]="editPT.code_article_generique" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
                </div>
    
                <div class="field">
                    <label for="libelle_article">libelle_article</label>
                    <input type="text" pInputText id="libelle_article" [(ngModel)]="editPT.libelle_article" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.libelle_article">Name is required.</small>
                </div>
    
                <div class="field">
                    <label for="prix vente">prix vente</label>
                    <input type="text" pInputText id="prix vente" [(ngModel)]="editPT.prix_vente" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.name">Name is required.</small>
                </div>
                <div class="field">
                    <label for="fournisseur">fournisseur</label>
                <p-autoComplete [(ngModel)]="editPT.fournisseur"  [suggestions]="filteredFourniss"    (completeMethod)="filterFourniss($event)" field="label" [dropdown]="true">
                </p-autoComplete>
                </div>
                <div class="field">
                    <label for="image">image</label>
                    
    <p-fileUpload name="files"  url="https://prefacture.rosace.com.tn/api/upload" (onUpload)="onUpload($event)"
    accept="image/*" maxFileSize="1000000"></p-fileUpload>
    
    
                    
                </div>
                <button pButton pRipple    (click)="addvariation(editPT.code_article_generique)" label="ajouter variation de produit " class="p-button-success">
                
                </button>
              <!-- <div *ngIf="VP==1"> 
                 
                    
        <br><br>-->
                   

                    <p-table [columns]="colsvariationP"  editMode="row" [value]="productvariation"  >
                        <ng-template pTemplate="header" let-columns>
                            
                            <tr ptemplate="header">
                               <th *ngFor="let col of columns">
                                <div *ngIf="col.field=='code_article_generique'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="code_article_generique" display="menu" ></p-columnFilter>
                                </div>
                                <div *ngIf="col.field=='color'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="color" display="menu" ></p-columnFilter>
                                </div>
                                <div *ngIf="col.field=='taille'" class="flex justify-content-between align-items-center">
                                    {{ col.header }}
                                    
        
                                    <p-columnFilter [filter] = "true" type="numeric" field="taille" display="menu" ></p-columnFilter>
                                </div>
                            </th>
                                </tr>
                        </ng-template>
    
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-variation let-ri="rowIndex">
                            <tr  [pEditableRow]="columns">
                                <td style="min-width: 12rem;" *ngFor="let col of columns">
    
                                
                                    <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                                    
                                        {{rowData[col.field] }}
                                   </div>
                                   <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center">
                                    <!-- <div *ngFor="let item of rowData[col.field] | keyvalue">
                                        {{item.name}}
                                      </div> -->
                                    
                                    {{rowData[col.field]?.name}}
                               </div>
                               <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
                                    
                                {{rowData[col.field]?.name }}
                           </div>
                                </td>
                            
                                <td>
                                    <div class="flex">
                                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editVariation2(variation)"></button>
                                     <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button> 
                                    </div>
                                </td></tr></ng-template>
                    </p-table>
                
    
    
    
              
            
    
    
    
    
                </ng-template>
    
    
              
    
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                    <button pButton pRipple label="save" icon="pi pi-check" class="p-button-text" (click)="editProducts()"></button>
                </ng-template>
            













            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="editProducts()"></button>
              
            </ng-template>


        </p-dialog>
          <p-toast key="1" ></p-toast>





         <p-dialog [(visible)]="editvariationDialog2" [style]="{width: '450px'}" header="variation Details2" [modal]="true" class="p-fluid">
           


            <ng-template pTemplate="content">


                <div class="field">
                    <label for="code">code_article_generique</label>
                    <input type="text"    [disabled]="true"  pInputText id="code_article_generique" [(ngModel)]="article.code_article_generique" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.code_article">Name is required.</small>
                </div>

                <!-- <div class="field">
                    <label for="code_barre">code_barre</label>
                    <input type="text"  [disabled]="true" pInputText id="code_barre" [(ngModel)]="article.code_barre" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !code_barre">Name is required.</small>
                </div> -->

                <div class="field">
                    <label for="taille">taille</label>
                    <p-dropdown [options]="taille"  [(ngModel)]="selectedTaille"   placeholder="Select a size" [showClear]="true"></p-dropdown>
                </div>
                <div class="field">
                    <label for="code_barre">couleur</label>
                 
                 
                    <p-dropdown [options]="couleurs" [(ngModel)]="selectedColor"  placeholder="Select a color" [showClear]="true"></p-dropdown>
                </div>


                
            </ng-template>

  <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="edithideDialogV2()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="editVariations()" ></button>

              
            </ng-template>
        </p-dialog>