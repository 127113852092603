
   

<div  class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">
        <div class="night-switch">
            <p-button styleClass="dark-switch"  icon="pi pi-circle-on" (click)="dark = true"></p-button>
            <p-button styleClass="light-switch"  icon="pi pi-circle-off" (click)="dark = false"></p-button>
        </div>
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                   <!-- <img src="assets/layout/images/logo-mirage@2x.png" alt="mirage-layout" />-->
                   <img src="assets/layout/images/devesty_it.png" style="width: 40%;">
                </div>
               <!-- <h1>Sign in to Mirage</h1>-->
                <!--<p>Welcome, please use the form to sign-in Mirage network</p> -->
           
                <div class="forms"   >  
                    <div class="login-input-wrapper">
                        <label for="identifier">E-mail</label>
                        <input id="identifier" pInputText placeholder="E-mail"   [formControl]="identifier"/>
                        <i class="pi pi-user"></i>
                    </div>

                    <div class="login-input-wrapper">
                        <label for="password">Password</label>
                        <input type="password" id="password" placeholder="Mot de Passe"  [formControl]="password" pPassword [feedback]="false"/>
                        <i class="pi pi-lock"></i>
                    </div>

                    <div class="col-12">
                        <p-checkbox  [(ngModel)]="checked" label="Remember me" value="SaveInfo" binary="true"></p-checkbox>
                    </div>


                    <div class="buttons">
                      
                        <button   pButton type="button"  [formGroup]="form" (click)="signIn(form.value)" label="Se connecter"></button>
                        <a href="#" class="secondary-button">Mot de passe oublié?</a>
                        <p-toast key="toast1" ></p-toast>
                    </div>
                </div>
            </div>
        </div>

        <div class="desert"></div>
    </div>
</div>
