import { Injectable } from '@angular/core';
import { HttpClient, HttpParams , HttpHeaders  } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class GenererVenteService {

  //---------------- URL ----------------//
  
    //  private apiUrl = 'https://prefacture.rosace.com.tn/api/ventes?&populate=client&populate=store&populate=etablissement&populate=products&populate=status_vente';
    private apiUrl = `${environment.url}/ventes?&populate=client&populate=store&populate=etablissement&populate=products&populate=status_vente`;

    // private apiPost = 'https://prefacture.rosace.com.tn/api/createFacture'
    private apiPost = `${environment.url}/createFacture`

     private isToastShown: boolean = false;

     constructor(private http: HttpClient, private messageService: MessageService) {
     }
     private handleApiError(error) {
      // Handle API error status here
      console.error('API call failed:', error);
    
      if (error.status !== 200) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
        this.isToastShown = true;
        setTimeout(() => {
          this.isToastShown = false;
        }, 5000);
      } 
      
    }
  //---------------- Get_Vente ----------------//

  getVentes(clientName: string, storeName: string, page: number, size: number , option?): Observable<any> {
    const filterstatus = option === 1 ? `filters[$and][0][status_facture][$eq]=false` :  ''
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let API = '';
    const filters = `&filters[$and][0][client][name][$eq]=${clientName}&filters[$and][1][store][name][$eq]=${storeName}`;
  
    if (clientName === '') {
      API = `${this.apiUrl}&${pagin}`;
    } else {
      if(option === 1){

        API = `${this.apiUrl}&${filters}&${pagin}&${filterstatus}`;
      }else{
        API = `${this.apiUrl}&${filters}&${pagin}`;
      }
    }
  
    return this.http.get<any[]>(API).pipe(
      catchError(error => {
        this.handleApiError(error);
        throw error; // Rethrow the error after handling it
      })
    );
  }
   formatDateToYYYYMMDD(date : any) {
    
      date.setDate(date.getDate() + 1);
      return date.toISOString();
  }
 //---------------- Filtres_Vente ----------------//

  async getfliterVentes(clients, stores, articles, date ,page: number, size: number , field?: string,
    option?: number): Promise<any> {

    let sort = ''
     let fieldvalue = ''
     if (field && option) {
      // Map the provided field to the corresponding API attribute
      switch (field) {
        case 'code_facture_client':
          fieldvalue = 'numeroTicket';
          break;
        case 'client':
          fieldvalue = 'client.name';
          break;
        case 'etablissement':
          fieldvalue = 'etablissement.name';
          break;
        case 'store':
          fieldvalue = 'store.name';
          break;
        case 'prix_net':
          fieldvalue = 'prix_net';
          break;
          case 'ht':
            fieldvalue = 'ht';
            break;
          case 'taxes':
            fieldvalue = 'taxes';
            break;
          case 'ttc':
            fieldvalue = 'ttc';
            break;
          case 'total_fodec':
            fieldvalue = 'total_fodec';
            break;
          case 'timbre':
            fieldvalue = 'timbre';
            break;
          case 'pourcentage_remise':
            fieldvalue = 'pourcentage_remise';
            break;
          case 'remise':
            fieldvalue = 'remise';
            break;
          case 'date_vente':
            fieldvalue = 'date_vente';
            break;
          case 'status_facture':
            fieldvalue = 'status_facture';
            break;
          case 'status_vente':
            fieldvalue = 'status_vente.name';
            break;
        // Add more cases for other fields as needed
        default:
          break;
      }
      const sortOrder = option === 1 ? 'ASC' : 'DESC';
      sort = `sort=${fieldvalue}:${sortOrder}`;
    }
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
      let filter = '';
      let count  = 0 ;
      if (stores && stores.length > 0) {
        for (const store of stores) {
          filter += `&filters[$or][${count}][store][name][$eq]=${store.name}`;
          count += 1;
        }
        console.log('aa6+51',filter);
      }
      if (clients && clients.length > 0) {
        for (const store of clients) {
          filter += `&filters[$and][${count}][client][name][$eq]=${store.name}`;
        }
        console.log('aa6+51',filter);
      }
      if (articles && articles.length > 0) {
        count += 1;
        for (const store of articles) {
          filter += `&filters[$and][${count}][products][code_article_generique][$eq]=${store.code_article_generique}`;
        }
        console.log('aa6+51',filter);
      }
      if (date) {
        count += 1;
        console.log(date)
        if (Array.isArray(date)) {
          // If date is an array with two elements
          if (date.length === 2 && date[1] != null) {
            const startDate = this.formatDateToYYYYMMDD(date[0]);
            const endDate = this.formatDateToYYYYMMDD(date[1]);
      
            filter += `&filters[$and][${count}][date_vente][$gt]=${startDate}`
            count += 1;
            filter += `&filters[$and][${count}][date_vente][$lt]=${endDate}`;
          } else if (date.length === 2 && date[1] == null) {
            // If date is an array with one element (and the other is null)
            const formattedDate = this.formatDateToYYYYMMDD(date[0]);
            filter += `&filters[$and][${count}][date_vente][$eq]=${formattedDate}`;
          } else {
            console.error('Invalid date range:', date);
            // Handle the error or log a message as needed
          }
        } else {
          // If date is not an array, format the single date
          const formattedDate = this.formatDateToYYYYMMDD(date);
          filter += `&filters[$and][${count}][date_vente][$eq]=${formattedDate}`;
        }
      }

      const apiUrl  = `${this.apiUrl}&${filter}&${pagin}&${sort}`;
 
      try {
        const response = await this.http.get<any[]>(apiUrl).toPromise();
        return response;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.handleApiError(error);
        throw error;       }
  }

  //---------------- Get_Variation ----------------//

  async getVariations(ids: number[]):Promise<any>  {
      // const baseUrl = 'https://prefacture.rosace.com.tn/api/vente-variations?populate[0]=variation&populate[1]=variation.product';
     
      const baseUrl = `${environment.url}/vente-variations?populate=vente&populate=variation&populate=variation.product&populate=variation.product.sous_famille`;
      const filters = ids.map((id, index) => `filters[$or][${index}][vente][id][$eq]=${id}`).join('&');
      const ApiUrl = `${baseUrl}&${filters}`;
      try {
        const result = await this.http.get<any[]>(ApiUrl).toPromise();
        return result;
      } catch (error) {
        this.handleApiError(error);
        throw error; // Rethrow the error after handling it
      }  }

    //---------------- Get_totalvente ----------------//

  async getnumvente():Promise<any>  {
    const ApiUrl = `${environment.url}/getTotalVente`
    // return await this.http.get<any[]>(ApiUrl).toPromise();
    try {
      const result =await this.http.get<any[]>(ApiUrl).toPromise();
      return result;
    } catch (error) {
      this.handleApiError(error);
      throw error; // Rethrow the error after handling it
    }
}
   //---------------- postfacture ----------------//

   async createFacture(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.apiPost, data).toPromise();
      return response;
    } catch (error) {
      // Handle error if needed
      console.error('Error creating facture:', error);
      this.handleApiError(error);
      throw error;     }
  }

}





