
<div class="card">
    <h5>Liste des Fournisseurs</h5>
   
        <p-table [value]="fourniss" responsiveLayout="stack" scrollHeight="flex" [loading]="loadingTable" 
        [lazy]="true"  (onLazyLoad)="onLazyLoad($event)">
            <ng-template pTemplate="caption">
                <button  pButton pRipple type="button" icon="pi pi-plus" label="Ajouter " class="p-button-success mr-2 mb-2" style="width: 100px;" (click)=" openDialog()"></button>
                <button  pButton pRipple type="button" icon="pi pi-trash"label="Supprimer " class="p-button-danger mr-2 mb-2 " style="width: 110px;  background-color: #EF4444;" 
                (click)="deleteFourniss()"
                [disabled]="!selectedFourniss || !selectedFourniss.length"></button>

                <span class="p-input-icon-left mb-2">
                   <i class="pi pi-search"></i>
                   <input pInputText type="text" #filter [(ngModel)]="searchTerm" placeholder="Rechercher" class="w-full" (input)="getSearch($event)" style="height: 35px;"/>
   
               </span>
           </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <div class="field-checkbox">
                        <p-checkbox  [binary]="true"
                        [(ngModel)]="selectAllCheckbox"
                        name="checkbox{{rowIndex}}"
                        [value]="fourniss"
                        (onChange)="selectAll($event)"></p-checkbox>
                    </div>
                </th>
                <th pSortableColumn="code">Code <p-sortIcon field="{{code}}"></p-sortIcon></th>
                <th pSortableColumn="name">Nom <p-sortIcon field="{{name}}"></p-sortIcon></th>
                <th pSortableColumn="adresse">Adresse <p-sortIcon field="{{adresse}}"></p-sortIcon></th>
                <th pSortableColumn="tel">Téléphone <p-sortIcon field="{{tel}}"></p-sortIcon></th>
                <th pSortableColumn="mf">RC <p-sortIcon field="{{mf}}"></p-sortIcon></th>
                <th pSortableColumn="code_tva">Code TVA  <p-sortIcon field="{{code_tva}}"></p-sortIcon></th>
                <th>Actions</th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fourniss let-rowIndex="rowIndex">
        
            <tr>
                <td>
                    <div class="field-checkbox">
                        <p-checkbox
                        [binary]="true"
                        name="checkbox{{rowIndex}}"
                        [(ngModel)]="fourniss.selected"
                        [value]="fourniss.id"
                        (onChange)="checkboxChanged($event, fourniss)"
                       ></p-checkbox>
                    </div>
                </td>
            
                <td >
                    {{fourniss.code}}
                </td>
                <td >
                    {{fourniss.name}}
                </td>
                <td >
                    {{fourniss.adresse}}
                </td>
                <td >
                    {{fourniss.tel}}
                </td>
                <td >
                    {{fourniss.mf}}
                </td>
                <td >
                    {{fourniss.code_tva}}
                </td>
                <td >
                    <button  pButton pRipple type="button" icon="pi pi-pencil" label="Modifier " class="p-button mr-2 mb-2" style="background-color: #3B82F6;width:100px;" (click)="editFournisseur(fourniss)"></button>

                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="card" style="display: flex; justify-content: space-between; align-items: center;">
        <div style="flex-grow: 1;">

        <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>

        </div>

    </div>
    
</div>

<p-toast></p-toast>
<app-delete-dialog #myDeleteDialog   (newItemEvent)="handleDeleteEvent($event)"></app-delete-dialog>
<app-add-fournisseur #myDialog [ListFourniss]="listFournissToSend"  (PaginationToParent)="receivePaginationChild($event)" (newItemEvent)="handleChildEvent($event)" (notifyParent)="handleNotification($event)"></app-add-fournisseur>

<div *ngIf="loading" class="splash-screen">
<div  class="spinner">
<span class="ball-1"></span>
<span class="ball-2"></span>
<span class="ball-3"></span>
<span class="ball-4"></span>
<span class="ball-5"></span>
<span class="ball-6"></span>
<span class="ball-7"></span>
<span class="ball-8"></span>
</div>
</div>