import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class MouvementService {
  pageSize=25
  currentPage
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
  async  sendData(data: any) {

    this.currentPage=data
  }
  async getMouvement(article?,typeMvt?,dates?) {
    let promise = new Promise<any>((resolve, reject) => {
     console.log(dates)
      const params = new HttpParams()
      .set('sort', 'id:DESC')
      .set('pagination[pageSize]',this.pageSize)
      .set('pagination[page]', this.currentPage)

      let url = `${environment.url}/getMouvementStock?order=ASC&page=1&pageSize=25`;
      if (article) {
        url += `&fltrproduct=${article}`;
    }
    
    if (typeMvt ) {
      url += `&type_mvt=${typeMvt}`;
    }

    if (dates && dates.length === 2) {
      if(dates[1]==null){
        url += `&startDate=${dates[0]}`;
        url += `&endDate=${dates[0]}`;
      }
      else{
        url += `&startDate=${dates[0]}`;
        url += `&endDate=${dates[1]}`;
      }
  
    }
   
      this.http
        .get(url,{params})
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;     });
    });
    return promise;
  }
}
