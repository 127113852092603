<div class="grid">
	<div class="col-12">
		<div class="card">

			<!--------------------Consultation Tickets PAGE---------------------->

            <h5>Consultation Tickets</h5>
           
              
              <button pButton pRipple type="button" label="Génerer Facture"  class="mr-2 mb-2"  [routerLink]="'/error'"></button>
            
  
			<!-- <div class="container"> -->
     
          
 	<!------------------------------------------  Filtres et buttons  -------------------------------------------------------->
               
                <!-- <p-multiSelect
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <p-multiSelect
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <p-multiSelect
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <p-multiSelect
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>

                
				<p-calendar
                class="drop"
                [showIcon]="true"
                inputId="icon"
                placeholder="Calendrier"
                style="margin-right: 10px;">
                </p-calendar>


				<button pButton pRipple type="button" 
                        icon="fa fa-filter bi bi-filter" 
                        label="Filtrer" 
                        class="p-button-success mr-0.5 mb-"
                        style="margin-right: 10px;"
                        [iconPos]="isPageMinimized() ? 'right' : 'left'"
                        >
                </button>

                <button pButton pRipple type="button" 
                            icon="bi bi-arrow-clockwise" 
                            label="Refresher" 
                            class="btn btn-primary mr-0.5 mb-" 
                            style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
                            [iconPos]="isPageMinimized() ? 'right' : 'left'"
                            >
                </button> -->
            <!-- </div> -->

	<!------------------------------------------------------------------------------------------------------------------------>
    <!-------------------------------------------------------------- Table --------------------------------------------------->
 
    <p-table
        [loading]="loading"
        [showCurrentPageReport]="true"
        responsiveLayout="stack"
        scrollHeight="flex"
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        dataKey="id"
        pDraggableRows
        [value]="Btickets"
       
        >
            <ng-template pTemplate="caption">
                <p-multiSelect
                [options]="cols"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                [style]="{ 'min-width': '200px' }"
                placeholder="Choose Columns"
                ></p-multiSelect>

             
            </ng-template>
        
            <ng-template pTemplate="header">
                <tr>
                  <th  style="width: 100rem;" >
                    <div class="field-checkbox">
                        <p-checkbox  [binary]="true"
                        name="checkbox{{rowIndex}}"
                        [(ngModel)]="selectAllCheckbox"
                        (onChange)="selectAll($event)"
                        ></p-checkbox>
                    </div>
                </th>
                <th *ngFor="let col of selectedColumns" [pSortableColumn]="col.value" style="width:20%">
                    {{ col.header }} <p-sortIcon [field]="col.value"></p-sortIcon>
                </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowIndex="rowIndex" let-rowData>
                <tr>
                  <td  style="width: 100rem;" >
                    <div class="field-checkbox">
                        <p-checkbox
                        [binary]="true"
                        name="checkbox{{rowIndex}}"
                        [(ngModel)]="rowData.selected"
                        (onChange)="checkboxChanged($event, rowData)"

                       ></p-checkbox>
                    </div>
                </td>
                  <td *ngFor="let col of selectedColumns">
                    <ng-container *ngIf="!editingRows[rowIndex]; else editColumn">
                      {{ rowData[col.value]}}
                    </ng-container>
                    <ng-template #editColumn>
                      <!-- Display editable content here -->
                      <input pInputText type="text" [(ngModel)]="rowData[col.value]">
                    </ng-template>
                  </td>
                  <!-- <td>
                    <ng-container *ngIf="!editingRows[rowIndex]; else editActions">
                      <button pButton pRipple icon="pi pi-pencil" (click)="startEditing(rowIndex)"></button>
                    </ng-container>
                    <ng-template #editActions>
                      <button pButton pRipple icon="pi pi-check" (click)="saveChanges(rowIndex)"></button>
                      <button pButton pRipple icon="pi pi-times" (click)="cancelEditing(rowIndex)"></button>
                    </ng-template>
                  </td> -->
                </tr>
              </ng-template>
              
            
    </p-table>

  



        <div class="card" style="display: flex; justify-content: space-between; align-items: center;">
                <div style="flex-grow: 1;">
                </div>
		</div>
		<div class="card flex justify-content-center"></div>
        <!------------------------------------------------------------------------------------------------------------------------>
</div></div>
<!-- <app-details-ticket ></app-details-ticket> -->




