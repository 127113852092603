<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Ajouter" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
                     </div>
                </ng-template>

            </p-toolbar>

            <h5>Achats</h5>
            <p-table #team [columns]="colsWithoutExcluded" (onFilter)="onFilter($event,team)" 
			 [lazy]="true"  [value]="achats" 
			 dataKey="ID" styleClass="p-datatable-gridlines" [globalFilterFields]="['Nom']"   >
			 <!-- [paginator]="true" [rows]="1" [showCurrentPageReport]="true" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[2,10,25,50]" -->

                <ng-template pTemplate="caption"></ng-template>

                <ng-template   let-filter="filterCallback" pTemplate="header" let-columns>
                    <tr>
						<th></th>
						<th *ngFor="let col of columns">
							<!-- <div *ngIf="col.field=='button'" class="flex justify-content-between align-items-center">
								
								
	
							</div>
	 -->

						<div *ngIf="col.field=='codeA'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter #ff (onChange)="test($event)" [filter] = "true" type="text" field="codeA" display="menu" ></p-columnFilter>
						</div>

						<div *ngIf="col.field=='store'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter [filter] = "true" type="text" field="store" display="menu" ></p-columnFilter>
						</div>


						<div *ngIf="col.field=='Pachat'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter [filter] = "true" type="numeric" field="Pachat" display="menu" ></p-columnFilter>
						</div>

						<div *ngIf="col.field=='taille'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter [filter] = "true" type="numeric" field="taille" display="menu" ></p-columnFilter>
						</div>

						<div *ngIf="col.field=='color'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<p-columnFilter [filter] = "true" type="text" field="color" display="menu" ></p-columnFilter>
						</div>

						<div *ngIf="col.field=='image'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							

							<!--<p-columnFilter type="text" field="color" display="menu" ></p-columnFilter>-->
						</div>


						
						</th>
						
						
						
					</tr>
					
                </ng-template>

				<ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex" >
					<tr  [pEditableRow]="columns">
						<td>
							<button type="button" pButton pRipple [pRowToggler]="rowData" 
							class="p-button-text p-button-rounded p-button-plain"
							 [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRowExpansion(ri)">
							</button>
						</td>
						<td style="min-width: 12rem;" *ngFor="let col of columns">
							
							<!-- <div  *ngIf="col.field=='button'"class="flex justify-content-between align-items-center">

								<button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain"
								 [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
								[pRowToggler]="rowData"

								(click)="toggleRowExpansion(rowData, achats)" >
								</button>
						</div> -->

							<div  *ngIf="col.field=='codeA'"class="flex justify-content-between align-items-center">
								
								 {{rowData[col.field] }}
							</div>
							<div  *ngIf="col.field=='store'"class="flex justify-content-between align-items-center">
								
								{{rowData[col.field] }}
						   </div>
						   <div  *ngIf="col.field=='Pachat'"class="flex justify-content-between align-items-center">
								
							{{rowData[col.field] }}
					   </div>
					   <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
								
						{{rowData[col.field]}}
				   </div>
				   <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center">
								
					{{rowData[col.field]}}
			   </div>
			   <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
								
				<img src= "{{ rowData[col.field] }}"  width="45" style="vertical-align: middle" />
			</div>
							
						</td></tr>
						
					</ng-template>


					<!-- expansion -->
					
					<ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex">
						<tr  *ngIf="expandedRows[rowIndex]">
							<td colspan="7">
								<div class="p-p-3">
									<!-- <ng-container *ngIf="listachat.attributes?.achat_variations?.data; else fixedContent"> -->
										<!-- <div class="p-p-3" *ngIf="rowData?.attributes?.achat_variations"> -->
										 

									<p-table [columns]="colsWithoutExcluded2"  [value]="rowData.achat_variations" dataKey="id"  >
										 
										 <ng-template pTemplate="header" let-columns >
                            <tr>
                                <!-- <td>{{achatvar.id}}</td> -->
								<th *ngFor="let coll of columns">
						
									<div *ngIf="coll.field=='codeB'" class="flex justify-content-between align-items-center">
										{{ coll.header }}
										
			
										<p-columnFilter #ff (onChange)="test($event)" [filter] = "true" type="text" field="codeB" display="menu" ></p-columnFilter>
									</div>
			
									<div *ngIf="coll.field=='qte'" class="flex justify-content-between align-items-center">
										{{ coll.header }}
										
			
										<p-columnFilter [filter] = "true" type="text" field="qte" display="menu" ></p-columnFilter>
									</div>
			
			
									<div *ngIf="coll.field=='couleur'" class="flex justify-content-between align-items-center">
										{{ coll.header }}
										
			
										<p-columnFilter [filter] = "true" type="numeric" field="couleur" display="menu" ></p-columnFilter>
									</div>
			
									<div *ngIf="coll.field=='taille'" class="flex justify-content-between align-items-center">
										{{ coll.header }}
										
			
										<p-columnFilter [filter] = "true" type="numeric" field="taille" display="menu" ></p-columnFilter>
									</div>
									
									</th>
                            </tr>
                        </ng-template>
						<ng-template pTemplate="body" let-achatvar>
                           <tr>
							<td>
								{{achatvar.id}}
							</td>
							<td>
								{{achatvar.attributes.quantity}}
							</td>
							<td>
								{{achatvar.attributes.variation.data.attributes.color.data.attributes.name}}
							</td>
							<td>
								{{achatvar.attributes.variation.data.attributes.taille.data.attributes.name}}
							</td>
						   </tr>
						</ng-template>
									</p-table>
									 
										</div>


							</td>
						</tr>
					</ng-template>
					
                     <!-- end expansion -->
					 <!-- <ng-template pTemplate="paginatorleft">
						<p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
					</ng-template>
					<ng-template pTemplate="paginatorright">
						<p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
					</ng-template> -->
					
					<!-- <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="120" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator> -->

					</p-table>
					<div class="card flex justify-content-center">
						<p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator>
					</div>
				</div></div></div>
				<div *ngIf="loading" class="splash-screen">
					<div  class="spinner">
						<span class="ball-1"></span>
						<span class="ball-2"></span>
						<span class="ball-3"></span>
						<span class="ball-4"></span>
						<span class="ball-5"></span>
						<span class="ball-6"></span>
						<span class="ball-7"></span>
						<span class="ball-8"></span>
					</div>
				</div>
			<p-dialog [(visible)]="achattDialog"  [style]="{width: '750px'}" [responsive]="true" [maximizable]="true"  header="achat Details" [modal]="true" class="p-fluid" (hide)="hideDialog()">
				<ng-template pTemplate="content">
					
				
					<h5>Sectionner un article</h5>
		<!-- <p-autoComplete [(ngModel)]="selectedProductAdvanced" (onSelect)="changeProd($event)" [suggestions]="filteredProducts" 
		(completeMethod)="filterProduct($event)" field="code_article_generique" [dropdown]="true" (dropdownClick)="enlargeDialog()"> -->
		<p-autoComplete [(ngModel)]="selectedProductAdvanced" (onSelect)="changeProd($event)" [suggestions]="filteredProducts" 
		(completeMethod)="filterProduct($event)" field="code_article_generique" [dropdown]="true" (dropdownClick)="enlargeDialog()" (input)="sendTheNewValue($event)">
			<ng-template let-prod pTemplate="item">
				<div class="contry-item">
					<img src="{{prod.image}}" style="width:32px;display:inline-block;margin:5px 0 2px 5px" />
					<div style="font-size:18px;float:right;margin:10px 10px 0 0">{{prod.code_article_generique}}</div>
				</div>
		
		
			</ng-template>
			
		</p-autoComplete>
		
		
		
		
		<div *ngIf="variationsFilter && variationsFilter.length">
		<table  [tableStyle]="{ 'min-width': '50rem' }">
			<tr>
			  <th colspan="2">Color / Size</th>
			  <th *ngFor="let color of variationsFilter[variationsFilter.length - 1]['colors']; let colorIndex = index;">
				{{ color }}
			  </th>
				</tr>
				
				
				
					  <tr *ngFor="let size of variationsFilter[variationsFilter.length - 1]['sizes']; let sizeIndex = index;" >
						<td rowspan="{{ variationsFilter[variationsFilter.length - 1]['colors'].length }}" class="p-datatable-cell">{{ size }}</td>
						<td></td>
						<td *ngFor="let color of variationsFilter[variationsFilter.length - 1]['colors']; let colorIndex = index;" >
								<input
								pInputText
								type="number"
								class="p-inputtext-sm"
								[id]="'input-' + colorIndex + '-' + sizeIndex"
								[name]="'input-' + colorIndex + '-' + sizeIndex"
								[value]="0"
								[min]="0"
								style="width: 5rem;"
								(input)="updateInputValue($event.target.value, sizeIndex, colorIndex)"
								[disabled]="!checkCombinationExists(size, color)" 
								 >
						  
						</td>
					  </tr>
					
			</table>
		</div>
		
		<div class="field">
		<label for="f">fournisseur</label>
		<p-autoComplete  id ="f" [(ngModel)]="selectedFournisseur" [suggestions]="filteredFourniss" (completeMethod)="filterFourniss($event)" field="name" [dropdown]="true">
		</p-autoComplete>
		</div>
		<div class="field">
		<label for="s">store</label>
		<p-autoComplete id="s" [(ngModel)]="selectedstore" [suggestions]="filteredstore" (completeMethod)="filterstore($event)" field="label" [dropdown]="true">
		</p-autoComplete>
		</div>
		<div class="field">
		<label for="s">Prix Achat</label>
		<input pInputText id="p" type="number" [(ngModel)]="prixAchat" >
		</div>
		
				</ng-template>
				<ng-template pTemplate="footer">
					<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
					<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
				</ng-template>
			</p-dialog>