import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ArticleService } from 'src/app/demo/service/article.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
deleteDialog:boolean=false
data
import
showImportMsg:boolean=false
showDeleteMsg:boolean=true
selectedButton: string;
  constructor(private s:ArticleService,private route:Router) { }
  @Output() newItemEvent = new EventEmitter<any>();

  ngOnInit(): void {
  }
  showDialog(data?:any) {
    console.log(data)
    this.data=data
    this.deleteDialog= true;
    if (data === "import") {
      this.import="Le fichier importé ne correspond pas au format demandé !"
      this.showDeleteMsg=false
      this.showImportMsg=true
      this.deleteDialog= true;


    }
  
    
  }

  hideDialog() {
    this.selectedButton='no'
    this.deleteDialog = false;
    this.newItemEvent.emit(this.selectedButton)
  }
  confirmDelete(){
    this.selectedButton='yes'
    this.newItemEvent.emit(this.selectedButton)
    this.deleteDialog= false;

   /* if(this.data.productId){
      this.s.deleteProduct(this.data.productId).then(res=>console.log(res))
      this.deleteDialog=false
      this.route.navigate(['/pages/product/product'])
    }
    else{
      this.s.deleteVariation(this.data.variationId).then(res=>(console.log(res)))
      this.deleteDialog=false
      this.route.navigate(['/pages/product/product'])
    }*/
    
  }
}
