import { Collection } from '@angular-devkit/schematics';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ParametreService } from 'src/app/demo/service/parametre.service';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
import { AddMasqueComponent } from '../add-masque/add-masque.component';

interface CompanyInfo {
  id:number
  nomSociete: string;
  nom: string;
  adresse: string;
  numeroRegistration: string;
  telephone: string;
  rc: string;
  fax: string;
  email: string;
  taxIdentification: string;
  routeAdresse: string;
  imageURL: string;
}

interface Info {
  dimensionne: boolean;
  tva: number;
  timbre: number;
  fodec: number;
}
@Component({
  selector: 'app-parametre',
  templateUrl: './parametre.component.html',
  styleUrls: ['./parametre.component.scss']
})
export class ParametreComponent implements OnInit {
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;
  @ViewChild(AddMasqueComponent) masqueDialog: AddMasqueComponent;

  constructor(private parameterService:ParametreService, private messageService: MessageService,private configService:ConfigurationService) { }
  SousFamille
  rayons
  collections
  selectAllCheckbox
  newFamille
  newCodeFamille
  selectedFamille= [];
  editing:boolean=true
  clonedFamille: { [s: string]: any } = {};
  clonedCollection: { [s: string]: any } = {};
  clonedRayon: { [s: string]: any } = {};
  loading:boolean=false
  newRow:boolean=false
  newRowCollection:boolean=false
  newCollection
  newCodeCollection
  selectedCollection= [];
  newRowRayon:boolean=false
  newRayon
  newCodeRayon
  selectedRayon= [];
  fieldsEmpty: boolean = false;
  deleteFamilles: boolean = false;
  deleteCollections: boolean = false;
  deleteRayons: boolean = false;
  config
  companyInfo:CompanyInfo= {
    id:null,
    nomSociete: '',
    nom: '',
    adresse: '',
    numeroRegistration: '',
    telephone: '',
    rc: '',
    fax: '',
    email: '',
    taxIdentification: '',
    routeAdresse: '',
    imageURL: '',
  }
  additionalInfo: Info={
    dimensionne: false,
    tva: 0,
    timbre: 0,
    fodec: 0,
  }
  disableEnregistrer:boolean=true
  oldInfo:Info
  oldCompanyInfo:CompanyInfo
  companyInfoChanged = {
    nomSociete: false,
    nom: false,
    adresse: false,
    numeroRegistration: false,
    telephone: false,
    rc: false,
    fax: false,
    email: false,
    taxIdentification: false,
    routeAdresse: false,
    imageURL: false,
  };
  additionalInfoChanged = {
    dimensionne: false,
    tva: false,
    timbre: false,
    fodec: false,
  };
  changesTracker: { [key: string]: boolean } = {};
  newCodeCouleur
  newCouleur
  newRowCouleur
  couleurs
  selectedCouleurs= [];
  deleteCouleurs: boolean = false;
  clonedCouleur: { [s: string]: any } = {};
  newRowTaille
  newTaille
  newCodeTaille
  tailles
  selectedTaille= [];
  deleteTaille: boolean = false;
  clonedTaille: { [s: string]: any } = {};
  masque
  selectedMasque= [];
  deleteMasque: boolean = false;
  currentPage: number=1;
  pageSize: number=10;
  totalItems: number;
  pageNumbers: number[];
  currentpage
  paginationInfo: any;
  rowsPerPageOptions = [5, 10,20,25];
  sortField
  sortOrder
  searchTermFamille
  searchTermCollection
  searchTermRayon
  searchTermCouleur
  searchTermTaille
  searchTermMasque
   async  ngOnInit() {
  //  await this.getSousFamille()
  //  await this.getCollection()
  //  await this.getRayons()
   await this.getConfiguration()
  //  this.getCouleurs()
  //  this.getTaille()
   this.getMasque()

   
    this.oldCompanyInfo = { ...this.companyInfo }
    this.oldInfo={...this.additionalInfo}

    console.log("old company info",this.oldCompanyInfo,"old info",this.oldInfo)

  
  }


    // ***************************************************************************************************

 

onPageChange(event: any): void {
  this.currentPage = event.page + 1;
  this.pageSize = event.rows;
  console.log("currentPage",this.currentPage,"pageSize",this.pageSize)
}


  // *********************************************Crud Sous Famille******************************************************

  loadSousFAmille(event: LazyLoadEvent) {  
    this.loading = false;
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    if(!this.searchTermFamille)
    {     this.getSousFamille()
    }else{
      this.getSearch(this.searchTermFamille)
    }
}

saveFamille(){
  if(this.editing){
    this.newRow=true
    this.newFamille=''
    this.newCodeFamille=''
  }

}
AddFamille(){
  console.log(this.newFamille)
  if(this.newFamille && this.newCodeFamille){
    const famille={data:{name:this.newFamille,code:this.newCodeFamille}}
    this.parameterService.postStrapi('sous-familles',famille).then(res=>{
      console.log("nouvelle famille",res)
      const famille={id:res.data.id,name:res.data.attributes.name,code:res.data.attributes.code}
      this.SousFamille.unshift(famille)
      this.newRow=false

    })
    this.messageService.add({
      severity: 'success',
      summary: 'Successful',
      detail: 'Sous Famille Créer avec Succé',
      life: 3000
    });
  }
  else{
    if(!this.newFamille || !this.newCodeFamille){
      // this.fieldsEmpty=true
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Erreur de creation',
        life: 3000
      });
      this.newRow=false
    }
 

  }
}
selectAll(event){
   
  this.SousFamille.forEach(famille => {
    famille.selected = event.checked;
    if (event.checked) {
      this.selectedFamille.push(famille.id);
    }
    else{
      this.selectedFamille=[]
    }
  });

  console.log(this.selectedFamille);

  }
  checkboxChanged(event, famille) {
    const familleId = famille.id;

  if (event.checked) {
    this.selectedFamille.push(familleId);
  } else {
    const index = this.selectedFamille.indexOf(familleId);
    if (index > -1) {
      this.selectedFamille.splice(index, 1);
    }
  }

  console.log(this.selectedFamille);
  
  }
  getSearch(value){
    console.log(value)
     this.parameterService.getFromStrapi('sous-familles',1,25, this.sortField,this.sortOrder,value,'name').then(res=>{
      // console.log('Sous Famille',res)
  
       this.SousFamille=res.map(item=>({id:item.id,name:item.attributes.name,          selected:false,
       }))
  
    })
    console.log('Sous Famille',this.SousFamille)
      }

async getSousFamille(){
  await this.parameterService.getFromStrapi('sous-familles',this.currentPage,this.pageSize, this.sortField ,this.sortOrder).then(res=>{
    console.log('Sous Famille',res)

     this.SousFamille=res.map(item=>(
      {id:item.id,
        name:item.attributes.name,
        code:item.attributes.code,
        selected:false,
     }
     ))
     console.log(this.parameterService.total)

     this.totalItems = this.parameterService.total;

  })
  console.log('Sous Famille',this.SousFamille)

}
deleteFamille(){
  this.myDeleteDialog.showDialog()
  this.deleteFamilles=true

}
receiveConfirmation(data){
console.log(data)
if(data=='yes' && this.deleteFamilles){
  for(let familleID of this.selectedFamille){
    this.parameterService.deleteWithId('sous-familles',familleID).then(res=>{
      console.log(res)
     
      this.SousFamille = this.SousFamille.filter(item => item.id !== familleID);
      const indexToRemove = this.SousFamille.findIndex(item => item.id === familleID);

      if (indexToRemove !== -1) {
        this.SousFamille.splice(indexToRemove, 1);
      }

    })
    .catch(erreur => {
      console.log(erreur); 
  })

  }
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Sous Famille Supprimé avec Succé',
    life: 3000
  });
}
this.selectedFamille=[]
}
onRowEditInit(famille) {
  // if(!this.newRow){
    this.clonedFamille[famille.id as string] = { ...famille };
    this.editing=false
    console.log(this.clonedFamille)
  // }

}
onRowEditSave(famille) {
  this.editing=true
  const familleId = famille.id as string;

console.log(this.clonedFamille[familleId])
console.log(famille)

  if (famille && (this.clonedFamille[familleId].code!==famille.code || this.clonedFamille[familleId].name!==famille.name)) {
    // console.log("la famille a modifier",famille)
    const familleModifier={data:{name:famille.name,code:famille.code}}
    this.parameterService.updateWithId('sous-familles',famille.id,familleModifier).then(res=>{console.log("famille modifer",res)})
      delete this.clonedFamille[famille.id as string];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sous Famille Modifié avec Succé' });
  } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'au moins une valeur doit être changer' });
  }
}

onRowEditCancel(famille, index: number) {
  this.editing=true

  this.SousFamille[index] = this.clonedFamille[famille.id as string];
  delete this.clonedFamille[famille.id as string];
}
  // *********************************************Crud Collection******************************************************
  loadSousCollection(event: LazyLoadEvent) {  
    this.loading = false;
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    if(!this.searchTermCollection)
{     this.getCollection()
}else{
  this.getSearchCollection(this.searchTermCollection)
}
}

  async getCollection(){
    await this.parameterService.getFromStrapi('collections',1,25, this.sortField,this.sortOrder).then(res=>{
      // console.log('Sous Famille',res)
  
       this.collections=res.map(item=>({id:item.id,name:item.attributes.name,code:item.attributes.code,    selected:false,}))
  
    })
    console.log('collections',this.collections)
  
  }
  saveCollection(){
    this.newRowCollection=true
    this.newCollection=''
    this.newCodeCollection=''


  }
  AddCollection(){
    console.log(this.newCollection)
    if(this.newCollection){
      const collection={data:{name:this.newCollection,code:this.newCodeCollection}}
      this.parameterService.postStrapi('collections',collection).then(res=>{
        console.log("nouvelle collection",res)
        const novcollection={id:res.data.id,name:res.data.attributes.name,code:res.data.attributes.code}
        this.collections.unshift(novcollection)
        this.newRowCollection=false
  
      })
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Collection Créer avec Succé',
        life: 3000
      });
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Erreur de creation',
        life: 3000
      });
      this.newRowCollection=false
  
    }
  }
  selectAllCollection(event){
    this.collections.forEach(coll => {
      coll.selected = event.checked;
      if (event.checked) {
        this.selectedCollection.push(coll.id);
      }
      else{
        this.selectedCollection=[]
      }
    });
  
    console.log(this.selectedCollection);
  }
  checkboxChangedCollection(event, collection){
    const collectionId = collection.id;

    if (event.checked) {
      this.selectedCollection.push(collectionId);
    } else {
      const index = this.selectedCollection.indexOf(collectionId);
      if (index > -1) {
        this.selectedCollection.splice(index, 1);
      }
    }
  
    console.log(this.selectedCollection);
  }
  deleteCollection(){
    this.myDeleteDialog.showDialog()
    this.deleteCollections=true

  }
  receiveConfirmationCollection(data){
    console.log(data)
    if(data=='yes' && this.deleteCollections){
      for(let collectionID of this.selectedCollection){
        this.parameterService.deleteWithId('collections',collectionID).then(res=>{
          console.log(res)
        
          this.collections = this.collections.filter(item => item.id !== collectionID);
          const indexToRemove = this.collections.findIndex(item => item.id === collectionID);
    
          if (indexToRemove !== -1) {
            this.collections.splice(indexToRemove, 1);
          }
    
        })
        .catch(erreur => {
          console.log(erreur); 
      })
    
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Collection Supprimé avec Succé',
        life: 3000
      });
    }
    this.selectedCollection=[]
    }

    getSearchCollection(value){
      const input = (event.target as HTMLInputElement).value;

      console.log(input)
      this.parameterService.getFromStrapi('collections',1,25, this.sortField,this.sortOrder,input,'name').then(res=>{
       // console.log('Sous Famille',res)
   
        this.collections=res.map(item=>({id:item.id,name:item.attributes.name,          selected:false,
        }))
   
     })
     console.log('collections searched',this.collections)
    }

    onRowEditInitCollection(collection) {
      this.clonedCollection[collection.id as string] = { ...collection };
      this.editing=false
      console.log(this.clonedCollection)
    }
    onRowEditSaveCollection(collection) {
      this.editing=true
      const collectionId = collection.id as string;

      if (collection  && (this.clonedCollection[collectionId].code!==collection.code || this.clonedCollection[collectionId].name!==collection.name)) {
        // console.log("la famille a modifier",famille)
        const collectionModifier={data:{name:collection.name,code:collection.code}}
        this.parameterService.updateWithId('collections',collection.id,collectionModifier).then(res=>{console.log("collection modifer",res)})
          delete this.clonedCollection[collection.id as string];
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Collection Modifié avec Succé' });
      } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'au moins une valeur doit être changer' });
      }
    }
    
    onRowEditCancelCollection(collection, index: number) {
      this.editing=true
    
      this.collections[index] = this.clonedCollection[collection.id as string];
      delete this.clonedCollection[collection.id as string];
    }

    // *********************************************Crud Rayon******************************************************
    loadRayon(event: LazyLoadEvent) {  
      this.loading = false;
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
      if(!this.searchTermRayon)
  {     this.getRayons()
  }else{
    this.getSearchRayon(this.searchTermRayon)
  }
  }

    async getRayons(){
      await this.parameterService.getFromStrapi('rayons',1,25, this.sortField,this.sortOrder).then(res=>{
        // console.log('Sous Famille',res)
    
         this.rayons=res.map(item=>({id:item.id,name:item.attributes.name, code:item.attributes.code,    selected:false}))
    
      })
      console.log('rayons',this.rayons)
    
    }
    saveRayon(){
      this.newRowRayon=true
      this.newRayon=''
      this.newCodeRayon=''
    }
    AddRayon(){
      console.log(this.newRayon)
      if(this.newRayon){
        const rayon={data:{name:this.newRayon,code:this.newCodeRayon}}
        this.parameterService.postStrapi('rayons',rayon).then(res=>{
          console.log("nouvelle rayon",res)
          const rayon={id:res.data.id,name:res.data.attributes.name,code:res.data.attributes.code}
          this.rayons.unshift(rayon)
          this.newRowRayon=false
    
        })
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Rayon Créer avec Succé',
          life: 3000
        });
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Erreur de creation',
          life: 3000
        });
        this.newRowRayon=false
    
      }
    }
  
    selectAllRayon(event){
      this.rayons.forEach(rayon => {
        rayon.selected = event.checked;
        if (event.checked) {
          this.selectedRayon.push(rayon.id);
        }
        else{
          this.selectedRayon=[]
        }
      });
    
      console.log(this.selectedRayon);
    }
    
    checkboxChangedRayon(event, rayon){
      const rayonId = rayon.id;

      if (event.checked) {
        this.selectedRayon.push(rayonId);
      } else {
        const index = this.selectedRayon.indexOf(rayonId);
        if (index > -1) {
          this.selectedRayon.splice(index, 1);
        }
      }
    
      console.log(this.selectedRayon);
    }
    deleteRayon(){
      this.myDeleteDialog.showDialog()
      this.deleteRayons=true
    }
    receiveConfirmationRayon(data){
    console.log(data)
    if(data=='yes' && this.deleteRayons){
      for(let rayonID of this.selectedRayon){
        this.parameterService.deleteWithId('rayons',rayonID).then(res=>{
          console.log(res)
         
          this.rayons = this.rayons.filter(item => item.id !== rayonID);
          const indexToRemove = this.rayons.findIndex(item => item.id === rayonID);
    
          if (indexToRemove !== -1) {
            this.rayons.splice(indexToRemove, 1);
          }
    
        })
        .catch(erreur => {
          console.log(erreur); 
      })
    
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Rayon Supprimé avec Succé',
        life: 3000
      });
    }
    this.selectedRayon=[]
      }
  onRowEditInitRayon(rayon) {
    this.clonedRayon[rayon.id as string] = { ...rayon };
    this.editing=false
    console.log(this.clonedRayon)
  }
  onRowEditSaveRayon(rayon) {
    this.editing=true
    const rayonId = rayon.id as string;

    if (rayon  && (this.clonedRayon[rayonId].code!==rayon.code || this.clonedRayon[rayonId].name!==rayon.name)) {
      // console.log("la famille a modifier",famille)
      const rayonModifier={data:{name:rayon.name,code:rayon.name}}
      this.parameterService.updateWithId('rayons',rayon.id,rayonModifier).then(res=>{console.log("rayon modifer",res)})
        delete this.clonedRayon[rayon.id as string];
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Rayon Modifié avec Succé' });
    } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'au moins une valeur doit être changer' });
    }
  }
  
  onRowEditCancelRayon(rayon, index: number) {
    this.editing=true
  
    this.rayons[index] = this.clonedRayon[rayon.id as string];
    delete this.clonedRayon[rayon.id as string];
  }
  getSearchRayon(value){
    const input = (event.target as HTMLInputElement).value;

    console.log(input)
     this.parameterService.getFromStrapi('rayons',1,25, this.sortField,this.sortOrder,input,'name').then(res=>{
      // console.log('Sous Famille',res)
  
       this.rayons=res.map(item=>({id:item.id,name:item.attributes.name,          selected:false,
       }))
  
    })
    console.log('rayons',this.rayons)
      }
      // *********************************************Configuration******************************************************
async getConfiguration(){
 await  this.configService.getConfig().then(res=>{
  console.log(res)
 
  this.companyInfo= {
    id:res.data.id,
    nomSociete: res.data.attributes.companyName,
    nom: res.data.attributes.name,
    adresse: res.data.attributes.address,
    numeroRegistration: res.data.attributes.registrationNumber,
    telephone: res.data.attributes.phone,
    rc: res.data.attributes.rc    ,
    fax: res.data.attributes.fax,
    email: res.data.attributes.email,
    taxIdentification: res.data.attributes.taxIdentification,
    routeAdresse: res.data.attributes.routeAddress,
    imageURL: res.data.attributes.imageUrl,
  }
  this.additionalInfo={
    dimensionne: res.data.attributes.article_dimensionne,
    tva: res.data.attributes.tva,
    timbre: res.data.attributes.timbre,
    fodec: res.data.attributes.fodec,
  }
})
 console.log(this.companyInfo,this.additionalInfo)
}

inputChange(propertyName: string, event: any): void {
  // const inputValue = (event.target as HTMLInputElement).value;
// console.log("input value ",inputValue,"property",propertyName)
  // Save the current state of the object
  // const oldCompanyInfoCopy = { ...this.companyInfo };

  // Update the corresponding property in companyInfo
  // this.companyInfo[propertyName] = inputValue;
console.log("old value",this.oldCompanyInfo[propertyName],"new value",this.companyInfo[propertyName],
"changed company info",this.companyInfo,"old company info",this.oldCompanyInfo,"companyInfoChanged",this.companyInfoChanged)

  this.companyInfoChanged[propertyName] = this.companyInfo[propertyName] !== this.oldCompanyInfo[propertyName];

 console.log(propertyName,this.companyInfoChanged[propertyName],this.companyInfoChanged)

  const anyCompanyInfoChanged = Object.values(this.companyInfoChanged).some(value => value);



  //  console.log("company changed",anyCompanyInfoChanged,"additional info changed",anyAdditionalInfoChanged)
  console.log("company changed",anyCompanyInfoChanged)


  // this.disableEnregistrer = !(anyCompanyInfoChanged || anyAdditionalInfoChanged);
    this.disableEnregistrer = !(anyCompanyInfoChanged );
    // this.companyInfoChanged=true

}
inputNumberChange(propertyName: string, event: any){
  console.log(event)
  console.log(propertyName)
  console.log(this.additionalInfo)
    const inputValue = event.value;
    this.additionalInfo[propertyName] = inputValue;

 
  console.log("old value",this.oldInfo[propertyName],"new value",this.additionalInfo[propertyName])

  this.additionalInfoChanged[propertyName] = this.additionalInfo[propertyName] != this.oldInfo[propertyName];

  const anyAdditionalInfoChanged = Object.values(this.additionalInfoChanged).some(value => value);
  console.log("additional info changed",anyAdditionalInfoChanged)
  this.disableEnregistrer = !( anyAdditionalInfoChanged);


}
inputSwitchChange(propertyName: string, event: any){
  console.log(event)
  console.log(propertyName)
  console.log(this.additionalInfo)
    const inputValue = event.checked;
    this.additionalInfo[propertyName] = inputValue;

 
  console.log("old value",this.oldInfo[propertyName],"new value",this.additionalInfo[propertyName])

  this.additionalInfoChanged[propertyName] = this.additionalInfo[propertyName] != this.oldInfo[propertyName];

  const anyAdditionalInfoChanged = Object.values(this.additionalInfoChanged).some(value => value);
  console.log("additional info changed",anyAdditionalInfoChanged)
  this.disableEnregistrer = !( anyAdditionalInfoChanged);
}
enregistrerConfig(){
  let hasEmptyProperty = false;
  const InfoChanged = Object.values(this.additionalInfoChanged).some(value => value);
  const CompanyInfoChanged = Object.values(this.companyInfoChanged).some(value => value);
if(InfoChanged || CompanyInfoChanged){
  const newConfig= {
    companyName:this.companyInfo.nomSociete,
    name:this.companyInfo.nom,
    address:this.companyInfo.adresse,
    registrationNumber:this.companyInfo.numeroRegistration,
    phone:this.companyInfo.telephone,
    rc : this.companyInfo.rc  ,
    fax:parseInt(this.companyInfo.fax),
    email:this.companyInfo.email,
    taxIdentification:this.companyInfo.taxIdentification,
    routeAddress:this.companyInfo.routeAdresse,
    imageUrl:this.companyInfo.imageURL,
    article_dimensionne:this.additionalInfo.dimensionne,
    tva:this.additionalInfo.tva,
    timbre:this.additionalInfo.timbre,
    fodec:this.additionalInfo.fodec,
  }
  for (const property in newConfig) {

  if (newConfig.hasOwnProperty(property) && property !== 'fax' && property !== 'email' && property !== 'article_dimensionne' && property !== 'fodec' && property !== 'timbre' && property !== 'tva' ) {

  if (!newConfig[property] ) {
           
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: `remplissez tous les champs obligatoires!`,
      life: 2500,
    });
    hasEmptyProperty = true;
    this.disableEnregistrer=false
    this.fieldsEmpty=true
    break;
  }
}
  }
  console.log(newConfig)
  if (!hasEmptyProperty) {
       
    this.fieldsEmpty=false
     
  // this.parameterService.updateWithId('configuration',this.companyInfo.id,newConfig).then(res=>console.log('modification config',res))
  this.parameterService.updateConfig(newConfig).then(res=>console.log('modification config',res))
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Modification effectuée avec Succé',
    life: 3000
  });
  this.companyInfoChanged= {
    nomSociete: false,
    nom: false,
    adresse: false,
    numeroRegistration: false,
    telephone: false,
    rc: false,
    fax: false,
    email: false,
    taxIdentification: false,
    routeAdresse: false,
    imageURL: false,
  }
  this.additionalInfoChanged= {
    dimensionne: false,
    tva: false,
    timbre: false,
    fodec: false,
  };
}
this.disableEnregistrer=true
}
}
      // *********************************************Crud Couleur******************************************************
      loadCouleurs(event: LazyLoadEvent) {  
        this.loading = false;
        this.sortField = event.sortField;
        this.sortOrder = event.sortOrder;
        console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
        if(!this.searchTermCouleur)
    {     this.getCouleurs()
    }else{
      this.getSearchCouleur(this.searchTermCouleur)
    }
    }
      async getCouleurs(){
    await this.parameterService.getFromStrapi('colors',1,25, this.sortField,this.sortOrder).then(res=>{
      // console.log('Sous Famille',res)
  
       this.couleurs=res.map(item=>({id:item.id,name:item.attributes.name, code:item.attributes.code,    selected:false}))
  
    })
    console.log('couleurs',this.couleurs)
  }
  saveCouleurs(){
    this.newRowCouleur=true
    this.newCouleur=''
    this.newCodeCouleur=''
  }
  AddCouleur(){
    console.log(this.newCouleur)
    if(this.newCouleur && this.newCodeCouleur){
      const color={data:{name:this.newCouleur,code:this.newCodeCouleur}}
      this.parameterService.postStrapi('colors',color).then(res=>{
        console.log("nouveau couleur",res)
        const color={id:res.data.id,name:res.data.attributes.name,code:res.data.attributes.code}
        this.couleurs.unshift(color)
        this.newRowCouleur=false
  
      })
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Couleur Créer avec Succé',
        life: 3000
      });
    }
    else{
      if(!this.newCouleur || !this.newCodeCouleur){
        // this.fieldsEmpty=true
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Erreur de creation',
          life: 3000
        });
        this.newRowCouleur=false
      }
   
  
    }
  }
  selectAllCouleur(event){
    this.couleurs.forEach(famille => {
      famille.selected = event.checked;
      if (event.checked) {
        this.selectedCouleurs.push(famille.id);
      }
      else{
        this.selectedCouleurs=[]
      }
    });
  
    console.log(this.selectedCouleurs);
  
  }
  checkboxChangedCouleur(event, couleur){
    const couleurId = couleur.id;

    if (event.checked) {
      this.selectedCouleurs.push(couleurId);
    } else {
      const index = this.selectedCouleurs.indexOf(couleurId);
      if (index > -1) {
        this.selectedCouleurs.splice(index, 1);
      }
    }
  
    console.log(this.selectedCouleurs);
  }
  deleteCouleur(){
    this.myDeleteDialog.showDialog()
    this.deleteCouleurs=true
  
  }
  receiveConfirmationCouleur(data){
    console.log(data)
    if(data=='yes' && this.deleteCouleurs){
      for(let couleurID of this.selectedCouleurs){
        this.parameterService.deleteWithId('colors',couleurID).then(res=>{
          console.log(res)
         
          this.couleurs = this.couleurs.filter(item => item.id !== couleurID);
          const indexToRemove = this.couleurs.findIndex(item => item.id === couleurID);
    
          if (indexToRemove !== -1) {
            this.couleurs.splice(indexToRemove, 1);
          }
    
        })
        .catch(erreur => {
          console.log(erreur); 
      })
    
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Couleur Supprimé avec Succé',
        life: 3000
      });
    }
    this.selectedCouleurs=[]
      }

      onRowEditInitCouleur(couleur) {
        this.clonedCouleur[couleur.id as string] = { ...couleur };
        this.editing=false
        console.log(this.clonedCouleur)
      }
      onRowEditSaveCouleur(couleur) {
        this.editing=true
        const couleurId = couleur.id as string;

        if (couleur  && (this.clonedCouleur[couleurId].code!==couleur.code || this.clonedCouleur[couleurId].name!==couleur.name)) {
          // console.log("la famille a modifier",famille)
          const CouleurModifier={data:{name:couleur.name,code:couleur.code}}
          this.parameterService.updateWithId('colors',couleur.id,CouleurModifier).then(res=>{console.log("couleur modifer",res)})
            delete this.clonedCouleur[couleur.id as string];
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'couleur Modifié avec Succé' });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'au moins une valeur doit être changer' });
        }
      }
      
      onRowEditCancelCouleur(couleur, index: number) {
        this.editing=true
      
        this.couleurs[index] = this.clonedCouleur[couleur.id as string];
        delete this.clonedCouleur[couleur.id as string];
      }
      getSearchCouleur(value){
        const input = (event.target as HTMLInputElement).value;

        console.log(value)
        this.parameterService.getFromStrapi('colors',1,25, this.sortField,this.sortOrder,input,'name').then(res=>{
         // console.log('Sous Famille',res)
     
          this.couleurs=res.map(item=>({id:item.id,name:item.attributes.name,          selected:false,
          }))
     
       })
       console.log('couleur',this.couleurs)
      }
            // *********************************************Crud Taille******************************************************
    loadSousTaille(event: LazyLoadEvent) {  
      this.loading = false;
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
      if(!this.searchTermTaille)
  {     this.getTaille()
  }else{
    this.getSearchTaille(this.searchTermTaille)
  }
  }
   async getTaille(){
    await this.parameterService.getFromStrapi('tailles',1,25, this.sortField,this.sortOrder).then(res=>{
      // console.log('Sous Famille',res)
  
        this.tailles=res.map(item=>({id:item.id,name:item.attributes.name,   selected:false}))
  
    })
    console.log('tailles',this.tailles)
  }
   saveTaille(){

    this.newRowTaille=true
    this.newTaille=''
    this.newCodeTaille=''
     }
    AddTaille(){
    console.log(this.newTaille)
    if(this.newTaille){
      const taille={data:{name:this.newTaille}}
      this.parameterService.postStrapi('tailles',taille).then(res=>{
        console.log("nouvelle taille",res)
        const novtaille={id:res.data.id,name:res.data.attributes.name}
        this.tailles.unshift(novtaille)
        this.newRowTaille=false
  
      })
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Taille Créer avec Succé',
        life: 3000
      });
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Erreur de creation',
        life: 3000
      });
      this.newRowTaille=false
  
    }
    }
    selectAllTaille(event){
      this.tailles.forEach(taille => {
        taille.selected = event.checked;
        if (event.checked) {
          this.selectedTaille.push(taille.id);
        }
        else{
          this.selectedTaille=[]
        }
      });
    
      console.log(this.selectedTaille);
    }
    checkboxChangedTaille(event, taille){
      const tailleId = taille.id;

      if (event.checked) {
        this.selectedTaille.push(tailleId);
      } else {
        const index = this.selectedTaille.indexOf(tailleId);
        if (index > -1) {
          this.selectedTaille.splice(index, 1);
        }
      }
    
      console.log(this.selectedTaille);
    }
    deleteTailles(){
      this.myDeleteDialog.showDialog()
      this.deleteTaille=true
  
    }
    receiveConfirmationTaille(data){
      console.log(data)
      if(data=='yes' && this.deleteTaille){
        for(let tailleID of this.selectedTaille){
          this.parameterService.deleteWithId('tailles',tailleID).then(res=>{
            console.log(res)
          
            this.tailles = this.tailles.filter(item => item.id !== tailleID);
            const indexToRemove = this.tailles.findIndex(item => item.id === tailleID);
      
            if (indexToRemove !== -1) {
              this.tailles.splice(indexToRemove, 1);
            }
      
          })
          .catch(erreur => {
            console.log(erreur); 
        })
      
        }
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Taille Supprimé avec Succé',
          life: 3000
        });
      }
      this.selectedTaille=[]
      }
      onRowEditInitTaille(taille) {
        this.clonedTaille[taille.id as string] = { ...taille };
        this.editing=false
        console.log(this.clonedTaille)
      }
      onRowEditSaveTaille(taille) {
        this.editing=true
        const tailleId = taille.id as string;

        if (taille  && (this.clonedTaille[tailleId].code!==taille.code || this.clonedTaille[tailleId].name!==taille.name)) {
          // console.log("la famille a modifier",famille)
          const tailleModifier={data:{name:taille.name}}
          this.parameterService.updateWithId('tailles',taille.id,tailleModifier).then(res=>{console.log("taille modifer",res)})
            delete this.clonedTaille[taille.id as string];
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Taille Modifié avec Succé' });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'au moins une valeur doit être changer' });
        }
      }
      
      onRowEditCancelTaille(taille, index: number) {
        this.editing=true
      
        this.tailles[index] = this.clonedTaille[taille.id as string];
        delete this.clonedTaille[taille.id as string];
      }
      getSearchTaille(value){
        const input = (event.target as HTMLInputElement).value;

        console.log(input)
        this.parameterService.getFromStrapi('tailles',1,25, this.sortField,this.sortOrder,input,'name').then(res=>{     
          this.tailles=res.map(item=>({id:item.id,name:item.attributes.name,selected:false,
          }))
     
       })
       console.log('Tailles searched',this.tailles)
      }
// *********************************************Crud Masque******************************************************
loadMasque(event: LazyLoadEvent) {  
  this.loading = false;
  this.sortField = event.sortField;
  this.sortOrder = event.sortOrder;
  console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
  if(!this.searchTermMasque)
{     this.getMasque()
}else{
this.getSearchMasque(this.searchTermMasque)
}
}
async getMasque(){
    await this.parameterService.getFromStrapi('masque-tailles',1,25, this.sortField,this.sortOrder,'','','','',['tailles_masques']).then(res=>{
      // console.log(res)
  
        this.masque=res.map(item=>(
          {id:item.id,
          name:item.attributes.name,
          tailles:item.attributes.tailles_masques.data.map(item=>({id:item.id,name:item.attributes.name})),
          selected:false}))
  
    })
    console.log('masque',this.masque)
  }
  saveMasque(){
    this.masqueDialog.showDialog(this.masque)
  }
  selectAllMasque(event){
    this.masque.forEach(masque => {
      masque.selected = event.checked;
      if (event.checked) {
        this.selectedMasque.push(masque.id);
      }
      else{
        this.selectedMasque=[]
      }
    });
  
    console.log(this.selectedMasque);
  }
  checkboxChangedMasque(event, masque){
    const masqueId = masque.id;

    if (event.checked) {                          
      this.selectedMasque.push(masqueId);
    } else {
      const index = this.selectedMasque.indexOf(masqueId);
      if (index > -1) {
        this.selectedMasque.splice(index, 1);
      }
    }
  
    console.log(this.selectedMasque);
  }

  deleteMasques(){
    this.myDeleteDialog.showDialog()
    this.deleteMasque=true

  }
  receiveConfirmationMasque(data){
    console.log(data)
    if(data=='yes' && this.deleteMasque){
      for(let masqueID of this.selectedMasque){
        this.parameterService.deleteWithId('masque-tailles',masqueID).then(res=>{
          console.log(res)
        
          this.masque = this.masque.filter(item => item.id !== masqueID);
          const indexToRemove = this.masque.findIndex(item => item.id === masqueID);
    
          if (indexToRemove !== -1) {
            this.masque.splice(indexToRemove, 1);
          }
    
        })
        .catch(erreur => {
          console.log(erreur); 
      })
    
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Masque Supprimé avec Succé',
        life: 3000
      });
    }
    this.selectedMasque=[]
    }

  ConsulterMasque(masque,index){
    console.log("index",index)
    const data={masque:masque,indexMasque:index,masques:this.masque}
    this.masqueDialog.showDialog(data)

  }
  getSearchMasque(value){
    const input = (event.target as HTMLInputElement).value;

    console.log(value)
    this.parameterService.getFromStrapi('masque-tailles',1,25, this.sortField,this.sortOrder ,input,'name','','',['tailles_masques']).then(res=>{
      this.masque=res.map(item=>(
      {id:item.id,
      name:item.attributes.name,
      tailles:item.attributes.tailles_masques.data.map(item=>({id:item.id,name:item.attributes.name})),
      selected:false}
      ))
    })
console.log('masques',this.masque)

   }
  
}