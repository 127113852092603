<p-dialog [(visible)]="ImportDialog" [style]="{width: '850px'}" header="Produit Importer " [modal]="true" class="p-fluid" >
    
    <p-tabView>
        <p-tabPanel header="Nouveaux Produits">
            <!-- <div class="container d-flex justify-content-center"> -->
                <div class="container ">
                <div class="flex-container ">
                    <div class="item">
            <div class="col-md-6 mb-4 ">
                <div class="grid grid-nogutter widget-overview-box overview-box-1 ">
                    <div class="col-3 overview-box-icon">
                    <div class="icon">
                        <i class="bi bi-bell-fill" style=" color: #ffe600;font-size: 1em;"></i>   
                    </div>
                      </div>              
                                                           
                     <div class="col-6 overview-box-text">
                        <h5>Nombre de produits ajouter avec succés</h5>
                        <!-- <span>24 new since last visit</span> -->
                    </div>
                    <div class="col-3 overview-box-status">
                        {{NbNouveauProduits}}
                    </div>
                    </div>
                </div>
                </div>
                <div class="item">
                <div class="col-md-6 mb-4">
                    <div class="grid grid-nogutter widget-overview-box overview-box-1">
                        <div class="icon">
                            <i class="bi bi-bell-fill" style=" color: #ffe600;font-size: 1em;"></i>
                                            </div>
                       <div class="col-6 overview-box-text">
                            <h5>Nombre de produits échoués</h5>
                            <!-- <span>24 new since last visit</span> -->
                        </div>
                        <div class="col-3 overview-box-status">
                           {{NbFauxProduits}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            <!-- </div> -->
            
       
        <br>
        <div class="item">
        <div class="col-12 xl:col-3 md:col-6">
        <button pButton pRipple label="Export" (click)="export()" class="p-button-success mr-2 mb-2 custom-button" ><i class="bi bi-upload" style="color: white;"></i></button>
        </div>
        </div>
        <div style="margin-bottom: 10px;"></div>
        <p-table [columns]="colsVariations" [value]="NouvVariations" [globalFilterFields]="['Nom']" dataKey="id"  >
			
                                
            <ng-template pTemplate="header" let-columns>
         
                <tr>
                    <th></th>
                    <th *ngFor="let col of columns">
                        <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center text-center">
                        {{ col.header }}
                        <!-- <p-columnFilter type="text" field="code_article_generique" display="menu" placeholder="Search by  code_article_generique"></p-columnFilter> -->
                    </div>
       
                    <div  *ngIf="col.field=='code_barre'"class="flex justify-content-between align-items-center text-center">
                        {{ col.header }}
                        <!-- <p-columnFilter type="text" field="code_barre" display="menu" placeholder="Search by  code_barre"></p-columnFilter> -->
                    </div>
       
       
                    <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center text-center">
                        {{ col.header }}
                        <!-- <p-columnFilter type="text" field="color" display="menu" placeholder="Search by  color"></p-columnFilter> -->
                    </div>
                 
                    <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center text-center">
                        {{ col.header }}
                        <!-- <p-columnFilter type="text" field="taille" display="menu" placeholder="Search by  taille"></p-columnFilter> -->
       
                        
                    </div>
                    <!-- <div  *ngIf="col.field=='Statut'"class="flex justify-content-between align-items-center text-center">
                        {{ col.header }}
       
                        
                    </div> -->
       
                    </th>
                   
                </tr>
            </ng-template>
       
       
            <ng-template pTemplate="body" let-rowData  let-columns="columns" let-ri="rowIndex">
                <tr  [pEditableRow]="columns">
                    <!-- <td>
                      
                     
                               <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
                               [icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRow(rowData,ri)"></button>
                    </td> -->
                    <td></td>
                    <td style="min-width: 12rem;" *ngFor="let col of columns">
       
                        
                        <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center text-center">
                            
                             {{rowData[col.field] }}
                        </div>
                    
                       <div  *ngIf="col.field=='code_barre'"class="flex justify-content-between align-items-center text-center">
                            
                        {{rowData[col.field] }}
                   </div>
                   <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center text-center">
                            
                    {{rowData[col.field]}}
               </div>
               <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center text-center">
                            
                {{rowData[col.field]}}
           </div>
               
           <!-- <div  *ngIf="col.field=='Statut'"class="flex justify-content-between align-items-center text-center">
                            
            {{rowData[col.field]}}
       </div> -->
                        
                    </td>
                  
                
                </tr>
                    
                </ng-template>
               
        </p-table> 
        <div class="card flex justify-content-center">
            <p-paginator  [totalRecords]="" [rows]="" ></p-paginator>
        </div>
        </p-tabPanel>
        <p-tabPanel header="Produits Échoués">
            <div class="container">
                <div class="flex-container">
                    <div class="item">
                        <div class="col-md-6 mb-4">
                            <div class="grid grid-nogutter widget-overview-box overview-box-1">
                                <div class="col-3 overview-box-icon">
                                    <div class="icon">
                                        <i class="bi bi-bell-fill" style="color: #ffe600; font-size: 1em;"></i>   
                                    </div>
                                </div>              
                                <div class="col-6 overview-box-text">
                                    <h5>Nombre de produits ajouter avec succès</h5>
                                    <!-- <span>24 new since last visit</span> -->
                                </div>
                                <div class="col-3 overview-box-status">
                                    {{NbNouveauProduits}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="col-md-6 mb-4">
                            <div class="grid grid-nogutter widget-overview-box overview-box-1">
                                <div class="icon">
                                    <i class="bi bi-bell-fill" style="color: #ffe600; font-size: 1em;"></i>
                                </div>
                                <div class="col-6 overview-box-text">
                                    <h5>Nombre de produits échoués</h5>
                                    <!-- <span>24 new since last visit</span> -->
                                </div>
                                <div class="col-3 overview-box-status">
                                    {{NbFauxProduits}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        <br>
        <div class="item">
        <div class="col-12 xl:col-3 md:col-6">

 <button pButton pRipple label="Export"  (click)="export()" class="p-button-success mr-2 mb-2 custom-button" ><i class="bi bi-upload" style="color: white;"></i></button>
 </div>
 </div>
 <div style="margin-bottom: 10px; text-align: center;"></div>
 <p-table [columns]="colsVariations" [value]="VariationsEchouees" [globalFilterFields]="['Nom']" dataKey="id"  >
     

                         
     <ng-template pTemplate="header" let-columns>
         
         <tr>
             <th></th>
             <th *ngFor="let col of columns">
                 <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center text-center">
                 {{ col.header }}
                 <!-- <p-columnFilter type="text" field="code_article_generique" display="menu" placeholder="Search by  code_article_generique"></p-columnFilter> -->
             </div>

             <div  *ngIf="col.field=='code_barre'"class="flex justify-content-between align-items-center text-center">
                 {{ col.header }}
                 <!-- <p-columnFilter type="text" field="code_barre" display="menu" placeholder="Search by  code_barre"></p-columnFilter> -->
             </div>


             <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center text-center">
                 {{ col.header }}
                 <!-- <p-columnFilter type="text" field="color" display="menu" placeholder="Search by  color"></p-columnFilter> -->
             </div>
          
             <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center text-center">
                 {{ col.header }}
                 <!-- <p-columnFilter type="text" field="taille" display="menu" placeholder="Search by  taille"></p-columnFilter> -->

                 
             </div>
             <div  *ngIf="col.field=='Statut'"class="flex justify-content-between align-items-center text-center">
                {{ col.header }}
                <!-- <p-columnFilter type="text" field="taille" display="menu" placeholder="Search by  taille"></p-columnFilter> -->

                
            </div>

             </th>
            
         </tr>
     </ng-template>


     <ng-template pTemplate="body" let-rowData  let-columns="columns" let-ri="rowIndex">
         <tr  [pEditableRow]="columns">
             <!-- <td>
               
              
                        <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
                        [icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRow(rowData,ri)"></button>
             </td> -->
             <td></td>
             <td style="min-width: 12rem;" *ngFor="let col of columns">

                 
                 <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center text-center">
                     
                      {{rowData[col.field] }}
                 </div>
             
                <div  *ngIf="col.field=='code_barre'"class="flex justify-content-between align-items-center text-center">
                     
                 {{rowData[col.field] }}
            </div>
            <div  *ngIf="col.field=='color'"class="flex justify-content-between align-items-center text-center">
                     
             {{rowData[col.field]}}
        </div>
        <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center text-center">
                     
         {{rowData[col.field]}}
    </div>
    <div  *ngIf="col.field=='Statut'"class="flex justify-content-between align-items-center text-center">
                     
        {{rowData[col.field]}}
   </div>
       
    
                 
             </td>
           
         
         </tr>
             
         </ng-template>

        
        
 </p-table> 
 <div class="card flex justify-content-center">
    <p-paginator  [totalRecords]="" [rows]="" ></p-paginator>
</div>
        </p-tabPanel>
    </p-tabView>
   
    
<ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple [disabled]="disableSaveButton" label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
</ng-template>

</p-dialog>