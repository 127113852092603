<p-dialog [(visible)]="masqueDialog" [style]="{width: 'auto' }"
[breakpoints]=" 
        { 
            '800px': '30vw',  
            '650px': '100vw' 
        }"  
header="Gérer Masque" [modal]="true" class="p-fluid" (onHide)="hide()" >
    <!-- <p-toast></p-toast> -->
  
    <ng-template pTemplate="content"  >


        <div class="grid formgrid " *ngIf="masqueToAdd">
                <div class="field col">
					<label htmlFor="name2">Nom Masque</label>
                <input type="text" pInputText  [(ngModel)]="nomMasque">

            </div>

            <div class="field col">
                <label htmlFor="name2">Taille </label>
            <p-multiSelect [appendTo]="'body'"   [options]="tailles" [(ngModel)]="selectedTailles" defaultLabel="Selectionner Tailles" optionLabel="name" class="multiselect-custom ">
			
			</p-multiSelect>
            </div>


            <div *ngIf="masqueToEdit">
                <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success ml-3 mb-2  w-7"  (click)="onEditTaille(masque)"></button>

                <ng-container  *ngIf=" addCellTaille">                          

                    <p-multiSelect [appendTo]="'body'" [options]="tailles" [(ngModel)]="selectedTailles" defaultLabel="Selectionner Tailles" optionLabel="name" class=" w-10rem multiselect-custom"></p-multiSelect>
                </ng-container>
            </div>

        </div>

        <div *ngIf="masqueToEdit"> 
            <div class="formgrid grid">
                <div class="field col">
                    <label for="firstname2"  >Masque</label>
                    <input id="firstname2"  readonly="true" [(ngModel)]="masqueTomodify.name" type="text" class=" surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full mb-2">

                    <button  pButton pRipple type="button" label="Modifier" icon="pi pi-pencil" class="mr-2 mb-2 w-12" style="background-color:  #3B82F6;" (click)="onEditTaille(masque)"></button>
                    <ng-container  *ngIf=" addCellTaille">                          

                    <p-multiSelect (onChange)="changesTailles($event)" [appendTo]="'body'" [options]="tailles" [(ngModel)]="selectedTailles" defaultLabel="Selectionner Tailles" optionLabel="name" class=" w-9 multiselect-custom"></p-multiSelect>

                </ng-container>

                </div>
                <div class="field col">
                    <label for="lastname2" class="mr-8 ">Taille 
                    </label>



                    <p-table [value]="masqueTomodify.tailles" dataKey="id"  responsiveLayout="stack" [scrollable]="true" scrollHeight="400px"  >
                      

                        <ng-template pTemplate="header">
                         
                            <!-- <button  pButton pRipple type="button" icon="pi pi-plus" class="p-button-success ml-3 mb-2  w-7"  (click)="onEditTaille(masque)"></button> -->

                       
                        </ng-template>
                        <ng-template pTemplate="body" let-taille   let-ri="rowIndex">
                            <!-- <ng-container  *ngIf="ri === 0  && addCellTaille">                          

                                <tr>
                                <td >
                                <p-multiSelect [appendTo]="'body'" [options]="tailles" [(ngModel)]="selectedTailles" defaultLabel="Selectionner Tailles" optionLabel="name" class=" w-10rem multiselect-custom"></p-multiSelect>
    
    
                                </td>
                               
                              </tr> 
                            </ng-container> -->
                            
                            <tr>
                               
                                <td >
                                 <!-- <input  [readOnly]="Edit" class="w-8rem" pInputText type="text" [(ngModel)]="taille.name" > -->
                                 {{taille.name}}

                             </td>
                         
                            </tr>
                           

                        </ng-template>
                    </p-table>        
                        </div>
            </div>
        </div>
  </ng-template>
  <ng-template pTemplate="footer">
  <button pButton pRipple label="Fermer" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
  <button pButton pRipple [disabled]="disableButton" label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="masqueToEdit ? AddTailleToMasque(masqueTomodify) : saveMasque()"></button>
  </ng-template>
  </p-dialog>

