import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ArticleService } from 'src/app/demo/service/article.service';
import { MouvementService } from 'src/app/demo/service/mouvement.service';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
interface Mouvement {
  date: string;
  num_piece: string;
  type_operation: string;
  clt_fourniss: string;
  entre: number;
  sortie: number;
}
@Component({
  selector: 'app-mouvement-stock',
  templateUrl: './mouvement-stock.component.html',
  styleUrls: ['./mouvement-stock.component.scss']
})
export class MouvementStockComponent implements OnInit {
  cols=[
    { field: 'date', header: "Date" },
    { field: 'num_piece', header: 'Numéro piéce' },
    { field: 'type_operation', header: "Type opération" },
    { field: 'clt_fourniss', header: 'Client/Fournisseur' },
    { field: 'entre', header: 'Entré' },
    { field: 'sortie', header: 'Sortie' },
  ];
  Types=['achat','vente']
  dates: Date[];
  fieldsEmpty: boolean = false;

  selectedType
  articles
  selectedArtilce
  filteredArticle
  currentPage: number=1;
pageSize: number;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
  Mouvements: Mouvement[] = [];
  constructor(private articleService:ArticleService,private MouvService:MouvementService,private messageService: MessageService) { }
  async ngOnInit() {
    this.onPageChange({ page: 0 });

   await this.getArticles()
  }
  getArticles(){
    this.articleService.getArticlesWithContain().then(res=>{
      
      console.log(res)
      this.articles=res.data.map(item=>({id:item.id,code_article_generique:item.attributes.code_article_generique}))
      console.log("Articles",  this.articles)
    })
  }
  filterArticle(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.articles as any[]).length; i++) {
        let country = (this.articles as any[])[i];
        if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredArticle = filtered;
}

getSelectedArticles(event: any){
  console.log('Selected article:', event);

}
onKeyUp(event: any) {
  // Capture the text as the user types
 const articleToSearch = event.target.value;
 console.log("searched article",articleToSearch)
 this.articleService.getArticlesWithContain(articleToSearch).then(res=>{
      
  console.log(res)
  this.articles=res.data.map(item=>({id:item.id,code_article_generique:item.attributes.code_article_generique}))
  console.log("Articles",  this.articles)
})

}
async  getMouvements(){
  if (!this.selectedArtilce && !this.selectedType && (!this.dates || this.dates.length === 0)) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Veuillez ajouter des données pour appliquer le filtre' });

  }
  else
  if (((!this.selectedType || this.selectedType  )|| (this.dates || this.dates.length === 0   )) && !this.selectedArtilce) {
    // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Veuillez ajouter un article' });
    this.fieldsEmpty=true

  }
  else{
    this.fieldsEmpty=false

    // const formattedDates = this.dates?.map(date => {
    //   const currentDate = new Date(date);
    //   const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    //   return formattedDate;
  
    // })
    // console.log("range date", formattedDates)
    const formattedDates: string[] = [];
        console.log("range date", this.dates)

    if (this.dates && this.dates.length === 2) {

      const startDate = new Date(this.dates[0]);
      const endDate = new Date(this.dates[1]);
      if (this.dates[1]!==null) {
        console.log("test ")

      // Subtract one day from the start date
      const adjustedStartDate = new Date(startDate);
      adjustedStartDate.setDate(startDate.getDate() - 1);
      console.log("start date ",adjustedStartDate)

      // Add one day to the end date
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(endDate.getDate() + 1);
      console.log("end date ",adjustedEndDate)

      // Format the adjusted dates
      const formattedStartDate = `${adjustedStartDate.getFullYear()}-${(adjustedStartDate.getMonth() + 1).toString().padStart(2, '0')}-${adjustedStartDate.getDate().toString().padStart(2, '0')}`;
      const formattedEndDate = `${adjustedEndDate.getFullYear()}-${(adjustedEndDate.getMonth() + 1).toString().padStart(2, '0')}-${adjustedEndDate.getDate().toString().padStart(2, '0')}`;
     
      formattedDates.push(formattedStartDate, formattedEndDate);

      console.log({ start: formattedStartDate, end: formattedEndDate });
    }
    else {
      // If endDate is null, apply the logic for adjusting based on a single date
      const currentDate = startDate;

      // Subtract one day from the start date
      const adjustedStartDate = new Date(currentDate);
      adjustedStartDate.setDate(currentDate.getDate() - 1);
      console.log("start date ",adjustedStartDate)

      // Add one day to the end date
      const adjustedEndDate = new Date(currentDate);
      adjustedEndDate.setDate(currentDate.getDate() + 1);
      console.log("end date ",adjustedEndDate)
      // Format the adjusted dates as strings
      const formattedStartDate = `${adjustedStartDate.getFullYear()}-${(adjustedStartDate.getMonth() + 1).toString().padStart(2, '0')}-${adjustedStartDate.getDate().toString().padStart(2, '0')}`;
      const formattedEndDate = `${adjustedEndDate.getFullYear()}-${(adjustedEndDate.getMonth() + 1).toString().padStart(2, '0')}-${adjustedEndDate.getDate().toString().padStart(2, '0')}`;

      formattedDates.push(formattedStartDate, formattedEndDate);
  }
  
  }
  else {
    console.log("Invalid or missing date range");
}
    console.log("type mvt" ,this.selectedType)
    console.log('Selected article:', this.selectedArtilce);
    await this.MouvService.getMouvement(this.selectedArtilce.code_article_generique,this.selectedType,formattedDates).then(res=>
      {console.log(res)
        this.Mouvements=res.data.map(item=>
         ( { 
            date:new Date(item.date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            num_piece:'',
            type_operation:item.type,
            clt_fourniss:item.name,
            entre:item.type === 'vente' ? 0 : item.total_quantity,
            sortie: item.type === 'achat' ? 0 : item.total_quantity
  
  
  
  
          }))
          
      this.paginationInfo = res.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
      })
     console.log("Mouvements",this.Mouvements)
  }


}
refreshMouvement(){
  this.selectedArtilce=''
  this.selectedType=''
  this.dates=[]
  this.Mouvements=[]
}
async onPageChange(event) {
  
    console.log(event)
    this.currentPage=event.page
    console.log(this.currentPage)
 await this.MouvService.sendData(this.currentPage+1)
 if(event.page!=0){
  await this.getMouvements()

 }
  //  await this.myDialog.toArray()[0].setFournissList(this.fourniss)

   
   }
}
