import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import { HttpClient ,HttpParams} from "@angular/common/http";
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class TransfertService {
  pageSize
  currentPage
  sortFiled
  sortMode
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
    // *****************************saisie transfert******************************************

  getEtablissements() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/etablissements?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  savetransfert(data){
    // return this.http.post(`${environment.url}/createTransfert`,data)
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/createTransfert?populate=* `;
      this.http
        .post(url,data)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }
  // *****************************consulte transfert******************************************
  async  sendData(data: any) {

    this.currentPage=data.currentPage
    this.pageSize=data.pageSize
    console.log( this.pageSize)
  }
  async  getSort(data: any) {
    this.sortMode=data.sortMode
    this.sortFiled=data.sortFiled
    console.log( this.sortMode)
    
    }
  async getArticles() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/products?populate=sous_famille&populate=variations
      &populate=variations.color&populate=variations.taille
      &populate=collection&populate=rayon&populate=fournisseur`;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }

  getFournisseurs() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/fournisseurs?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          this.handleApiError(error);
          throw error;        });
    });
    return promise;
  }

  formatDateToYYYYMMDD(date: Date): string {
    date.setDate(date.getDate() + 1);
    return date.toISOString();
  }
  
ConsulteTransfert(etabliss?,depot?,depotRecep?,fourniss?,article?,date?){
  let promise = new Promise<any>((resolve) => {
    const params = new HttpParams()
    .set('pagination[pageSize]',this.pageSize)
    .set('pagination[page]', this.currentPage)
     
    let url = `${environment.url}/transferts`;
    url += '?populate=etablissement_recepteur&populate=etablissement_emetteur&populate=store_emetteur&populate=store_recepteur&populate=products&populate=products.fournisseur &populate=transfert_variations &populate=transfert_variations.variation&populate=transfert_variations.variation.color&populate=transfert_variations.variation.taille';
     let filed
    if (this.sortFiled) {
      // Map the provided field to the corresponding API attribute
      switch (this.sortFiled) {
        case 'date':
          filed = 'date_transfert';
          break;
        case 'etabEmett':
          filed = 'etablissement_emetteur.name';
          break;
        case 'depotEmet':
          filed = 'store_emetteur.name';
          break;
        case 'etabRecep':
          filed = 'etablissement_recepteur.name';
          break;
        case 'depotRecep':
          filed = 'store_recepteur.name';
          break;
  
        default:
          break;
      }
    }
    if (this.sortMode === 1) {
      url += `&sort=${filed}:DESC`;
    } else if (this.sortMode === -1) {
      url += `&sort=${filed}:ASC`;
    }
    
    if (fourniss && fourniss.length > 0) {
    for(let Fourniss of fourniss){
      url += `&filters[products][fournisseur][id][$eq]=${Fourniss}`;
    }
  }
 
    if (depot && depot.length > 0) {
      for(let Depot of depot){
      url += `&filters[store_emetteur][id][$eq]=${Depot}`;
      }
    }
    if (depotRecep && depotRecep.length > 0) {
      for(let Depot of depotRecep){
      url += `&filters[store_recepteur][id][$eq]=${Depot}`;

      }
    }
    if (article && article.length > 0) {
      for(let Article of article){

      url += `&filters[products][id][$eq]=${Article}`;
      }
    }
    if (date) {
      const formattedDate = this.formatDateToYYYYMMDD(date);

      url += `&filters[date_transfert][$eq]=${formattedDate}`;
    }
  
    this.http
      .get(url,{params})
      .toPromise()

      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        this.handleApiError(error);
        throw error;      });
  });
  return promise; 
}

}
