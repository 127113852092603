import { InputDemoComponent } from './../../demo/view/inputdemo.component';
import { FournisseurService } from './../../demo/service/fournisseur.service';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ArticleService } from 'src/app/demo/service/article.service';
import { MessageService } from 'primeng/api';
import { RoundToThreeDecimalsPipe } from 'src/app/round-to-three-decimals.pipe';
interface Variation {
  color;
taille
}
@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss'],
 
})
export class AddArticleComponent implements OnInit {
  @Output() ArticleFromChild = new EventEmitter<string>();
  @Output() EditedArticleFromChild = new EventEmitter<any>();

  @Output() notifyParent = new EventEmitter<any>();
disableArticle
  tvaValue: number;
  fieldsEmpty: boolean = false;
  EXIST: boolean = false;
  loading: boolean = false;
  articleDialog
  Familles
  Collections
  Rayons
  Fournisseurs
  Masques
  Couleurs
  couleurs
  selectedFamille
  selectedCollection
  selectedrayon
  selectedFourniss
  selectedColor=[]
  selectedCells: { row, size, color }[] = [];
  newSelectedCells: { row, size, color }[] = [];
  showIcon:boolean=true
  BlocUpdateShow:boolean=false
  disableSaveButton:boolean=false
  tailles
  ListMasques
  sizes
  idimage
  dimension
  uploadedFiles:any[]=[]
  Natures=['matière première','produit finis']
  UniteStock=['métre','paires','piéces']
  CheckArticle:boolean=false
  existingImageUrl: string;
  ArtilceToEdit
  selectedMasque
  changeTaille:boolean=false
  dropdownChanges = {
    nature: false,
    sous_famille: false,
    collection: false,
    rayon: false,
    unite_de_stock: false,
    fournisseur: false,
    tailles:false,
    couleurs:false
  };
  inputsChanges={
    code_article_generique:false,
    code_fournisseur:false,
    designation:false,
    prix_achat_ht:false,
    prix_vente_ht:false
  }
  oldArticle
  oldTailles
  oldCoulerus
  oldSelectedCells
  idArticleToEdit
   isChangedDropdown:boolean=false
  isChangedInputs:boolean=false
  isSelectedCellsChanged :boolean=false

  article: {
    id?
    code_article_generique,
    code_fournisseur,
    designation,
    unite_de_stock,
    fournisseur,
    nature,
    sous_famille,
    collection,
    rayon,
    prix_achat_ht,
    prix_vente_ht,
    prix_achat_ttc,
    prix_vente_ttc,
    image,
    variations:Variation[]
  } = {
    id:null,
    code_article_generique: '',
    code_fournisseur: '',
    designation: '',
    image:'',
    sous_famille: '',
    collection: '',
    rayon: '',
    fournisseur: '',
    nature:'produit finis',
    unite_de_stock:'paires',
    prix_achat_ttc:'',
    prix_vente_ttc:'',
    prix_achat_ht:'',
    prix_vente_ht:'',
    variations:[]
  }; 

  constructor(private articleService:ArticleService,private fournissService: FournisseurService,
     private messageService: MessageService,private Roundpipe:RoundToThreeDecimalsPipe,private cdr: ChangeDetectorRef) { 

  }
  test = [
    { id: 'Q35', Marron: '', Jaune: '' },
  ];
  // cols=[
  //   { field: 'code_article_generique', header: "Code article" },
  //   { field: 'code_fournisseur', header: 'Code fournisseur ' },
  // ];
  ngOnInit(): void {
    this.disableSaveButton=false
    this.disableArticle=false

    this.getSousFamille()
    this.getCollection()
    this.getFournisseurs()
    this.getRayons()
    this.getMasques()
    this.getCouleurs()
    this.getConfig()

    // this.articleService.getTVA().subscribe(
    //   (response) => {
    //     this.tvaValue = response.data.attributes.tva;
    //   },
    //   (error) => {
    //     console.error('Error fetching TVA value', error);
    //   }
    // );

  }

  updateValuesAchat() {

    this.article.prix_achat_ttc =  this.Roundpipe.transform(this.article.prix_achat_ht * (1 + this.tvaValue / 100));
// this.article.prix_achat_ttc = parseFloat((this.article.prix_achat_ht * (1 + this.tvaValue / 100)).toFixed(3));

  }
  updateValuesVente(event) {
    console.log(event)
    this.article.prix_vente_ttc = this.Roundpipe.transform(event * (1 + this.tvaValue / 100));
  }
  showDialog(data?){
    this.disableArticle=false

    this.disableSaveButton=false
    this.articleDialog=true
          console.log("list of masques & tailles",this.ListMasques)
          console.log("tailles",this.tailles)

          console.log("couleurs",this.couleurs)


    if(data){
      console.log("article to edit ",data)
      this.disableArticle=true
      this.ArtilceToEdit=data
      this.BlocUpdateShow=true
      this.idArticleToEdit=data.id
      this.article.id=data.id
      this.article.code_article_generique=data.code_article_generique
      this.article.code_fournisseur=data.code_fournisseur
      this.article.designation=data.designation
      this.idimage=data.image.id
      this.existingImageUrl = data.image.name;
      // this.article.sous_famille=data.sous_famille
      // this.article.collection=data.collection
      // this.article.rayon=data.rayon
      // this.article.fournisseur=data.fournisseur
      // Set this.article.sous_famille to null if data.sous_famille is undefined or has missing properties
      if (data.sous_famille && data.sous_famille.id !== undefined && data.sous_famille.name !== undefined) {
        this.article.sous_famille = data.sous_famille;
      } else {
        this.article.sous_famille = null;
      }

      // Set this.article.collection to null if data.collection is undefined or has missing properties
      if (data.collection && data.collection.id !== undefined && data.collection.name !== undefined) {
        this.article.collection = data.collection;
      } else {
        this.article.collection = null;
      }

      // Set this.article.rayon to null if data.rayon is undefined or has missing properties
      if (data.rayon && data.rayon.id !== undefined && data.rayon.name !== undefined) {
        this.article.rayon = data.rayon;
      } else {
        this.article.rayon = null;
      }

      if (data.fournisseur && data.fournisseur.id && data.fournisseur.name) {
        this.article.fournisseur = data.fournisseur;
      } else {
        this.article.fournisseur = null;
      }
      
      this.article.nature=data.nature
      this.article.unite_de_stock=data.Unite_de_stock
      this.article.prix_achat_ht=data.prix_achat_ht
      this.article.prix_vente_ht=data.prix_vente_ht
      this.article.prix_achat_ttc=data.prix_achat_ttc
      this.article.prix_vente_ttc=data.prix_vente_ttc
      this.article.variations=data.variations
      const sizesarray=this.article.variations?.map(variation => ({name:variation.taille.name,id:variation.taille.id}))
      const Colorsarray=this.article.variations?.map(variation => ({name:variation.color.name,id:variation.color.id}))
      const distinctColors = Array.from(new Set(Colorsarray?.map(color => color.name)))
      .map(name => {
        return Colorsarray.find(color => color.name === name);
      });


      // this.couleurs = Array.from(new Set( this.article.variations.map(variation => variation.color.name)));
      // this.sizes = Array.from(new Set( this.article.variations.map(variation => ({name:variation.taille.name,id:variation.taille.id}))));
      const matchedMasques = this.ListMasques.find(masque => {
        const masqueTailleIds = masque.tailles.map(taille => taille.id);
        const masqueTailleNames = masque.tailles.map(taille => taille.name);
      
        return sizesarray?.some(variation => {
          return masqueTailleIds.includes(variation.id) && masqueTailleNames.includes(variation.name);
        });
      });
      
      this.selectedMasque = matchedMasques?.name;
      console.log("name masque",this.selectedMasque )

      console.log('Matched Masques:', matchedMasques);
      this.tailles=matchedMasques
      // console.log( this.article.variations)
      this.getSizes()
      this.generateCustomLabelTaille()
      console.log( this.generateCustomLabelTaille())
         console.log('Matched Colors:', distinctColors);
      this.couleurs=distinctColors
      this.generateCustomLabel()
      this.selectedCells=this.article.variations.map(item=>({
        row:item.color,
        size:item.taille,
        color:item.color.name
      }))
      console.log( "selected cells",this.selectedCells)
         console.log(this.article)
         this.oldArticle= JSON.parse(JSON.stringify(this.article))
         this.oldTailles= JSON.parse(JSON.stringify(this.tailles ?? []))
         this.oldCoulerus= JSON.parse(JSON.stringify(this.couleurs ?? []))
         this.oldSelectedCells= JSON.parse(JSON.stringify( this.selectedCells ?? []))


         console.log(`old article`, this.oldArticle);
         console.log(`old selected cells`, this.oldSelectedCells);


    }
  }
  hideDialog() {
    this.articleDialog=false
    this.article={
      id:null,
      code_article_generique: '',
      code_fournisseur: '',
      designation: '',
      image:'',
      sous_famille: '',
      collection: '',
      rayon: '',
      fournisseur: '',
      nature:'produit finis',
      unite_de_stock:'paires',
      prix_achat_ttc:'',
      prix_vente_ttc:'',
      prix_achat_ht:'',
      prix_vente_ht:'',
      variations:[]
    }; 
    this.selectedFourniss=''
    this.selectedCollection=''
    this.selectedrayon=''
    this.existingImageUrl=''
    this.idimage=null
    this.couleurs=[]
    this.tailles=''
    this.sizes=[]
    this.uploadedFiles=[]
    this.selectedColor=[]
    this.selectedCells=[]
    this.newSelectedCells=[]

    this.ArtilceToEdit=""
    this.idArticleToEdit=""
    this.isSelectedCellsChanged=false
    this.isChangedInputs=false
    this.isChangedDropdown=false
    this.fieldsEmpty=false
    this.EXIST=false
    this.disableArticle=false
  }

// *****************************************image****************************************************
  onUpload(event) {
    this.idimage=event.originalEvent.body[0].id
    console.log(this.idimage)
    console.log("event",event.originalEvent.body[0].id)
    console.log(event.files)

    for (const file of event.files) {
        this.uploadedFiles.push(file);
        console.log("file",file);
        console.log("uploadedfiles",this.uploadedFiles);

    }
   
}
// *****************************************get methods****************************************************

  getSizes(){
    if(this.ArtilceToEdit){
      
      if(this.changeTaille){
        this.sizes=this.tailles?.tailles?.data.map(res=>{return{ name: res.attributes.name, id: res.id }})

        console.log(this.tailles)  
      console.log(this.sizes)
      }
      else{
        this.sizes=this.tailles?.tailles?.map(res=>{return{ name: res.name, id: res.id }})
      console.log(this.tailles)
      console.log(this.sizes)
      console.log(this.changeTaille)
      }

    }
    else{
      this.sizes=this.tailles?.tailles?.data.map(res=>{return{ name: res.attributes.name, id: res.id }})

      console.log(this.tailles)
      this.selectedCells=[]
    console.log(this.sizes)
    }
      
    
  }
  generateCustomLabel() {
    // console.log(this.couleurs)
    if(this.couleurs){
      const selectedOptions = this.couleurs?.map(option => option.name).join(', ');
      // console.log(selectedOptions)
      return selectedOptions || 'Couleur';
    }
    
  }
  generateCustomLabelTaille() {
    // console.log(this.tailles)
    if (this.tailles && this.tailles.name) {
      return this.tailles.name; // Return the name property of the object
    }
    return 'Taille'; // Default label when no tailles are selected
  }
  getConfig(){
    this.articleService.getConfiguration().then(res=> {
      console.log(res)
      this.tvaValue = res.data.attributes.tva;

      this.dimension=res.data.attributes
      .article_dimensionne
      console.log("Dimension",this.dimension)

 
    })
  }
  getSousFamille(){
    this.articleService.getSousFamille().then(res=> {
      console.log(...res.data)
    this.Familles=res.data.map(item=>{return { name: item.attributes?.name, id: item.id };})
    // console.log(...this.Familles)
    })
  }
  getCollection(){
    this.articleService.getCollection().then(res=>{
      console.log(...res.data)
      this.Collections=res.data.map(item=>
        {return { name: item.attributes?.name, id: item.id };}
      )

    })
    // console.log(this.Collections  )
  }
  getFournisseurs(){
    this.fournissService.getFourniss().then(res=> {
      this.Fournisseurs=res.data.map(item=>{return { name: item.attributes?.name, id: item.id };})
    // console.log(this.Fournisseurs)
    })
  }
  getRayons(){
    this.articleService.getRayons().then(res=> {
      console.log(...res.data)
    this.Rayons=res.data.map(item=>{return { name: item.attributes?.name, id: item.id };})
    // console.log(...this.Rayons)
    })
  }
  getMasques(){
    this.articleService.getMasques().then(res=> {
      console.log(...res.data)
    this.Masques=res.data.map(item=>{return { name: item.attributes?.name, id: item.id, tailles:item.attributes?.tailles_masques };})
    console.log(...this.Masques)
    this.ListMasques=this.Masques.map(res=>({
      name:res.name,
      tailles:res.tailles.data?.map(item=>({id:item.id,name:item.attributes.name}))
    }))
    })
  }
  getCouleurs(){
    this.articleService.getCouleurs().then(res=> {
      // console.log(...res.data)
    this.Couleurs=res.data?.map(item=>{return { name: item.attributes?.name, id: item.id };})
    // console.log(...this.Couleurs)
    })
  }
  // *****************************************select cells****************************************************

  toggleCellSelection(row: any, size) {
    if(this.idArticleToEdit){
      console.log("control selected cells in update article")
      console.log("color and size of cell",row,size)

      // Check if the cell is already selected
  const isCellSelected = this.oldSelectedCells.some(cell => cell.row.id === row.id && cell.size.id === size.id);
console.log(isCellSelected) 
 // If the cell is already selected, return without making changes
  if (isCellSelected) {
  }
  else{

    
    const selectedCell = this.selectedCells.find(cell => cell.color === row.name && cell.size === size);
    console.log(row,size,selectedCell)
      if (selectedCell) {
        this.selectedCells = this.selectedCells.filter(cell => !(cell.color === row.name && cell.size === size));
        this.newSelectedCells = this.newSelectedCells.filter(cell => !(cell.color === row.name && cell.size === size));

      } else {
        this.newSelectedCells.push({ row, size, color: row.name });
        this.selectedCells.push({ row, size, color: row.name });
    
            console.log("selected cells", this.selectedCells)
            console.log("new selected cells", this.newSelectedCells)
      }
      
      }
    //return if new cell is selected
  this.isSelectedCellsChanged=this.selectedCells.some(selectedCell =>
    !this.oldSelectedCells.some(oldCell =>
      oldCell.color === selectedCell.color && oldCell.size.name === selectedCell.size.name
    )
  );
  console.log("is selected cells changed", this.isSelectedCellsChanged)

  // return this.isSelectedCellsChanged 
    }
    else{
      console.log("control selected cells in add article")

      const selectedCell = this.selectedCells.find(cell => cell.color === row.name && cell.size === size);
      console.log(row,size,selectedCell)
        if (selectedCell) {
          this.selectedCells = this.selectedCells.filter(cell => !(cell.color === row.name && cell.size === size));
        } else {
          this.selectedCells.push({ row, size, color: row.name });
          // this.newSelectedCells.push({ row, size, color: row.name });
        }
        console.log(row,size, this.selectedCells)
        console.log("selected cells", this.selectedCells)
    }
  
  }

  isSelectedCell(color, size): boolean {

    return this.selectedCells.some(cell => cell.color === color && cell.size === size);
  }
  isCellSelected(colorName: string, taille: any): boolean {
      
    return this.selectedCells.some(cell => cell.color === colorName && cell.size.name === taille.name);
  }
  
  // *****************************************save article****************************************************
  checkEmptyProperties() {
    for (const property in this.article) {
      if (this.article.hasOwnProperty(property) && this.isRequiredProperty(property) && !this.article[property]) {
        this.showMessage('error', 'remplissez tous les champs obligatoires!');
        this.fieldsEmpty = true;
        return true;
      }
    }
    return false;
  }
  
  isRequiredProperty(property: string) {
    const excludedProperties = ['id', 'image', 'prix_vente_ttc', 'prix_achat_ttc', 'code_fournisseur', 'designation'];
    return !excludedProperties.includes(property);
  }
  
  checkDimension() {
    if (this.dimension && this.selectedCells.length === 0) {
      this.showMessage('error', 'dimension est obligatoire .');
      this.fieldsEmpty = true;
      return true;
    }
    return false;
  }
  
  showMessage(severity: string, detail: string) {
    this.messageService.add({
      severity,
      summary: '',
      detail,
      life: 2500,
    });
  }
  
  handleSaveError(res: any) {
    const error_message = "Product with the same code_article_generique already exists.";
  
    if (res.error || res.error === error_message) {
      this.showMessage('error', 'Article deja existe!');
      this.EXIST = true;
      this.disableSaveButton = false;
      // this.restoreOldValues();
    }
  }
  
   saveArticle() {
    this.disableSaveButton=true
    let hasEmptyProperty = false;
    this.fieldsEmpty=false

      for (const property in this.article) {
        if (this.article.hasOwnProperty(property) && property !== 'id' && property !== 'image' && property !== 'prix_vente_ttc' && property !== 'prix_achat_ttc' && property!=='code_fournisseur' && property!=='designation') {
          if (!this.article[property] ) {
           
            this.messageService.add({
              severity: 'error',
              summary: '',
              detail: `remplissez tous les champs obligatoires!`,
              life: 2500,
            });
            hasEmptyProperty = true;
            this.fieldsEmpty=true
            this.disableSaveButton=false
            break;
          }

        }
      }
    //   if (!hasEmptyProperty) {

    // }
    if (!hasEmptyProperty && this.dimension) {
      if (this.selectedCells.length === 0) {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'dimension est obligatoire .',
          life: 2500,
        });
        hasEmptyProperty = true;
        this.fieldsEmpty=true
        this.disableSaveButton=false
         
      }
    }
    
      if (!hasEmptyProperty) {
       
        this.fieldsEmpty=false
         

       
    console.log("prix_achat_ht",this.article.prix_achat_ht,"prix_vente_ht",this.article.prix_vente_ht);

    this.selectedColor = this.couleurs?.map(item => item.name);
    console.log("article", this.article, "couleur", this.couleurs, "tailles", this.tailles,
    "prix_achat_ht",this.article.prix_achat_ht,"prix_vente_ht",this.article.prix_vente_ht);
  this.selectedFamille=this.article.sous_famille.id
  this.article.sous_famille=this.selectedFamille
  this.selectedCollection=this.article.collection.id
  this.article.collection=this.selectedCollection
  this.article.image=this.idimage
  this.selectedrayon=this.article.rayon.id
  this.article.rayon=this.selectedrayon
  this.selectedFourniss=this.article.fournisseur.id
  this.article.fournisseur=this.selectedFourniss
    for (let selected of this.selectedCells) {
      this.article.variations.push({
        color: selected.row.id,
        taille:selected.size.id // Assuming selected.row.name holds the color name
      });
    }
  
    console.log(this.article);
    // this.articleService.saveArticles({"data":this.article}).then(res=>console.log(res))
    var error_message = "Product with the same code_article_generique already exists.";
    
 try {
          this.loading = true
      this.articleService.saveArticles(this.article).then(res=>{

      console.log(res)
      if(res.product && res.productVariations){
        
        this.disableSaveButton=false
        this.hideDialog()
        this.EXIST=false
        this.ArticleFromChild.emit(res)
        this.notifyParent.emit(1)
      }
      else{
        if(res.error || res.error===error_message) {
          const savedCollection = this.selectedCollection;
          const savedFamille = this.article.sous_famille;
          const savedRayon = this.article.rayon;
          const savedFourniss = this.article.fournisseur;

          console.log("collection",savedCollection, "famille",savedFamille,"rayon", savedRayon);

          // Display error message
          this.messageService.add({
              severity: 'error',
              summary: '',
              detail: 'Article deja existe!',
              life: 3000
          });
          this.EXIST=true
          this.disableSaveButton=false

          // Restore the old values
        const resCollection =this.Collections.find(coll=> coll.id==savedCollection)
        console.log(resCollection )
        this.article.collection=resCollection

        const resFamille =this.Familles.find(coll=> coll.id==savedFamille)
        this.article.sous_famille=resFamille

        const resRayon =this.Rayons.find(coll=> coll.id==savedRayon)
        this.article.rayon=resRayon

        const resFourniss =this.Fournisseurs.find(coll=> coll.id==savedFourniss)
        this.article.fournisseur=resFourniss
             }


      }
     
    })
  } catch (error) {
    console.error('Error CREATING ARTICLE:', error);
  } finally {
    this.loading = false; // Set loading to false after receiving the response or in case of an error
  } 

      }
   
   
  }
  // *****************************************detect changes****************************************************
  onKeyPress(event: KeyboardEvent) {
    // Get the pressed key
    const key = event.key;
  
    // Allow numeric values, decimal point, and comma
    const isNumeric = /^[0-9.,]*$/.test(key);
  
    // Prevent negative values
    if (key === '-' && this.article.prix_achat_ht === undefined) {
      event.preventDefault();
    }
  
    // Prevent other characters if not allowed
    if (!isNumeric) {
      event.preventDefault();
    }
  }
  onInputChange(inputField: string,event: any){
    this.EXIST=false
   if(this.ArtilceToEdit)
   { let changedInputName: string | null = null; 
    const newValue = event.target.value;
    console.log('Input value changed:', newValue);
    switch (inputField) {

      case 'code_article_generique':
        this.inputsChanges.code_article_generique  =newValue !== this.oldArticle.code_article_generique;
        changedInputName = this.inputsChanges.code_article_generique ? 'code_article_generique' : null;

        break;
      case 'code_fournisseur':
        this.inputsChanges.code_fournisseur=  newValue !== this.oldArticle.code_fournisseur;
        changedInputName = this.inputsChanges.code_fournisseur ? 'code_fournisseur' : null;

        break;
      case 'designation':
        this.inputsChanges.designation= newValue !== this.oldArticle.designation;
        changedInputName = this.inputsChanges.designation ? 'designation' : null;

        break;
        case 'prix_achat_ht':
          this.inputsChanges.designation= newValue !== this.oldArticle.prix_achat_ht;
          changedInputName = this.inputsChanges.designation ? 'prix_achat_ht' : null;
  
          break;
          case 'prix_vente_ht':
            this.inputsChanges.designation= newValue !== this.oldArticle.prix_vente_ht;
            changedInputName = this.inputsChanges.designation ? 'prix_vente_ht' : null;
    
            break;
      // default:
      //   return false;
  }
  const isAnyValueChanged = Object.values(this.inputsChanges).some(value => value);
  console.log('Are there any changes?','Changed input:'+ changedInputName, isAnyValueChanged);
this.isChangedInputs=isAnyValueChanged
console.log(this.inputsChanges)
  return ('Changed input:'+ changedInputName) && isAnyValueChanged;
 }
 else
 {
  this.EXIST=false
  return ('Changed input:'+ event.target.value) }
}
 async selectChanges(event, dropdownType: string){

console.log("couleurs",this.couleurs)
// if(dropdownType=='fournisseur'){
  // await this.fournissService.getFournisseurById(event.value.id).then(data=>{
  //   this.article.code_fournisseur=data.data.attributes.code
  // })
// }

    if(this.ArtilceToEdit){
      console.log(`Dropdown (${dropdownType}) selection changed:`, event.value);
    const newValue = event.value;
    console.log(`old article`, this.oldArticle);
    console.log(`old tailles`, this.oldTailles);
    console.log(`old Couleurs`, this.oldCoulerus);
    console.log(`old selected cells`, this.oldSelectedCells,'new slelected cells',this.selectedCells,'article variation',this.article.variations);




    switch (dropdownType) {

      case 'nature':
        this.dropdownChanges.nature = newValue !== this.oldArticle.nature;
        break;
      case 'unite_de_stock':
        this.dropdownChanges.unite_de_stock = newValue !== this.oldArticle.Unite_de_stock;
        break;
      case 'fournisseur':
        this.dropdownChanges.fournisseur = newValue.name !== this.oldArticle.fournisseur?.name;
      
        //this.article.code_fournisseur = newValue.name !== this.oldArticle.fournisseur.name;
        break;
      case 'sous_famille':
        this.dropdownChanges.sous_famille= newValue.name !== this.oldArticle.sous_famille?.name;
        break;
      case 'collection':
        this.dropdownChanges.collection = newValue.name!== this.oldArticle.collection?.name;
        break;
      case 'rayon':
        this.dropdownChanges.rayon = newValue.name !== this.oldArticle.rayon?.name;
        break;
        case 'couleurs':
        this.dropdownChanges.couleurs = newValue.name !== this.oldCoulerus.some(oldColor => newValue.name !== oldColor.name);
        // if (this.dropdownChanges.couleurs) {
         
        // }
        break;
        case 'tailles':
        this.dropdownChanges.tailles = newValue.name !== this.oldTailles.name;

        console.log('Tailles changed:', this.dropdownChanges.tailles);
        console.log('newValue.name:', newValue.name);
        console.log('this.oldTailles.name:', this.oldTailles.name);

        if (this.dropdownChanges.tailles) {
          this.changeTaille=true
          console.log('masque taille changed')


          this.selectedCells = [];
          // this.oldSelectedCells=[]
          console.log('old selected cells:', this.oldSelectedCells);
          console.log('slected cells:', this.selectedCells);

        }
        break;
       
      }
      const changedDropdowns = Object.keys(this.dropdownChanges).filter(dropdown => this.dropdownChanges[dropdown]);
      console.log('Changed dropdowns:', changedDropdowns);
    
      const atLeastOneValueChanged = changedDropdowns.length > 0;
      console.log('At least one value changed:', atLeastOneValueChanged);
      this.isChangedDropdown=atLeastOneValueChanged
        console.log('selected cells:', this.selectedCells);
        console.log(this.dropdownChanges)

    }
      else{
        console.log(`Dropdown (${dropdownType}) selection changed:`, event.value);

          }
  }
   SelectedCellsChanged(): boolean {
    this.isSelectedCellsChanged=this.selectedCells.some(selectedCell =>
      !this.oldSelectedCells.some(oldCell =>
        oldCell.color === selectedCell.color && oldCell.size.name === selectedCell.size.name
      )
    );
    return this.isSelectedCellsChanged 
  }
  // *****************************************edit article****************************************************
  
  async EditArtilce(){
    this.fieldsEmpty=false
    this.EXIST=false

      // this.disableSaveButton=true;

      let hasEmptyProperty = false;
      for (const property in this.article) {
        if (this.article.hasOwnProperty(property) && property !== 'id' && property !== 'image' && property !== 'prix_vente_ttc' && property !== 'prix_achat_ttc' && property!=='code_fournisseur' && property!=='designation') {
          if (!this.article[property] ) {
           
            this.messageService.add({
              severity: 'error',
              summary: '',
              detail: `remplissez tous les champs obligatoires!`,
              life: 2500,
            });
            this.disableSaveButton=false

            hasEmptyProperty = true;
            this.fieldsEmpty=true

            break;
          }

        }
      }
     
    
    if (!hasEmptyProperty && this.dimension) {
      if (this.selectedCells.length === 0) {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: 'dimension est obligatoire .',
          life: 2500,
        });
        hasEmptyProperty = true;
        this.fieldsEmpty=true
         this.disableSaveButton=false
         
      }
    }
    
      if (!hasEmptyProperty) {
        this.fieldsEmpty=false
        this.EXIST=false
        this.disableSaveButton=false

    console.log('Are there any changes on selected cells?', this.isSelectedCellsChanged);
    console.log("dropdown change",this.isChangedDropdown)
    console.log("input change",this.isChangedInputs)
    const hasTrueValue = Object.values(this.dropdownChanges).some(value => value) || Object.values(this.inputsChanges).some(value => value);

    if (this.isChangedDropdown || this.isChangedInputs || this.isSelectedCellsChanged) {
      this.fieldsEmpty=false
      this.disableSaveButton=true


console.log("prix_achat_ht",this.article.prix_achat_ht,"prix_vente_ht",this.article.prix_vente_ht);

    this.selectedColor = this.couleurs.map(item => item.name);
    console.log("article", this.article, "couleur", this.couleurs, "tailles", this.tailles,
    "prix_achat_ht",this.article.prix_achat_ht,"prix_vente_ht",this.article.prix_vente_ht);
  this.selectedFamille=this.article.sous_famille.id
  this.article.sous_famille=this.selectedFamille
  this.selectedCollection=this.article.collection.id
  this.article.collection=this.selectedCollection
  // this.article.image=this.idimage
  this.selectedrayon=this.article.rayon.id
  this.article.rayon=this.selectedrayon
  this.selectedFourniss=this.article.fournisseur.id
  this.article.fournisseur=this.selectedFourniss

    console.log(this.article);
    const { image,variations, ...editedArticle } = this.article;
    console.log("article exluding image",editedArticle);
    const modifArticle={article:editedArticle,variations:this.newSelectedCells}
    console.log(modifArticle);
    // try {
    //   this.loading = true;
    await this.articleService.editProducts(modifArticle).then(res=>{

      console.log('response updated product',res)
      var error_message = "Product with the same code_article_generique already exists.";

      if(res.product){
        this.disableSaveButton=true
    
         this.articleService.getArticle(this.idArticleToEdit).then(res=>{
          console.log("get updated article",res)
             
    
              
                this.EditedArticleFromChild.emit(res)
                this.notifyParent.emit(2)
      
                this.hideDialog()
            
    
    })
  }
      else{
        this.disableSaveButton=false

        this.fieldsEmpty=false

        if(res.error || res.error===error_message) {
          this.fieldsEmpty=false
          this.EXIST=false

          const savedCollection = this.selectedCollection;
          const savedFamille = this.article.sous_famille;
          const savedRayon = this.article.rayon;
          const savedFourniss = this.article.fournisseur;

          
          // Restore the old values
        const resCollection =this.Collections.find(coll=> coll.id==savedCollection)
        console.log(resCollection )
        this.article.collection=resCollection

        const resFamille =this.Familles.find(coll=> coll.id==savedFamille)
        this.article.sous_famille=resFamille

        const resRayon =this.Rayons.find(coll=> coll.id==savedRayon)
        this.article.rayon=resRayon

        const resFourniss =this.Fournisseurs.find(coll=> coll.id==savedFourniss)
        this.article.fournisseur=resFourniss

          console.log("collection",savedCollection, "famille",savedFamille,"rayon", savedRayon);

          // Display error message
          this.messageService.add({
              severity: 'error',
              summary: '',
              detail: 'Article avec le meme code article deja existe!',
              life: 3000
          });
          this.EXIST=true

            }
      }


    })
 
  // } catch (error) {
  //   console.error('Error in the update:', error);
  // } finally {
  //   this.loading = false; // Set loading to false after receiving the response or in case of an error
  // } 
  
    }
    else{
      this.disableSaveButton=false
      this.fieldsEmpty=false
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Au moins une valeur doit etre changer.',
        life: 2500,
      });
    }
  }
  }
}