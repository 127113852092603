import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ParametreService } from 'src/app/demo/service/parametre.service';

@Component({
  selector: 'app-add-masque',
  templateUrl: './add-masque.component.html',
  styleUrls: ['./add-masque.component.scss']
})
export class AddMasqueComponent implements OnInit {
  masqueDialog
  tailles
  selectedTailles
  nomMasque
  masques
  indexMasque
  masqueToEdit:boolean=false
  masqueTomodify
  masqueToAdd:boolean=false
  clonedMasque: { [s: string]: any } = {};
  addCellTaille:boolean=false
  Edit:boolean=true
  NewTailleMasque
  disableButton:boolean
  lengthTailles:number
  constructor(private parameterService:ParametreService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getTaille()
  }
  async showDialog(data?){
    this.masqueDialog=true
    // if(data){
    //   this.masques=data
    //   console.log("masques",this.masques)
    // }
    if(data.masque || data.indexMasque){
      this.masqueToEdit=true
      this.masqueTomodify=data.masque
      this.lengthTailles=data.masque.tailles.length
      this.masques=data.masques
      this.indexMasque=data.indexMasque
      this.selectedTailles=this.masqueTomodify.tailles
      this.disableButton=true

      console.log(this.masqueTomodify)
      console.log("taille de masque a modifier",this.selectedTailles)
      console.log("length selected tailles ",this.lengthTailles)
      console.log("all the masques",this.masques)
   
    }
    else{
      this.masqueToAdd=true
           this.masques=data
      console.log("masques",this.masques)
    }

  }
  hide(){
 
    this.masqueDialog=false
    this.masqueToAdd=false
    this.nomMasque=""
    this.masqueToEdit=false
    this.addCellTaille=false
    this.Edit=true
    this.selectedTailles=[]
    this.indexMasque = null;
    this.disableButton=false


  }
  hideDialog() {
    this.masqueDialog=false
    this.masqueToAdd=false
    this.nomMasque=""
    this.masqueToEdit=false
    this.addCellTaille=false
    this.Edit=true
    this.selectedTailles=[]
    this.indexMasque = null;
    this.disableButton=false

  
}
async getTaille(){
  await this.parameterService.getFromStrapi('tailles',1,25).then(res=>{
    // console.log('Sous Famille',res)

      this.tailles=res.map(item=>({id:item.id,name:item.attributes.name}))

  })
  console.log('tailles',this.tailles)
}
saveMasque(){
  // console.log(this.selectedTailles)
    if(this.nomMasque && this.selectedTailles){
      let item=[]

      for(let size of this.selectedTailles){
        item.push({id:size.id})
      }
      console.log(item)
      const masque={data:{name:this.nomMasque,tailles_masques:item}}
      this.parameterService.postStrapi('masque-tailles',masque,['tailles_masques']).then(res=>{
        console.log("nouveau masque",res)
        const masque={
          id:res.data.id,
          name:res.data.attributes.name,
          tailles:res.data.attributes.tailles_masques.data.map(item=>({id:item.id,name:item.attributes.name})),
          selected:false}
      this.masques.unshift(masque)  
      })
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Masque Créer avec Succé',
        life: 3000
      });
      this.hideDialog()
      

    }
    else{
      // if(!this.newCouleur || !this.newCodeCouleur){
      //   this.fieldsEmpty=true
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Erreur de creation',
          life: 3000
        });
      }
   
  
    }

    onEditTaille(){
      this.addCellTaille=true
      // this.Edit=false
    }
  
    AddTailleToMasque(Masque){
      console.log(Masque)
      console.log(this.selectedTailles)
      let tailles=[]
      if(this.selectedTailles && this.selectedTailles.length>0){
       tailles=this.selectedTailles.map(item=>({id:item.id}))
       console.log(tailles)
        const masque={data:{name:Masque.name,tailles_masques:tailles}}
        this.parameterService.updateWithId('masque-tailles',Masque.id,masque,['tailles_masques']).then(res=>{
          console.log("modification de masque",res)
          const novMasque=
          {id:res.data.id,
            name:res.data.attributes.name,
            tailles:res.data.attributes.tailles_masques.data.map(item=>({id:item.id,name:item.attributes.name})),
            selected:false}
          console.log(novMasque)
          // if (this.indexMasque !== null && this.indexMasque >= 0 && this.indexMasque < this.masques.length) {
          //   this.masques.splice(this.indexMasque, 1);
          // }
        
          // this.masques.unshift(novMasque)  

    // Find the index of the old masque in the array
          const oldMasqueIndex = this.masques.findIndex(m => m.id === Masque.id);

          // If the old masque is found, replace it; otherwise, add the new masque to the beginning of the array
          if (oldMasqueIndex !== -1) {
            this.masques.splice(oldMasqueIndex, 1);
            this.masques.unshift(novMasque);
          } 
        })
        this.addCellTaille=false

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Masque Modifier avec Succé',
          life: 3000
        });
        this.hideDialog()
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Erreur de creation',
          life: 3000
        });
    
      }
    }

    changesTailles(event){
    if(this.selectedTailles.length!==this.lengthTailles){
    this.disableButton=false
    }  
  }
  }
