import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Output() dataEvent = new EventEmitter<any>();

  ConfirmDialog:boolean=false
  constructor() { }

  ngOnInit(): void {
  }

  showDialog(data?:any) {
this.ConfirmDialog=true
    }

  hideDialog() {
    this.dataEvent.emit(0)
    this.ConfirmDialog=false

  }

  confirm(){
    this.dataEvent.emit(1)
    this.ConfirmDialog=false

}


// showDialog(data?:any) {
//   console.log(data)
// this.ConfirmDialog=true
// if (data=='Brouillon'){
// this.status=data
// }
// if(data=='fini'){
// this.status=data
// }
//   }

// hideDialog() {
//   this.dataEvent.emit({confirm:0,status:this.status})
//   this.ConfirmDialog=false

// }

// confirm(){
//   this.dataEvent.emit({confirm:1,status:this.status})
//   this.ConfirmDialog=false

// }
}
