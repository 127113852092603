import { Component, Input,SimpleChanges, OnInit } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { map } from 'rxjs/operators';
import { UploadService } from 'src/app/demo/service/upload.service';

@Component({
  selector: 'app-import-product',
  templateUrl: './import-product.component.html',
  styleUrls: ['./import-product.component.scss'],
  styles: [`
  ::ng-deep .p-tabview .p-tabview-nav {
  justify-content: center;
  
}

.ui-tabview-title{       
      color:blue !important; 
}
/* .card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
} */
.custom-button {
  width: 100px;
  height: 30px;
}
.icon{
 
        font-size: 2em;
        color: #ffe600;
        position: relative;
        left: -6%;
    
}
.container {
    display: flex;
    justify-content: center;
}

.flex-container {
    display: flex;
}

.item {
    margin: 0 10px; /* Add margin between items if needed */
    width: 48%;
}

.centered-table {
  margin: auto;
}




  `]
})
export class ImportProductComponent implements OnInit {
  @Input() receiveImportedData;
  ImportDialog:boolean=false
  ProduitsEchoues
  VariationsEchouees
  NouvVariations
  ProdEchoues
  ProdEchouesVariations
  ProdNovVariations
  NouveauProduit
  NbNouveauProduits
  NbFauxProduits
  MergedArray
  testArray
  expandedRows:boolean[] = [];
  isExpanded
  currentPage: number=1;
  pageSize: number;
  totalItems: number;
  pageNumbers: number[];
  currentpage
  cols = [
    { field: 'reference', header: "reference" },
    { field: 'code_article_generique', header: 'code_article_generique' },
    {field: 'fournisseur', header: 'fournisseur'},
    { field: 'prix_vente', header: 'prix vente' },
    
    
  ];
  colsVariations = [
    { field: 'code_article_generique', header: 'code_article_generique' },
    { field: 'code_barre', header: "code_barre" },
    { field: 'color', header: 'couleur' },
    {field: 'taille', header: 'taille'},
    {field: 'Statut', header: 'Statut'},

    
    
  ];
  
  constructor(private uploadService:UploadService) { 
   this.ProdEchoues=[]
   
  }

 async  ngOnInit(){
    console.log("test")
    
    console.log(this.receiveImportedData)

  }
  exportToCsv() {
    // const csv = Papa.unparse();
    // const blob = new Blob([csv], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'data.csv';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // window.URL.revokeObjectURL(url);
  }
  export() {
    // Assuming your data is in a property called 'myData'
    const data = this.ProduitsEchoues; 

    // Fetch the mapping configuration
    this.uploadService.getConfig().subscribe(mappingConfig => {
      const csvContent = this.convertToCSV(data, mappingConfig);
      this.downloadCSV(csvContent);
    });
  }
  convertToCSV(data: any[], mappingConfig: any): string {
    const csvHeaders = Object.keys(mappingConfig.csvHeaders);
  
    const csvRows = data.map(item => {
      const row = csvHeaders.map(header => {
        switch (header) {
          case 'REFERENCE':
            return item.product.reference;
          case 'CODE_ARTICLE_GENERIQUE':
            return item.product.code_article_generique;
          // Add cases for other headers as needed
          default:
            return ''; // Handle unknown headers if necessary
        }
      });
      return row.join(',');
    });
  
    return `${csvHeaders.join(',')}\n${csvRows.join('\n')}`;
  }
  
  downloadCSV(content: string) {
    const blob = new Blob([content], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'exported_data.csv';
    link.click();
  }

 /* export() {
    this.uploadService.getConfig().subscribe(config => {
      const flattenedData = this.flattenData(this.ProdEchoues, config);
    
      this.uploadService.exportToCsv(flattenedData, config).subscribe(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'exported_data.csv';
        link.click();
      });
    });
  }

  private flattenData(data, config) {
    return data.map(item => {
      const flattenedItem = {};
      for (const csvHeader in config.csvHeaders) {
        const dataProperty = config.csvHeaders[csvHeader];
        flattenedItem[csvHeader] = this.deepGet(item, dataProperty);
      }
      return flattenedItem;
    });
  }

  private deepGet(obj, path) {
    const keys = path.split('.');
    let result = obj;
    for (const key of keys) {
      result = result[key];
    }
    return result;
  }*/
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.receiveImportedData && changes?.receiveImportedData.currentValue) {
      this.receiveImportedData = changes?.receiveImportedData.currentValue;
      console.log(this.receiveImportedData)
    }
    this.NouveauProduit=this.receiveImportedData?.NouveauProduit
    this.ProduitsEchoues=this.receiveImportedData?.variatFalse
    this.NbNouveauProduits=this.receiveImportedData?.NbNouveauProduits
    this.NbFauxProduits=this.receiveImportedData?.NbFauxProduits
    console.log(this.ProduitsEchoues)
    
    console.log("noveaux produits",this.NouveauProduit)
    this.ProdEchoues=this.ProduitsEchoues?.map(x=>
      x.product
    )
    console.log(this.ProdEchoues)

    this.testArray=this.ProduitsEchoues?.map(y=> 
   (  { ...y.product,
      "variation":y.variation
      })
      )
      console.log(this.testArray)

   this.ProdEchouesVariations=this.ProduitsEchoues?.map(x=>
  ( {  ...x.product,
    "variation":[{id:x.variation.id,
    code_article_generique:x.variation.code_article_generique,
    code_barre:x.variation.code_barre,
    product:x.variation.product,
    color:x.variation.color.name,
    taille:x.variation.taille.name,}],
    expanded:false,
  })
  )
  console.log(this.ProdEchouesVariations)
  

  this.ProdNovVariations=this.NouveauProduit?.map(x=>
    ( {  ...x.product,
      "variation":[{id:x.variation.id,
      code_article_generique:x.variation.code_article_generique,
      code_barre:x.variation.code_barre,
      product:x.variation.product,
      color:x.variation.color.name,
      taille:x.variation.taille.name,}],
      expanded:false,
    })
    )
    console.log(this.ProdNovVariations)
    this.VariationsEchouees=this.ProduitsEchoues?.map(x=>({
      id: x.variation.id,
      code_article_generique: x.variation.code_article_generique,
      code_barre: x.variation.code_barre,
      product: x.variation.product,
      color: x.variation.color,
      taille: x.variation.taille,
      // color: x.variation.color.name,
      // taille: x.variation.taille.name,
      Statut:x.statut
    })
    )
  console.log("variations echouées ",this.VariationsEchouees)
  this.NouvVariations=this.NouveauProduit?.map(x=>({
    id: x.variation.id,
    code_article_generique: x.variation.code_article_generique,
    code_barre: x.variation.code_barre,
    product: x.variation.product,
    color: x.variation.color,
    taille: x.variation.taille
    // color: x.variation.color.name,
    // taille: x.variation.taille.name
  })
  )
  console.log("Nouvelles variations  ",this.NouvVariations)

}
  currentExpandedRowIndex: number = -1;

  toggleRow(item,rowIndex) {
    console.log(item)
    console.log(rowIndex)

    this.currentExpandedRowIndex=rowIndex
    item.expanded = !item.expanded;
    
  }
  async showDialog(){
this.ImportDialog=true


  }
 test(data ,prod){
  console.log(data,prod)
 }
//  showvariation(rowdata){
//   console.log(rowdata)

//  }
 /*currentExpandedRowIndex: number = -1;
 toggleRowExpansion(rowIndex: number) {
  console.log(rowIndex)
  // this.expandedRows[rowIndex] = !this.expandedRows[rowIndex];
  // console.log(this.expandedRows[rowIndex] )
  console.log(this.currentExpandedRowIndex )

  if (this.currentExpandedRowIndex === rowIndex) {
    this.currentExpandedRowIndex = -1; // Collapse the currently expanded row
    this.expandedRows[rowIndex] = false;
    } else {
    this.currentExpandedRowIndex = rowIndex; // Expand the clicked row
    this.expandedRows[rowIndex] = true;  
  }
  console.log(this.currentExpandedRowIndex )
  
}*/
async onPageChange(event) {
  /* this.loading=true
   setTimeout(() => {
     // this.getProduct()
           this.loading = false;
           
       }, 5000);
 */

   console.log(event)
   this.currentPage=event.page
   console.log(this.currentPage)
  //  await this.s.sendData(this.currentPage+1)
  //  await this.getProduct()
 
   // this.loading=false
 
     //  this.first = event.first;
     //  this.rows = event.rows;
  }
 
}
