<p-dialog [(visible)]="ConfirmDialog" header="Confirmation" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;color: #EF4444;" ></i>
		<span >Êtes-vous sûr(e) ?</span>
	</div>
	<ng-template pTemplate="footer">
		<div >

		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="Non" (click)="hideDialog ()" style="color: #EF4444;"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Oui" (click)="confirm()"></button>
		</div>
		

	</ng-template>
</p-dialog>