<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Saisie Transfert</h5>
			<br>

      <div class="container" style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 10px; padding-left: 30px;  " >

        <div style="display: flex; flex-direction: column;"  >
          <h6 style="margin-top: 10px;"> Etablissement émetteur  :</h6>
            <h6 style="margin-top: 10px;"> Dépot émetteur  :</h6>
        </div>
        <!-- First Column -->
        <div style="display: flex; flex-direction: column;">
          <p-autoComplete [(ngModel)]="selectedEtablissementEmett" [suggestions]="filtredEtablissements" (completeMethod)="filterEtablissements($event)" placeholder="Etablissement" field="name" [dropdown]="true" (onSelect)="getSelectedEatbEmett()"></p-autoComplete>
      
          <p-autoComplete [(ngModel)]="selectedDepotEmett" [suggestions]="filtredDepot" (completeMethod)="filterDepotsEmett($event)" placeholder="Depot" field="name" [dropdown]="true" style="margin-top: 10px;"  (onSelect)="getSelectedDepotEmett()"></p-autoComplete>
        </div>
        <div style="display: flex; flex-direction: column;">
          <h6 style="margin-top: 10px;"> Etablissement récepteur  :</h6>
            <h6 style="margin-top: 10px;"> Dépot récepteur  :</h6>
        </div>
      
        <!-- Second Column -->
        <div style="display: flex; flex-direction: column;">
          <p-autoComplete [(ngModel)]="selectedEtablissementRecep" [suggestions]="filtredEtablissements" (completeMethod)="filterEtablissements($event)" placeholder="Etablissement" field="name" [dropdown]="true" (onSelect)="getSelectedEatbRecep()" ></p-autoComplete>
      
          <p-autoComplete [(ngModel)]="selectedDepotRecep" [suggestions]="filtredDepot" (completeMethod)="filterDepotsRecep($event)" placeholder="Depot" field="name" [dropdown]="true" style="margin-top: 10px;" (onSelect)="getSelectedDepotRecep()"></p-autoComplete>
        </div>
      
        <!-- Third Column -->
        <div style="display: flex; flex-direction: column;">

          <p-calendar class="drop" [appendTo]="'body'"  [disabled]="true"  [(ngModel)]="date" [showWeek]="true" [showIcon]="true" inputId="icon"></p-calendar>
        <button style="margin-top: 10px;" pButton pRipple type="button" icon="pi pi-plus" label="Ajouter transfert" class="p-button-success mr-1 mb-" (click)="openDialog()"></button>
      </div>
      </div>
      

    <!-- <p-messages ></p-messages> -->
    <p-table [columns]="cols" [value]="Transferts" dataKey="id" rowGroupMode="subheader" responsiveLayout="stack" scrollHeight="flex"  >
                  
            		
                  <ng-template pTemplate="header" let-columns>

            <tr>
         
                <th *ngFor="let col of columns">

              

              <div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
                
                {{ col.header }}

              </div>


              <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
                {{ col.header }}

              </div>
              
              <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
                {{ col.header }}


              </div>
              <div  *ngIf="col.field=='couleur'"class="flex justify-content-between align-items-center">
                {{ col.header }}


              </div>
              <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
                {{ col.header }}


              </div>

<!--               
                          <div  *ngIf="col.field=='prixMontant'"class="flex justify-content-between align-items-center">
                {{ col.header }}

              </div>
                         -->
                          <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
                {{ col.header }}


              </div>

              </th>
            </tr>


            </ng-template>
          


                  <ng-template pTemplate="body" let-rowData  let-columns="columns" let-Transfert let-ri="rowIndex">
            <tr  [pEditableRow]="columns">
<!-- 
              <td style="border-right: 1px solid #ccc; text-align: center;">
                <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
                [icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRow(rowData,ri)" style="font-size: 1em;"></button>

                          </td> -->
              
              <td  *ngFor="let col of columns">
              
                
                <div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
                  
                  {{Transfert.ref}}
                </div>
              
                <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
                  
                {{Transfert.designation }}
              </div>
              <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
                  
                <p-inputNumber [(ngModel)]="Transfert.qte" mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="0" [max]="100"> </p-inputNumber>
              </div>
              <div  *ngIf="col.field=='couleur'"class="flex justify-content-between align-items-center">
                  
                {{Transfert.color }}
              </div>
              <div  *ngIf="col.field=='taille'"class="flex justify-content-between align-items-center">
                  
                {{Transfert.taille }}
              </div>
                
            <!-- <div  *ngIf="col.field=='prixMontant'"class="flex justify-content-between align-items-center">
                  
            {{Achats.remise}}
            </div> -->

            <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">

              <button pButton pRipple type="button" icon="pi pi-trash" 
              class="p-button-danger mr-2 mb-2" style="width: 40px; background-color: #EF4444;"
              (click)="SupprimerTransfert(ri)">
            </button>

              </div>
            

      
              </td>
                      </tr>
            </ng-template>

              </p-table>
              <div style="display: flex; margin-top: 20px; justify-content: flex-end;">
                <button pButton pRipple type="button" label="Enregistrer" class="p-button-success mr-2 mb-" style="font-weight: semi-bold; font-size: 16px;"
                  (click)="AddTransfert()"></button>
              </div>
                  

    </div>
    </div>
    </div>
    <p-toast></p-toast>

<app-add-transfert (NewTransfertToParent)="receiveTransfertFromChild($event)"></app-add-transfert>
<app-confirmation-dialog (dataEvent)="receiveConfirm($event)"></app-confirmation-dialog>
