import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
   configUrl = 'assets/mapping.config.json';
   private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
  getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result as string;
        const rows = content.split('\n').map(row => row.split(';'));
        const headers = rows[0]; // Assuming the first row contains headers
  
        const data = rows.slice(1).map(row => {
          let rowData: any = {};
  
          // Check if the row has at least one non-empty value
          if (row.some(value => value.trim() !== '')) {
            headers.forEach((header, index) => {
              let value = row[index];
  
              rowData[header] = value; // Just assign the value directly without conversion
            });
          }
  
          return rowData;
        }).filter(row => Object.keys(row).length > 0); // Filter out empty rows
  
        resolve(data);
        console.log(data);
      };
  
      reader.onerror = (error) => {
        this.handleApiError(error);
        throw error;      }
  
      reader.readAsText(file);
    });
  }
  
 /* getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result as string;
        const rows = content.split('\n').map(row => row.split(';'));
        const headers = rows[0]; // Assuming the first row contains headers
  
        const data = rows.slice(1).map(row => {
          let rowData: any = {};
  
          // Check if the row has at least one non-empty value
          if (row.some(value => value.trim() !== '')) {
            headers.forEach((header, index) => {
              let value = row[index];
  
              const scientificNotationRegex = /^[0-9]+,[0-9]+E[+-][0-9]+$/; // Matches values like 2,58E+03
  
              if (scientificNotationRegex.test(value)) {
                const numericValue = parseFloat(value.replace(',', '.'));
                if (!isNaN(numericValue)) {
                  value = numericValue.toString();
                }
              }
  
              rowData[header] = value;
            });
          }
  
          return rowData;
        }).filter(row => Object.keys(row).length > 0); // Filter out empty rows
  
        resolve(data);
        console.log(data);
      };
  
      reader.onerror = (error) => {
        reject(error);
      }
  
      reader.readAsText(file);
    });
  }
  
  /*getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result as string;
        const rows = content.split('\n').map(row => row.split(';'));
        const headers = rows[0]; // Assuming the first row contains headers
  
        const data = rows.slice(1).map(row => {
          let rowData: any = {};
          headers.forEach((header, index) => {
            let value = row[index];
  
            const scientificNotationRegex = /^[0-9]+,[0-9]+E[+-][0-9]+$/; // Matches values like 2,58E+03
  
            if (scientificNotationRegex.test(value)) {
              const numericValue = parseFloat(value.replace(',', '.'));
              if (!isNaN(numericValue)) {
                value = numericValue.toString();
              }
            }
  
            rowData[header] = value;
          });
          return rowData;
        });
  
        resolve(data);
        console.log(data);
      };
  
      reader.onerror = (error) => {
        reject(error);
      }
  
      reader.readAsText(file);
    });
  }
  
  
  
 /* getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result as string;
        const rows = content.split('\n').map(row => row.split(';'));
        
        // Assuming the number is in the first column of the first row
        const scientificNotationValue = rows[1][9];
        const numericalValue = parseFloat(scientificNotationValue.replace(',', '.'));
        
        resolve(numericalValue);
        console.log(numericalValue);
      };
  
      reader.onerror = (error) => {
        reject(error);
      }
  
      reader.readAsText(file);
    });
  }
  
/*  getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        const content = reader.result;
        const wb: XLSX.WorkBook = XLSX.read(content, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
        console.log(data)
      };

      reader.onerror = (error) => {
        reject(error);
      }

      reader.readAsText(file);
    });
  }
 /* getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result;
        const lines = (content as string).split('\n');
        const data = [];
  
        for (let line of lines) {
          const values = line.split(';');
          data.push(values);
        }
  
        resolve(data);
      };
  
      reader.onerror = (error) => {
        reject(error);
      }
  
      reader.readAsText(file);
    });
  }
  /* getFile(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event.currentFiles[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        const content = reader.result;
        const lines = (content as string).split('\n');
  
        const data = lines.map(line => line.split(';'));
        
        resolve(data);
      };
  
      reader.onerror = (error) => {
        reject(error);
      }
  
      reader.readAsText(file);
    });
  }
  */
  getConfig() {
    return this.http.get(this.configUrl);
  }
  
  exportToCsv(data: any[], config: any): Observable<Blob> {
    const headers = Object.keys(config);
    const mappedData = data.map(item => {
      return headers.map(header => item[config[header]]);
    });

    const csvData = [headers, ...mappedData].map(e => e.join(','));

    const blob = new Blob([csvData.join('\n')], { type: 'text/csv' });
    return new Observable<Blob>(observer => observer.next(blob));
  }
}
