import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulte-details-achat',
  templateUrl: './consulte-details-achat.component.html',
  styleUrls: ['./consulte-details-achat.component.scss']
})
export class ConsulteDetailsAchatComponent implements OnInit {
  detailsAchatDialog:boolean=false
  achat
  constructor() { }

  ngOnInit(): void {
  }
  async showDialog(data?){
    this.detailsAchatDialog=true
    console.log('achat from parent',data)
    this.achat=data
  }
  hideDialog() {
    this.detailsAchatDialog=false
  }
}
