import { Component, OnInit,ViewChild } from '@angular/core';
import { AddTransfertComponent } from '../add-transfert/add-transfert.component';
import { AchatsService } from './../../demo/service/achats.service';
import { TransfertService } from 'src/app/demo/service/transfert.service';
import { MessageService } from 'primeng/api';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-saisietransfert',
  templateUrl: './saisietransfert.component.html',
  styleUrls: ['./saisietransfert.component.scss']
})
export class SaisietransfertComponent implements OnInit {
  @ViewChild(AddTransfertComponent) addtransfert: AddTransfertComponent;
  @ViewChild(ConfirmationDialogComponent) ConfirmDialog: ConfirmationDialogComponent; 

  Transferts :any[]=[]
  Etablissements
  selectedEtablissementEmett
  selectedEtablissementRecep
  Depots
  filtredDepot
  filtredEtablissements
  selectedDepotRecep
  selectedDepotEmett
  transferedVariations
  newTransfert
  confirm
  date
  prodIds=[]
  transfert_variations=[]
  constructor(private transfertService:TransfertService,private messageService: MessageService ,private toastService: MessageService ) { }
  cols = [
    { field: 'ref', header: 'Référence' },
    { field: 'designation', header: 'Designation' },
    { field: 'qte', header: 'Quantité' },
    { field: 'couleur', header: 'Couleur' },

    { field: 'taille', header: 'taille' },

    // { field: 'prix', header: 'Prix' },
    // {field: 'prixMontant', header: 'Prix Montant'},
    {field: 'action', header: 'Action'},
  

  ];
  ngOnInit(): void {
    this.getEtablissement()
    this.date=new Date
  }
  openDialog(){
    this.addtransfert.showDialog()
  }
  async getEtablissement(){

  await this.transfertService.getEtablissements().then(res=>{
      
    console.log(res)
  this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
    store=>({id:store.id,name:store.attributes.name})
  )}))})
  console.log(this.Etablissements)
  this.selectedEtablissementEmett=this.Etablissements[0]
  this.selectedDepotEmett=this.Etablissements[0].stores[0]
  this.selectedEtablissementRecep=this.Etablissements[1]
  this.selectedDepotRecep=this.Etablissements[1].stores[1]

}
getSelectedEatbEmett(){
  this.filtredDepot=[]
  this.selectedDepotEmett=""
  console.log(this.selectedEtablissementEmett)
  if(this.selectedDepotRecep  && this.selectedEtablissementEmett){
    // const firstStore = this.selectedEtablissementEmett.stores[0];
    // console.log(firstStore)
    // if (firstStore && firstStore.id !== this.selectedDepotRecep.id) {
    //   this.selectedDepotEmett = firstStore;
    // }
    const emettStores = this.selectedEtablissementEmett.stores;
    let suitableStoreFound = false;

    for (const store of emettStores) {
      if (store.id !== this.selectedDepotRecep.id) {
        this.selectedDepotEmett = store;
        suitableStoreFound = true;
        break; 
      }
    
    }

    
  }
  // else{
  //   this.selectedDepotEmett=this.selectedEtablissementEmett.stores[0]
  // }
  console.log(this.selectedDepotEmett)

}
getSelectedEatbRecep(){
  this.filtredDepot=[]
  this.selectedDepotRecep=""
  console.log(this.selectedEtablissementRecep)
  if (this.selectedDepotEmett) {
    // const firstRecepStore = this.selectedEtablissementRecep.stores[0];
    // if (firstRecepStore && firstRecepStore.id !== this.selectedDepotEmett.id) {
    //   this.selectedDepotRecep = firstRecepStore;
    //   console.log(this.selectedDepotRecep);
    const recepStores = this.selectedEtablissementRecep.stores;

    for (const store of recepStores) {
      if (store.id !== this.selectedDepotEmett.id) {
        this.selectedDepotRecep = store;
        break; // Break out of the loop after finding the first store that is not equal
      }
    }
    // }
  }
  // else{
  //  this.selectedDepotRecep=this.selectedEtablissementRecep.stores[0]

  // }
  console.log(this.selectedDepotRecep)

}
getSelectedDepotRecep(){
  console.log(this.selectedDepotRecep)
  if(this.selectedDepotEmett && this.selectedDepotRecep &&
    this.selectedDepotRecep.id === this.selectedDepotEmett.id){

      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Le dépôt doit être différent du dépôt émetteur',
      });
      this.selectedDepotRecep = null;
  }

}
getSelectedDepotEmett(){
  console.log(this.selectedDepotEmett)
  if(this.selectedDepotRecep && this.selectedDepotEmett &&
    this.selectedDepotRecep.id === this.selectedDepotEmett.id){

      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Le dépôt doit être différent du dépôt recepteur',
      });
      this.selectedDepotEmett = null;
  }

}
filterEtablissements(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
      let country = (this.Etablissements as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredEtablissements = filtered;
}

filterDepotsEmett(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.selectedEtablissementEmett.stores as any[]).length; i++) {
      let country = (this.selectedEtablissementEmett.stores as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredDepot = filtered;
}
filterDepotsRecep(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.selectedEtablissementRecep.stores as any[]).length; i++) {
      let country = (this.selectedEtablissementRecep.stores as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredDepot = filtered;
}
  receiveTransfertFromChild(data){
console.log(data)
this.transferedVariations=data
for(let varTransfert of data){
  const existVariation=this.Transferts.some (item=>item.idVariation==varTransfert.ID)
  if(existVariation){
    const variationIndex=this.Transferts.findIndex(item=>item.idVariation==varTransfert.ID)
    this.Transferts[variationIndex].qte +=varTransfert.QTE
  }
  else{
    const newTransfert={idArticle:varTransfert.article.id,ref:varTransfert.article.code_article_generique,designation:varTransfert.article.designation,idVariation:varTransfert.ID,qte:varTransfert.QTE,color:varTransfert.Color,taille:varTransfert.Size}
    this.Transferts.push(newTransfert)
  }
 
  console.log(this.Transferts)


}
  }

  SupprimerTransfert(rowIndex){
    this.Transferts.splice(rowIndex, 1);
    console.log(this.Transferts)


  }
  async AddTransfert(){
    switch (true) {

      case !this.selectedEtablissementEmett:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Etablissement Emetteur' });
        break;
        case !this.selectedDepotEmett:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez depot Emetteur' });
        break;
      case !this.selectedEtablissementRecep:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Etablissement Recepteur' });
        break;
     
      case !this.selectedDepotRecep:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez depot Recepteur' });
        break;
        case !this.date:
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez date' });
          break;
        case this.Transferts.length===0:
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ajouter un transfert' });
          break;
      default :
      await this.ConfirmDialog.showDialog()
         
        }
       


  }
  saveTransfert(){
    console.log(this.selectedEtablissementEmett,this.selectedDepotEmett)
  //  if(this.selectedEtablissementRecep && this.selectedEtablissementEmett && this.selectedDepotRecep && this.selectedDepotEmett  &&  this.Transferts){
     for(let transfer of this.Transferts){
      this.prodIds.push(transfer.idArticle)
      const variation={   
       "variation": transfer.idVariation,
      "quantity": transfer.qte,
      "idArticle": transfer.idArticle}
      this.transfert_variations.push(variation)
      this.newTransfert={
        "products": this.prodIds,
        "etablissement_emetteur": this.selectedEtablissementEmett.id,
        "etablissement_recepteur": this.selectedEtablissementRecep.id,
        "store_emetteur": this.selectedDepotEmett.id,
        "store_recepteur": this.selectedDepotRecep.id,
        "date_transfert": "2023-11-15T12:34:56.789Z",
        "transfert_variations": this.transfert_variations
      }
    }
        
          console.log(this.newTransfert)
      this.transfertService.savetransfert(this.newTransfert).then(
        res=>{console.log(res)
        this.toastService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Transfert Ajouté avec succé',
          life: 3000
        });}
        )
        this.selectedEtablissementEmett=''
        this.selectedEtablissementRecep=''
        this.selectedDepotEmett=''
        this.selectedDepotRecep=''
        this.newTransfert=''
        this.Transferts=[]
        this.transfert_variations=[]
        this.prodIds=[]
      // }
      // else{
      //   this.messageService.add({ severity: 'error', summary: '', detail: 'Selectionner Etablissement/Depot' });

      // }
        }

        receiveConfirm(data){
          console.log("response of confirmation",data)
          this.confirm=data
          if(this.confirm==1){
            this.saveTransfert()
          }
          else{
      
                }
        }
}
