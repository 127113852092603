<div class="card">
  <h5>Gérer Sous-Famille</h5>

  <p-table
    [value]=""
    responsiveLayout="stack"
    responsive="false"
    scrollHeight="flex"
    [loading]="loading"
    [lazy]="true"
    (onLazyLoad)="onLazyLoad($event)"
  >
    <ng-template pTemplate="caption">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        label="Ajouter "
        class="p-button-success mr-2 mb-2"
        style="width: 100px"
        (click)="openDialog()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-trash"
        label="Supprimer "
        class="p-button-danger mr-2 mb-2"
        style="width: 110px; background-color: #ef4444"
        (click)="deleteFourniss()"
        [disabled]="!selectedFourniss || !selectedFourniss.length"
      ></button>

      <span class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          #filter
          [(ngModel)]="searchTerm"
          placeholder="Rechercher"
          class="w-full"
          (input)="getSearch($event)"
          style="height: 35px"
        />
      </span>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 100rem">
          <div class="field-checkbox">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="selectAllCheckbox"
              name="checkbox{{ rowIndex }}"
              [value]="fourniss"
              (onChange)="selectAll($event)"
            ></p-checkbox>
          </div>
        </th>
        <th
          *ngFor="let col of columns"
          style="width: 100rem"
          pSortableColumn="{{ col.field }}"
        >
          <div
            *ngIf="col.field == 'code_article_generique'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>

          <div
            *ngIf="col.field == 'code_fournisseur'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>

          <div
            *ngIf="col.field == 'designation'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>

          <div
            *ngIf="col.field == 'sous_famille'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'collection'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'image'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}
          </div>
          <div
            *ngIf="col.field == 'rayon'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'prix_achat_ttc'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'prix_vente_ttc'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'fournisseur'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
          <div
            *ngIf="col.field == 'Action'"
            class="flex justify-content-between align-items-center"
          >
            {{ col.header }}<p-sortIcon field="{{ col.field }}"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-product
      let-ri="rowIndex"
    >
      <tr [pEditableRow]="columns">
        <td style="width: 100rem">
          <div class="field-checkbox">
            <p-checkbox
              [binary]="true"
              name="checkbox{{ rowIndex }}"
              [(ngModel)]="product.selected"
              (onChange)="checkboxChanged($event, product)"
            ></p-checkbox>
          </div>
        </td>

        <td
          style="min-width: 12rem"
          style="width: 100rem"
          *ngFor="let col of columns"
        >
          <div
            *ngIf="col.field == 'code_article_generique'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field] }}
          </div>

          <div
            *ngIf="col.field == 'code_fournisseur'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field] }}
          </div>
          <div
            *ngIf="col.field == 'designation'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field] }}
          </div>
          <div
            *ngIf="col.field == 'image'"
            class="flex justify-content-between align-items-center"
          >
            <img
              src="{{ rowData[col.field]?.name }}"
              width="45"
              style="vertical-align: middle"
            />
          </div>
          <div
            *ngIf="col.field == 'sous_famille'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field].name }}
          </div>
          <div
            *ngIf="col.field == 'collection'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field].name }}
          </div>
          <div
            *ngIf="col.field == 'rayon'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field].name }}
          </div>
          <div
            *ngIf="col.field == 'prix_achat_ttc'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field] | round }}
          </div>
          <div
            *ngIf="col.field == 'prix_vente_ttc'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field] | round }}
          </div>
          <div
            *ngIf="col.field == 'fournisseur'"
            class="flex justify-content-between align-items-center"
          >
            {{ rowData[col.field].name }}
          </div>

          <div
            *ngIf="col.field == 'Action'"
            class="flex justify-content-between align-items-center"
          >
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-pencil"
              label="Modifier "
              class="p-button mr-2 mb-2"
              style="background-color: #3b82f6; width: 100px"
              (click)="editArticler(ri, product)"
            ></button>
          </div>
        </td>
        <!-- <td>
                    <div class="flex">
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editProduct(rowData)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(rowData)" ></button> 


                    </div>
                </td> -->
      </tr>
    </ng-template>
  </p-table>

  <div
    class="card"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div style="flex-grow: 1">
      <!-- <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator> -->
    </div>
  </div>
</div>

<p-toast></p-toast>

<div *ngIf="loading" class="splash-screen">
  <div class="spinner">
    <span class="ball-1"></span>
    <span class="ball-2"></span>
    <span class="ball-3"></span>
    <span class="ball-4"></span>
    <span class="ball-5"></span>
    <span class="ball-6"></span>
    <span class="ball-7"></span>
    <span class="ball-8"></span>
  </div>
</div>
