<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-toast></p-toast>

            <h5>Liste des articles</h5>
			<p-toolbar styleClass="mb-4">
				
				<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
				<!-- <button pButton pRipple label="test" icon="pi pi-plus" class="p-button-success mr-2" (click)="test()" ></button> -->
								
				<div class="upload-container">			
				<p-fileUpload mode="basic" accept=".csv" [maxFileSize]="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" (onUpload)="uploaded($event)" (onSelect)="importedFile($event)"></p-fileUpload>
				<!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportToCsv()"></button> -->
				</div>
			</p-toolbar>
			
            <p-table [columns]="cols" [value]="products" [globalFilterFields]="['Nom']" dataKey="id"  >
			
				<!-- [paginator]="true"
			  [showCurrentPageReport]="true" 
			currentPageReportTemplate="Showing {{currentPage}} to {{pageSize}} of {{totalItems}} entries" 
			[rows]="pageSize" [rowsPerPageOptions]="[10,25,50]" -->
				<!-- 	[paginator]="true" [rows]="10" [showCurrentPageReport]="true" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]" -->
		
                <!-- <ng-template pTemplate="caption">
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>

                                    </ng-template> -->
									
									
									
									
                <ng-template pTemplate="header" let-columns>
					
					<tr>
                        <th></th>
						<th *ngFor="let col of columns">
							<div  *ngIf="col.field=='reference'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="reference" display="menu" placeholder="Search by  reference"></p-columnFilter>
						</div>

						<div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="code_article_generique" display="menu" placeholder="Search by  code_article_generique"></p-columnFilter>
						</div>


						<div  *ngIf="col.field=='prix_vente'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="prix_vente" display="menu" placeholder="Search by  prix_vente"></p-columnFilter>
						</div>
						
						<div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="image" display="menu" placeholder="Search by  image"></p-columnFilter>

						</div>
                        <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
							{{ col.header }}

							<p-columnFilter type="text" field="fournisseur" display="menu" placeholder="Search by  fournisseur"></p-columnFilter>

						</div>

						

						

						
					

						
						</th>
						
						
						
					</tr>
				</ng-template>


                <ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
                        <td>
                            <button *ngIf="et at" type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
							[icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="toggleRow(rowData)"></button>
                        </td>
						<td style="min-width: 12rem;" *ngFor="let col of columns">

							
							<div  *ngIf="col.field=='reference'"class="flex justify-content-between align-items-center">
								
								 {{rowData[col.field] }}
							</div>
						
						   <div  *ngIf="col.field=='prix_vente'"class="flex justify-content-between align-items-center">
								
							{{rowData[col.field] }}
					   </div>
					   <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
								
						{{rowData[col.field]}}
				   </div>
                   <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
								
                    {{rowData[col.field]?.attributes?.name}}
               </div>
				   
			   <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
								
				<img src= "{{ rowData[col.field] }}"  width="45" style="vertical-align: middle" />
			</div>
							
						</td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editProduct(rowData)"></button>
								<button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProduct(rowData)" ></button> 


                            </div>
                        </td>
                    
                    </tr>
						
					</ng-template>

					<ng-template pTemplate="rowexpansion" let-rowData>
						<tr *ngIf="expandedRows[rowIndex]">
							<td colspan="7">
								<div class="p-p-3">
									<div   style="text-align: center;">
										<button pButton pRipple  (click)="addvariation(rowData)" label="ajouter variations de produit " class="p-button-success">
                    
										</button>
									</div>
									<br>
									<p-table [value]="rowData.variations.data" dataKey="id">
										<ng-template pTemplate="header">
											<tr>
												<th>id</th>
												<th>code_barre</th>
												<th>couleur</th>
												<th>taille</th>
												
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-variation>
											<tr>
												<td>{{variation.id}}</td>
												<td>{{variation.attributes.code_barre	}}</td>
												<td>{{variation.attributes.color.data.attributes.name	}}</td>
												<!-- <td><i class="bi bi-record" style="font-size: 3rem; " [style.color]="variation.attributes.color.data.attributes.code" [style.background-color]="variation.attributes.color.data.attributes.code"></i></td> -->

												<td>{{variation.attributes.taille.data.attributes.name	}}</td>
												<td> <div class="flex">
													<button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editVariation2(variation,rowData)"></button>
												 <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteVariation(variation)"></button> 
												</div></td>
												
											</tr>
										</ng-template>
										
									</p-table>
								</div>
							</td>
						</tr>
					</ng-template>
					<!-- <ng-template pTemplate="paginatorleft">
						<p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
					</ng-template>
					<ng-template pTemplate="paginatorright">
						<p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
					</ng-template> -->
					
					<!-- <div *ngIf="paginationInfo">
						<p>Page {{ currentPage }} of {{ paginationInfo.pageCount }}</p>
						<button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
						<button (click)="nextPage()" [disabled]="currentPage === paginationInfo.pageCount">Next</button>
						<button *ngFor="let page of pageNumbers" (click)="goToPage(page)">{{ page }}</button>

					  </div> -->
            </p-table>
        
			
        
			<div class="card flex justify-content-center">
				<p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator>
			</div>
        </div></div></div>
		<div *ngIf="loading" class="splash-screen">
			<div  class="spinner">
				<span class="ball-1"></span>
				<span class="ball-2"></span>
				<span class="ball-3"></span>
				<span class="ball-4"></span>
				<span class="ball-5"></span>
				<span class="ball-6"></span>
				<span class="ball-7"></span>
				<span class="ball-8"></span>
			</div>
		</div>
<!-- add new product  -->
<app-add-product  (dataToParent)="handleData($event)" (notifyParent)="handleNotification($event)" (updatedListOfProd)="handleList($event)" [prodlist]="productsToSend"></app-add-product>
<app-add-variation #myDialogV (outputvariationToList)="receivedMessageHandler($event)" (notifyParent)="handleNotificationV($event)"></app-add-variation>
            <!-- <div *ngIf="VP==1"> -->
                
                <!-- <button pButton pRipple    (click)="addvariation(product.code)" label="ajouter variation de produit " class="p-button-success">
			
                </button> -->
<br><br>
<app-delete-dialog></app-delete-dialog>
<app-import-product [receiveImportedData]="sendImportedData"></app-import-product>
<!-- <p-dialog [(visible)]="deleteVariationDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
		<span >Are you sure you want to delete ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteProductDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>



	</ng-template>
</p-dialog> -->
