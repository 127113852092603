<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-toast></p-toast>

            <h3>Mouvement Stock</h3>

		

        <div class="container1 ">

                <div class="container3">
                    <div class="container">

            <label >Code article générique<span style="color: red;"> *</span></label>
            <p-autoComplete  class="autocomplete"  [class.empty-field]="fieldsEmpty" [(ngModel)]="selectedArtilce"  [suggestions]="filteredArticle"  (completeMethod)="filterArticle($event)" 
                field="code_article_generique" [dropdown]="true"    (onSelect)="getSelectedArticles($event)"  (onKeyUp)="onKeyUp($event)" placeholder="Article"
                ></p-autoComplete>
                    </div>
           

             <div class="buttons">

                <button pButton pRipple type="button" 
                icon="fa fa-filter bi bi-filter" 
                label="Filtrer" class="p-button-success mr-0.5 mb-" 
                (click)="getMouvements()"
                ></button>

                <button pButton pRipple type="button" 
                        icon="bi bi-arrow-clockwise" 
                        label="Refresh" class="btn btn-primary mr-0.5 mb-"  
                        style = "margin-right : 4px; background-color: rgba(0, 78, 156, 0.158); color : black;"
                        (click)="refreshMouvement()"
                        ></button
                        >
                
               
                    </div>
              </div>

            <div class="container2">

            <p-dropdown class="drop" [(ngModel)]="selectedType" [options]="Types" optionLabel="" placeholder="Type Mouvement"></p-dropdown>

            <div class="p-field p-col-12 p-md-4">
                <p-calendar [appendTo]="'body'" [(ngModel)]="dates" selectionMode="range" placeholder="Dates" dateFormat="yy-mm-dd" [showIcon]="true" inputId="range"></p-calendar>
            </div>

           
            </div>

        </div>
       

           <br>
            <p-table [columns]="cols" [value]="Mouvements" [globalFilterFields]="['Nom']" dataKey="id"  class="centered-table"  responsive="false" 
            responsiveLayout="stack" scrollHeight="flex" >
			

                <ng-template pTemplate="header" let-columns>
					
					<tr>
						<th *ngFor="let col of columns"  class="centered-header">
							<div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						<div  *ngIf="col.field=='num_piece'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						<div  *ngIf="col.field=='type_operation'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>


						<div  *ngIf="col.field=='clt_fourniss'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>

						<div  *ngIf="col.field=='entre'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						
						<div  *ngIf="col.field=='sortie' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						
						
                   
						</th>
					
					</tr>
				</ng-template>

                <ng-template pTemplate="body" let-rowData  let-columns="columns" let-Mouvement let-ri="rowIndex">
					<tr  [pEditableRow]="columns">
                     
						<td style="min-width: 12rem;" *ngFor="let col of columns">

							
							<div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
								
								 {{rowData[col.field] }}
							</div>
						
						   <div  *ngIf="col.field=='num_piece'"class="flex justify-content-between align-items-center">
								
							{{rowData[col.field] }}
					   </div>
					   <div  *ngIf="col.field=='type_operation'"class="flex justify-content-between align-items-center">
								
						<!-- {{rowData[col.field]}} -->
                        <span [class]="'rowData-badge status-' + rowData[col.field].toLowerCase()">{{rowData[col.field]}}</span>

				   </div>
                   <div  *ngIf="col.field=='clt_fourniss'"class="flex justify-content-between align-items-center">
								
                    {{rowData[col.field]}}
               </div>
				   
			   <div  *ngIf="col.field=='entre'"class="flex justify-content-between align-items-center">
								
		        {{ rowData[col.field] }}
			</div>

			<div  *ngIf="col.field=='sortie' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
								
		        {{ rowData[col.field] }}
			</div>

			
							
						</td>
                    </tr>
						
					</ng-template>
            </p-table>
    
			<div class="card flex justify-content-center">
				<p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator>
			</div>
        </div></div></div>

<br><br>
<app-delete-dialog></app-delete-dialog>

<!-- <div *ngIf="loading" class="splash-screen">
    <div  class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
</div> -->