import { Component, OnInit,ViewChild ,HostListener } from '@angular/core';
import { VenteService } from 'src/app/demo/service/vente.service';
import { DetailsTicketComponent } from '../details-ticket/details-ticket.component';
import { ArticleService } from 'src/app/demo/service/article.service';
import { ShareticketService } from 'src/app/demo/service/shareticket.service';
import { GenererVenteService } from 'src/app/demo/service/generer-vente.service';
import { LazyLoadEvent } from 'primeng/api';
import {FactureTicketPDFComponent} from  '../facture-ticket-pdf/facture-ticket-pdf.component';
import { ShareticketsService } from 'src/app/demo/service/sharetickets.service';


//----------------Interface----------------//
interface Vente {
  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number ;
  timbre: number;
  prix_net: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  // code_facture_client: string;
  numeroTicket: string;
  date_vente: string;
  client : string ;
  store : string ;
  etablissement : string ;
  selected?: boolean;
  product?: string ;
  productids? : any[];
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 
  status_facture
  status_vente? : string ;
  clientCTVA?  : string ;
}

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

//--------------------------------//

@Component({
  selector: 'app-consultation-ticket',
  templateUrl: './consultation-ticket.component.html',
  styleUrls: ['./consultation-ticket.component.scss']
})
export class ConsultationTicketComponent implements OnInit {
@ViewChild(DetailsTicketComponent) detailDialog: DetailsTicketComponent;
@ViewChild(FactureTicketPDFComponent) factureDialog: FactureTicketPDFComponent; 

  //---------------- columns ---------------------- //
        cols: { header: string, field: string }[] = [
          // { header: 'Numero Ticket', field: 'code_facture_client' },
          { header: 'Numero Facture', field: 'numeroTicket' },
          { header: 'Client', field: 'client' },
          { header: 'Etablissements', field: 'etablissement' },
          { header: 'Depot', field: 'store' },
          { header: 'Prix net', field: 'prix_net' },
          { header: 'Remise (%)', field: 'pourcentage_remise' },
          { header: 'Remise', field: 'remise' },
          { header: 'Total HT', field: 'ht' },
          { header: 'Taxes', field: 'taxes' },
          { header: 'Total TTC', field: 'ttc' },
          { header: 'Total Fodec', field: 'total_fodec' },
          { header: 'Timbre', field: 'timbre' },
          { header: 'Date Vente', field: 'date_vente' },
          { header: 'status facture', field: 'status_facture' },
          { header: 'status Facture', field: 'status_vente' }
        ];



  //--------------Declaration------------------//

      selectedColumns: any[] = [
        this.cols[0],
        this.cols[1],
        this.cols[2],
        this.cols[12],
        this.cols[14],
        this.cols[7],
        this.cols[9],
        this.cols[4],
      ];
      ventes: Vente[] = [];
      filteredVentes: Vente[] = [];
      selectedVentes: Vente[] = [];
      selectAllCheckbox: boolean = false;
      datevente
      Etablissements
      Depots : any[] = [];
      Clients
      selectedEtablissement: any[] = [];
      selectedStore
      selectedClient
      filtredClient
      filtredStore
      filtredEtablissement
      selectedArticle
      articles
      filteredArticle
      pageMinimized = false;
      fieldsEmpty: boolean = false;
      loading = false;
      first = 1
      last = 5
      totalRecords 
      page=1
      size=5
      sortField: string | undefined;
      sortOrder: number | undefined;
      event: AutoCompleteCompleteEvent
      TotalNet: number;


//-------------- Constructor------------------//



  constructor( private venteService:VenteService , private sharedServices: ShareticketsService , private articleService:ArticleService, private sharedService: ShareticketService ,private GenerVente :GenererVenteService )  { }


//---------------- on change----------------//

  onStoreChange(event: any) {
    // Handle the change in selectedStore
    console.log('Selected Store:', this.selectedStore);
    // Add additional logic as needed
  }

  onClientChange(event: any) {
    // Handle the change in selectedClient
    console.log('Selected Client:', this.selectedClient);
    // Add additional logic as needed
  }
  

  onArticleChange(event: any) {
    // Handle the change in selectedArticle
    console.log('Selected Article:', this.selectedArticle);
    // Add additional logic as needed
  }

  onEtablissementChange(event: any) {
    this.selectedStore = [];
   
    this.Depots = [] ;

  for ( let etablissement of this.selectedEtablissement) {
    // Use optional chaining to safely access the 'stores' property
    for ( let store of etablissement.stores){
      this.Depots.push(store) ;
    }
     

    console.log('sf',this.Depots)
  }
  
    console.log('feffe',this.selectedEtablissement)
  }

  //--------------onInitialisation------------------//
  async initializeData() {
   
    await this.getEtablissement().then(() => {
      if (this.Etablissements && this.Etablissements.length > 0) {
        for ( let etablissement of this.selectedEtablissement) {
          // Use optional chaining to safely access the 'stores' property
          for ( let store of etablissement.stores){
            this.Depots.push(store) ;
          }}
        setTimeout(() => {
          this.selectedEtablissement = this.Etablissements;
          for ( let etablissement of this.selectedEtablissement) {
            // Use optional chaining to safely access the 'stores' property
            for ( let store of etablissement.stores){
              this.Depots.push(store) ;
            }}
            this.selectedStore =this.Depots
       });
      }
    });
  
    this.getClients();
    this.getArticles();
    await this.GenerVente.getnumvente().then((data) => {
       this.totalRecords = data;
    });
   // this.getVentes('', '', this.page, this.size);
    this.sharedService.currentVenteData.subscribe((vente) => {
       if (this.detailDialog) {
          this.detailDialog.setData(vente);
       }
    });
 }
   ngOnInit()  {
    
    this.initializeData();
  }

  onPageChange(event: any) {
   const page = event.first / event.rows + 1;
   const pageSize = event.rows;
   this.page = page;
   this.size = pageSize;
   console.log('aaaaaaaa', page, pageSize);

   this.getfilterVentes();
}



  //--------------TAble parameters------------------//

  
  onRowDragEnd(event: any) {
    // Handle the row drag end event here if needed
  }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.size);
  }
  
  onPageChangePaginator(event: any) {
    this.page = event.page + 1; // Adjust for 0-based index
    this.size = event.rows;
    this.getfilterVentes();
  }
  isPageMinimized() {
    return this.pageMinimized;
  }
  onLazyLoad(event: LazyLoadEvent): void {
    console.log('Sort Event:', event);
  this.sortField = event.sortField;
  this.sortOrder = event.sortOrder;
  console.log('Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
  this.getfilterVentes();
  }

  onSortChange(event: any): void {
    // Handle sorting changes
    this.sortField = event.field;
    this.sortOrder = event.order;
    console.log('Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    // Call your API with sortField and sortOrder parameters
    this.getfilterVentes();
  }

  //--------------OnCellClick------------------//

  onCellClick(rowIndex: number ,bool : boolean) {
    const val = this.filteredVentes[rowIndex] ;
    this.sharedService.setVenteData(val);
    console.log('zfef',val)
    this.detailDialog.opendialog(bool) ;
}

//--------------Vente------------------//

  async getVentes(clientName: string, storeName: string,page : number ,size : number) {
    this.loading = true;
    this.filteredVentes = [];
    await this.GenerVente.getVentes(clientName, storeName,page,size).subscribe((response) => {
      // Map the data to your Vente interface
      this.filteredVentes = response.data.map((venteData: any) => {
        const vente: Vente = {
          id: venteData.id,
          pourcentage_remise: venteData.attributes.pourcentage_remise,
          remise: venteData.attributes.remise,
          ht: venteData.attributes.ht,
          taxes: venteData.attributes.taxes,
          ttc: venteData.attributes.ttc,
          total_fodec: venteData.attributes.total_fodec,
          timbre: venteData.attributes.timbre,
          prix_net: parseFloat(venteData.attributes.prix_net.toFixed(3)),
          uuid: venteData.attributes.uuid,
          createdAt: venteData.attributes.createdAt,
          updatedAt: venteData.attributes.updatedAt,
          // code_facture_client: venteData.attributes.numeroTicket,
          numeroTicket: venteData.attributes.numeroTicket,
          date_vente: venteData.attributes.date_vente,
          client: venteData.attributes.client.data.attributes.name,
          etablissement: venteData.attributes.etablissement.data.attributes.name,
          store: venteData.attributes.store.data.attributes.name,
          // product : venteData.attributes.products.data.attributes.code_article_generique,
        // designation :  venteData.attributes.products.data.attributes.designation,
          clientcode :  venteData.attributes.client.data.attributes.code,
          clientadress: venteData.attributes.client.data.attributes.adresse ,
          clienttelephone :  venteData.attributes.client.data.attributes.telephone,
          status_facture :venteData.attributes.status_facture, 
          status_vente : venteData.attributes.status_vente.data?.attributes?.name  ? venteData.attributes.status_vente.data?.attributes?.name : '',
           clientCTVA : venteData.attributes.client.data.attributes.matricule_fiscale ,
        };
        if (venteData.attributes.products && venteData.attributes.products.data.length > 0) {
          vente.productids = [];
          const productAttributes = venteData.attributes.products.data[0].attributes;
          let ids : any[] = venteData.attributes.products.data
          for ( let productAttribute of ids){
            vente.productids.push(productAttribute.id)
          }
        
          console.log ('less idsssssssss : ', vente.productids)
          vente.product = productAttributes.code_article_generique;
     
          vente.designation = productAttributes.designation;
        } else {
          vente.product = null;
          vente.designation = null;
        }
        console.log(vente.product)
        console.log(vente);
  
        this.filteredVentes.push(vente);
       // Add a selected property for the checkbox
        return vente;
  
      });
    });
    this.loading = false;
  }

  async getfilterVentes() {
    this.TotalNet = 0 ;
   this.loading = true;
    this.filteredVentes = [];
    await this.GenerVente.getfliterVentes( this.selectedClient, this.selectedStore, this.selectedArticle, this.datevente, this.page, this.size, this.sortField , this.sortOrder).then((response) => {
      console.log('response  = :' , response.data)
      if (response.data) {
      this.filteredVentes = response.data.map((venteData: any) => {
        this.totalRecords = response.meta.pagination.total
        const vente: Vente = {
          id: venteData.id,
          pourcentage_remise: venteData.attributes.pourcentage_remise,
          remise: venteData.attributes.remise,
          ht: venteData.attributes.ht,
          taxes: venteData.attributes.taxes,
          ttc: venteData.attributes.ttc,
          total_fodec: venteData.attributes.total_fodec,
          timbre: venteData.attributes.timbre,
          prix_net: parseFloat(venteData.attributes.prix_net.toFixed(3)),
          uuid: venteData.attributes.uuid,
          createdAt: venteData.attributes.createdAt,
          updatedAt: venteData.attributes.updatedAt,
          // code_facture_client: venteData.attributes.numeroTicket,
          numeroTicket: venteData.attributes.numeroTicket,
          date_vente: venteData.attributes.date_vente,
          client: venteData.attributes.client.data.attributes.name,
          etablissement: venteData.attributes.etablissement.data.attributes.name,
          store: venteData.attributes.store.data.attributes.name,
          // product : venteData.attributes.products.data.attributes.code_article_generique,
          designation :  venteData.attributes.designation,
          clientcode :  venteData.attributes.client.data.attributes.code,
          clientadress: venteData.attributes.client.data.attributes.adresse ,
          clienttelephone :  venteData.attributes.client.data.attributes.telephone,
          status_facture :venteData.attributes.status_facture, 
          status_vente : venteData.attributes.status_vente.data?.attributes?.name  ? venteData.attributes.status_vente.data?.attributes?.name : '',
          clientCTVA : venteData.attributes.client.data.attributes.matricule_fiscale ,

        };
        if (venteData.attributes.products && venteData.attributes.products.data.length > 0) {
          const productAttributes = venteData.attributes.products.data[0].attributes;
          vente.product = productAttributes.code_article_generique;
     
          vente.designation = productAttributes.designation;
        } else {
          vente.product = null;
          vente.designation = '';
        }
        console.log(vente.product)
        console.log(vente);
        
        this.filteredVentes.push(vente);
       // Add a selected property for the checkbox
        return vente;
  
      });
    }});
    this.loading = false;
    if (this.datevente && this.datevente[0]) {
      const startDate = this.datevente[0];
    const oneDayBefore = new Date(startDate);
    oneDayBefore.setDate(startDate.getDate() - 1);
    this.datevente[0] = oneDayBefore
    console.log('One day before:', oneDayBefore);
    console.log('output datevente:', this.datevente);
  }
  if (this.datevente && this.datevente[1]) {
    const startDate = this.datevente[1];
  const oneDayBefore = new Date(startDate);
  oneDayBefore.setDate(startDate.getDate() - 1);
  this.datevente[1] = oneDayBefore
  console.log('One day before:', oneDayBefore);
  console.log('output datevente:', this.datevente);
} 

    this.filteredVentes.forEach((vente) =>{
      console.log( 'la vente est : ' ,vente)
      this.TotalNet += parseFloat(vente.prix_net.toFixed(3)); 
    })
    console.log('le total est ' , this.TotalNet)
}
  async resetFilters() {
  
  this.selectedEtablissement = [];
  this.selectedStore = [];
  this.selectedClient = [];
  this.selectedArticle = [];
  this.datevente = '';
  await this.GenerVente.getnumvente().then((data) => {
    this.totalRecords = data;
 });
  this.getVentes('', '', this.page, this.size);
  }

  async Filters() {

    this.loading = true;
  console.log('Input datevente:', this.datevente);
     this.page = 1
    await this.getfilterVentes()
    
    this.loading = false;

  }

  //--------------------------------//


  //------------filtres--------------------//

      filterClient(event: AutoCompleteCompleteEvent) {
          let filtered: any[] = [];
          let query = event.query;

          for (let i = 0; i < (this.Clients as any[]).length; i++) {
              let country = (this.Clients as any[])[i];
              if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                  filtered.push(country);
              }
          }

          this.filtredClient = filtered;
      }

      filterArticle(event: AutoCompleteCompleteEvent) {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < (this.articles as any[]).length; i++) {
            let country = (this.articles as any[])[i];
            if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }

        this.filteredArticle = filtered;
      }

      filterStore(event: AutoCompleteCompleteEvent) {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < (this.Depots as any[])?.length; i++) {
            let country = (this.Depots as any[])[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }

        this.filtredStore = filtered;
      }

      filterEtablissement(event: AutoCompleteCompleteEvent) {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
            let country = (this.Etablissements as any[])[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }

        this.filtredEtablissement = filtered;
        console.log('qdqsd',this.filtredEtablissement)
      }

  //--------------------------------//


  //----------------Get----------------//


            async getEtablissement(){
                await this.venteService.getEtablissements().then(res=>{
                  
                console.log(res)
                this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
                  store=>({id:store.id,name:store.attributes.name})
                )}))})
                console.log(this.Etablissements)
            
            }

            async getSelectedEatb(){

                console.log("selected Etablissement",this.selectedEtablissement)
              //  this.Depots=this.selectedEtablissement.stores

            }

            getClients(){
              this.venteService.getClients().then(res=>{
                // console.log('client',res)
              this.Clients=res.data.map(item=>({id:item.id,name:item.attributes.name}))
              console.log('clients',this.Clients)
            })
            }

            async getArticles(){
              await this.articleService.getArticles().then(res=>
                {console.log(res)
                this.articles=res?.data.map(items=>
                  ({
                    id: items.id,
                    code_article_generique:items?.attributes?.code_article_generique,
                    code_fournisseur:items?.attributes?.code_fournisseur,
                    designation:items?.attributes?.designation,
                    sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
                    collection:items?.attributes?.collection.data?.attributes?.name,
                    rayon:items?.attributes?.rayon.data?.attributes?.name,
                    prix_achat_ttc:items?.attributes?.prix_achat_ttc,
                    prix_vente_ttc:items?.attributes?.prix_vente_ttc,
                    prix_achat_ht:items?.attributes?.prix_achat_ht,
                    prix_vente_ht:items?.attributes?.prix_vente_ht,
                    fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
                    nature:'',
                    Unite_de_stock:'',
                    variations:items?.attributes?.variations.data
                  })
                  )
                
                })
                console.log(this.articles)
              
            }

//--------------------------------//


                //------------------------------- ticket to facture ---------------------------- //

            imprimFacture(rowIndex : number)
            {
              let  val = []
              val.push(this.filteredVentes[rowIndex])  
               this.sharedServices.setSelectedVentes(val); 

               const Client = {
                id:this.filteredVentes[rowIndex].id,
                name:this.filteredVentes[rowIndex].client, 
                clientcode:this.filteredVentes[rowIndex].clientcode,
                address:this.filteredVentes[rowIndex].clientadress ,
                phone:this.filteredVentes[rowIndex].clienttelephone ,
                CTVA:this.filteredVentes[rowIndex].clientCTVA  
               }
               console.log('le client tva est :' , this.filteredVentes[rowIndex].clientCTVA  )
               this.factureDialog.showDialog(true, Client, this.selectedStore, this.selectedEtablissement ,null , null , null ,this.filteredVentes[rowIndex].numeroTicket , this.filteredVentes[rowIndex].date_vente);
            }


}
