<div class="grid">
	<div class="col-12">
		<div class="card">
            <!-- <h5>Listes des articles</h5> -->
            <!-- <br>
            <a routerLink="/pages/Add-achat">Continuer L'achat</a>
            <br> -->
            <br>
<p-table [columns]="cols" [value]="products" [globalFilterFields]="['Nom']" dataKey="id" [lazy]="true"
(onLazyLoad)="onLazyLoad($event)"   responsive="true" responsiveLayout="stack"   scrollDirection="vertical">
			

                        
    <ng-template pTemplate="header" let-columns>
        
        <tr>
            <th style="width:10rem; text-align: center; position: relative;">
                <button *ngIf="dimension" type="button" pButton pRipple [pRowToggler]="{}" class="p-button-text p-button-rounded p-button-plain" 
                        icon="pi pi-chevron-right" (click)="toggleAllRows()"></button>
              </th>

            <th *ngFor="let col of columns"  pSortableColumn="{{col.field}}"  style="width: 100rem;" >
                <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>


            </div>

            <div  *ngIf="col.field=='code_fournisseur'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>


            </div>
            <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>


            </div>


            <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>


            </div>
            
            <div  *ngIf="col.field=='sous_famille'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='collection'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='rayon'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='prix_achat_ttc'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='prix_vente_ttc'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
                {{ col.header }}                <p-sortIcon field="{{col.field}}"></p-sortIcon>



            </div>
            <div  *ngIf="col.field=='Action'"class="flex justify-content-between align-items-center">
                {{ col.header }}


            </div>

            

            

            
        

            
            </th>
            
            
            
        </tr>
    </ng-template>


    <ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
        <tr  [pEditableRow]="columns" >

            <td style="width:10rem solid #ccc; text-align: center;">
                <button *ngIf="dimension" type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
                [icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"   style="font-size: 1em;" (click)="toggleExpanded(rowData)"></button>

            </td>
            
            <td  style="width: 100rem;"  *ngFor="let col of columns">

                
            <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                
                    {{rowData[col.field] }}
            </div>
        
        
            <div  *ngIf="col.field=='code_fournisseur'"class="flex justify-content-between align-items-center">
                
                    {{rowData[col.field] }}
            </div>
            <div  *ngIf="col.field=='image'"class="flex justify-content-between align-items-center">
                        
                <img src= "{{ rowData[col.field]?.name }}"  width="45" style="vertical-align: middle" />
            </div>

            <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
                        
                {{rowData[col.field]}}
            </div>

                <div  *ngIf="col.field=='sous_famille'"class="flex justify-content-between align-items-center">
                                
                    {{rowData[col.field]}}
            </div>

            <div  *ngIf="col.field=='collection'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>

            <div  *ngIf="col.field=='rayon'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>

            <div  *ngIf="col.field=='prix_achat_ttc'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>

            <div  *ngIf="col.field=='prix_vente_ttc'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>

            <div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>
            <div  *ngIf="col.field=='Action'"class="flex justify-content-between align-items-center">
                    
                <!-- {{rowData[col.field]?.attributes?.name}} -->
           </div>
                
            </td>
           
        
        </tr>
            
        </ng-template>

    	<ng-template  pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" > 
            <tr >
              <td colspan="5">
                <!-- <div class="p-p-6"> -->
                    <div class="p-p-6" style="text-align: center; color: rgb(159, 195, 216);">
                        <p-table [value]="rowData.variations" dataKey="id"  >
                    <ng-template pTemplate="header">
                      <tr style="text-align: center; background-color: rgb(159, 195, 216);" >
                        <th style="text-align: center; background-color: rgb(159, 195, 216);"
                        >Id</th>
                        <th style="text-align: center; background-color: rgb(159, 195, 216);">Couleur</th>
                        <th  style="text-align: center; background-color: rgb(159, 195, 216);">Taille</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-variations>
                      <tr style="text-align: center;">
                        <td style="text-align: center;">{{variations.id}}</td>
                        <td style="text-align: center;">{{variations.color.name}}</td>
                        <td style="text-align: center;">{{variations.taille.name}}</td>
                
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                                <!-- </div> -->
              </td>
            </tr>
          </ng-template>

    
</p-table>
<div class="card flex justify-content-center">
    <p-paginator #paginator (onPageChange)="onPageChange($event)" 
    [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
</div>
</div>
</div>

<div *ngIf="loading" class="splash-screen">
    <div  class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
</div>