<p-dialog [(visible)]="deleteDialog" header="Confirmation" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;color: #EF4444;" ></i>
		<div *ngIf="showDeleteMsg">
		<span >Êtes-vous sûr(e) de vouloir supprimer ?</span>
	</div>
	<div *ngIf="showImportMsg">
		<span >{{import}}</span>
	</div>
	</div>
	<ng-template pTemplate="footer">
		<div *ngIf="showDeleteMsg">

		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="hideDialog ()" style="color: #EF4444;"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
		</div>
		<div *ngIf="showImportMsg">
		<button pButton pRipple label="OK" icon="pi " class="p-button-text" (click)="hideDialog()"></button>
	</div>

	</ng-template>
</p-dialog>