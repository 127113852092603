import { Component, OnInit, EventEmitter, Output, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ArticleService } from 'src/app/demo/service/article.service';
import { AddVariationComponent } from '../add-variation/add-variation.component';
import { DialogService } from 'primeng/dynamicdialog';
import { map, filter } from 'rxjs/operators';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import {  MessageService } from 'primeng/api';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
interface Product {
  ref: string;
  code: string;
  libelle: string;
  pVente: any;
  fourniss: any;
  // etat:boolean;
  variations?

}
export interface ProductData {
  reference: string;
  code_article_generique: string;
  libelle_article: string;
  prix_vente: number;
  fournisseur: string;
  image: string;
  // etat:boolean
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  providers: [MessageService],
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent implements OnInit {
  @Input() data: any; 
  @Input() inputFromParent : string;
  @Output() dataToParent = new EventEmitter<any>(); // Replace 'any' with the appropriate type
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Input() prodlist
  @Output() updatedListOfProd: EventEmitter<any> = new EventEmitter();

  @ViewChild(AddVariationComponent) myDialog: AddVariationComponent; // ViewChild decorator
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;

  productDialog: boolean;
  productData:Product={
    ref: '',
    code: '',
    libelle: '',
    pVente: 0,
    fourniss: '',
    // etat:null
  }
  idimage
  showVariationTable:boolean= false;
  uploadedFiles:any[]=[]
  listfournisseurs
  filteredFourniss: any[];
  showButton: boolean = false; // Initially, the button is hidden
  showImage:boolean=false
  imageNewProduct
  idProd
  dataToSend
  disable:boolean=false
  editVariation
  disableSaveButton:boolean=false
isChanged
selectedFourniss
  receivedVariation
  Value
  val1Check
  val2Check
  etat: boolean ;
  oldEtat: boolean | null = null;
  colsvariationP = [
  
    { field: 'code_article_generique', header: 'code article' },
  
    // { field: 'code_barre', header: 'code barre' },
    { field: 'color', header: 'couleur' },
    { field: 'taille', header: 'taille' },
   
  ];
  constructor(private s:ArticleService ,private messageService: MessageService,private config:ConfigurationService) {

   }
   handleNotification(data) {
    if(data==0){
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Variation Created',
        life: 3000
      });
    }
    else{
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Variation Updated',
        life: 3000
      });
    }
  }
   receivedMessageHandler(event){
    console.log(event)
    this.productData.variations=event
    console.log(this.productData.variations)
   }
  async ngOnInit(): Promise<void> {
   // await this.getfournisseur()
    await this.getVariationsOfProd(this.idProd)
    this.receivedMessageHandler(event)
   console.log (this.listfournisseurs)
   console.log(this.data)
   console.log(this.prodlist)
   this.config.getConfig().then(res=>
{     console.log(res)
  // this.productData.etat=res.data.attributes.article_dimensionne
this.etat=res.data.attributes.article_dimensionne

}   )
   

  //  this.selectedFourniss=this.productData.fourniss

   
  }
//   receiveVariation(data){
// this.receivedVariation=data
//   }
// ngOnChanges(changes: SimpleChanges) {
    
//   console.log(changes);
  

// }
  showDialog(data?:any) {
    this.showImage=false
    this.productDialog= true;
    if(data){
      console.log(data)
      this.disableSaveButton=true
      this.productData.ref=data?.reference
      // this.productData.etat=data?.etat
      // this.oldEtat=this.productData.etat
      this.disable=true
      this.idProd=data?.id
      console.log(this.idProd)
      // console.log(this.productData.etat)
      this.productData.code=data?.code_article_generique
      this.productData.libelle=data?.libelle_article

      this.productData.pVente=data?.prix_vente
      if(data?.variations?.data){
        this.productData.variations=data?.variations?.data?.map(x=>({
          id:x.id,
            
          code_article_generique:x.attributes.code_article_generique,
          code_barre:x.attributes.code_barre,
          taille:{id:x.attributes.taille.data.id,name:x.attributes.taille.data.attributes.name},
          color:{id:x.attributes.color.data.id,name:x.attributes.color.data.attributes.name},
         
      
        }))
      }
      this.productData.fourniss={"value":data?.fournisseur?.id,"label":data?.fournisseur?.attributes?.name}
      this.showButton=true
      // this.addvariation()
      console.log(this.productData)
      
    }
    else{
      // this.productData={
      //   ref: '',
      //   code: '',
      //   libelle: '',
      //   pVente: 0,
      //   fourniss: '',
      //   etat:null

      // }  
      this.productData.ref=''
      this.productData.code=''
      this.productData.libelle=''
      this.productData.pVente=null
      this.productData.fourniss=''
      this.productData.variations=[]

      this.disable=false
      this.showButton=false
      this.idProd=null
    }
  }

  hideDialog() {
     
    this.productDialog = false;
    this.productData={
      ref: '',
      code: '',
      libelle: '',
      pVente: 0,
      fourniss: '',
      // etat:null
      
    }
    this.etat = null; 
    this.idProd=null
    // this.productData.etat=null
    this.oldEtat=null
    // this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000});

  }
 
  sendDataToParent() {
    this.dataToParent.emit(this.productData); // 'this.dataToSend' is the data you want to send
  }
  
  onUpload(event) {
    this.idimage=event.originalEvent.body[0].id
    console.log(this.idimage)
    console.log("event",event.originalEvent.body[0].id)
    
    for (const file of event.files) {
        this.uploadedFiles.push(file);
        console.log("file",file);
        console.log("uploadedfiles",this.uploadedFiles);

    }
   
    //this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
}


/*async getfournisseur(){
 // this.s.sendData('test')
  await this.s.getfournisseur().then(data=>{
    this.listfournisseurs=data.data.map(x=>({
      value:x.id,
label:x.attributes.name}))
    console.log('fournisseur',this.listfournisseurs)
    
  })
  

}*/

filterFourniss(event) {
  console.log(event)
  if (!event.query) {
    return; 
  }
else{
  this.selectedFourniss=this.productData?.fourniss.value
  console.log(this.productData?.fourniss)
  this.s.sendData({fourniss:this.productData?.fourniss})
  this.s.getfournisseur().then(data=> {this.listfournisseurs=data.data.map(x=>({
    value:x.id,
label:x.attributes.name})
)}
)
  const filtered: any[] = [];
  const query = event.query;
  for (let i = 0; i < this.listfournisseurs?.length; i++) {
      const country = this.listfournisseurs[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }
 
  this.filteredFourniss = filtered;
//  console.log(this.filteredFourniss)
// if (this.selectedFournisseur) {
//   // Assign the selected value to editPT.fournisseur
//   this.editPT.fournisseur = this.selectedFournisseur;
// } 
}
  

}
async sendTheNewValue(event){
 
  console.log(this.selectedFourniss)
  console.log(event.target.value);
  this.isChanged=event.target.value
  if(this.isChanged ){
    this.disableSaveButton=false

  }
  }
  onCheckboxClick(value: string) {
    this.etat = (value === 'true');
  
    if (value === 'true') {
        this.val2Check = false; // Uncheck the other checkbox
    } else if (value === 'false') {
        this.val1Check = false; // Uncheck the other checkbox
    }
    console.log(this.etat)
}


onRadioButtonChange(value) {

  console.log(this.oldEtat)
 this.etat= value
 console.log(this.etat)
 if(this.etat!=this.oldEtat ){
  this.disableSaveButton=false
  // this.productData.etat = value;
  
 }
 
   
}
async saveProduct(){
  //  this.product.fournisseur=this.selectedFournisseur
  //console.log(this.idProd)
  
  // console.log(this.selectedFournisseur)

  if(!this.idProd){
    try {
    this.s.addarticle({"data":{
       "reference": this.productData.ref,
       "code_article_generique": this.productData.code,
       "libelle_article":this.productData.libelle,
       "prix_vente": this.productData.pVente,
       "fournisseur":this.Value,
       "image":this.idimage,
      //  "etat":this.productData.etat
       }})
     .then(async (res)=>{
       // console.log(this.product)
       if(this.etat==false){
        console.log(this.prodlist)
        this.showButton=false
        this.showVariationTable=false
        console.log("reponse api save article",res)
        this.imageNewProduct=res.data.attributes.image.data!=null?res.data.attributes.image.data.attributes.formats.small.url:""
        this.showImage=true
        this.idProd=res.data.id
        this.dataToSend={"idProd":this.idProd,"code":this.productData.code}
    
        const newVariation = {
          id: res?.data?.attributes?.variations?.data.id, 
          code_article_generique:res?.data?.attributes?.code_article_generique,
        code_barre: res?.data?.attributes?.variations?.data.attributes?.code_barre,
          taille: {
            id: res?.data?.attributes?.variations?.data.attributes?.taille?.data?.id,
            name: res?.data?.attributes?.variations?.data.attributes?.taille?.data?.attributes?.name,
          },
          color: {
            id: res.data.attributes.variations.data.attributes.color.data.id,
            name: res.data.attributes.variations.data.attributes.color.data.attributes.name,
          }
        };
        console.log(newVariation)
        console.log(this.productData.variations)

          this.productData.variations.push(newVariation)
        //  this.productData.variations=res?.data?.attributes?.variations?.data
    
             let  updatedProd={
          id:res.data.id,
          libelle_article:res.data.attributes.libelle_article,
          reference:res.data.attributes.reference,
          code_article_generique:res.data.attributes.code_article_generique,
          prix_vente:res.data.attributes.prix_vente,
          fournisseur:res.data?.attributes?.fournisseur?.data,
          image:  res.data.attributes.image?.data!=null? "https://prefacture.rosace.com.tn"+res.data.attributes.image.data.attributes.formats.small.url:"",
          variations:res.data.attributes.variations
        }
      console.log(updatedProd)
      this.prodlist.unshift(updatedProd)
            console.log(this.prodlist)
     
            // this.showButton=true
            this.disableSaveButton=true
            // this.hideDialog()
            this.notifyParent.emit(0);
        
          console.log(this.productData)
      
       }//END IF
       else{
       console.log(this.prodlist)
       this.showButton=true
       this.showVariationTable=true
       console.log("reponse api save article",res)
       this.imageNewProduct=res.data.attributes.image.data!=null?res.data.attributes.image.data.attributes.formats.small.url:""
       this.showImage=true
       this.idProd=res.data.id
       this.dataToSend={"idProd":this.idProd,"code":this.productData.code}
   
       this.productData.variations=res?.data?.attributes?.variations?.data        
       console.log(this.productData.variations)

       
       let  updatedProd={
         id:res.data.id,
         libelle_article:res.data.attributes.libelle_article,
         reference:res.data.attributes.reference,
         code_article_generique:res.data.attributes.code_article_generique,
         prix_vente:res.data.attributes.prix_vente,
         fournisseur:res.data?.attributes?.fournisseur?.data,
         image:  res.data.attributes.image?.data!=null? "https://prefacture.rosace.com.tn"+res.data.attributes.image.data.attributes.formats.small.url:"",
         variations:res.data.attributes.variations
       }
     console.log(updatedProd)
     this.prodlist.unshift(updatedProd)
           console.log(this.prodlist)
    
           this.showButton=true
           this.disableSaveButton=true
           // this.hideDialog()
           this.notifyParent.emit(0);
       
         console.log(this.productData)
        }//END ELSE
     })
     .catch((error)=> {console.log(error)
     this.notifyParent.emit(-1);

    })
    
  }
   catch(error){}
   }
     else{
          try{
       console.log(this.prodlist)
 
         this.s.editProducts(this.idProd,{
           "libelle_article":this.productData.libelle,
           "prix_vente": this.productData.pVente,
           "fournisseur":this.productData.fourniss.value,
           "image":this.idimage,
          //  "etat":this.productData.etat
           
           }).then(res=>{
             console.log("updated product",res)
             this.prodlist=this.prodlist.filter(prod=> prod.id!== res.data.id )
             this.imageNewProduct=res.data.attributes.image.data!=null?res.data.attributes.image.data.attributes.url:""
             this.showImage=true
             let  updatedProd={
               id:res.data.id,
               libelle_article:res.data.attributes.libelle_article,
               reference:res.data.attributes.reference,
               code_article_generique:res.data.attributes.code_article_generique,
               prix_vente:res.data.attributes.prix_vente,
               fournisseur:res.data?.attributes?.fournisseur?.data,
               image:  res.data.attributes.image?.data!=null? "https://prefacture.rosace.com.tn"+res.data.attributes.image.data.attributes.formats.small.url:"",
               variations:res.data.attributes.variations
             }
           console.log(updatedProd)
           this.prodlist=this.prodlist.filter(prod=>prod.id != updatedProd.id)
           this.prodlist.unshift(updatedProd)
           console.log(this.prodlist)
          this.updatedListOfProd.emit(this.prodlist)
          //  this.idProd=null
           // this.hideDialog()
           this.notifyParent.emit(1);
           this.disableSaveButton=true;
 
           })
           .catch((error)=> {console.log('test')
           this.notifyParent.emit(-2);
      
          })
         
          
          }
          catch(error){}    
 } 
  }
  async setDataToSend(data){
    this.dataToSend=data
  }
 
 async onFournisseurSelect(event){
 console.log('Selected fournisseur:', event)
 const fourniss=event.value
 this.Value=fourniss
//  this.productData.fourniss=event.value
 //console.log(event)
 //this.s.sendData(fourniss)
   if(fourniss!=this.selectedFourniss)
 {
   this.disableSaveButton=false
 }
  }
  async  addvariation(){
   //  this.dataToSend={"idProd":this.idProd,"code":this.productData.code,"prodVariations":this.productData.variations}
//await this.setDataToSend({"idProd":this.idProd,"code":this.productData.code,"prodVariations":this.productData.variations})
 
   await  this.myDialog.showDialog(null,{"idProd":this.idProd,"code":this.productData.code,"prodVariations":this.productData.variations})

   }
   async getVariationsOfProd(idprod){
    this.s.getVariationsOfProd(idprod).then(async (res)=>{
      console.log("variation of prod",res)
    })
   }
   async editVariation2(variation){
    console.log(this.productData)
    console.log(variation)
    this.editVariation=variation
    
     await this.myDialog.showDialog(this.editVariation,  {"idProd":this.idProd,"code":this.productData.code,"prodVariations":this.productData.variations})
   }
   deleteVariation(variation){
    console.log(variation)
    // const variat={
    //   code_article_generique:  variation.attributes.code_article_generique,
    
    // code_barre: variation.attributes.code_barre,
    // color: {id:variation.attributes.color.data.id , name: variation.attributes.color.data.attributes.name},
    // id: variation.id,
    // taille:{id:variation.attributes.taille.data.id , name: variation.attributes.taille.data.attributes.name},
    // }
    this.myDeleteDialog.showDialog({"variationId":variation.id})
   }
}
