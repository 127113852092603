import { Component, OnInit , ViewChild  } from '@angular/core';
import { ClientService } from 'src/app/demo/service/Client/client-service.service';
import { Message } from 'primeng/api';
import { Client } from 'src/app/models/client';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms'; 
import { ClienttansferService } from 'src/app/demo/service/Client/clienttansfer.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  //-------------------Déclaration-------------------//

  modifyClient : Client ;
  clientForm: FormGroup;
  name = new FormControl('', [Validators.required]);
  code = new FormControl('', [Validators.required]);
  matricule_fiscale = new FormControl('' , [Validators.required]);
  adresse = new FormControl('', [Validators.required]);
  tel = new FormControl('', [Validators.minLength(8), Validators.maxLength(8)]);
  visible = false;
  show = false;
  message = '';
  msgs: Message[] = [];
  showRequiredMsg = false;
  loading : boolean = false ;
  oldClient : Client ;


  //-------------------constructor and init -------------------//

  constructor(private formBuilder: FormBuilder, private clientService: ClientService , private clientUpdateService: ClienttansferService) {
    this.clientForm = this.formBuilder.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      matricule_fiscale: [null, Validators.required],
      adresse: [null, Validators.required],
      telephone: [null, [Validators.minLength(8), Validators.maxLength(8)]]
    });
  }

  ngOnInit(): void {

  }
    //-------------------create and update client -------------------//

  async createClient() {
    this.loading = true ;
    if (this.clientForm.valid) {
      const updatedClient: any = {
        "data": {
          "name": this.clientForm.get('name').value,
          "code": this.clientForm.get('code').value,
          "adresse": this.clientForm.get('adresse').value,
          "telephone": this.clientForm.get('telephone').value || null,
          "matricule_fiscale":this.clientForm.get('matricule_fiscale').value || null
        }
      };
      console.log("telephone",updatedClient.telephone)
      const id = this.modifyClient?.id;
      if (id) {
        if (this.isClientModified(updatedClient)) {
          console.log("Client modifier",updatedClient)
          console.log("Client modifier?",this.isClientModified(updatedClient))
        try {
          await this.clientService.updateClientById(id, updatedClient);
          console.log('Client updated successfully');
          this.clientUpdateService.notifyClientAdded();
          this.close();
        } catch (error) {
          this.showErrorMessage('Verifiez votre internet ou vos données');
          console.error('Error updating client', error);
        }
      }
      else {
        this.showErrorMessage('au moins une valeur doit etre changer ');

      }
      } else {
        try {
          const response = await this.clientService.postClient(updatedClient);
          this.clientUpdateService.notifyClientAdded();
          console.log('Client posted successfully', response);
          this.close()
        } catch (error) {
          this.showErrorMessage('Verifiez votre internet ou vos données');
          console.error('Error posting client', error);
        }
      }
    } else {
      this.showErrorMessage('Veuillez remplir tous les champs obligatoires.');
    }
    this.loading = false ;
  }

  private isClientModified(updatedClient: any): boolean {
    // Compare the updatedClient with this.oldClient
    return (
      updatedClient.data.name !== this.oldClient.name ||
      updatedClient.data.code !== this.oldClient.code ||
      updatedClient.data.adresse !== this.oldClient.adresse ||
      updatedClient.data.telephone !== this.oldClient.telephone ||
      updatedClient.data.matricule_fiscale !== this.oldClient.matricule_fiscale
    );
  }
  //------------------- Form and dialog parameters -------------------//

  showErrorMessage(message: string): void {
    this.msgs = [{ severity: 'error', detail: message }];
  }


  showdialog(client?: Client): void {
    this.clientForm.reset();
    this.visible = true;
    if (client) {
      this.modifyClient = client;
      // Assuming modifyClient has properties like 'name', 'code', 'adresse', 'telephone'
      this.clientForm.patchValue({
        name: this.modifyClient.name  || '',
        code: this.modifyClient.code  || '',
        matricule_fiscale: this.modifyClient.matricule_fiscale  || '',
        adresse: this.modifyClient.adresse  || '',
        telephone: this.modifyClient.telephone || '',
      });
    }
    this.oldClient={...this.modifyClient}
    console.log("oldClient",this.oldClient);
  }

  close(): void {
    this.visible = false;
    this.clientForm.reset();
    this.modifyClient= { 
      id:null,
       name:'',
    code:'',
    matricule_fiscale: '',
    adresse:  '',
    telephone:  '',
    selected: false,
} ;  
    this.oldClient= {
      id:null,
      name:'',
   code:'',
   matricule_fiscale: '',
   adresse:  '',
   telephone:  '',
   selected: false,
    } ;  
  }

}

  //----------------- more ... -------------------- // 




