<div class="grid">
	<div class="col-12">
		<div class="card">

			<!--------------------Consultation Tickets PAGE---------------------->

            <h5>Consultation Factures</h5>
			<div class="container">

 	<!------------------------------------------  Filtres et buttons  -------------------------------------------------------->
                
                <p-multiSelect
                [(ngModel)]="selectedEtablissement"
                [options]="Etablissements"
                (onChange)="onEtablissementChange($event)"
                placeholder="Etablissements"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <div *ngIf="selectedEtablissement && selectedEtablissement.length > 0">
                <p-multiSelect [(ngModel)]="selectedStore"
                [options]="Depots"
                (onChange)="onStoreChange($event)"
                placeholder="Depot"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '10px' , 'max-width' : '150px','min-width' : '150px'}">
                </p-multiSelect>
                </div>
                <p-multiSelect
                [(ngModel)]="selectedClient"
                [options]="Clients"
                (onChange)="onClientChange($event)"
                placeholder="Client"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '10px', 'max-width' : '150px' ,'min-width' : '150px'}">
                </p-multiSelect>
                <p-multiSelect
                [(ngModel)]="selectedArticle"
                [options]="articles"
                (onChange)="onArticleChange($event)"
                placeholder="Article"
                optionLabel="code_article_generique"
                [filter]="true"
                [style]="{ 'margin-right': '10px' , 'max-width' : '150px','min-width' : '150px'}">
                </p-multiSelect>

                <p-calendar [touchUI]="true" [(ngModel)]="date"
                selectionMode="range"
                placeholder="Date"
                [readonlyInput]="true"></p-calendar>


				<button pButton pRipple type="button" 
                        icon="fa fa-filter bi bi-filter" 
                        label="Filtrer" 
                        class="p-button-success mr-0.5 mb-"
                        style="margin-right: 10px;"
                        [iconPos]="isPageMinimized() ? 'right' : 'left'"
                        (click)="Filters()">
                </button>

                <button pButton pRipple type="button" 
                            icon="bi bi-arrow-clockwise" 
                            label="Refresher" 
                            class="btn btn-primary mr-0.5 mb-" 
                            style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
                            [iconPos]="isPageMinimized() ? 'right' : 'left'"
                            (click)="resetFilters()">
                </button>
            </div>

	  <!------------------------------------------------------------------------------------------------------------------------>
    <!------------------------------------------------------- Table ---------------------------------------------------------->

    <p-table
    [lazy]="true"
    [loading]="loading"
    [showCurrentPageReport]="true"
    [value]="factureitems"
    responsiveLayout="stack"
    scrollHeight="flex"
    [paginator]="false"
    [rowHover]="true"
    [rows]="5"
    dataKey="id"
    responsive="false"
    sortMode="single"
    (onLazyLoad)="onLazyLoad($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th  pSortableColumn="numero_facture">Numero Ticket <p-sortIcon field="numero_facture"></p-sortIcon></th>
        <th pSortableColumn="client">Client <p-sortIcon field="client"></p-sortIcon></th>
        <th pSortableColumn="date_facture">Date <p-sortIcon field="date_facture"></p-sortIcon></th>
        <th pSortableColumn="store">Depot <p-sortIcon field="store"></p-sortIcon></th>
        <th pSortableColumn="netpaye">Net Paye <p-sortIcon field="netpaye"></p-sortIcon></th>
        <th style="width:4%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-facture let-rowIndex="rowIndex">
      <tr>
        <td>{{ facture.numero_facture }}</td>
        <td>{{ facture.client }}</td>
        <td>{{ facture.date_facture }}</td>
        <td>{{ facture.store }}</td>
        <td>{{ facture.netpaye | roundToThreeDecimals}}</td>
        <td> <button type="button" pButton pRipple icon="bi bi-eye" (click)="showfacture(facture.id)"></button> </td>
        <!-- <td> <button type="button" pButton pRipple icon="bi bi-printer" (click)="printfacture(facture.id)"></button> </td> -->
      </tr>
    </ng-template>
  </p-table>
      <p-paginator
        [totalRecords]="totalRecords"
        [rows]="size"
        [rowsPerPageOptions]="[ 5,10, 25, 50]"
        [pageLinkSize]="totalPages"
        (onPageChange)="onPageChangePaginator($event)"
        [alwaysShow]="true"
      ></p-paginator>


            <div class="card" style="display: flex; justify-content: space-between; align-items: center;">

                <div style="flex-grow: 1;">

            </div>
		</div>
		<div class="card flex justify-content-center"></div>
        <!------------------------------------------------------------------------------------------------------------------------>
</div></div>
<!-- <app-details-ticket ></app-details-ticket> -->

<app-facture-ticket-pdf></app-facture-ticket-pdf>

