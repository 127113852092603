import { Component, EventEmitter, OnInit ,Output} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ArticleService } from 'src/app/demo/service/article.service';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-add-transfert',
  templateUrl: './add-transfert.component.html',
  styleUrls: ['./add-transfert.component.scss']
})
export class AddTransfertComponent implements OnInit {
  @Output() NewTransfertToParent = new EventEmitter<any>();

  venteDialog
  articles
  filteredArticle
  selectedArticle
  variationsArticle
  ColorsArray= []
  TailleArray= []
  cellValue
  TransferedVariation:any[]=[]
  emptyarticle:boolean=false

  
test = [
  { id: 'Q35', Marron: '', Jaune: '' },
  // { id: 'Q36', Marron: '', Jaune: '' },
  // Add more data as needed
];
  constructor(private ArticleService:ArticleService,private messageService: MessageService) { }

  ngOnInit(): void {
   this.getArticles() 
  }
  async showDialog(data?){
    this.venteDialog=true

  }
  onKeyPress(event: any) {
    console.log(event)
    const pattern = /^(?!0)[0-9]+$/; 
    const existingContent = event.target.innerText;
    const keyPressed = event.key;
  
    const inputValue = existingContent + keyPressed;
  
    if (!pattern.test(inputValue)) {
      console.log('Invalid value:', inputValue);
        event.preventDefault();
  
    } else {
      console.log('Valid value:', inputValue);
    }
  
  }
  hide(){
    this.venteDialog=false
    this.selectedArticle=''
    this.TransferedVariation=[]
    this.emptyarticle=false

  }
  hideDialog() {
    if(this.selectedArticle){
      if(this.TransferedVariation.length>0){
        console.log("send transfert variation to parent",this.TransferedVariation);
        this.NewTransfertToParent.emit(this.TransferedVariation)
        this.venteDialog=false
        this.selectedArticle=''
        this.TransferedVariation=[]
      }
      else{
        if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){

        this.messageService.add({
           severity: 'error', 
           summary: 'Error',
           detail: 'Ajouter Quantité  ' });
        }

      }
     
    }
    else{
      this.emptyarticle=true

    }

  }

  
  getwithkeyup(event){
    console.log("key press article", event.target.value)
    console.log(this.articles)
    const matchingArticle = this.articles.find(article => article.code_article_generique === event.target.value);
    
      if (matchingArticle) {
    this.selectedArticle=matchingArticle
      } else {
    
        this.TransferedVariation=[]
        this.variationsArticle = [];
        this.ColorsArray = [];
        this.TailleArray = [];
     
    
      }
      }
  async getArticles(){
    await this.ArticleService.getArticles().then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      
      })
      console.log(this.articles)
     
  }
 
  async onInputArticle(event){
    console.log("articles writed",event.target.value)
  await this.ArticleService.getArticlesWithContain(event.target.value).then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      
      })
      console.log("articles",this.articles)
     
  }
  filterArticle(event: AutoCompleteCompleteEvent) {

  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.articles as any[]).length; i++) {
      let country = (this.articles as any[])[i];
      if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filteredArticle = filtered;
}
getSelectedArticles(event) {
  this.TransferedVariation=[]
  console.log('Selected Article:', event);
  this.selectedArticle=event
  if (this.selectedArticle) {
      
   

    this.variationsArticle = this.selectedArticle.variations.map(item =>
      ({
        id: item.id,
        color: {
          colorName: item.attributes.color.data.attributes.name,
          colorId: item.attributes.color.data.id
        },
        taille: {
          tailleName: item.attributes.taille.data.attributes.name,
          TailleId: item.attributes.taille.data.id
        }
      }));
 console.log("variation article", this.variationsArticle)
 this.getDistinctColorsAndTailles();

}
}
getDistinctColorsAndTailles() {
  
  const uniqueColors = new Set<string>();
  const uniqueTailles = new Set<string>();

  this.variationsArticle.forEach(item => {
    uniqueColors.add(item.color.colorName);
    uniqueTailles.add(item.taille.tailleName);
  });

  this.ColorsArray = Array.from(uniqueColors).map(colorName => ( colorName ));
  this.TailleArray = Array.from(uniqueTailles).sort((a, b) => a.localeCompare(b));
  ;


  console.log(this.ColorsArray)
  console.log(this.TailleArray)

}
// onKeyPress(event: any) {
//   const pattern = /[0-9]/;
//   const inputChar = String.fromCharCode(event.charCode);

//   if (!pattern.test(inputChar)) {
//     event.preventDefault();
//   }
  
// }
isDisabled(size, color): boolean {
  // console.log(size,color)

  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  if(variation){

    return false
  }
  else {

    return true}
  // console.log(variation)
  // return variation !== undefined;
}
findVariationId(size, color): any | undefined {
  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  return variation ? variation.id : undefined;
}
removeSavedValue(size: string, color: string) {
  const indexToRemove = this.TransferedVariation.findIndex(item =>
    item.Size === size && item.Color === color
  );
  
  if (indexToRemove !== -1) {
    this.TransferedVariation.splice(indexToRemove, 1);
  }
}

onCellInput(event: any, color: any, size: any ,i: number, j: number) {
  this.cellValue=0
  const idVariation=this.findVariationId(size,color)
  const cellValue = +event.target.innerText.trim();
  this.cellValue=cellValue
  console.log({'QTE': cellValue,'ID':idVariation,'Color':color,'Size': size});

  if (cellValue === 0) {
  
    return;
  }
  const newItem={'QTE': cellValue,'ID':idVariation,'Color':color,'Size': size,'article':this.selectedArticle}
  
  this.removeSavedValue(size, color);
  this.TransferedVariation.push(newItem);

    console.log("transfert variation",this.TransferedVariation);

}
saveTransfert(){
  if(this.selectedArticle){
    if(this.TransferedVariation.length>0){

    console.log("send transfert variation to parent",this.TransferedVariation);
    this.NewTransfertToParent.emit(this.TransferedVariation)
    this.selectedArticle=''
    this.TransferedVariation=[]
    }
    else{
      if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){

      this.messageService.add({
         severity: 'error', 
         summary: 'Error',
         detail: 'Ajouter Quantité  ' });
      }

    }
  }
  else{
    this.emptyarticle=true

  }

}
}