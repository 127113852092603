<div class="grid">
	<div class="col-12">
		<div class="card">

			<!--------------------Consultation Tickets PAGE---------------------->

            <h5>Consultation Clients</h5>

            <!--------------------Tableau Client ---------------------->


            <p-table
            [value]="clients"
            [tableStyle]="{'min-width': '60rem'}"
            [rows]="5"
            sortMode="single"
            responsiveLayout="stack"
            scrollHeight="flex"
            [totalRecords]="totalRecords"
            [showCurrentPageReport]="true"
            dataKey="id"
            [paginator]="false"
            [lazy]="true"
            [loading]="loading"
            (onLazyLoad)="onLazyLoad($event)"
            >

                  <!-------------------- Buttons et recherche ---------------------->

                <ng-template pTemplate="caption">
                    <button
                    pButton pRipple type="button"
                    icon="pi pi-plus"
                    label="Ajouter "
                    class="p-button-success mr-2 mb-2"
                    style="width: 100px;"
                    (click)="showdialog()">
                    </button>
                    <button
                    pButton
                    pRipple type="button"
                    icon="pi pi-trash"label="Supprimer "
                    class="p-button-danger mr-2 mb-2 "
                    style="width: 110px;  background-color: #EF4444;"
                    (click)="deleteClient()"
                    [disabled]="isAnyClientSelected()">
                    </button>
                    <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search"></i>
                    <input
                    pInputText
                    type="text"
                    [(ngModel)]="filter"
                    (input)="searchClients()"
                    placeholder="Rechercher"
                    class="w-full"
                    style="height: 35px;"
                  />
                   </span>
               </ng-template>

               <!-------------------- Columns ---------------------->


                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <div class="field-checkbox">
                              <p-checkbox [binary]="true" [(ngModel)]="selectAllCheckbox"
                              (onChange)="onSelectAllCheckboxChange()"></p-checkbox>
                            </div>
                        </th>
                        <th pSortableColumn="code" style="width:20%">Code <p-sortIcon field="code"></p-sortIcon></th>
                        <th pSortableColumn="name" style="width:20%">Name <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="matricule_fiscale" style="width:20%">Matricule Fiscale <p-sortIcon field="matricule_fiscale"></p-sortIcon></th>
                        <th pSortableColumn="adresse" style="width:40%">Adresse <p-sortIcon field="adresse"></p-sortIcon></th>
                        <th pSortableColumn="telephone" style="width:20%">Telephone <p-sortIcon field="telephone"></p-sortIcon></th>
                        <th ></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-client>
                    <tr>
                    <tr>
                        <td>
                            <div class="field-checkbox">
                              <p-checkbox [binary]="true" [(ngModel)]="client.selected"></p-checkbox>
                            </div>
                        </td>
                        <td>{{ client.code }}</td>
                        <td>{{ client.name }}</td>
                        <td>{{ client.matricule_fiscale }}</td>
                        <td>{{ client.adresse }}</td>
                        <td>{{ client.telephone }}</td>
                        <td><button  pButton pRipple type="button"
                            icon="pi pi-pencil"
                            label="Modifier "
                            class="p-button mr-2 mb-2"
                            style="background-color: #3B82F6;width:100px;"
                            (click)="showdialog(client)">
                        </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

                <!-------------------- Paginator ---------------------->
            
                <p-paginator
                [totalRecords]="totalRecords"
                [rows]="size"
                [rowsPerPageOptions]="[5, 10, 25, 50]"
                [pageLinkSize]="totalPages"
                [alwaysShow]="true"
                (onPageChange)="onPageChange($event)"
              ></p-paginator>

        </div>
        <!------------------------------------------------------------------------------------------------------------------------>
        <app-add-client></app-add-client>
</div></div>
