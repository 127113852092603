import { Component, OnInit , ViewChild , OnDestroy } from '@angular/core';
import { ClientService } from 'src/app/demo/service/Client/client-service.service';
import { Client } from 'src/app/models/client';
import { LazyLoadEvent } from 'primeng/api';
import {AddClientComponent} from 'src/app/pages/ClientGestion/add-client/add-client.component' ;
import { ClienttansferService } from 'src/app/demo/service/Client/clienttansfer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-client-gestion',
  templateUrl: './client-gestion.component.html',
  styleUrls: ['./client-gestion.component.scss']
})
export class ClientGestionComponent implements OnInit {
  @ViewChild(AddClientComponent) AddClient:AddClientComponent;

  //--------------------- Declaration ----------------------------//
  clients: Client[] = [];
  totalRecords: number;
  page: number = 1; 
  size: number = 5;
  sortField: string | undefined;
  sortOrder: number | undefined;
  loading = false ;
  filter
  ids : number[] = [];
  selectAllCheckbox: boolean = false;
  
 //-------------------- Constructor and Init --------------------//
  constructor(private clientService: ClientService , private clientUpdateService: ClienttansferService) { }
  private unsubscribe$ = new Subject<void>();
  ngOnInit(): void {
    // this.getClients();
    this.clientUpdateService.clientAdded$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.getClients();
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

//---------------------- CRUD Client -------------------------- //

  async getClients() {
    this. loading = true ; 

      // Initialize sortField and sortOrder
  // this.sortField = this.sortField ?? 'id';
  // this.sortOrder = this.sortOrder !== undefined ? this.sortOrder : -1;

    await this.clientService.getClients(this.page , this.size ,this.sortField , this.sortOrder , this.filter).then((clients) => {
      this.clients = clients;
      this.totalRecords =  this.clientService.total
    });
    this.loading = false ; 
    console.log(this.clients[0].selected)
    console.log("clietns",this.clients)

  }

  searchClients(): void {
    this.getClients()
  }

  deleteClient(): void {
    this.loading = true ;
    this.clients.forEach(async (client) => {
      if (client.selected) {
        try {
          await this.clientService.deleteClientById(client.id);
          console.log(`Client with ID ${client.id} deleted successfully`);
          this.getClients()

        } catch (error) {
          console.error(`Error deleting client with ID ${client.id}`, error);
          // Handle the error as needed
        }
      }
    });
    this.getClients()
    this.loading = false ;
  }

//-------------------- paginator sort et checks --------------------------//

    onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log(event,this.sortField,this.sortOrder)
    this.getClients();
    }

    onSelectAllCheckboxChange() {
      this.clients.forEach((client) => (client.selected = this.selectAllCheckbox));
    }
    isAnyClientSelected(): boolean {
      return !this.clients || this.clients.every(client => !client.selected);
    }

    get totalPages(): number {
      return Math.ceil(this.totalRecords / this.size);
    }

    onPageChange(event: any): void {
      this.page = event.page + 1;
      this.size = event.rows;
      this.getClients();
    }

    //------------------- add dialog ---------------- //
    showdialog(client? : Client){
      this.AddClient.showdialog(client)
    }


}
