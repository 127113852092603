<!-- details-achats.component.html -->
<p-dialog header="Details Achats" [(visible)]="detailsAchatDialog" [style]="{ width: '70%' }" [modal]="true">
  <div *ngIf="achat" class="details">


      <div class="details-container">
        <!-- Container 1 -->
        <div class="container">
          <div class="title"><strong>Fournisseur:</strong></div>
          <div>{{achat.fournisseur.name}}</div>
          <div class="title"><strong>Code Fournisseur :</strong></div>
          <div>{{achat.fournisseur.code}}</div>
          <!-- <div class="title"><strong>Addresse:</strong></div>
          <div>{{ ClientInfo.address }}</div>
          <div class="title"><strong>Numéro :</strong></div>
          <div>{{ ClientInfo.phone }}</div> -->
        </div>
      </div>
  
      <!-- Other Details Container -->
      <div class="details-container">
        <!-- Container 1 -->
        <div class="container">
          <div class="title"><strong>Etablissement:</strong></div>
          <div>{{ achat.etablissement }}</div>
          <div class="title"><strong>Store:</strong></div>
          <div>{{ achat.depot }}</div>
          <div class="title"><strong>Date Achat:</strong></div>
          <div>{{ achat.date }}</div>
        </div>
      </div>
      
      <div class="details-container">
        <!-- Container 2 -->
        <div class="container">
          <div class="title"><strong>Pourcentage Remise:</strong></div>
          <div>{{achat.pourcentage_remise}}<span style="margin-right: 5px;"> %</span></div>
  
          <div class="title"><strong>Remise:</strong></div>
          <div *ngIf="achat?.remise !== undefined && achat?.remise !== null && !isNaN(achat?.remise)">

          <div>{{ achat?.remise | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span></div>
          </div>
          <div *ngIf="achat?.remise === undefined || achat?.remise === null || isNaN(achat?.remise)">
            0<span style="margin-right: 5px;"> TND</span>
          </div>
        </div>
      </div>
      
      <div class="details-container">
        <!-- Container 3 -->
        <div class="container">
          <div class="title"><strong>HT:</strong></div>
          <div>{{ achat.ht | roundToThreeDecimals }}<span style="margin-right: 5px;"> TND</span></div>
  
          <div class="title"><strong>Taxes:</strong></div>
          <div>{{ achat.taxes | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span></div>
  
          <div class="title"><strong>TTC:</strong></div>
          <div>{{ achat.ttc | roundToThreeDecimals }}<span style="margin-right: 5px;"> TND</span></div>
        </div>
      </div>
      
      <div class="details-container">
        <!-- Container 4 -->
        <div class="container">
          <div class="title" *ngIf=" achat.total_fodec>0"><strong>Total Fodec:</strong></div>
          <div *ngIf=" achat.total_fodec>0">{{ achat.total_fodec | roundToThreeDecimals  }}<span style="margin-right: 5px;" *ngIf=" achat.total_fodec>0"> TND</span></div>
          <div class="title" *ngIf=" achat.timbre>0"><strong>Timbre:</strong></div>
          <div *ngIf=" achat.timbre>0">{{ achat.timbre | roundToThreeDecimals}}<span style="margin-right: 5px;" *ngIf=" achat.timbre>0"> TND</span></div>
          <div class="title"><strong>Prix Net:</strong></div>
          <div>{{ achat.prixnet | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span></div>
        </div>
      </div>
    </div>

    <p-table
    [value]="achat?.achatsVariations"
    responsiveLayout="stack" 
    scrollHeight="flex"
    [paginator]="true"
    [rows]="5"
    [rowsPerPageOptions]="[5,10, 25, 50]"
    dataKey="id"
    >
    <ng-template pTemplate="header">
    <tr>
    <th>code_article_generique</th>
    <th>quantité</th>
    <th>remise</th>
    <th>prix_ht</th>
    <th>tva</th>
    <th>prix_TTc</th>
    <!-- <th pSortableColumn="Prix" style="width:20%">Prix <p-sortIcon field="Prix"></p-sortIcon></th> -->
    
    </tr>
    </ng-template>
    <ng-template pTemplate="body" let-achat let-rowIndex="rowIndex">
    <tr>
    
    <td >{{ achat.variation.code_article_generique }}</td>
    <td >{{ achat.quantite }}</td>
    <td >{{ achat.remise }}</td>
    <td>{{ achat.prixht | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span></td>
    <td>{{ achat.tva }}</td>
    <td>{{ achat.prixttc | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span></td>
    
    </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
    </ng-template>
    <ng-template pTemplate="paginatorright">
    </ng-template>
    </p-table>
    
</p-dialog>
