export class Client {
    id: number;
    name?: string;
    code?: string;
    matricule_fiscale?: string;
    adresse?: string;
    telephone?: string;
    selected : boolean = false ;


    constructor(id?: number, name?: string, code?: string,    matricule_fiscale?: string,      adresse?: string, telephone?: string 
        ) {
      this.id = id;
      this.name = name;
      this.code = code;
      this.matricule_fiscale = matricule_fiscale;
      this.adresse = adresse;
      this.telephone = telephone;
    }

    static fromApiResponse(apiResponse: any): Client {
      return new Client(
        apiResponse.id,
        apiResponse.attributes.name,
        apiResponse.attributes.code,
        apiResponse.attributes.matricule_fiscale,
        apiResponse.attributes.adresse,
        apiResponse.attributes.telephone,  
      );
    }
  }