<p-dialog [(visible)]="variationdialog" [style]="{width: '450px',height:'auto'}" header="Ajouter variation de produit" [modal]="true" class="p-fluid">


           

    <ng-template pTemplate="content" >


        <div class="field">
            <label for="code">code_article_generique</label>
            <input type="text"    [disabled]="true"  pInputText id="code_article_generique" [(ngModel)]="code" required  [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
            <small class="ng-dirty ng-invalid" *ngIf="submitted && !code">Name is required.</small>
        </div>

        <!-- <div class="field">
            <label for="code_barre">code_barre</label>
            <input type="text" pInputText id="code_barre" [(ngModel)]="codebarre" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.name}"/>
            <small class="ng-dirty ng-invalid" *ngIf="submitted && !code_barre">Name is required.</small>
        </div> -->

        <div class="field">
            <label for="taille">taille</label>
            <p-dropdown [options]="taille"  [(ngModel)]="selectedTaille"   placeholder="Select a size" appendTo="body" [showClear]="true"></p-dropdown>
        </div>
        <div class="field">
            <label for="code_barre">couleur</label>
         
         
            <p-dropdown [options]="couleurs" [(ngModel)]="selectedColor"  placeholder="Select a color"appendTo="body" [showClear]="true"></p-dropdown>
        </div>


        
    </ng-template>

<ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialogV()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveVariations()" ></button>

      
    </ng-template>

  
    <p-toast></p-toast>

</p-dialog>
