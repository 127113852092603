import { Component, EventEmitter, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FournisseurService } from '../demo/service/fournisseur.service';
import { FormBuilder,  FormControl,  FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NgZone } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { AddFournisseurComponent } from './fournisseur/add-fournisseur/add-fournisseur.component';
//import { ViewChild } from 'node_modules-/@angular/core';
// import { MatDialog } from '@angular/material';
import { DialogService } from '../demo/service/dialog.service';

@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  // standalone:true,
  // imports:[AddFournisseurComponent],
  styles: [`  
.field {
  display: inline-block;
  width: 48%; /* Adjust the width as needed */
  margin: 10px 1%; /* Adjust the margin as needed */
  box-sizing: border-box;
}

.field label {
  display: block;
  margin-bottom: 5px;
}

.field input {
  width: 100%;
  padding: 5px;
}
.required {
  color: red;
}
.bi.bi-exclamation-circle {
  color: red; /* Customize the color as needed */
  /* position: absolute;
  right: 10px;
  top: 50%; */
  /* transform: translateY(-50%); */
  cursor: pointer;
}
.icon {
  display: flex;
}
.input-container {
    position: relative;
    width: 100%;
}
.bi {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px; /* Adjust as needed */
   
}
.field .text-danger {
  color: red;
  font-size: 12px; /* Adjust as needed */
  position: absolute;
  bottom: 30;

}
`]
})
export class FournisseurComponent implements OnInit {
     @ViewChildren(AddFournisseurComponent)   myDialog:QueryList< AddFournisseurComponent>;

  fourniss
  position: string;
  visible: boolean;
  submitted=false
  dataToSend
    affiche:boolean=false
fournissDialog
listFournissToSend
currentPage: number=1;
pageSize: number;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
loading
  cols = [
    { field: 'name', header: "Nom" },
    { field: 'code', header: 'Code' },
    { field: 'adresse', header: 'Adresse' },
    { field: 'tel', header: 'Téléphone' },
    { field: 'mf', header: 'Matricule fiscale ' },
    { field: 'date', header: 'Date de création' },
  
    
    
];

   constructor(private service:FournisseurService,
    private messageService: MessageService,public dialogService: DialogService,) {
      this.loading = true;
     }
    
      ngAfterViewInit() {
       
      console.log('on after view init', this.myDialog.toArray());
      this.listFournissToSend=this.fourniss

      //this.myDialog.forEach(comp=>{console.log(comp )})
   // this.myDialog.changes.subscribe((comp :QueryList< AddFournisseurComponent>) =>{console.log(comp.first)
   // this.fournissDialog=comp.first
   // })

      // console.log(component.showDialog('top'))
     
    //})
    } 
 async  ngOnInit() {
// await this.openDialog()
  //  this.openDialog()
    // this.dataToSend=false
    // this.handleData()
    this.onPageChange({ page: 0 });
 // await this.getfourniss()
  }
  handleData(data){
    console.log(data)
  }
  
   async openDialog() {
   // this.dataToSend=true
    //this.dialogService.setVisible(true)
    this.affiche=true
   console.log( this.myDialog)
   this.myDialog.toArray()[0].showDialog('top',true)
  // this.fournissDialog.showDialog('top',true)
   //await this.myDialog.showDialog('top') 
   }
    
  
   async getfourniss() {
    try {
      this.loading = true; // Set loading to true before making the request
  
      const data = await this.service.getFournisseur();
      console.log("data", data);
  
      this.fourniss = data.data.map(x => ({
        id: x.id,
        name: x.attributes.name,
        code: x.attributes.code,
        adresse: x.attributes.adresse,
        tel: x.attributes.telephone,
        mf: x.attributes.mf,
        date: new Date(x.attributes.createdAt).toLocaleDateString()
      }));
  
      this.paginationInfo = data.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  
      this.listFournissToSend = this.fourniss;
  
      console.log(this.fourniss);
      console.log("pagination info", this.paginationInfo);
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
  }
  
  
 
  editFournisseur(rowdata){
  this.dataToSend=rowdata.id
console.log(rowdata)
this.myDialog.toArray()[0].showDialog('top',true,rowdata)
  }
  async onPageChange(event) {
  /*  this.loading=true
    setTimeout(() => {
      // this.getProduct()
            this.loading = false;
            
        }, 5000);
  */
    console.log(event)
    this.currentPage=event.page
    console.log(this.currentPage)
 await this.service.sendData(this.currentPage+1)
   await this.getfourniss()
  //  await this.myDialog.toArray()[0].setFournissList(this.fourniss)

   
   }
}
