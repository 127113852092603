import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }
  private showDialogSource = new BehaviorSubject <boolean>(false);
  showDialog$ = this.showDialogSource.asObservable();

  showDialog(position: string) {
    this.showDialogSource.next(true);
    // Additional logic for position, if needed
  }
visible:boolean 
setVisible(data){
this.visible=data
}
getVisible():boolean{
  return this.visible
}

}
